<template>
  <!-- 批次库存 -->
  <!-- :totalField="['InvQty','TotalCostAmount']"-->
  <div class="module-wrapper">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      v-loading="loading"
      :columns="columns"
      :drag-done="onDragDone"
      :header-menus="getResetTableHeaderMenu('StkStock')"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-index="pageIndex"
      :page-size="pageSize"
      :search-fields="onSearch"
      :show-title-menus="true"
      :show-table-body-menus="false"
      :showCheck="true"
      :total-count="totalCount"
      checkboxBindingKey="StkGUID"
      element-loading-text="数据加载中,请稍后..."
      gridtype="action"
      @select-change="selectChange"
      @on-refresh-click="onSearch"
      @bookedQty-click="bookedQtyCellClick"
      @transitQty-click="transitQtyCellClick"
      @on-show-trigger="onHideShowColTrigger"
      @drag-col-position="
        (val) => {
          onSaveConfig(val, true);
        }
      "
      @on-config-save="
        (val) => {
          onSaveConfig(val, false);
        }
      "
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-button
          v-if="hasRes('StkOutAdd')"
          :width="74"
          class="minor-self-btn"
          style-type="mac"
          @click="stkOutAddRows()"
        >
          <i class="iconfont icon-sell-wh_btn_ic"></i>
          <span>销售出库</span>
        </znl-button>

        <znl-button
          v-if="hasRes('StkInAdd')"
          :width="74"
          class="minor-self-btn"
          style-type="mac"
          @click="stkInAddRows()"
        >
          <i class="iconfont icon-buy-wh_btn_ic"></i>
          <span>采购入库</span>
        </znl-button>

        <znl-button
          v-if="hasRes('Quote')"
          :width="50"
          class="minor-self-btn"
          style-type="mac"
          @click="quotedPrice()"
        >
          <i class="iconfont icon-edit_btn_ic"></i>
          <span>报价</span>
        </znl-button>

        <znl-button
          v-if="hasRes('Inquiry')"
          :width="50"
          class="minor-self-btn"
          style-type="mac"
          @click="onBatchStkInquiry()"
        >
          <i class="iconfont icon-edit_btn_ic"></i>
          <span>询价</span>
        </znl-button>

        <znl-button
          class="minor-self-btn"
          style-type="mac"
          @click="onMarketSearch()"
        >
          <i class="iconfont icon-search_ic"></i>
          <span>市场查货</span>
        </znl-button>

        <znl-button
          v-if="hasRes('StkTransfer')"
          style-type="mac"
          @click="onStkTransfer()"
        >
          <i class="iconfont icon-show_btn_ic"></i>
          <span>库存调拨</span>
        </znl-button>

        <znl-button
          v-if="hasRes('StkMerge')"
          style-type="mac"
          @click="onStkMerge()"
        >
          <i class="iconfont icon-change_btn_ic"></i>
          <span>库存合并</span>
        </znl-button>

        <znl-button
          v-if="hasRes('Quote')"
          style-type="mac"
          @click="onBatchStkSplit()"
        >
          <i class="iconfont icon-table_set_btn_n"></i>
          <span>库存拆分</span>
        </znl-button>

        <znl-button
          v-if="this.hasRes('StkSign')"
          style-type="mac"
          @click="onMarking()"
        >
          <i class="iconfont icon-float_edit_ic"></i>
          <span>打标记</span>
        </znl-button>

        <znl-float-list v-show="isShowMoreBtn(moreBtns)" :lists="moreBtns">
          <znl-button style-type="mac">
            <i class="iconfont icon-left_nav_show_btn"></i>
            <span>更多操作</span>
          </znl-button>
        </znl-float-list>

        <znl-button
          v-if="isShowBtnSave"
          class="save-self-btn"
          style-type="main"
          @click="onBatchSave()"
        >
          <i class="iconfont icon-save_btn_ic"></i>
          <span>保存修改</span>
        </znl-button>

        <a
          style="
            text-decoration: underline;
            margin-left: 3px;
            margin-right: 3px;
          "
          title="点击查看"
          @click="onCheckInfo()"
          >已选择 ({{ selectedRows.length }}) 条</a
        >

        <a
          style="text-decoration: underline; margin-left: 5px"
          title="清除选中条数"
          @click="onCleaSelectCount()"
          >清除</a
        >
      </div>
      <div slot="footer" class="v-table-footer-total">
        <el-row v-if="getSpecialResourceByCode('ViewBuyPrice')">
          <el-col>
            <span>
              <a
                style="
                  text-decoration: underline;
                  margin-left: 3px;
                  margin-right: 5px;
                "
                title="统计库存的总量 | 总成本 (采购未税价 * 库存量)"
                @click="onSumData()"
                >统计库存 (按当前搜索条件)</a
              >
            </span>
            <span v-show="this.isShowCost">
              <span>
                总数量:
                <span class="stsPurchaseIn-amount">{{ this.totalQty }}</span>
                <span>&nbsp;|&nbsp;</span>
              </span>
              <span>
                总成本:
                <span class="stsPurchaseIn-amount">{{ this.totalCost }}</span>
              </span>
            </span>
          </el-col>
        </el-row>
      </div>
    </znl-table>

    <!-- 销售出库 -->
    <stk-out-edit
      v-if="stkOutEditVisible"
      :editvisible="stkOutEditVisible"
      edit-type="edit"
      @close="stkOutEditVisible = false"
      @save-success="onRefresh(), onCleaSelectCount()"
    ></stk-out-edit>

    <!-- 采购入库 -->
    <stk-in-edit
      v-if="stkInEditVisible"
      :editvisible="stkInEditVisible"
      edit-type="edit"
      @close="stkInEditVisible = false"
      @confirm="stkInEditVisible = false"
      @save-success="onRefresh(), onCleaSelectCount()"
    ></stk-in-edit>

    <!-- 新增客户需求 -->
    <znl-bom-master-edit
      v-if="bomMasterDetailEditVisibleTwo"
      :adddata="bomMasterDetail"
      :editvisible="bomMasterDetailEditVisibleTwo"
      edit-type="addNew"
      title="新增客户需求"
      @close="bomMasterDetailEditVisibleTwo = false"
      @confirm="bomMasterDetailEditVisibleTwo = false"
    ></znl-bom-master-edit>

    <!--销售备货-->
    <stk-stock-reserve-add
      v-if="stkReserveAddVisible"
      ref="StkStockReserveAdd"
      :available-qty="availableQty"
      :is-show-lock="true"
      :stkGuid="stockGuid"
      :visible="stkReserveAddVisible"
      @close="
        () => {
          stkReserveAddVisible = false;
        }
      "
      @confirm="
        (val) => {
          val && (stkReserveAddVisible = false),
            this.onRefresh(),
            this.onCleaSelectCount();
        }
      "
    ></stk-stock-reserve-add>

    <!--采购订货-->
    <stk-stock-purchase-add
      v-if="stkPurchaseAddVisible"
      ref="StkStockPurchaseAdd"
      :stkGuid="stockGuid"
      :visible="stkPurchaseAddVisible"
      @close="
        () => {
          stkPurchaseAddVisible = false;
        }
      "
      @confirm="
        (val) => {
          val && (stkPurchaseAddVisible = false),
            this.onRefresh(),
            this.onCleaSelectCount();
        }
      "
    ></stk-stock-purchase-add>

    <!--日志dialog-->
    <stk-stock-log
      v-if="showStkstocklog"
      ref="StkStockLog"
      :showstockLog="refreshStklog"
      :stkGUID="stockGuid"
      :visible="showStkstocklog"
      @close="
        () => {
          showStkstocklog = false;
        }
      "
    ></stk-stock-log>

    <!-- 显示图片 -->
    <show-image
      :has-delete="true"
      :has-upload="true"
      :image-list="imageList"
      :imageStkGUID="this.imageStkGUID"
      :title="imageTitle"
      :visible="imageBoxVisible"
      @close="
        (imgGuids) => {
          onShowImageClose(imgGuids);
        }
      "
    ></show-image>

    <!--上传图片-->
    <image-upload
      :imageStkGUID="this.imageStkGUID"
      :visible="imageUploadVisible"
      @close="
        () => {
          this.imageUploadVisible = false;
        }
      "
      @confirm="
        () => {
          (this.imageUploadVisible = false), this.onBindData(this.pageIndex);
        }
      "
    ></image-upload>

    <!--销售备货-->
    <stk-stock-reserve-detail
      v-if="stkReserveDetailVisible"
      ref="stkStockReserveDetail"
      :isRefresh="refreshStkReserveDetail"
      :stkguid="stkReserveDetailModel"
      :visible="stkReserveDetailVisible"
      @close="
        () => {
          stkReserveDetailVisible = false;
        }
      "
      @confirm="
        (val) => {
          val && (stkReserveDetailVisible = false),
            this.onRefresh(),
            this.onCleaSelectCount();
        }
      "
    ></stk-stock-reserve-detail>

    <!--订货编辑界面-->
    <stk-stock-purchase-detail
      v-if="stkPurchaseDetailVisible"
      ref="stkStockPurchaseDetail"
      :isRefresh="refreshStkPurchaseDetail"
      :stkInfo="stkPurchaseDetailModel"
      :visible="stkPurchaseDetailVisible"
      @close="
        () => {
          stkPurchaseDetailVisible = false;
        }
      "
      @confirm="
        (val) => {
          val && (stkPurchaseDetailVisible = false),
            this.onRefresh(),
            this.onCleaSelectCount();
        }
      "
    ></stk-stock-purchase-detail>

    <!--已选择库存列表-->
    <stk-check-info
      v-if="showStkCheckInfo"
      ref="stkCheckInfo"
      :visible="showStkCheckInfo"
      @checkInfo="
        (val) => {
          this.onSelectedInfo(val);
        }
      "
      @close="
        () => {
          showStkCheckInfo = false;
        }
      "
    ></stk-check-info>

    <!--库存合并列表-->
    <stk-stock-merge
      v-if="showstkMerge"
      ref="StkStockMerge"
      :markingCode="markingCode"
      :stkGUIDS="stockGuids"
      :visible="showstkMerge"
      @close="
        () => {
          showstkMerge = false;
        }
      "
      @save-success="
        () => {
          (showstkMerge = false), this.onRefresh(), this.onCleaSelectCount();
        }
      "
    ></stk-stock-merge>

    <!-- 库存调货 -->
    <stk-tranfer-list
      v-if="showStktransferList"
      ref="StkTranferList"
      :stkGUIDS="stockGuids"
      :visible="showStktransferList"
      @close="
        () => {
          showStktransferList = false;
        }
      "
      @confirm="
        (val) => {
          val && (showStktransferList = false),
            this.onRefresh(),
            this.onCleaSelectCount();
        }
      "
      @update:visible="
        (val) => {
          showStktransferList = val;
        }
      "
    ></stk-tranfer-list>

    <!--拆分库存-->
    <stk-stock-split
      v-if="showstkSplit"
      ref="StkStockSplit"
      :stkGUID="stockGuids"
      :visible="showstkSplit"
      @close="
        () => {
          showstkSplit = false;
        }
      "
      @confirm="
        (val) => {
          val && (showstkSplit = false),
            this.onRefresh(),
            this.onCleaSelectCount();
        }
      "
    ></stk-stock-split>

    <!-- 打标记 -->
    <stk-stock-marking
      v-if="setMarkingVisible"
      ref="StkStockMarking"
      :mark-names-selected="selectedMarkNames"
      :stkGUID="stkGuids"
      :stockMarkParam="searchCondition"
      :visible="setMarkingVisible"
      markingType="stock"
      @close="
        () => {
          setMarkingVisible = false;
        }
      "
      @confirm="
        (val) => {
          (setMarkingVisible = false),
            this.onRefresh(),
            this.onCleaSelectCount();
        }
      "
    ></stk-stock-marking>

    <!-- 验证码密码 -->
    <confirm-password
      v-if="stkCheckPwdVisible"
      ref="ConfirmPassword"
      :isRefresh="stkCheckPwdVisible"
      :visible="stkCheckPwdVisible"
      @close="
        () => {
          stkCheckPwdVisible = false;
        }
      "
      @confirm="onChangeEndCheckPwd"
    ></confirm-password>

    <!-- 导出库存格式 -->
    <export-format
      :defaultConfigColumns="setDefaultConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      :searchCondition="searchCondition"
      :visible="exportFormatVisible"
      actionServiceName="StkStock"
      @close="
        () => {
          exportFormatVisible = false;
        }
      "
      @confirm="
        (v) => {
          exportFormatVisible = false;
        }
      "
    ></export-format>
    <!-- 未出库存统计 -->
    <stk-un-shipped
      v-if="unShippedVisible"
      ref="stsUnShipped"
      :visible="unShippedVisible"
      @close="
        () => {
          unShippedVisible = false;
        }
      "
    ></stk-un-shipped>

    <!-- 出货统计 -->
    <StsShipMent
      v-if="shippedVisible"
      ref="stsShipment"
      :visible="shippedVisible"
      @close="
        () => {
          shippedVisible = false;
        }
      "
    ></StsShipMent>

    <!--库存回收站-->
    <stk-stock-recycle
      v-if="showstkRecycle"
      ref="StkStockRecycle"
      :isRefresh="refreshStkRecycle"
      :visible="showstkRecycle"
      @close="
        () => {
          showstkRecycle = false;
        }
      "
      @confirm="
        (val) => {
          (showstkRecycle = false),
            val && this.onRefresh(),
            this.onCleaSelectCount();
        }
      "
    ></stk-stock-recycle>

    <!--搜索统计-->
    <search-keys-history
      v-if="showSearchKeysHistory"
      ref="SearchKeysHistory"
      :visible="showSearchKeysHistory"
      @close="
        () => {
          showSearchKeysHistory = false;
        }
      "
      @confirm="
        (val) => {
          showSearchKeysHistory = false;
        }
      "
    ></search-keys-history>

    <enquiry-view ref="enquiryView" @onConfirm="onConfirm"></enquiry-view>

    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />

    <stk-set-warn-list ref="stkSetWarnList"></stk-set-warn-list>

    <!--库存预警列表-->
    <stk-stock-warning
      ref="StkStockWarning"
      :isRefresh="refreshStkWarning"
      :visible="showstkWarning"
      :openFrom="warningFrom"
      v-if="showstkWarning"
      @close="
        () => {
          showstkWarning = false;
        }
      "
      @confirm="
        (val) => {
          val && (showstkWarning = false);
        }
      "
    ></stk-stock-warning>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { erpTableSetHandler } from "@scripts/methods/common";

import StkStockLog from "@c_modules/Stk/StkStockLog";
import StkInEdit from "@c_modules/Stk/StkInEdit";
import StkOutEdit from "@c_modules/Stk/StkOutEdit";
import StkStockMerge from "@c_modules/Stk/StkStockMerge";
import StkTranferList from "@c_modules/Stk/StkTransferList";
import StkStockRecycle from "@c_modules/Stk/StkStockRecycle";
import StkStockSplit from "@c_modules/Stk/StkStockSplit";
import ZnlBomMasterEdit from "@c_modules/BOM/BomMasterEdit";
import ExportFormat from "@c_modules/StkSum/ExportFormat";
import ImageUpload from "@c_modules/StkSum/ImageUpload";
import ShowImage from "@c_modules/StkSum/ShowImage";
import StkReserveStockDetial from "@c_modules/Stk/StkReserveStockDetial";
import StkStockReserveAdd from "@c_modules/Stk/StkStockReserveAdd";
import StkStockPurchaseAdd from "@c_modules/Stk/StkStockPurchaseAdd";
import ConfirmPassword from "@c_modules/Stk/ConfirmPassword";
import StkStockReserveDetail from "@c_modules/Stk/StkStockReserveDetail";
import StkStockPurchaseDetail from "@c_modules/Stk/StkStockPurchaseDetail";
import StkStockMarking from "@c_modules/Stk/StkStockMarking";
import StkCheckInfo from "@c_modules/Stk/StkCheckInfo";
import StkUnShipped from "@c_modules/Sts/StsUnshipped";
import StsShipMent from "@c_modules/Sts/StsShipment";
import SearchKeysHistory from "@c_modules/Sys/SearchKeysHistory";
import EnquiryView from "@c_modules/Enquiry/index";
import { isClient } from "~/lib/runtime";

import StkSetWarnList from "./StkSetWarnList";
import StkStockWarning from "@c_modules/Stk/StkStockWarning";
const CONFIG = {
  PK: "StkGUID",
  configURL: "StkStock/GetConfig",
  searchURL: "StkStock/Search",
  saveConfigURL: "StkStock/SaveConfig",
  DeleteURL: "StkStock/Delete",
  saveRowsURL: "StkStock/BatchSave",
  checkAllowImport: "StkStock/checkAllowImport", // 检查是否允许导入库存
  summaryCostURL: "StkStock/SummaryCost", // 统计库存成本
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  resetParamConfigURL: "StkStock/ResetParamConfig",
  onClearURL: "StkStock/Clear",
  deleteByConditionURL: "StkStock/DeleteByCondition",
  getModelSearchCntURL: "WebSearchLog/GetModelSearch",
  getStockImage: "StkStock/GetStockImageList",
  getStockLockQty: "StkStockReserve/GetStockLockQty",
  getTransitQty: "StkStockPurchase/GetTransitQty",
  updatePubStockQty: "PubStkStock/UpdatePubStockEnsureQty",
  clearPubStockQty: "PubStkStock/ClearPubStockEnsureQty",
};
export default {
  name: "MBatchStock",
  mixins: [erpTableSetHandler, getCommonDataMixin],
  config: CONFIG,
  components: {
    EnquiryView,
    StkStockLog,
    StkInEdit,
    StkOutEdit,
    StkStockMerge,
    StkTranferList,
    StkStockRecycle,
    StkStockSplit,
    ZnlBomMasterEdit,
    ExportFormat,
    ImageUpload,
    ShowImage,
    StkReserveStockDetial,
    StkStockReserveAdd,
    StkStockPurchaseAdd,
    ConfirmPassword,
    StkStockReserveDetail,
    StkStockPurchaseDetail,
    StkStockMarking,
    StkCheckInfo,
    StkUnShipped,
    StsShipMent,
    SearchKeysHistory,
    StkSetWarnList,
    StkStockWarning,
  },
  props: {
    matchOperation: {
      type: String,
      default: "Like",
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    initData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stkInEditVisible: false,
      stkOutEditVisible: false,
      showStkstocklog: false, // 是否显示日志
      showStktransferList: false, // 是否显示调出库存列表
      showstkMerge: false, // 是否显示库存合并列表
      showstkRecycle: false, // 是否显示库存回收站
      refreshStklog: true, // 是否刷新库存日志页面
      showstkSplit: false, // 是否显示拆分页面
      refreshStkRecycle: true, // 是否刷新回收站
      showSearchKeysHistory: false, //是否显示搜索统计
      stockGuid: "", // 传递库存的GUID
      loading: false,
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      searchFields: {},
      bomMasterDetail: [],
      moreBtns: [], // 更多操作
      stkInquiry: {
        // 询价参数传递
        SupplierName: "", // 供应商名称
        PartNo: "", // Model
        Brand: "",
        Packaging: "",
        MakeYear: "",
        Quality: "",
        DeliveryDate: "",
        InvQty: 0,
        Qty: 0,
        MPQ: "",
        Price: 0,
      },
      isNegative: false, // 负库存
      isZero: false, // 零库存
      gridtype: "action", // 表格类型。 action: 可编辑， base: 不可编辑
      bomMasterDetailEditVisibleTwo: false,
      exportFormatVisible: false,
      setColumns: [],
      setDefaultConfigColumns: {},
      setSaveConfigURL: "",
      refreshReserveDetail: false,
      // 左下角数据
      totalCost: 0, // 库存总成本
      totalQty: 0, // 总数量
      isShowCost: false, // 是否显示库存总成本
      imageUploadVisible: false, // 上传图片
      imageBoxVisible: false, // 是否显示型号的图片
      imageList: [], // 待显示的图片
      imageTitle: "", //
      imageStkGUID: "", // 库存GUID
      parameterConfig: [],
      ParamJosn: [],
      stkReserveAddVisible: false, // 是否显示备货编辑页面
      stkPurchaseAddVisible: false, // 是否显示订货编辑页面
      refreshStkPurchase: true, // 是否刷新订货编辑页面
      stkReserveDetailVisible: false, // 是否显示备货详情
      stkPurchaseDetailVisible: false, // 是否显示订货详情
      stkReserveDetailModel: "", // 传递StkGUID到备货详情
      stkPurchaseDetailModel: "", // 传递型号和品牌到订货详情
      stkCheckPwdVisible: false, // 是否显示密码验证页面
      isClear: false, // 判断是否是清除库存
      selectedMarkNames: "", // 当只选中一行时，当前行的自定义标签
      stkGuids: [],
      setMarkingVisible: false, // 是否显示打标记页面
      selectedRows: [], // 选中的数据
      showStkCheckInfo: false, // 是否显示已选择库存列表
      availableQty: null, // 可用量
      searchCondition: {}, // 导出库存查询条件
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
      invTypeList: [], // 库存类型集合
      qtyUnitList: [], // 库存单位集合
      isShowBtnSave: true, // 显示保存按钮
      unShippedVisible: false,
      shippedVisible: false,

      showstkWarning: false, // 是否显示库存预警
      refreshStkWarning: true,
      warningFrom: "set",

      permission: {
        viewBuyPrice: true, // 看采购价
        viewSalesPrice: true, // 看销售价
        noSeeSupplier: false, // 不看供应商
      },
    };
  },
  async created() {
    this.invTypeList = await this.selectStorageType;
    this.qtyUnitList = await this.selectOptionsQtyUnit;
  },
  async mounted() {
    this.$nextTick(async () => {
      await this.onInit();
    });
  },
  methods: {
    onInit: async function (config) {
      this.loading = true;
      if (!config) {
        config = await this.isSaveStorage("batchesStock", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map((item) => item.Code);
      }

      let mouseBtn = [];
      if (this.isMainAccount || this.hasRes("Inquiry")) {
        mouseBtn.push({
          title: "询价",
          name: "Inquiry",
          click: (row) => {
            let rows = [];
            // rows.push(row)
            this.onStkInquiry(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("BtnStkReserve")) {
        mouseBtn.push({
          title: "销售备货",
          name: "StkReserve",
          click: (row) => {
            this.onAddStkReserve(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("BtnStkPurchase")) {
        mouseBtn.push({
          title: "采购订货",
          name: "StkPurchase",
          click: (row) => {
            this.onAddStkPurchase(row);
          },
        });
      }
      if (this.isMainAccount || this.hasRes("Delete")) {
        mouseBtn.push({
          title: "删除库存",
          name: "DelStock",
          click: (row) => {
            this.onDeleteOne(row);
          },
        });
      }
      if (this.isMainAccount || !this.hasRes("NoSeeStockLog")) {
        // 特殊权限 不查看日志
        mouseBtn.push({
          title: "查看日志",
          name: "ShowStockLog",
          click: (row) => {
            this.stockGuid = row.StkGUID;
            this.showStkstocklog = true;
            this.refreshStklog = !this.refreshStklog; // 改变状态进行刷新
          },
        });
      }
      let tempConfig = [
        {
          width: 34,
          type: "menus",
          title: "操作",
          btnTxt: "",
          visible: true,
          isDeal: false,
          config: mouseBtn,
          isFrozen: true,
        },
      ];
      if (this.hasRes("StkOutAdd")) {
        tempConfig.push({
          width: 28,
          type: "button",
          title: "出",
          btnTxt: "出",
          isFrozen: true,
          click: (row) => {
            this.stkOutAddRows(row);
          },
        });
      }
      if (this.hasRes("StkInAdd")) {
        tempConfig.push({
          width: 28,
          type: "button",
          title: "入",
          btnTxt: "入",
          isFrozen: true,
          click: (row) => {
            this.stkInAddRows(row);
          },
        });
      }

      let hasQuotePrice = await this.hasResource("BomDetailLine");
      if (hasQuotePrice) {
        tempConfig.push({
          width: 28,
          type: "button",
          title: "报",
          btnTxt: "报",
          isFrozen: true,
          click: (row) => {
            this.quotedPrice(row);
          },
        });
      }
      // 获取参数配置
      this.ParamJosn = config.ParamJosn;
      let columns = config.ColumnConfigs;

      // 图片列 start
      let colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "StockImage"
      );
      if (colIndex > -1) {
        let _this = this;
        _.extend(columns[colIndex], {
          type: "icon",
          width: 34,
          isFrozen: true,
          classNames: [
            "show-hand icon iconfont icon-pic_btn_ic color-ccc",
            "show-hand icon iconfont icon-pic_btn_ic color-green",
          ],
          status(row) {
            if (
              row.StockImages !== undefined &&
              row.StockImages["length"] > 0
            ) {
              return 1;
            } else {
              return 0;
            }
          },
          click(row) {
            if (row.StockImages !== undefined && row.StockImages.length > 0) {
              _this.imageList = row.StockImages;
              _this.imageBoxVisible = true;
              _this.imageTitle = row.Model;
              _this.imageStkGUID = row.StkGUID;
            } else {
              _this.imageStkGUID = row.StkGUID;
              _this.imageUploadVisible = true;
            }
          },
        });
      }
      // 图片列 end

      // linkAll 列
      colIndex = _.findIndex(columns, (item) => item.BindField === "Model");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          linkAll: true,
        });
        //this.setLinkAllColumns(columns[colIndex]);
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "SupplierName"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          linkAll: true,
        });
      }

      // 表格值的点击 start
      colIndex = _.findIndex(columns, (item) => item.BindField === "BookedQty");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "bookedQty-click",
          className: "v-cell-link",
        });
      }
      colIndex = _.findIndex(
        columns,
        (item) => item.BindField === "TransitQty"
      );
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          event: "transitQty-click",
          className: "v-cell-link",
        });
      }
      // 表格值的点击 end

      // 员工列表
      let userList = _.map(this.selectOptionsAllUserURL, function (obj) {
        return { key: obj.value, value: obj.value };
      });
      // 下拉框 start
      colIndex = _.findIndex(columns, (item) => item.BindField === "BuyerName");

      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 72,
          options: userList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "InvType");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 58,
          options: this.invTypeList,
        });
      }
      colIndex = _.findIndex(columns, (item) => item.BindField === "Unit");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 58,
          options: this.qtyUnitList,
        });
      }
      // 下拉框 end
      if (!this.IsMainAccount) {
        this.setHideColumns(columns);
      }
      this.parameterConfig = config.ParamJosn;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.defaultConfig = config;
      columns = tempConfig.concat(columns);

      this.initMoreBtns();
      this.columns = columns;
      this.$refs.table.init();
      if (this.initData) {
        await this.onBindData();
      }
      this.loading = false;
      this.$emit("page-loading", false);
    },

    /* 打印 */
    onPrint() {
      console.log("触发打印");
      window.print();
    },

    /** **********更多操作按钮赋值start*************/
    initMoreBtns() {
      this.moreBtns = [
        {
          name: "删除选中",
          isShow: this.hasRes("Delete"),
          click: () => {
            this.onMulitDeleteRow();
          },
        },
        {
          name: "按条件删除",
          isShow: this.isMainAccount && this.hasRes("Delete"),
          click: () => {
            this.onDeleteByCondition();
          },
        },
        {
          name: "清除库存",
          isShow: this.isMainAccount && this.hasRes("Delete"),
          click: () => {
            this.onClearInventory();
          },
        },
        {
          name: "库存回收站",
          isShow: this.hasRes("StkRecycle"),
          click: () => {
            this.onStkRecycle();
          },
        },
        {
          name: "导入库存",
          isShow: this.hasRes("ImportStock"),
          click: () => {
            this.onstkImport();
          },
        },
        {
          name: "导出库存(按查询)",
          isShow: this.hasRes("ImportStock"),
          click: () => {
            this.onstkExport();
          },
        },
        {
          name: "未出货统计",
          isShow: true,
          //isShow: this.hasRes('ImportStock'),
          click: () => {
            this.unShippedVisible = !this.unShippedVisible;
          },
        },
        {
          name: "搜索统计",
          isShow: true,
          click: () => {
            this.onSearchKeywordsLog();
          },
        },
        {
          name: "设置库存预警",
          isShow: this.hasRes("ImportStock"),
          click: () => {
            // this.onSetStkWarning()
            this.showstkWarning = true;
          },
        },
      ];
    },

    // 设置预警库存
    onSetStkWarning() {
      this.$refs.stkSetWarnList.onOpen();
    },
    /** **********更多操作按钮赋值end*************/

    setLinkAllColumns(col) {
      // _.extend(col, {
      //   isReadOnly: false,
      //   event: 'linkAll-click',
      //   formatter(item, rowIndex, pagingIndex, field) {
      //     return `<div class="znl-linkAll-column">${item[field]}
      //                   <i title="打开LinkAll" class="iconfont icon-linkall__ic znl-linkAllBtn"></i>
      //             </div>`
      //   }
      // })
    },

    /** *********表格单元格的列值点击链接事件 start ***********/
    bookedQtyCellClick(e) {
      if (e.field !== "BookedQty") {
        return;
      }
      if (this.hasValue(e.row.BookedQty) && e.row.BookedQty > 0) {
        this.onLoadStkReserveDetail(e.row);
      }
    },
    transitQtyCellClick(e) {
      if (e.field !== "TransitQty") {
        return;
      }
      if (this.hasValue(e.row.TransitQty) && e.row.TransitQty > 0) {
        this.onLoadStkPurchaseDetail(e.row);
      }
    },

    /** *********表格单元格的列值点击链接事件 end ***********/

    //  /** **********权限控制start*************/
    setHideColumns(columns) {
      // 成本价权限控制BuyPrice 采购价未税BuyPrice 和TaxBuyPrice 采购价(含税)  和 成本金额TotalCostAmount
      let viewBuyPrice = this.getSpecialResourceByCode("ViewBuyPrice");

      // 成本价权限控制 SalesPrice 销售定价 FollowPrice 建议销售价
      let viewSalesPrice = this.getSpecialResourceByCode("ViewSalesPrice");

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode("NoSeeSupplier");

      // 批次库存 1=不可编辑
      let allowDirectlyUpdateStock = this.getCompanySetupValue(
        "AllowDirectlyUpdateStock"
      );
      if (allowDirectlyUpdateStock === "1") {
        this.isShowBtnSave = false;
      }
      // 没有修改型号&数量、修改库存属性的权限，全部不可编辑
      if (!this.hasRes("Save") && !this.hasRes("EditModelQty")) {
        allowDirectlyUpdateStock = "1";
      }
      _.remove(columns, (item) => {
        // 不看成本价
        if (!viewBuyPrice) {
          switch (item.BindField) {
            case "BuyPrice": // 未税采购价
            case "TaxBuyPrice": // 含税采购价
            case "TotalCostAmount":
            case "CostAmountInTax":
              return true;
          }
          this.permission.viewBuyPrice = false;
        }
        // 不看销售价
        if (
          !viewSalesPrice &&
          (item.BindField === "SalesPrice" || item.BindField === "FollowPrice")
        ) {
          this.permission.viewSalesPrice = false;
          return true;
        }
        // 不看供应商或隐藏供应商
        if (
          (noSeeSupplier || this.hasRes("HideSupplier")) &&
          item.BindField === "SupplierName"
        ) {
          if (noSeeSupplier) {
            this.permission.noSeeSupplier = true;
          }
          return true;
        }
        // 看备注1 没有这个权限隐藏列
        if (item.BindField === "Remark" && !this.hasRes("ViewRemark1")) {
          return true;
        }
        // 看备注2 没有这个权限隐藏列
        if (item.BindField === "Remark1" && !this.hasRes("ViewRemark2")) {
          return true;
        }
        // 看备注3 没有这个权限隐藏列
        if (item.BindField === "Remark2" && !this.hasRes("ViewRemark3")) {
          return true;
        }
        // 不看替代型号，有这个权限隐藏列
        if (
          item.BindField === "ReplaceModel" &&
          this.hasRes("NoSeeReplaceModel")
        ) {
          return true;
        }
        // 批次库存 1=不可编辑  所有列不可编辑
        if (!item.isReadOnly && allowDirectlyUpdateStock === "1") {
          item.isReadOnly = true;
        } else {
          // 没有编辑型号&数量的权限
          if (!this.hasRes("EditModelQty")) {
            switch (item.BindField) {
              case "Model":
              case "InvQty":
                item.isReadOnly = true;
                break;
            }
          }
          // 没有编辑库存属性的权限
          if (!this.hasRes("Save")) {
            if (item.BindField !== "Model" && item.BindField !== "InvQty") {
              item.isReadOnly = true;
            }
          }

          // 修改备注权限
          if (
            (item.BindField === "Remark" ||
              item.BindField === "Remark1" ||
              item.BindField === "Remark2") &&
            !this.hasRes("EditRemark")
          ) {
            item.isReadOnly = true;
          }
        }
        // 合伙人 没有这个权限隐藏列
        if (item.BindField === "Partner" && !this.hasRes("ViewPartner")) {
          return true;
        }
      });
    },

    onHeadSearch(params) {
      let rows = this.$refs.table.getChangedData();
      if (rows.length > 0) {
        this.$confirm("当前数据已发生修改, 是否继续操作?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          this.$refs.table.cleanEdit();
          this.searchFields = params;
          this.onBindData(1);
        });
      } else {
        this.$refs.table.cleanEdit();
        this.isShowCost = false;
        this.searchFields = params;
        this.onBindData(1);
      }
    },

    // 搜索
    onSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields = _.extend({}, this.searchFields, params);
      }
      this.isShowCost = false;
      this.onBindData(1);
    },

    // 刷新
    async onRefresh(e) {
      await this.onBindData(this.pageIndex);
    },

    // 绑定数据
    async onBindData(pageIndex = 1) {
      this.loading = true;
      let filterFields = {
        // 外部搜索条件
        isNegative: this.searchModel.isNegative, // 负库存
        isZero: this.searchModel.isZero, // 零库存
        MatchOperation: this.searchModel.matchOperation,
      };
      let data = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
          pageIndex: pageIndex,
          pageSize: this.pageSize,
        },
        filterFields
      );
      return this.$post(CONFIG.searchURL, data, (datas, logic) => {
        if (logic.code === 200) {
          this.pageIndex = pageIndex;
          this.totalCount = datas.TotalCount;
          this.itemSource = datas.ResultList;
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
        this.$emit("page-loading", false);
      });
    },

    // 获取库存图片
    async getStockImage(datas) {
      let items = datas;
      if (items === null || items.length <= 0) {
        return [];
      }
      // 若隐藏,不统计
      let col = _.find(this.columns, (col) => col.binding === "StockImage");
      if (!this.hasValue(col) || !col.visible) {
        return items;
      }
      let models = _.uniq(_.map(items, (item) => item.Model));
      await this.$post(CONFIG.getStockImage, { Models: models }, (result) => {
        _.each(items, (item) => {
          let imageList = _.filter(result, (i) => {
            return i.Model === item.Model && i.FileUrl !== undefined;
          });
          if (imageList !== undefined && imageList !== null) {
            item.StockImages = imageList;
            // this.$set(item, 'StockImages', imageList)
          }
        });
        // 刷新库存图片列
        // this.genColImage(
        //   _.find(this.columns, col => col.binding === 'StockImage')
        // )
      });
      return items;
    },

    // 获取搜索次数
    async getModelSearch(datas) {
      let items = datas;
      if (items === null || items.length <= 0) {
        return [];
      }
      // 若隐藏,不统计
      let col = _.find(this.columns, (col) => col.binding === "SearchCount");
      if (!this.hasValue(col) || !col.visible) {
        return items;
      }
      // 1.映射  2.去重
      let models = _.uniq(_.map(items, (item) => item.Model));
      await this.$post(
        CONFIG.getModelSearchCntURL,
        { Models: models },
        (result) => {
          _.each(items, (item) => {
            let cnt = result[item.Model];
            if (cnt) {
              // this.$set(item, 'SearchCount', cnt)
              item.SearchCount = cnt;
            }
          });
        }
      );
      return items;
    },

    // 获取库存锁定量
    async getStockLockQty(datas) {
      let items = datas;
      if (items === null || items.length <= 0) {
        return [];
      }
      // 若隐藏,不统计
      let col = _.find(this.columns, (col) => col.binding === "BookedQty");
      if (!this.hasValue(col) || !col.visible) {
        return items;
      }
      let stkGuids = _.uniq(_.map(items, (item) => item.StkGUID));
      this.$post(
        CONFIG.getStockLockQty,
        { StkGUIDList: stkGuids },
        (result) => {
          _.each(items, (item) => {
            let info = _.find(result, (i) => {
              return i.StkGUID === item.StkGUID;
            });
            if (info !== undefined && info != null) {
              // item.BookedQty = info.ReservedNum
              this.$set(item, "BookedQty", info.ReservedNum);
            }
          });
        }
      );
      // return items
    },

    // 获取库存在途量
    async getTransitQty(datas) {
      let items = datas;
      if (items === null || items.length <= 0) {
        return;
      }
      // 若隐藏,不统计
      let col = _.find(this.columns, (col) => col.binding === "TransitQty");
      if (!this.hasValue(col) || !col.visible) {
        return items;
      }
      // 1.映射  2.去重
      let models = _.uniq(_.map(items, (item) => item.Model));
      let brands = _.uniq(_.map(items, (item) => item.Brand));
      this.$post(
        CONFIG.getTransitQty,
        { Models: models, Brands: brands },
        (result) => {
          _.each(items, (item) => {
            let info = _.find(result, (i) => {
              return i.Model === item.Brand;
            });
            if (info !== undefined && info != null) {
              let qty = info.Qty === undefined ? 0 : info.Qty;
              let readyInQty =
                info.ReadyInQty === undefined ? 0 : info.ReadyInQty;
              let transitQty = qty - readyInQty;
              // item.TransitQty = transitQty
              this.$set(item, "TransitQty", transitQty);
            }
          });
        }
      );
      // return items
    },

    // 刷新库存统计
    async onSumData() {
      let filterFields = {
        // 外部搜索条件
        isNegative: this.isNegative, // 负库存
        isZero: this.isZero, // 零库存
        MatchOperation: this.matchOperation,
      };
      let param = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
        },
        filterFields
      );
      let _this = this;
      this.$post(CONFIG.summaryCostURL, param, (data) => {
        _this.isShowCost = true;
        _this.totalCost = _this.toFloat(data.TotalCost, 2);
        _this.totalQty = _this.toFloat(data.TotalQty, 2);
      });
    },

    // ************************* 操作栏按钮事件 start **************************//
    // 销售发货
    stkOutAddRows(row) {
      // 没有看备注1权限，清空值
      if (!this.hasRes("ViewRemark1")) {
        row.Remark = null;
      }
      // 没有看备注2权限，清空值
      if (!this.hasRes("ViewRemark2")) {
        row.Remark1 = null;
      }
      // 没有看备注3权限，清空值
      if (!this.hasRes("ViewRemark3")) {
        row.Remark2 = null;
      }

      let rows = [];
      if (row) {
        rows.push(row);
      } else {
        rows = this.selectedRows;
      }
      this.onStkOut(rows);
    },
    onStkOut(rows) {
      if (!this.hasValue(rows) || rows.length <= -0) {
        rows = [];
      }
      let order = { stockLine: rows };
      this.$store.commit("setParamOrder", order);
      this.$nextTick(function () {
        this.stkOutEditVisible = true;
      });
      this.onCleaSelectCount();
    },

    // 采购入库
    stkInAddRows(row) {
      let rows = [];
      if (row) {
        rows.push(row);
      } else {
        rows = this.selectedRows;
      }
      this.onStkIn(rows);
    },
    onStkIn(rows) {
      if (!this.hasValue(rows) || rows.length <= 0) {
        rows = [];
      }
      if (!this.permission.viewBuyPrice && rows.length > 0) {
        rows.forEach((a) => {
          a.BuyPrice = null;
          a.TaxBuyPrice = null;
        });
      }
      if (!this.permission.viewSalesPrice && rows.length > 0) {
        rows.forEach((a) => {
          a.SalesPrice = null;
          a.FollowPrice = null;
          a.aaa = null;
        });
      }
      let order = { stockLine: rows };
      this.$store.commit("setParamOrder", order);
      this.$nextTick(function () {
        this.stkInEditVisible = true;
      });
      this.onCleaSelectCount();
    },

    // 市场查货(询价)
    async onMarketSearch() {
      let rows = this.selectedRows;
      let marketRows = [];
      if (
        (this.searchFields.Model === null || this.searchFields.Model === "") &&
        (rows === null || rows.length === 0 || _.isUndefined(rows[0]))
      ) {
        return this.$message({
          message: "请输入搜索条件或勾选需要市场查货的汇总库存",
          type: "warning",
        });
      } else if (
        (this.searchFields.Model !== null || this.searchFields.Model !== "") &&
        (rows === null || rows.length === 0 || _.isUndefined(rows[0]))
      ) {
        const regex = /,|，/;
        let modeList = (
          this.searchFields.Model === null ? "" : this.searchFields.Model
        ).split(regex);
        _.each(modeList, (item) => {
          marketRows.push({
            Model: item,
          });
        });
      } else {
        marketRows = rows;
      }
      let request = [];
      _.each(marketRows, (item) => {
        request.push({
          Model: item.Model, // 型号，
          Brand: item.Brand, // 品牌，
          Pack: item.Packaging, // 封装，
          MakeYear: item.MakeYear, // 年份，
          Qty: item.InvQty, // 数量，
          Remark: item.Remark, // 需求说明
        });
      });
      this.$nextTick(function () {
        this.openMarketSearch({ Requests: request, ClearRequests: false });
      });
    },

    // 给客户报价
    quotedPrice(row) {
      let rows = [];
      if (row) {
        rows.push(row);
      } else {
        rows = this.selectedRows;
      }
      this.onBomLine(rows);
    },
    onBomLine(rows) {
      if (!this.hasValue(rows) || rows.length <= 0) {
        rows = [];
      }
      this.bomMasterDetailEditVisibleTwo = true;
      this.$nextTick(() => {
        this.bomMasterDetail = rows;
      });
      this.onCleaSelectCount();
    },

    // 库存调货
    onStkTransfer() {
      let rows = this.selectedRows;
      if (rows.length > 0) {
        this.stockGuids = _.uniq(_.map(rows, (item) => item.StkGUID));
        this.showStktransferList = true;
      }
      this.onCleaSelectCount();
    },

    // 库存合并
    onStkMerge() {
      let rows = this.selectedRows;
      let zeroQtyInfo = _.filter(rows, (item) => {
        return item.UsableQty === 0;
      });
      if (rows.length < 2) {
        this.$message({
          message: "合并操作,必须选择2条及以上的我的库存！",
          type: "warning",
        });
        return;
      } else if (zeroQtyInfo.length > 0) {
        this.$message({
          message: "选择的库存可用量不能为0！",
          type: "warning",
        });
        return;
      }
      // 去重 判断
      let selectedModle = _.uniq(_.map(rows, (item) => _.trim(item.Model)));
      if (selectedModle.length > 1) {
        this.$confirm("已选择的合并库存中有不同型号,是否继续？", "提示", {
          confirmButtonText: "继续",
          cancelButtonText: "重新选择",
        })
          .then(() => {
            this.showStockMerge(rows);
          })
          .catch((action) => {
            return false;
          });
      } else {
        this.showStockMerge(rows);
      }
    },
    // 显示库存合并窗体
    showStockMerge(rows) {
      let stkGuids = _.uniq(_.map(rows, (item) => item.StkGUID));
      this.stockGuids = stkGuids;
      this.markingCode = "";
      _.some(rows, (code) => {
        if (!_.isEmpty(code.MarkingCode)) {
          this.markingCode = code.MarkingCode;
          return true;
        }
      });
      this.showstkMerge = true;
      this.onCleaSelectCount();
    },

    // 库存拆分
    onBatchStkSplit() {
      let rows = this.selectedRows;
      if (rows.length > 0) {
        if (rows.length > 1) {
          return this.$message({
            message: "只能勾选一条数据进行操作",
            type: "error",
          });
        } else {
          this.stockGuids = JSON.stringify([rows[0].StkGUID]);
          this.showstkSplit = true;
          this.onCleaSelectCount();
        }
      } else {
        return this.$message({
          message: "请勾选上一条数据进行操作",
          type: "error",
        });
      }
    },

    // 库存打标记
    onMarking() {
      this.selectedMarkNames = "";
      if (!_.isUndefined(this.selectedRows) && this.selectedRows.length > 0) {
        this.stkGuids = _.map(this.selectedRows, (m) => m.StkGUID);
        if (this.selectedRows.length === 1) {
          this.selectedMarkNames = this.selectedRows[0].InvMarkNames;
        }
      } else {
        this.stkGuids = [];
      }
      this.searchCondition = {
        // 外部搜索条件
        FieldWhereString: JSON.stringify(this.searchFields), // 表头搜索
      };
      this.setMarkingVisible = true;
    },

    // 库存回收站
    onStkRecycle() {
      // 库存回收站
      this.showstkRecycle = true;
      this.refreshStkRecycle = !this.refreshStkRecycle;
      this.onCleaSelectCount();
    },

    // ************************* 操作栏按钮事件 end **************************//

    // ************************* 更多按钮操作事件 start **************************//
    onMulitDeleteRow() {
      // 删除多条库存
      let rows = this.selectedRows;
      if (rows.length > 0) {
        this.$confirm("您确定要删除【" + rows.length + "】条库存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          return this.onPostDelete(rows);
        });
      } else {
        return this.$message({
          message: "请勾选上一条数据进行操作",
          type: "warning",
        });
      }
    },

    // 按查询条件删除
    onDeleteByCondition() {
      this.isClear = false;
      // 判断查询条件  如果查询条件为null给出提示  后面完善
      if (this.totalCount <= 0) {
        return this.$message({
          message: "请输入查询条件搜索再操作",
          type: "warning",
        });
      }
      return this.$confirm(
        "您确定要删除【" + this.totalCount + "】条库存?",
        "按查询条件删除库存",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.stkCheckPwdVisible = true;
      });
    },
    // 清除库存
    onClearInventory() {
      this.isClear = true;
      return this.$confirm(
        '<p>该操作将会 <b style="color: red">清除所有库存</b>。清除之后，不会进入回收站，而是直接删除，<b style="color:red;">不可恢复！！！</b> 您确定要清除吗?</p>',
        "清除库存",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      ).then(() => {
        this.stkCheckPwdVisible = true;
      });
    },
    onChangeEndCheckPwd(val) {
      this.stkCheckPwdVisible = false;
      let param = {};
      if (val) {
        let url = "";
        if (this.isClear) {
          // 库存清除
          url = CONFIG.onClearURL;
        } else {
          let filterFields = {
            isNegative: this.isNegative, // 负库存
            isZero: this.isZero, // 零库存
            MatchOperation: this.matchOperation,
          };
          param = _.extend(
            {},
            { fieldWhereString: JSON.stringify(this.searchFields) },
            filterFields
          );
          url = CONFIG.deleteByConditionURL;
        }
        let result = [];
        this.$post(url, param, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "删除成功", type: "success" });
            this.onRefresh();
            result = data;
          } else {
            this.$message({ message: "删除失败", type: "error" });
          }
        }).then(() => {
          if (this.isClear) {
            this.clearPubStockEnsureQty();
          } else {
            this.delPubStock(result);
          }
        });
      }
    },
    onstkImport: async function () {
      // 导入自有库存
      // 先做验证，库存量已超出最大库存量，不能再导入
      let IsAllowImport = await this.$post(CONFIG.checkAllowImport);
      this.$nextTick(function () {
        if (!IsAllowImport) {
          return this.$message({
            message: "您的库存量已超出最大库存量，不能再导入",
            type: "error",
          });
        }
        this.importData({ importType: "stock" });
      });
    },
    onstkExport: async function () {
      // 导出（按查询条件）
      let filterFields = {
        // 外部搜索条件
        isNegative: this.searchModel.isNegative, // 负库存
        isZero: this.searchModel.isZero, // 零库存
        MatchOperation: this.searchModel.matchOperation,
      };
      let data = _.extend(
        {},
        { fieldWhereString: JSON.stringify(this.searchFields) },
        filterFields
      );
      this.exportFormatVisible = true;
      this.setColumns = this.defaultConfig.ColumnConfigs;
      this.setDefaultConfigColumns = this.defaultConfig;
      this.searchCondition = data;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },
    onSearchKeywordsLog: async function () {
      this.showSearchKeysHistory = true;
    },
    // ************************* 更多按钮操作事件 end **************************//
    isShowMoreBtn(btns) {
      let isShow = false;
      _.each(btns, (btn) => {
        if (btn.isShow) {
          isShow = true;
          return true;
        }
      });
      return isShow;
    },

    // 验证操作权限
    hasRes(code) {
      return _.includes(this.resourceList, code);
    },

    onDeleteOne(row) {
      // 删除单条
      this.$confirm(
        "您确定要删除型号为 “" + row.Model + "” 的库存吗?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let rows = [];
        rows.push(row);
        return this.onPostDelete(rows);
      });
    },
    // 批量询价
    onBatchStkInquiry() {
      let rows = this.selectedRows;
      let data = "";
      if (rows.length > 0) {
        data = rows[0];
      }
      this.onStkInquiry(data);
    },

    async onStkInquiry(data) {
      if (isClient()) {
        await this.onInquiry(data);
      } else {
        const mapData = [
          "SupplierName",
          "Model",
          "Brand",
          "Packaging",
          "MakeYear",
          "Quality",
          "InvQty",
          "MPQ",
          "Qty",
        ];
        let editItem = {};
        if (data) {
          mapData.forEach((key) => (editItem[key] = data[key]));
        }
        this.$refs.enquiryView.onOpen(editItem);
      }
    },
    onConfirm(data) {
      console.log(data);
    },

    // 库存备货
    onAddStkReserve(row) {
      if (!this.hasValue(row)) {
        return;
      }
      let invQty = row.InvQty;
      let reserveLockQty = row.BookedQty;
      this.availableQty = invQty < 0 ? -invQty : invQty;
      if (reserveLockQty) {
        this.availableQty = invQty > 0 ? invQty - reserveLockQty : 0;
      }
      this.stockGuid = row.StkGUID;
      this.stkReserveAddVisible = true;
    },
    // 采购订货
    onAddStkPurchase(row) {
      if (!this.hasValue(row)) {
        return;
      }
      this.refreshStkPurchase = !this.refreshStkPurchase;
      this.stockGuid = row.StkGUID;
      this.stkPurchaseAddVisible = true;
    },
    onLoadStkReserveDetail(row) {
      // 显示备货详情
      this.stkReserveDetailVisible = true;
      this.stkReserveDetailModel = row.StkGUID;
      this.refreshStkReserveDetail = !this.refreshStkReserveDetail;
    },
    onLoadStkPurchaseDetail(row) {
      // 显示订货详情
      this.stkPurchaseDetailVisible = true;
      this.stkPurchaseDetailModel =
        row.Model + "," + row.Brand + "," + row.StkGUID;
      this.refreshStkPurchaseDetail = !this.refreshStkPurchaseDetail;
    },
    async onInquiry(data) {
      // 询价
      this.stkInquiry.SupplierName = data.SupplierName;
      this.stkInquiry.Model = data.Model;
      this.stkInquiry.Brand = data.Brand;
      this.stkInquiry.Packaging = data.Packaging;
      this.stkInquiry.MakeYear = data.MakeYear;
      this.stkInquiry.Quality = data.Quality;
      this.stkInquiry.InvQty = data.InvQty;
      this.stkInquiry.MPQ = data.MPQ;
      this.stkInquiry.Qty = data.InvQty;

      await this.addInquire(this.stkInquiry, function (msg) {
        if (msg === "不能向自己询价") {
          this.$message({ message: msg, type: "warning" });
        }
      });
      this.onCleaSelectCount();
    },

    // 删除库存
    onPostDelete(rows) {
      // 删除请求
      let list = rows;
      let stkGuids = _.uniq(_.map(list, (item) => item.StkGUID));
      this.$post(CONFIG.DeleteURL, { StkGUIDS: stkGuids }, (data, logic) => {
        if (logic.code === 200) {
          this.$message({ message: "删除成功", type: "success" });
          this.onCleaSelectCount();
          this.onRefresh();
        } else {
          this.$message({ message: "删除失败", type: "error" });
        }
      }).then(() => {
        this.delPubStock(list);
      });
    },
    // 关闭图片走马灯时执行
    onShowImageClose(deletedImgGuids) {
      this.imageBoxVisible = false;
      if (deletedImgGuids && deletedImgGuids.length > 0) {
        _.each(this.itemSource, (item) => {
          _.each(deletedImgGuids, (imgGuid) => {
            let index = _.findIndex(
              item.StockImages,
              (m) => m.ImgGuid === imgGuid
            );
            if (index > -1) {
              item.StockImages.splice(index, 1);
            }
          });
        });
      }
    },

    // 批量保存
    onBatchSave() {
      let rows = this.$refs.table.getChangedData();
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({ message: "数据没有发生变化", type: "warning" });
      } else {
        this.onSaveRows(rows);
      }
    },
    onSaveRows(rows) {
      if (rows.length > 0) {
        _.each(rows, (item) => {
          item.StockImages = null;
        });
        return this.$post(CONFIG.saveRowsURL, rows, (data) => {
          if (data >= 0) {
            this.$refs.table.cleanEdit();
            this.$message({ message: "保存成功", type: "success" });
          } else {
            this.$message({ message: "保存失败", type: "error" });
          }
        });
      }
    },
    // 查看已选中的库存详情
    onCheckInfo() {
      if (this.selectedRows.length === 0) {
        return this.$message({ message: "至少选择一条数据", type: "warning" });
      } else {
        _.each(this.selectedRows, (item) => {
          item.CheckGUID = item.StkGUID;
        });
        let order = { checkInfo: this.selectedRows };
        this.$store.commit("setParamOrder", order);
        this.$nextTick(function () {
          this.showStkCheckInfo = true;
        });
      }
    },
    onSelectedInfo(val) {
      this.selectedRows = val;
    },
    // 勾选选择框事件
    selectChange(rows) {
      if (this.selectedRows.length <= 0) {
        this.selectedRows = rows;
      } else {
        // 先把勾选的添加进集合
        _.each(rows, (row) => {
          if (!_.some(this.selectedRows, (a) => a.StkGUID === row.StkGUID)) {
            this.selectedRows.push(row);
          }
        });

        // 再把当前页的库存没有勾选的从集合移除
        _.each(this.itemSource, (row) => {
          // 当前页的数据 不在已选中的列表里面，但是在 selectedRows 里面，则需要移除
          if (!_.some(rows, (a) => a.StkGUID === row.StkGUID)) {
            let index = _.findIndex(
              this.selectedRows,
              (b) => b.StkGUID === row.StkGUID
            );
            if (index > -1) {
              this.selectedRows.splice(index, 1);
            }
          }
        });
      }
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.table.cleanAllCheck();
    },
    // 更新推广库存(现货库存)
    async delPubStock(rows) {
      if (rows === undefined || rows.length <= 0) {
        return;
      }
      let list = _.filter(rows, (item) => {
        return (
          this.hasValue(item.Model) &&
          this.hasValue(item.Brand) &&
          this.hasValue(item.Packaging)
        );
      });
      let pubStockList = _.map(list, (item) => {
        return {
          Model: item.Model,
          Brand: item.Brand,
          Packaging: item.Packaging,
          Qty: item.InvQty,
        };
      });
      if (pubStockList.length > 0) {
        this.$post(CONFIG.updatePubStockQty, pubStockList, (data) => {
          if (data > 0) {
            console.log("更新推广库存的数量(现货):", data);
          }
        });
      }
    },
    // 清空推广库存(现货库存)
    async clearPubStockEnsureQty() {
      this.$post(CONFIG.clearPubStockQty, {}, (data) => {
        if (data > 0) {
          console.log("清空推广库存的数量(现货):", data);
        }
      });
    },

    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StkStock", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StkStock", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StkStock", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StkStock");
    },
  },
};
</script>

<style lang="scss">
// @import "app/assets/styles/_variables.scss";
</style>
