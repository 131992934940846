<template>
  <znl-layout-topbottom
    class="znl-master-slave-layout"
    top-height="35px"
    :page-loading="hasPageLoading || pageLoading">
    <znl-search-condition
      ref="ZnlSearchCondition"
      slot="znlTop"
      class="znlTop"
      v-show="!(hasPageLoading || pageLoading)"
      @on-search="(val)=>{this.searchModel=val}"
      :stock-checked-rows="stockCheckedRows"
    >
    </znl-search-condition>
    <div slot="znlBottom"
        class="znlBottom, znl-dev-stock"
        v-show="!(hasPageLoading || pageLoading)">
            <!-- znl-bottom-tab -->
        <el-tabs v-model="activeName"
            value="StockQuery"
            @tab-click="pageSearch"
            class="scoped-bottom-tab"
            v-show="!(hasPageLoading || pageLoading)"
            @page-loading="setClientSelectLoading"
           >
           <!--  type="card" -->

      <el-tab-pane label="我的库存" v-if='hasRight("StkStock")'
                  name="StockQuery" >
        <znl-stock :init-data="true"
                 :is-fields-search="true"
                  v-if="isShowStockQuery"
                  :searchModel="stockSearchModel"
                  @get-checked-rows="rows=>{getCheckedRows(rows, 'StockQuery')}"
                  @page-loading="setClientSelectLoading"></znl-stock>
      </el-tab-pane>

      <el-tab-pane label="供应商库存" v-if='hasRight("StockVMI")'
                  name="StockVMIMini">
        <znl-stock-vmi ref="stockvmiMini"
                      :init-data="true"
                      :is-fields-search="true"
                      v-if="isShowStockVMIMini"
                      :searchModel="stockVMISearchModel"
                      @ondblclick="row=>{$emit('ondblclick', row, 'StockVMIMini')}"></znl-stock-vmi>
      </el-tab-pane>

      <el-tab-pane label="采购入库记录" v-if='hasRight("PastStkIn")'
                  name="StkInRecord">
        <znl-stk-in-record :init-data="true"
                      v-if="isShowStkInRecord"
                      :is-fields-search="false"
                      :is-mini="false"
                      :is-init="true"
                      :is-multi-rows-check="false"
                      :searchModel="stkInRecordSearchModel"
                      @ondblclick="row=>{$emit('ondblclick', row, 'StkInRecord')}"></znl-stk-in-record>
      </el-tab-pane>

      <el-tab-pane label="销售出货记录" v-if='hasRight("StkSalesRecord")'
                  name="StkSalesRecord">
        <znl-sales-record
                      v-if="isShowStkSalesRecord"
                      :is-mini="false"
                      :is-fields-search="false"
                      :is-init="true"
                      :init-data="true"
                      :searchModel="salesSearchModel"
                      @ondblclick="row=>{$emit('ondblclick', row, 'StkSalesRecord')}"></znl-sales-record>
      </el-tab-pane>

      <el-tab-pane label="询价记录" v-if='hasRight("PastInquire")'
                  name="Inquire">
        <znl-inquire ref="stockInquire" :init-data="false"
                      v-if="isShowInquire"
                      :is-fields-search="false"
                      :isVisibleAdopt="true"
                       :isShowBtn="true"
                      :has-operation-column="false"
                      :has-search-condition="false"
                      :is-multi-rows-check="true"
                      :is-show-select="true"
                      @ondblclick="row=>{$emit('ondblclick', row, 'Inquire')}"
                      :searchModel="InquireSearchModel"></znl-inquire>
      </el-tab-pane>

      <el-tab-pane label="给客户报价记录" v-if='hasRight("HisOfferPrice")'
                  name="HisOfferPriceByModel">
        <znl-hisofferpricebymodel
              :is-fields-search="false"
              :init-data="true"
              :is-mini="true"
              height="100%"
              layout-type="other"
              :hasOperateBtn="true"
              :isShowSelect="true"
              :btnShow="false"
              v-if="isShowHisOfferPriceByModel"
              :searchModel="HisofferpriceSearchModel"
              @ondblclick="row=>{$emit('ondblclick', row, 'HisOfferPriceByModel')}">
        </znl-hisofferpricebymodel>
      </el-tab-pane>

      <el-tab-pane label="采购订货" v-if='hasRight("StkStockPurchase")'
                  name="PurchaseOrder">
        <znl-stock-purchase
                      :init-data="true"
                      v-if="isShowPurchaseOrder"
                      :is-fields-search="false"
                      header=""
                      layout-type="other"
                      :has-search-input="false"
                      :searchModel="purchaseSearchModel"
                      @ondblclick="row=>{$emit('ondblclick', row, 'StkInRecord')}"></znl-stock-purchase>
      </el-tab-pane>

      <el-tab-pane label="销售备货" v-if='hasRight("StkStockReserve")'
                  name="SalesStocking">
        <znl-stock-reserve :init-data="true"
                      v-if="isShowSalesStocking"
                      header=""
                      :is-fields-search="false"
                      :has-search-input="false"
                      :searchModel="salesStockingSearchModel"
                      @ondblclick="row=>{$emit('ondblclick', row, 'salesStocking')}"></znl-stock-reserve>
      </el-tab-pane>

    </el-tabs>
    </div>
    </znl-layout-topbottom>
</template>

<script>
import ZnlSearchCondition from '@c_modules/Stk/StkSearchCondition'
import ZnlStock from '@c_modules/Stk/StkStock_v2'
import ZnlStockVmi from '@c_modules/Stk/StockVMI_v2'
import ZnlStkInRecord from '@c_modules/Stk/StkInRecord'
import ZnlSalesRecord from '@c_modules/Stk/StkSalesRecord'
import ZnlInquire from '@c_modules/Stk/StkInquireRecord'
import ZnlHisofferpricebymodel from '@c_modules/Ord/HisOfferPrice'
import ZnlStockPurchase from '@c_modules/Stk/StkStockPurchase'
import ZnlStockReserve from '@c_modules/Stk/StkStockReserve'

export default {
  name: 'VStock',
  components: {
    ZnlSearchCondition,
    ZnlStock,
    ZnlStockVmi,
    ZnlStkInRecord,
    ZnlSalesRecord,
    ZnlInquire,
    ZnlHisofferpricebymodel,
    ZnlStockPurchase,
    ZnlStockReserve
  },
  data () {
    return {

      gridShow: false,
      clientSelectLoading: true,
      pickerOptions0: {
      },
      searchModel: {},
      pageData: [],
      pageLoading: true,
      hasPageLoading: true,
      //
      // 提取到view
      hearShow: false,
      config: {
        DictItemURL: 'DictItem/GetItemValue'
      },
      activeName: 'StockQuery',

      isShowStockQuery: false,      // 是否显示 我的库存
      isShowStockVMIMini: false,    // 是否显示 供应商库存
      isShowStkInRecord: false,     // 是否显示 采购入库记录
      isShowStkSalesRecord: false,  // 是否显示 销售出货记录
      isShowInquire: false,         // 是否显示 询价记录
      isShowHisOfferPriceByModel: false, // 是否显示 给客户报价记录
      isShowPurchaseOrder: false,   // 是否显示 采购订货
      isShowSalesStocking: false,    // 是否显示 销售备货

      stockSearchModel: {},         // 我的库存
      stockVMISearchModel: {},      // 供应商库存
      stkInRecordSearchModel: {},    // 采购入库记录
      salesSearchModel: {},         // 销售出货记录
      InquireSearchModel: {},       // 询价记录
      HisofferpriceSearchModel: {}, // 给客户报价记录
      purchaseSearchModel: {},      // 采购订货
      salesStockingSearchModel: {},  // 销售备货

      stockCheckedRows: [] // 我的库存选中的行
    }
  },
  prop: {
    searchModel: {
      type: Object,
      default () {
        return { Model: '' }
      }
    }
  },
  watch: {
    // pageLoading (val) {
    //   console.log('123', val)
    // },
    // hasPageLoading (val) {
    //   console.log('222', val)
    // },
    // 提取到view
    searchModel (val) {
      this.pageSearch()
    }
  },
  methods: {
    async onInit () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return this.$post(pageUrl, { PageCode: 'VStock' }, (data) => {
        this.pageData = data
        // if (data && data[1] && data[1].Code) {
        //   this.activeName = data[1].Code
        // }
        // console.log('actiove', data, data[1].Code)
        if (this.activeName === 'StockQuery') {
          this.isShowStockQuery = true
        }
        if (this.activeName === 'StockVMIMini') {
          this.isShowStockVMIMini = true
        }
        if (this.activeName === 'Inquire') {
          this.isShowInquire = true
        }
        if (this.activeName === 'StkInRecord') {
          this.isShowStkInRecord = true
        }
        if (this.activeName === 'HisOfferPriceByModel') {
          this.isShowHisOfferPriceByModel = true
        }
        if (this.activeName === 'StkSalesRecord') {
          this.isShowStkSalesRecord = true
        }
        if (this.activeName === 'PurchaseOrder') {
          this.isShowPurchaseOrder = true
        }
        if (this.activeName === 'SalesStocking') {
          this.isShowSalesStocking = true
        }
      })
    },
    async getPageData () {
      // let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      // return await this.$post(pageUrl, {PageCode: 'VStock'})
    },

    // onShowFiedsSearch () {
    //   if (!this.isShowFiedsSearch) {
    //     this.isShowFiedsSearch = true
    //   } else {
    //     this.isShowFiedsSearch = false
    //   }
    //   this.onInit()
    //   console.log('isShowFiedsSearch', this.isShowFiedsSearch)
    // },
    setClientSelectLoading (val) {
      this.pageLoading = val
    },
    async ondblclick (row, typeName) {

    },
    pageSearch (val) {
      let _modelInfo = { 'Model': this.searchModel.Model }

      if (this.activeName === 'StockQuery') {
        // 我的库存
        let searchModel = {
          'isNegative': this.searchModel.isNegative,
          'isZero': this.searchModel.isZero,
          'isSureMerge': this.searchModel.isSureMerge,
          'isAlreadyExt': this.searchModel.isAlreadyExt,
          'matchOperation': this.searchModel.matchOperation,
          'Model': _modelInfo.Model
        }
        if (!_.isEqual(this.stockSearchModel, searchModel) || val === undefined) {
          this.isShowStockQuery = true
          this.stockSearchModel = searchModel
        }
      } else if (this.activeName === 'StockVMIMini' && (!_.isEqual(this.stockVMISearchModel, _modelInfo) || val === undefined)) {
        // 供应商库存
        this.isShowStockVMIMini = true
        this.stockVMISearchModel = _modelInfo
      } else if (this.activeName === 'StkInRecord' && (!_.isEqual(this.stkInRecordSearchModel, _modelInfo) || val === undefined)) {
        // 采购入库记录
        this.isShowStkInRecord = true
        this.stkInRecordSearchModel = _modelInfo
      } else if (this.activeName === 'StkSalesRecord' && (!_.isEqual(this.salesSearchModel, _modelInfo) || val === undefined)) {
        // 销售出货记录
        this.isShowStkSalesRecord = true
        this.salesSearchModel = _modelInfo
      } else if (this.activeName === 'Inquire' && (!_.isEqual(this.InquireSearchModel, _modelInfo) || val === undefined)) {
        // 询价记录
        this.isShowInquire = true
        this.InquireSearchModel = _modelInfo
      } else if (this.activeName === 'HisOfferPriceByModel' && (!_.isEqual(this.HisofferpriceSearchModel, _modelInfo) || val === undefined)) {
        // 给客户报价记录
        this.isShowHisOfferPriceByModel = true
        this.HisofferpriceSearchModel = _modelInfo
      } else if (this.activeName === 'PurchaseOrder' && (!_.isEqual(this.purchaseSearchModel, _modelInfo) || val === undefined)) {
        // 采购订货
        this.isShowPurchaseOrder = true
        this.purchaseSearchModel = _modelInfo
      } else if (this.activeName === 'SalesStocking' && (!_.isEqual(this.salesStockingSearchModel, _modelInfo) || val === undefined)) {
        // 销售备货
        this.isShowSalesStocking = true
        this.salesStockingSearchModel = _modelInfo
      }
    },
    getCheckedRows (rows, tabName) {
      this.stockCheckedRows = rows
    },
    // 提取到view
    hasRight (code) {
      let show = _.some(this.pageData, page => page.Code === code)
      if (show) {
        this.hearShow = true
      }
      return show
    }
  },
  async created () {
    // this.getPageData().then((data) => {
    //   this.pageData = data
    //   // if (!this.hasRight('BomMasterDetail')) {
    //   //   }
    //   this.$nextTick(() => {
    //     this.hasPageLoading = false
    //     // initData(this)
    //   })
    // }).catch((e) => {
    //   console.log(e)
    // })

    // 提取到view
    this.onInit().then(e => {
      this.$nextTick(() => {
        this.hasPageLoading = false
        // this.pageLoading = false
      })
    })
  }

}
</script>

<!-- scoped -->
<style lang="scss">
.scoped-bottom-tab {
  flex: 1;
  display: flex;
  flex-direction: column;
  .znl-content {
    display: flex;
    flex-direction: column;
    .base-flex-grid {
      flex: 1;
      display: flex;
      flex-direction: column;
      .el-table {
        flex: 1;
        display: flex;
        flex-direction: column;
        // .el-table__header-wrapper{
        //   height: 28px;
        // }
        .el-table__body-wrapper {
          flex: 1;
        }
      }
      .footer {
        height: 32px;
      }
    }
  }
  > .el-tabs__header {
    height: 38px;
    // border-bottom: 1px solid #ED9E00;
    margin: 0 !important;
    .el-tabs__nav-wrap {
      height: 100%;
      .el-tabs__nav-scroll {
        .el-tabs--top.el-tabs--border-card .el-tabs__item:nth-child(2) {
          padding-left: 0 !important;
        }
        height: 100%;

        .el-tabs__nav {
          // width: 100%;
          height: 100%;
          // padding: 0 5px;
          display: flex;
          // border-top: none;
          align-items: center;
          margin-left: 15px;
          // border: none;
          .el-tabs__item {
            padding: 0 15px !important;
            // height: 20px;
            // line-height: 20px;
            // border: none;
            color: #ccc;
            font-size: 12px;
            transition: none !important;
            &.is-active {
              // background-color: #4A90E2;
              // color: #fff;
              color: #4a90e2;
              border-radius: 2px;
            }
          }
          .el-tabs__item:nth-child(2) {
            padding-left: 0px !important;
          }
        }
      }
    }
    .el-tabs__nav-wrap::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0px;
      background-color: #e4e7ed;
      z-index: 1;
    }
  }
  .el-tabs__content {
    flex: 1;
    display: flex !important;
    flex-direction: column !important;
    .el-tab-pane {
      // height: 100%!important;
      flex: 1;
      display: flex;
      flex-direction: column;
      .znl-grid-component {
        // height: 100%!important;
        display: flex;
        flex-direction: column;
        flex: 1;
        .znl-content {
          flex: 1;
        }
      }
    }
  }
}
</style>
