<template>
  <znl-dialog
    :visible="visible"
    height="550px"
    width="428px"
    v-loading="dialogLoading"
    element-loading-text="数据加载中,请稍后..."
    title="搜索统计"
    :is-footer-show="false"
    @close="()=>{$emit('close')}">
    <znl-gridmodule
      ref="flexGrid"
      :is-init="true"
      :is-mini="true"
      height="100%"
      :columns="columns"
      :item-source="itemSource"
      :role="role"
      :is-show-rightbtns="false"
      :users="$store.state.users"
      :on-init="onInit"
      :on-search="onSearch"
      >
      <div slot="heading-slot">
        <label>最近</label>
        <znl-input
            form-type="input"
            :disabled="false"
            placeholder=""
            size="mini"
            layout="left"
            width='50px'
            inp-perc="100%"
            :clearable="true"
            :border="true"
            v-model="days"
            @keyup.enter.native="$refs.flexGrid.search()"
            type="text">
        </znl-input>
        <label>天</label>
        <a style="" title="今天" @click="onSetDays(1)">今天</a>
        <a style="" title="最近一周" @click="onSetDays(7)">最近一周</a>
        <znl-button class='minor-self-btn' style-type="mac" :height='22' :width='60' @click="$refs.flexGrid.search()" >
          <span><i class="iconfont icon-search_ic"></i> 统计</span>
        </znl-button>
        <znl-button class='minor-self-btn' style-type="mac" :height='22' :width='60' @click="onDownData()" >
          <span><i class="iconfont icon-leading-out-wh_btn_i"></i>导出</span>
        </znl-button>
      </div>
    </znl-gridmodule>
  </znl-dialog>
</template>
<script>

import { exportData } from '@scripts/methods/common'
export default {
  name: 'SearchKeysHistory',
  mixins: [exportData],
  components: {
  },
  data () {
    return {
      config: {
        configURL: 'SearchKeysHistory/GetConfig',
        saveConfigURL: 'SearchKeysHistory/SaveConfig',
        resetConfigURL: 'SearchKeysHistory/ResetConfig',
        searchURL: 'SearchKeysHistory/GetSearchStatistics',
      },
      columns: [],
      itemSource: [],
      dialogLoading: true,
      role: '1',
      days:1,
    }
  },
  props: {
    visible: Boolean
  },
  watch: {
    itemSource () {
      _.delay(() => {
        this.dialogLoading = false
      }, 150)
    }
  },
  methods: {
    onInit: async function () {
      let config = await this.$post(this.config.configURL)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.columns = columns
      this.role = config.Role
      this.onBindData()
    },
    onBindData () {
      let data = _.extend({}, {}, 
      {
        // 外部搜索条件
        Days: this.days
      })
      return this.$post(this.config.searchURL, data, (data) => {
        this.itemSource = data
      })
    },
    onSetDays(sum){
      this.days = sum;
    },
    onSearch (params) {
      return this.onBindData()
    },
    async onDownData (params) {
      let data = _.extend({}, {}, 
      {
        // 外部搜索条件
        Days: this.days
      })
      this.pageLoading = true
      this.pageLoading = (await this.exportData("SearchKeysHistory", data, this.columns, "搜索统计"))
    }
  }
}
</script>