<template>
  <div class="container">
    <znl-dialog
      title="编辑采购订货"
      :visible="visible"
      :loading.sync="loading"
      :loading-text="elementLoadingText"
      height="390px"
      width="530px"
      class="m-stk-purchaseadd-box"
      @close="()=>{$emit('close')}"
      :close-on-click-modal="false"
    >
      <div slot="znl-dialog">
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple-dark">
              <znl-input
                title-width="70px"
                form-type="input"
                placeholder="输入供应商简称或拼音字母,并按回车键查询"
                size="mini"
                layout="left"
                title="供应商："
                width="100%"
                :is-must-fill="false"
                :clearable="true"
                :border="true"
                v-model="stkPurchase.SupplierName"
                @keyup.enter.native="SearchCompany"
                type="text"
              >
                <i slot="suffix" class="iconfont icon-edit_btn_ic" @click="SearchCompany"></i>
              </znl-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="false"
                placeholder
                layout="left"
                title="*型号："
                :is-must-fill="true"
                width="100%"
                :border="true"
                v-model="stkPurchase.Model"
                :clearable="true"
                type="text"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <el-row :gutter="5">
                <el-col :span="17">
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    :disabled="false"
                    placeholder
                    layout="left"
                    title="*订货数量："
                    :is-must-fill="true"
                    :clearable="true"
                    width="100%"
                    :border="true"
                    v-model="stkPurchase.Qty"
                    type="text"
                  ></znl-input>
                </el-col>
                <!--下拉列表-->
                <el-col :span="7">
                  <znl-input
                    form-type="select"
                    :disabled="false"
                    placeholder="请选择"
                    layout="left"
                    :is-must-fill="false"
                    width="100%"
                    :select-options="qtyUnitList"
                    :border="true"
                    :clearable="true"
                    v-model="stkPurchase.Unit"
                    type="text"
                  ></znl-input>
                </el-col>
                <!--下拉列表end-->
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-row :gutter="5">
                <el-col :span="17">
                  <znl-input
                    title-width="70px"
                    form-type="input"
                    :disabled="false"
                    placeholder
                    layout="left"
                    title="单价："
                    :clearable="true"
                    :is-must-fill="false"
                    width="100%"
                    :border="true"
                    v-model="stkPurchase.Price"
                    type="text"
                  ></znl-input>
                </el-col>
                <el-col :span="7">
                  <znl-input
                    title-width="70px"
                    form-type="select"
                    :disabled="false"
                    placeholder="税率"
                    layout="left"
                    :is-must-fill="true"
                    width="100%"
                    :clearable="true"
                    :select-options="taxRateList"
                    :border="true"
                    v-model="stkPurchase.TaxRate"
                    type="text"
                  ></znl-input>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <znl-input
                title-width="70px"
                form-type="datepicker"
                :disabled="false"
                placeholder
                layout="left"
                :clearable="true"
                title="交期："
                :is-must-fill="false"
                width="100%"
                :border="true"
                v-model="stkPurchase.Delivery"
              ></znl-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="false"
                placeholder
                layout="left"
                title="品牌："
                :is-must-fill="true"
                width="100%"
                :border="true"
                :clearable="true"
                type="text"
                v-model="stkPurchase.Brand"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="false"
                placeholder
                layout="left"
                :clearable="true"
                title="封装："
                :is-must-fill="false"
                width="100%"
                :border="true"
                type="text"
                v-model="stkPurchase.Packaging"
              ></znl-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="false"
                placeholder
                layout="left"
                :clearable="true"
                title="包装："
                :is-must-fill="false"
                width="100%"
                :border="true"
                type="text"
                v-model="stkPurchase.MPQ"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="false"
                placeholder
                layout="left"
                title="批号："
                :clearable="true"
                :is-must-fill="false"
                width="100%"
                :border="true"
                type="text"
                v-model="stkPurchase.MakeYear"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="false"
                placeholder
                layout="left"
                title="品质："
                :clearable="true"
                :is-must-fill="false"
                width="100%"
                :border="true"
                v-model="stkPurchase.Quality"
                type="text"
              ></znl-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <znl-input
                title-width="70px"
                style="margin-top:5px"
                form-type="input"
                :disabled="false"
                placeholder
                layout="left"
                :clearable="true"
                title="订货说明："
                :rows="1"
                :is-must-fill="false"
                width="100%"
                :border="true"
                type="text"
                v-model="stkPurchase.Explain"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple-light">
              <znl-input
                title-width="70px"
                form-type="input"
                :disabled="false"
                style="margin-top:5px"
                placeholder
                layout="left"
                :rows="3"
                :clearable="true"
                title="订货备注："
                :is-must-fill="false"
                width="100%"
                :border="true"
                type="text"
                v-model="stkPurchase.Remark"
              ></znl-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <znl-input
                title-width="70px"
                form-type="input"
                style="margin-top:5px"
                :disabled="false"
                placeholder
                layout="left"
                :clearable="true"
                title="订货备注2："
                :rows="3"
                :is-must-fill="false"
                width="100%"
                :border="true"
                type="text"
                v-model="stkPurchase.Remark2"
              ></znl-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <znl-input
                title-width="70px"
                form-type="input"
                style="margin-top:5px"
                :disabled="false"
                placeholder
                layout="left"
                :clearable="true"
                title="订货备注3："
                :rows="3"
                :is-must-fill="false"
                width="100%"
                :border="true"
                type="text"
                v-model="stkPurchase.Remark3"
              ></znl-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-row align="middle" type="flex" justify="end" slot="footer" class="dialog-footer">
        <el-col style="display: flex;justify-content: flex-end;align-items: center">
          <el-checkbox v-model="IscontinueSave" style="margin-right:5px"></el-checkbox>
          <label for="continueSave" style="margin-right:5px;">保存后继续操作</label>
          <znl-button @click="onClear" :height="33" :width="60" style="margin-right:5px;">清空</znl-button>
          <znl-button style-type="main" :height="33" :width="60" @click="Save">保存</znl-button>
        </el-col>
      </el-row>
    </znl-dialog>
    <d-company-contact-select
      v-if="dialogCompanySelectVisible"
      :visible="dialogCompanySelectVisible"
      companyType="2,3"
      title="选择供应商名称"
      :CompanyName="stkPurchase.SupplierName"
      :searchModel="SelectSearchModel"
      @close="()=>{dialogCompanySelectVisible=false}"
      @update:visible="val=>{dialogCompanySelectVisible=val}"
      @confirm="companyChecked"
    ></d-company-contact-select>
  </div>
</template>
<script>
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { DatePicker } from 'element-ui'
import VCompanySelect from '@c_modules/Crm/DCompanyContactSelect'
import dCompanyContactSelect from '../Crm/DCompanyContactSelect'

const defaultContent = {
  SPID: -1,
  SPGUID: '',
  StkGUID: '',
  SupplierGUID: '', // 供应商GUID
  SupplierName: '', // 供应商名
  Model: '', // 型号
  Qty: '', // 订货数量
  Unit: 'PCS', // 单位
  Price: '', // 单价
  CurrencyCode: '', // 币种
  Delivery: new Date().toLocaleDateString(), // 交期
  Brand: '', // 品牌
  Packaging: '', // 封装
  MPQ: '', // 包装
  MakeYear: '', // 年份
  Quality: '', // 品质
  Explain: '', // 说明
  Remark: '', // 备注
  Remark2: '', // 备注2
  Remark3: '' // 备注3
}

export default {
  name: 'StkWarningAdd',
  mixins: [getCommonDataMixin],
  components: {
    elDatePicker: DatePicker,
    VCompanySelect,
    dCompanyContactSelect
  },
  data () {
    return {
      dialogCompanySelectVisible: false, // 是否显示客户选择框
      InTypes: '', // 类型
      IscontinueSave: false, // 是否继续保存备货
      SelectSearchModel: {
        CompanyName: ''
      },
      stkPurchase: {
        // 库存订货
        SPID: -1,
        SPGUID: '',
        StkGUID: '',
        SupplierGUID: '', // 供应商GUID
        SupplierName: '', // 供应商名
        Model: '', // 型号
        Qty: '', // 订货数量
        Unit: 'PCS', // 单位
        Price: '', // 单价
        CurrencyCode: 'RMB', // 币种
        TaxRate: null, // 税点
        Delivery: new Date().toLocaleDateString(), // 交期
        Brand: '', // 品牌
        Packaging: '', // 封装
        MPQ: '', // 包装
        MakeYear: '', // 年份
        Quality: '', // 品质
        Explain: '', // 说明
        Remark: '', // 备注
        Remark2: '', // 备注2
        Remark3: '' // 备注3
      },
      config: {
        stkReverseSave: 'StkStockPurchase/Save',
        SearchByGUID: 'StkStock/GetStockListByGuids',
        SearchBySRGUID: 'StkStockPurchase/SearchBySRGUID',
        DropDownURL: 'DictItem/GetItemValue',
        CurrencyCodeURL: 'SysCurrency/Search'
      },
      loading: false,
      elementLoadingText: '正在保存数据,请稍后...',
      qtyUnitList: [],
      taxRateList: []
    }
  },
  props: {
    isRefresh: Boolean, // 是否刷新
    stkInfo: String, // 库存信息传递
    isLoadByStk: Boolean, // 是否根据库存信息加载
    PurchaseGUID: String, // 我的订单GUID
    visible: Boolean,
    stkGuid: null
  },
  watch: {
    isLoadByStk: {
      handler (value) {
        if (!_.isEmpty(this.stkInfo)) {
          this.LoadModelByStock(this.stkInfo)
        }
      },
      immediate: true
    }
  },
  methods: {
    async onInit () {
      let unit = await this.selectOptionsQtyUnit
      let taxrate = await this.selectOptionsTaxRate
      this.$nextTick(() => {
        this.qtyUnitList = unit
        this.taxRateList = taxrate
      })
      if (this.hasValue(this.stkGuid)) {
        await this.$post(this.config.SearchByGUID, { StkGUID: this.stkGuid }, (data, logic) => {
          if (logic.code !== 200) {
            this.$message({ message: logic.msg, type: 'error' })
          } else {
            if (this.hasValue(data) && data.length > 0) {
              this.onLoad(data[0])
            }
          }
        })
      }
      if (this.hasValue(this.PurchaseGUID)) {
        this.onBound(this.PurchaseGUID)
      }
    },
    onResetPage () {
      // 重置页面
      this.stkPurchase.SPID = -1 // SRID
      this.stkPurchase.SPGUID = ''
      this.stkPurchase.StkGUID = ''
      this.stkPurchase.SupplierGUID = '' // 供应商GUID
      this.stkPurchase.SupplierName = '' // 供应商名
      this.stkPurchase.Model = '' // 型号
      this.stkPurchase.Qty = '' // 订货数量
      this.stkPurchase.Unit = 'PCS' // 单位
      this.stkPurchase.Price = '' // 单价
      this.stkPurchase.Delivery = new Date().toLocaleDateString() // 交期
      this.stkPurchase.Brand = '' // 品牌
      this.stkPurchase.Packaging = '' // 封装
      this.stkPurchase.MPQ = '' // 包装
      this.stkPurchase.MakeYear = '' // 年份
      this.stkPurchase.Quality = '' // 品质
      this.stkPurchase.Explain = '' // 说明
      this.stkPurchase.Remark = '' // 备注
      this.stkPurchase.Remark2 = '' // 备注2
      this.stkPurchase.Remark3 = '' // 备注3
    },
    LoadModelByStock: async function (stk) {
      // 根据库存的信息进行复制新增
      this.onResetPage()
      let stkModel = await this.$post(this.config.SearchByGUID, {
        StkGUIDS: JSON.parse(stk)
      })
      this.onLoad(stkModel)
    },
    onLoad (stkModel) {
      this.$nextTick(function () {
        this.stkPurchase.SPID = -1
        this.stkPurchase.StkGUID = stkModel.StkGUID
        this.stkPurchase.Model = stkModel.Model // 型号
        this.stkPurchase.Qty =
          stkModel.InvQty < 0 ? -stkModel.InvQty : stkModel.InvQty // 订货数量
        this.stkPurchase.Unit =
          stkModel.Unit === '' || _.isUndefined(stkModel.Unit)
            ? 'PCS'
            : stkModel.Unit // 单位
        this.stkPurchase.Delivery = new Date().toLocaleDateString() // 交期
        this.stkPurchase.Brand = stkModel.Brand // 品牌
        this.stkPurchase.Packaging = stkModel.Packaging // 封装
        this.stkPurchase.MPQ = stkModel.MPQ // 包装
        this.stkPurchase.MakeYear = stkModel.MakeYear // 年份
        this.stkPurchase.Quality = stkModel.Quality // 品质
      })
    },
    Save () {
      // 保存
      if (!this.hasValue(this.stkPurchase.Model)) {
        this.$message({ message: '型号不能为空', type: 'wa' })
        return
      }
      if (!this.hasValue(this.stkPurchase.Brand)) {
        this.$message({ message: '品牌不能为空', type: 'error' })
        return
      }
      if (
        this.stkPurchase.Qty === '' ||
        this.stkPurchase.Qty <= 0 ||
        !isFinite(this.stkPurchase.Qty)
      ) {
        this.$message({
          message: '数量不能为空且不能小于零的数字',
          type: 'error'
        })
        return
      }
      this.loading = true
      this.$post(this.config.stkReverseSave, this.stkPurchase, datas => {
        this.loading = false
        if (datas > 0) {
          this.$message({ message: '保存成功', type: 'success' })
          this.onResetPage()
          if (!this.IscontinueSave) {
            this.$emit('confirm', true)
          }
        } else {
          this.$message({ message: '保存失败', type: 'error' })
        }
      })
    },
    SearchCompany () {
      // 显示供应商选择框
      this.InTypes = '2,3'
      this.dialogCompanySelectVisible = true
      this.SelectSearchModel.CompanyName = this.stkPurchase.SupplierName
    },
    companyChecked (item) {
      // 选择后的值
      this.stkPurchase.SupplierName = item.CompanyName
      this.stkPurchase.SupplierGUID = item.CCompanyID
    },
    onClear () {
      this.onResetPage()
    },

    onBound: async function (srguid) {
      // 绑定页面
      let RModel = await this.$post(this.config.SearchBySRGUID, {
        SPGUID: srguid
      })
      this.$nextTick(function () {
        this.stkPurchase = _.extend({}, defaultContent, RModel)
        this.stkPurchase.Unit =
          this.stkPurchase.Unit === '' ? 'PCS' : this.stkPurchase.Unit // 单位(PCS)
      })
    }
  },
  created () {
    this.$nextTick(() => {
      this.onInit()
    })
  }
}
</script>

<style lang="scss">
.m-stk-purchaseadd-box .form_Row_W100 {
  width: 100% !important;
  padding-top: 5px;
}
.container {
  .grid-content {
    padding: 3px;
  }
}
</style>
