<template>
<!-- 给客户报价记录 -->
  <znl-gridmodule ref="flexGrid"
    header=""
    height="100%"
    class="BomDetailLine"
    :columns="columns"
    :item-source="itemSource"
    :gridtype="gridType"
    :is-init="true"
    :is-show-rightbtns="false"
    :btn-mouse-enter-menus="btnMouseEnterMenus"
    :is-fields-search="isShowFiedsSearch"
    :show-header="false"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :on-init="onInit"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :on-save-setting="onSaveSetting"
    :on-save-config='onSaveConfig'
    :on-reset-setting="onResetSetting"
    :on-page-changed="onBindData"
    :loading="loading"
    :is-activity="isActivity"
    :is-multi-rows-check-fixed="false"
    @switch-to-hide="val=>{$emit('switch-to-hide',val)}">

    <stk-out-edit :title="StkOutAddTitle"
    :editvisible="stkOutEditVisible"
    v-if="stkOutEditVisible"
    :edit-type="editType"
    :autoAddNewRow="false"
    @confirm="stkOutEditVisible=false"
    @close="stkOutEditVisible=false">
  </stk-out-edit>
  <stk-in-edit :title="StkInAddTitle"
    :editvisible="stkInEditVisible"
    v-if="stkInEditVisible"
    :edit-type="editType"
    :autoAddNewRow="false"
    @confirm="stkInEditVisible=false"
    @close="stkInEditVisible=false">
  </stk-in-edit>
  </znl-gridmodule>

</template>
<script>
// const CONFIG = {
//   configURL: 'BomDetailLine/GetConfig',
//   saveConfigURL: 'BomDetailLine/SaveConfig',
//   resetConfigURL: 'BomDetailLine/ResetConfig',
//   searchURL: 'BomDetailLine/Search',
//   bomConfigURL: 'BomMaster/GetConfig'
// }

// BomMastLineSelect
const CONFIG = {
  configURL: 'BomMastLineSelect/GetConfig',
  saveConfigURL: 'BomMastLineSelect/SaveConfig',
  resetConfigURL: 'BomMastLineSelect/ResetConfig',
  searchURL: 'BomMastLineSelect/Search'
}
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'
import StkInEdit from '@c_modules/Stk/StkInEdit'
import { openLinkAll } from '@scripts/methods/common'

export default {
  name: 'MQuoteRecord',
  mixins: [znlMethodsMixin, getCommonDataMixin, openLinkAll],
  config: CONFIG,
  components: {
    StkOutEdit,
    StkInEdit
  },
  props: {
    header: {
      type: String,
      default: '给客户报价记录'
    },
    pagePosition: {
      type: String,
      defaule: 'bottom'
    },
    gridType: {
      type: String,
      default: 'base'
    },
    initData: {
      type: Boolean,
      default: false
    },
    isShowFiedsSearch: false,
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isActivity: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'auto'
    },
    hideColumns: {
      type: Array,
      default: () => {
        return [
          'DscQuotePriceText',
          'LiChuangPriceText',
          'YunHanPriceText',
          'DigikeyPriceText',
          'BuyPrice',
          'BuyerQuoteTime',
          'BuyerName',
          'QuoteRemark',
          'BuyerRemark',
          'Remark'
        ]
      }
    },
    isShowBtn: {
      type: Boolean,
      dafault: false
    },
    isShowOperate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      loading: false,
      resourceList: [],
      searchFields: {
        Model: ''
      },
      IsStatus: true,
      gridShow: false,
      editType: 'addNew',
      StkOutAddTitle: '新增发货单',
      stkOutEditVisible: false, // 新增发货单弹窗
      StkInAddTitle: '新增入库单',
      stkInEditVisible: false,
      btnMouseEnterMenus: {
        List: []
      }
    }
  },
  watch: {
    searchModel () {
      this.searchFields = this.searchModel
    }
  },
  methods: {
    async onInit (config, refresh) {
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('bomMastLineSelect', CONFIG.configURL)
      }

      // 获取模块打开或者关闭的状态
      this.GetStatus(config.Status)
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      // 列的初始化
      this.settingColumns(columns)
      // 操作按钮初始化
      this.settingActionBtn(columns)
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      this.defaultConfig = config
      if (this.initData || refresh) {
        this.onBindData()
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    // 列的初始化
    settingColumns (columns) {
      _.each(columns, (column, index) => {
        if (column.binding === 'Model' ||
          column.binding === 'SupplierName' ||
          column.binding === 'CustomerName' ||
          column.binding === 'BillNo') {
          _.extend(column,
            {
              className: 'znl-linkAll-column',
              renderCell: this.openLinkAll(column)
            })
        }
      })
      // 隐藏不需要的列
      if (this.hideColumns && this.hideColumns.length > 0) {
        for (let index = 0; index < columns.length; index++) {
          if (this.hideColumns.indexOf(columns[index].binding) !== -1) {
            columns[index].hasRole = false
          }
        }
      }
      // 不看客户资料
      let hideCols = ',CustomerName,Contact,Telephone,Email,Address,QQ,'
      _.each(columns, (item, index) => {
        if (this.getSpecialResourceByCode('NoSeeCustomer') && hideCols.indexOf(item.binding) > 0) {
          item.hasRole = false
        }
      })

      // 处理特殊权限
      let hideBuyPrice = ',BuyPrice,' // 供应商报价
      let hideRate = ',ProfitRate,' // 不看毛利率
      if (
        !this.getSpecialResourceByCode('ViewBuyPrice') &&
        hideBuyPrice.indexOf(columns.binding) > -1
      ) {
        columns.hasRole = false
      }
      if (
        this.getSpecialResourceByCode('NoSeeProfit') &&
        hideRate.indexOf(columns.binding) > -1
      ) {
        columns.hasRole = false
      }
    },
    // 操作按钮初始化
    settingActionBtn (columns) {
      !this.isShowBtn
        ? ''
        : columns.unshift({
          // 操作列
          name: 'operate',
          dataType: 5,
          binding: 'operate',
          width: 36,
          header: '操作',
          visible: true,
          isReadOnly: true,
          isFieldsSearch: false,
          isSystem: true,
          align: 'left',
          className: 'znlerp-operate znl-text-center',
          buttons: [
            {
              content: '<i class="iconfont icon-left_nav_show_btn"></i>',
              className: 'znl-table-btn',
              click: () => { }
            }
          ]
        })
      this.btnMouseEnterMenus = {
        List: [
          {
            name: '销售出货',
            isShow: this.hasRes('OnStkOut'),
            click: row => {
              this.onAddStkOut(row)
            }
          },
          {
            name: '采购入库',
            isShow: this.hasRes('OnStkIn'),
            click: row => {
              this.onAddStkIn(row)
            }
          }
        ],
        Binding: 'operate'
      }

      this.isShowOperate && this.hasRes('OnStkIn') && !this.onlySalesPurchaseOrder ? columns.unshift({
        name: 'stkIn',
        dataType: 5,
        binding: 'stkIn',
        width: 30,
        header: '入',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '入',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              this.onAddStkIn(row)
            }
          }
        ]
      }) : ''
      this.isShowOperate && this.hasRes('OnStkOut') && !this.onlySalesPurchaseOrder ? columns.unshift({
        name: 'stkOut',
        dataType: 5,
        binding: 'stkOut',
        width: 30,
        header: '出',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '出',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              this.onAddStkOut(row)
            }
          }
        ]
      }) : ''
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    async onHeadSearch (params) {
      this.searchFields.Model = params.Model
      await this.onBindData(1)
    },
    async onSearch (params = {}) {
      _.extend(this.searchFields, params)
      await this.onBindData(1)
    },
    async onBindData (pageIndex = 1) {
      this.loading = true
      var searchFields = JSON.stringify(this.searchFields)
      let filterFields = {
        Status: ['销售已报']
      }
      let response = await this.$post(
        this.$options.config.searchURL,
        _.extend(
          {
            FieldWhereString: searchFields,
            PageIndex: pageIndex,
            PageSize: this.pageSize
          },
          filterFields
        )
      )
      let itemSource = response.ResultList
      this.pageIndex = pageIndex
      this.itemSource = itemSource
      this.totalCount = response.TotalCount
      this.loading = false
      this.$emit('page-loading', false)
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('bomMastLineSelect')
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      )
    },
    async onResetSetting () {
      localStorage.removeItem('bomMastLineSelect')
      await this.$post(this.$options.config.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('bomMastLineSelect')
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      )
    },
    hasRes (code) {
      return _.some(this.resourceList, m => m === code)
    },
    // 获取模块状态
    // GetStatus (val) {
    //   this.$nextTick(() => {
    //     this.$refs.flexGrid && this.$refs.flexGrid.toggleTableShow(val !== 0)
    //   })
    // },
    // 获取模块状态
    GetStatus (val) {
      if (this.isActivity) {
        this.Status = val !== 0
        this.Status ? this.$refs.flexGrid.toggleTableShow(true) : this.$refs.flexGrid.toggleTableShow(false)
      } else {
        this.$refs.flexGrid.toggleTableShow(true)
      }
    },
    // 保存模块状态
    saveStatus (val) {
      let Status = val ? 1 : 0
      let defaultConfig = this.defaultConfig
      if (defaultConfig !== undefined && Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    },
    // 生成发货单
    onAddStkOut (row) {
      // console.log('onAddStkOut', row)
      let guid = [row.BDLineGUID]
      if (guid !== null && guid.length > 0) {
        let data = { BDLineGuids: guid }
        let order = { bomInfo: data }
        this.$store.state.visibled = false
        this.$store.commit('setParamOrder', order)
        this.$nextTick(function () {
          this.editType = 'addNew'
          this.stkOutEditVisible = true
        })
      }
    },
    // 生成入库单
    onAddStkIn (row) {
      // console.log('onAddStkIn', row)
      let guid = [row.BDLineGUID] // 选中行的bdlineguid数组
      if (guid !== null && guid.length > 0) {
        let data = { BDLineGuids: guid }
        let order = { bomInfo: data }
        this.$store.state.visibled = false
        this.$store.commit('setParamOrder', order)
        this.$nextTick(function () {
          this.editType = 'add'
          this.stkInEditVisible = true
        })
      }
    }
  },
  computed: {}
}
</script>
