<template>
  <znl-gridmodule :gridtype="gridtype"
                  ref="flexGrid"
                  layoutType="spa"
                  height="100%"
                  :header="header"
                  :action-btns="actionBtns"
                  :columns="columns"
                  :item-source="itemSource"
                  :is-fields-search="true"
                  :is-multi-rows-check="true"
                  :show-save-row-right-menu="showSaveRowRightMenu"
                  :show-add-row-right-menu="showAddRowRightMenu"
                  :show-delete-row-right-menu="showDeleteRowRightMenu"
                  :search-fields="searchModel"
                  :page-size="pageSize"
                  :page-index="pageIndex"
                  :total-count="totalCount"
                  :role="role"
                  :has-znl-actions="this.Urltype!==null&&this.Urltype==='0'?true:false"
                  :on-init="onInit"
                  :is-init="false"
                  :on-refresh="onRefresh"
                  :on-search="onSearch"
                  :on-save-row="onSaveRows"
                  :on-save-rows="onSaveRows"
                  :on-delete-row="OnStkDelete"
                  :on-save-setting="onSaveSetting"
                  :on-save-config='onSaveConfig'
                  :on-reset-setting="onResetSetting"
                  :on-page-changed="onBindData"
                  :isShowPagination="isShowPagination"
                  :isGridContentChange="isGridContentChange"
                  :users="$store.state.users"
                  :grid-show="gridShow"
                  @edit:cell-ended="endedCellEdit"
                  @has-base-flex-grid="(val)=>{$emit('has-base-flex-grid', val)}"
                  @page-changed="onBindData"
                  @page-loading="d=>{$emit('page-loading', d)}"
                  @selection-changed="d=>{this.stkstockSelectItem=d,$emit('selection-changed',d)}"
                  checkboxBindingKey="SSGuid">
    <div slot="action-form"
         v-if="this.hasRes('IsOpen')">
      <!-- -->
      已启用&nbsp;
      <el-tooltip :content="syncQtyTitle"
                  placement="top">
        <el-switch v-model="enableSharedStock"
                   @change="switchChange"
                   active-color="#409EFF"
                   inactive-color="#ff4949">
        </el-switch>
      </el-tooltip>
    </div>
    <div slot="znl-footer-slot"
         class="StsSalesOut-footer"
         v-show="this.Urltype==1?false:true">
      <el-row>
        <el-col :span="12">
          <span>
            <span>
              已共享 :
              <span class="stsPurchaseIn-amount">{{this.ShareCount}} 条</span>&nbsp;&nbsp; 未共享 :
              <span class="stsPurchaseIn-amount">{{this.UnShareCount}} 条</span>&nbsp;&nbsp;
            </span>
          </span>
        </el-col>
      </el-row>
    </div>
  </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
const CONFIG = {
  configURL: 'PubSStock/GetConfig',
  StkconfigURL: 'PubSharedStock/GetConfig',
  saveRowsURL: 'PubSStock/BatchSave',
  searchURL: 'PubSStock/Search', // 我的共享库存查询
  OpenOrCloseStockShared: 'PubSStockCust/OpenOrCloseStockShared',
  setIsSharedUrl: 'PubSStockCust/OpenOrCloseModelSharedByGuids',
  setIsOpenSharedUrl: 'PubSStockCust/OpenModelSharedByGuids',
  setIsCloseSharedUrl: 'PubSStockCust/CloseModelSharedByGuids',
  GetShaedStockURL: 'PubSharedStock/GetShaedStock', // 共享库存查询
  GetShareStockStatistics: 'PubSStock/GetShareStockStatistics',
  DeleteURL: 'PubSStock/BatchDelete',
  default: null
}

export default {
  name: 'PUB_SStock',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {

  },
  data () {
    return {
      actionBtns: [
      ],
      gridShow: false,
      stkstockSelectItem: [], // 选中的库存数据
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      showSaveRowRightMenu: false,
      showAddRowRightMenu: false,
      showDeleteRowRightMenu: false,
      resourceList: [],
      specialResource: [],
      isShowPagination: true,
      isGridContentChange: false,
      enableSharedStock: false,
      syncQtyTitle: '启用共享库存，列表中已打开共享的型号将会被分享到共享库存中',
      ShareCount: 0,
      UnShareCount: 0
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String,
    tagName: String,
    Urltype: String,
    header: String,
    gridtype: String
  },
  watch: {
    selectOptionsQtyUnit (val) {
      _.each(this.columns, column => {
        if (column.binding === 'Unit') {
          this.$set(column, 'dataMap', val)
        }
      })
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        this.Urltype !== null && this.Urltype === '1' ? config = await this.isSaveStorage('pubStockSharedS', CONFIG.StkconfigURL) : config = await this.isSaveStorage('pubStockShared', CONFIG.configURL)
        // this.Urltype !== null && this.Urltype === '1' ? config = await this.$post(CONFIG.StkconfigURL) : config = await this.$post(CONFIG.configURL)
      }
      this.resourceList = config.ResourceList
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      // 下拉列表
      let QtyUnitDropDown = this.selectOptionsQtyUnit
      // console.log(this.selectOptionsQtyUnit, 'this.selectOptionsQtyUnit')
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )
      // 库存类型
      let stkTypeColumn = _.find(columns, column => column.name === 'InvType')
      stkTypeColumn && _.extend(stkTypeColumn, {
        dataMap: this.selectStorageType,
        showDropDown: true
      })
      // 是否开启共享
      let IsShared = _.find(columns, column => column.name === 'IsShared')
      IsShared && _.extend(IsShared, {
        dataMap: [{ 'key': '2', 'value': '是' }, { 'key': '1', 'value': '否' }],
        showDropDown: true
      })
      // 仓库
      let WarehouseOptions = this.selectOptionsWarehouseOptions
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'InvPosition'
        }),
        { dataMap: WarehouseOptions, showDropDown: true }
      )

      !this.hasRes('OpenOrClose') ? ''
        : this.Urltype !== null && this.Urltype === '1'
          ? '' : columns.unshift({
            'name': 'IsextensionResult',
            'dataType': 5,
            'binding': 'IsextensionResult',
            'width': 36,
            'header': '共享',
            'visible': true,
            'allowSorting': false,
            'isReadOnly': true,
            'isFieldsSearch': false,
            'isSystem': true,
            'buttons': [{
              'content': '<span><i class="iconfont icon-table_share_btn"></i></span>',
              'className': (d) => d.IsShared ? 'znl-table-btn-success' : 'znl-table-btn-default',
              'when': true,
              'click': (row) => {
                let ssguids = []
                ssguids.push(row.ShareGuid)
                if (row.IsShared) {
                  this.setIsShared(ssguids, 0, 0)
                } else {
                  this.setIsShared(ssguids, 1, 0)
                }
              }
            }]
          })

      // 操作列
      this.Urltype !== '1' ? '' : columns.unshift({
        'name': 'operate',
        'dataType': 5,
        'binding': 'operate',
        'width': 60,
        // 'width': 145,
        'header': '操作',
        'visible': true,
        'allowSorting': false,
        'isReadOnly': true,
        'isFieldsSearch': false,
        'isSystem': true,
        'isShow': false,
        'buttons': [
          {
            'content': '出',
            'className': 'znl-table-btn-success', // (d) => d.IsReceived === true ? 'success' : '',
            'when': this.hasRes('StkOutAdd'),
            'click': (row) => {
              this.onStkOutadd(row)
            }
          },
          {
            'content': '入',
            'className': 'znl-table-btn-success', // (d) => d.IsStkOuted === true ? 'success' : '',
            'when': this.hasRes('StkInAdd'),
            'click': (row) => {
              this.onStkInadd(row)
            }
          },
          {
            'content': '报',
            'className': 'znl-table-btn-success', // (d) => d.IsReceived === true ? 'success' : '',
            'when': this.hasRes('Quote'),
            'click': (row) => {
              this.onBomAdd(row)
            }
          }
        ]
      })

      this.Urltype !== null && this.Urltype === '1'
        ? this.actionBtns = [
          {
            name: '批量出库',
            isShow: this.hasRes('StkOutAdd'),
            iconName: 'icon-sell-wh_btn_ic',
            click: () => { this.stkOutAddRows() }
          },
          {
            name: '批量入库',
            iconName: 'icon-buy-wh_btn_ic',
            isShow: this.hasRes('StkInAdd'),
            click: () => { this.stkInAddRows() }
          }, {
            name: '批量报价',
            isShow: this.hasRes('Quote'),
            iconName: 'icon-quoted-price_btn_ic',
            click: () => { this.stkCustomerRequest() }
          }
        ] : this.actionBtns = [
          //  {
          //    name: '批量出库',
          //    isShow: this.hasRes('StkOutAdd'),
          //    iconName: 'icon-sell-wh_btn_ic',
          //    click: () => { this.stkOutAddRows() }
          //  },
          //  {
          //    name: '批量入库',
          //    iconName: 'icon-buy-wh_btn_ic',
          //    isShow: this.hasRes('StkInAdd'),
          //    click: () => { this.stkInAddRows() }
          //  }, {
          //    name: '批量报价',
          //    isShow: this.hasRes('Quote'),
          //    iconName: 'icon-quoted-price_btn_ic',
          //    click: () => { this.stkCustomerRequest() }
          //  },
          {
            name: '批量删除',
            isShow: this.hasRes('Delete'),
            iconName: 'icon-delete_btn_ic',
            click: () => { this.OnStkDelete() }
          }, {
            name: ' 开启共享',
            iconName: 'icon-share_add_btn',
            isShow: this.hasRes('OpenOrClose'),
            click: () => { this.batchSetIsShared() }
          },
          {
            name: '取消共享',
            iconName: 'icon-share_cancel_btn',
            isShow: this.hasRes('OpenOrClose'),
            click: () => { this.batchCancelIsShared() }
          }
        ]

      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      // console.log('pageIndex', this.pageIndex)
      this.pageSize = config.PageSize
      this.showDeleteRowRightMenu = this.hasRes('Delete')
      this.showSaveRowRightMenu = this.hasRes('Save')
      this.initData && this.onBindData()
      this.getShareStockStatistics()
    },
    // 批量删除
    OnStkDelete () {
      let guid = this.onSelectRows()
      if (guid.length > 0) {
        this.$confirm('删除确认, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          return this.onPostDelete(guid)
        })
      }
    },
    onSelectRows () { // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (this.stkstockSelectItem.length === 0) { // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          guid.push(this.stkstockSelectItem.ShareGuid)
        }
      } else {
        guid = _.map(rows, item => { return item.ShareGuid })
      }
      return guid
    },
    onPostDelete (guid) { // 删除请求
      return this.$post(CONFIG.DeleteURL, { ShareGuidList: guid }, (data) => {
        if (data) {
          this.$message({ message: '删除成功', type: 'success' })
          // this.refreshCheckedCount(guid)
          this.onRefresh()
          this.$refs.flexGrid.clearSelection()
        } else { this.$message({ message: '删除失败', type: 'error' }) }
      })
    },
    onSaveSetting (columns, pageSize) {
      this.Urltype !== null && this.Urltype === '1' ? localStorage.removeItem('pubStockSharedS') : localStorage.removeItem('pubStockShared')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.config.default))
    },
    onResetSetting () {
      this.Urltype !== null && this.Urltype === '1' ? localStorage.removeItem('pubStockSharedS') : localStorage.removeItem('pubStockShared')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      this.Urltype !== null && this.Urltype === '1' ? localStorage.removeItem('pubStockSharedS') : localStorage.removeItem('pubStockShared')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, this.config.default))
    },
    batchSetIsShared () {
      // 批量设置同步
      var rows = this.$refs.flexGrid.getSelectedRows()
      var ssguids = rows.map(function (v) {
        return v.ShareGuid
      })
      this.setIsShared(ssguids, 1, 1)
    },
    batchCancelIsShared () {
      // 批量取消同步
      var rows = this.$refs.flexGrid.getSelectedRows()
      var ssguids = rows.map(function (v) {
        return v.ShareGuid
      })
      this.setIsShared(ssguids, 0, 2)
    },

    setIsShared (ssguids, shared, state) {
      if (ssguids.length <= 0) {
        this.$message({ message: '请选择要共享的库存', type: 'warning' })
        return
      }
      if (shared === null || shared === undefined) return
      let url = CONFIG.setIsSharedUrl
      if (state === 1) { url = CONFIG.setIsOpenSharedUrl } else if (state === 2) { url = CONFIG.setIsCloseSharedUrl }
      var jsonData = _.extend({}, { IsShared: shared }, { ShareGuid: ssguids }, { ShareGuidList: ssguids })
      // console.log(1111, jsonData)
      this.$confirm((shared === 1 ? '确定要开启【' : '确定要取消【') + ssguids.length + '】条共享库存?', '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        this.$post(url, jsonData, data => {
          if (data > 0) {
            this.$refs.flexGrid.clearSelection()
            this.$message({ message: '操作成功', type: 'success' })
          } else {
            this.$message({ message: '操作失败', type: 'error' })
          }
        }).then(() => {
          this.onBindData(this.pageIndex)
        })
      })
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      },
        {
          // 外部搜索条件
          CCompanyGuid: this.searchModel.SCustGuid
        })
      this.stkstockSelectItem = []
      return this.$post(this.Urltype !== null && this.Urltype === '1' ? CONFIG.GetShaedStockURL : CONFIG.searchURL, data, (datas, logiccode) => {
        // if (datas.logiccode === 120) {
        //   this.$message({ message: datas.logicmsg, type: 'warning' })
        // } else {
        this.pageIndex = pageIndex
        this.itemSource = datas === null ? [] : datas.ResultList
        this.totalCount = datas === null ? 0 : datas.TotalCount
        this.$emit('page-loading', false)
        // }
        this.gridShow = true
      })
    },
    // 单元格结束触发事件
    endedCellEdit (aftervalue, beforevalue, colname, gridobj, rowindex, colindex) {
      // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // 修改的行
      // 销售定价
      if (colname === 'SalesPrice') {
        this.itemSource[rowindex]['SalesPrice'] = this.toFloat(aftervalue, 6)
      }
      // 采购价(含税)

      if (colname === 'TaxBuyPrice') {
        this.itemSource[rowindex]['TaxBuyPrice'] = this.toFloat(aftervalue, 6)
      }
      // 共享价
      if (colname === 'SharePrice') {
        this.itemSource[rowindex]['SharePrice'] = this.toFloat(aftervalue, 6)
      }
      // 建议售价
      if (colname === 'FollowPrice') {
        this.itemSource[rowindex]['FollowPrice'] = this.toFloat(aftervalue, 6)
      }
      // 采购价(未税)
      if (colname === 'BuyPrice') {
        this.itemSource[rowindex]['BuyPrice'] = this.toFloat(aftervalue, 6)
      }
      // 库存量
      if (colname === 'InvQty') {
        this.itemSource[rowindex]['InvQty'] = this.toFloat(aftervalue, 2)
      }
      // 指导价
      if (colname === 'BatchPrice') {
        this.itemSource[rowindex]['BatchPrice'] = this.toFloat(aftervalue, 6)
      }
      // 共享量
      if (colname === 'ShareQty') {
        this.itemSource[rowindex]['ShareQty'] = this.toFloat(aftervalue, 2)
      }
    },
    onSearch (params) {
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },

    onRefresh () {
      this.getShareStockStatistics()
      return this.onBindData(this.pageIndex)
    },
    onSaveRows (rows) {
      if (rows.length > 0) {
        return this.$post(CONFIG.saveRowsURL, rows, (data) => {
          console.log('data', data)
          if (data > 0) {
            this.$message({ message: '保存成功', type: 'success' })
            this.isGridContentChange = false
            this.$refs.flexGrid.$refs.actionFlexGrid.saveScueess()
          } else {
            this.$message({ message: '保存失败', type: 'error' })
          }
        })
      }
    },
    onDeleteRow (deleteData) { // 删除行
      let guid = []
      guid.push(deleteData.StkGUID)
      this.onPostDelete(guid)
    },

    clearSelectedRows () {
      this.$refs.flexGrid.clearSelection()
    },
    onStkOutadd (rows) { // 销售出库
      // let order = { setParamShared: [rows] }
      // this.$store.commit('setParamOrder', order)
      // this.$store.commit('removeTab', 'Stk/StkOutAdd')
      // this.$nextTick(function () {
      //   this.openTab('Stk/StkOutAdd', {})
      // })
    },
    stkOutAddRows () { // 批量销售出库
      // let rows = this.onSelectRow() // 选中行的SSGuid数组
      // if (rows.length > 0) {
      //   let order = { InquireModel: rows }
      //   this.$store.commit('setParamOrder', order)
      //   this.$store.commit('removeTab', 'Stk/StkOutAdd')
      //   this.$nextTick(function () {
      //     this.$refs.flexGrid.clearSelection()
      //     this.openTab('Stk/StkOutAdd', {}) // 显示编辑页面
      //   })
      // }
    },
    onStkInadd (rows) { // 采购入库
      // let order = { setParamShared: [rows] }
      // this.$store.commit('setParamOrder', order)
      // this.$store.commit('removeTab', 'Stk/StkInAdd')
      // this.$nextTick(function () {
      //   this.openTab('Stk/StkInAdd', {})
      // })
    },
    stkInAddRows () { // 批量采购入库
      // let rows = this.onSelectRow() // 选中行的SSGuid数组
      // if (rows.length > 0) {
      //   let order = { InquireModel: rows }
      //   this.$store.commit('setParamOrder', order)
      //   this.$store.commit('removeTab', 'Stk/StkInAdd')
      //   this.$nextTick(function () {
      //     this.$refs.flexGrid.clearSelection()
      //     this.openTab('Stk/StkInAdd', {}) // 显示编辑页面
      //   })
      // }
    },
    onBomAdd (rows) { // 报价
      let order = { setParamShared: [rows] }
      this.$store.commit('setParamOrder', order)
      this.$store.commit('removeTab', 'Ord/BomMasterAdd')
      this.$nextTick(function () {
        this.openTab('Ord/BomMasterAdd', {}) // 显示编辑页面
      })
    },
    stkCustomerRequest () { // 批量报价
      let rows = this.onSelectRow() // 选中行的SSGuid数组
      if (rows.length > 0) {
        let order = { InquireModel: rows }
        this.$store.commit('setParamOrder', order)
        this.$store.commit('removeTab', 'Ord/BomMasterAdd')
        this.$nextTick(function () {
          this.$refs.flexGrid.clearSelection()
          this.openTab('Ord/BomMasterAdd', {}) // 显示编辑页面
        })
      }
    },
    onSelectRow () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (!this.columns.SSGuid) {
          // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          this.$refs.flexGrid.clearSelection()
        }
      } else {
        // 判断选中行的客户是否一致
        var lastCustomerName = rows[0].SupplierName
        var isDiff = false
        _.each(rows, r => {
          if (lastCustomerName !== r.SupplierName) {
            isDiff = true
          }
        })
        if (isDiff) {
          return this.$message({ message: '请选择相同供应商的数据进行操作', type: 'error' })
        }
      }
      return rows
    },
    switchChange (value) {
      var msg = value === true ? '已启用共享库存功能！' : '已关闭共享库存功能！'
      this.$post(CONFIG.OpenOrCloseStockShared, { enableSharedStock: value === true ? 1 : 0 }, data => {
        if (data === true) {
          this.$message({ message: msg, type: 'success' })
        } else {
          this.$message({ message: '操作失败', type: 'error' })
        }
      })
    },
    getSharedStockStatus () {
      this.$post('PubSStock/GetSharedStockStatus', {}, data => {
        this.enableSharedStock = data
      })
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page.Code === code)
    },

    getShareStockStatistics () {
      this.Urltype !== null && this.Urltype === '1' ? '' : this.$post(CONFIG.GetShareStockStatistics, data => {
        this.ShareCount = data.ShareCount === null ? 0 : data.ShareCount
        this.UnShareCount = data.UnShareCount === null ? 0 : data.UnShareCount
      })
    }
  },
  created () {
    this.getSharedStockStatus()
  },

  mounted () {
  },
  destroyed () {
  }

}
</script>

<style lang="scss">
.stkStock-action-form {
  display: flex;
  position: relative;
  .znl-line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 115px;
    color: #ccc;
  }
}
</style>
