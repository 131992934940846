<template>
<znl-dialog
  :visible="visible"
  title="待推广的型号"
  height="500px"
  @close="()=>{$emit('close')}"
  >
<div slot="znl-dialog">
    <znl-gridmodule ref="flexGrid"
                gridtype="action"
                 layout-type="other"
                  height="100%"
                  :has-znl-actions="false"
                  :has-znl-btns="false"
                :is-init="true"
                :columns="columns"
                :item-source="itemSource"
                :hasDefaultBeforeNewRow="true"
                :edit-row-index="editRowIndex"
                :column-colors="columnColors"
                :row-colors="rowColors"
                :summary-columns="summaryColumns"
                :is-fields-search="false"
                :is-multi-rows-check="false"
                :search-fields="searchModel"
                :total-count="totalCount"
                :role="role"
                :users="$store.state.users"
                :on-init="onInit">
  </znl-gridmodule>
  <!-- <div slot="footer" class="dialog-footer">
    <el-row>
      <el-col>
        <el-button type="primary"  size="mini" @click="onStkExt">推广库存</el-button>
      </el-col>
    </el-row>
  </div> -->
</div>

<el-row slot="footer">
      <!-- <el-col style="display: flex;justify-content: flex-end;align-items: center"> -->

      <!-- <el-button type="default"
                 :disabled="loading"
                 @click="onCancel"
                 class="znl-btn-auxiliary">取消</el-button> -->
      <el-button type="primary"
                 :loading="loading"
                 class="znl-btn-main"
                 @click="onStkExt">{{loading?'保存中':'上传到推广库存'}}</el-button>
      <!-- </el-col> -->
    </el-row>
</znl-dialog>
</template>

<script>
export default {
  name: 'StkExtList',
  components: {
  },
  data () {
    return {
      config: {
        configURL: 'StkExtList/GetConfig',
        saveConfigURL: 'StkExtList/SaveConfig',
        resetConfigURL: 'StkExtList/ResetConfig',
        searchURL: 'StkExtList/SearchExtView',
        // stkExtSaveURL: 'StkExtList/stkExtSave',
        stkExtSaveURL: 'PubStkStockAdd/AddLine',

        hasServiceUrl: 'PubStkStock/HasService'
      },
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      users: [],
      usableUser: [],
      role: '1',
      hasService: 0
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: Boolean,
    height: String,
    stkGUIDS: String, // 库存GUID
    isRefresh: Boolean, // 是否刷新列表
    visible: Boolean
  },
  watch: {
    stkGUIDS (value) {
      if (!_.isEmpty(value)) {
        this.onBindData()
      }
    },
    isRefresh (value) {
      if (!_.isEmpty(this.stkGUIDS)) {
        this.onBindData()
      }
    }
  },
  methods: {
    onInit: async function () {
      let hasservice = await this.$post(this.config.hasServiceUrl)
      this.hasService = hasservice
      let config = await this.$post(this.config.configURL)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      // console.log('oninte', config)
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData & this.onBindData()
    },
    onBindData () {
      let stockguidList = JSON.parse(this.stkGUIDS)
      return this.$post(this.config.searchURL, {StkGUIDS: stockguidList}, (datas) => {
        this.itemSource = datas
      })
    },
    onSaveSetting (columns, pageSize) {
      return this.$post(this.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.config.default))
    },
    onResetSetting () {
      return this.$post(this.config.resetConfigURL)
    },
    onCancel () {
      this.p_dialogVisible = false
      this.$emit('confirm', false)
    },
    onStkExt () { // 推广库存
      if (this.hasService === 0) {
        return this.$message({ message: '你尚未开通库存推广服务，请联系正能量客服人员', type: 'error' })
      }
      let rows = this.itemSource
      // console.log(rows)
      return this.$post(this.config.stkExtSaveURL, {LineList: rows}, (datas) => {
        if (datas > 0) {
          this.$message({message: '上传推广库存成功', type: 'success'})
          this.$emit('confirm', true)
        } else {
          this.$message({message: '上传推广库存失败', type: 'error'})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.col-label-right {
  text-align: right;
}
</style>
