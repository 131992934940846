<template>
  <div>
    <znl-dialog
      :append-to-body="true"
      :confirm-call-back="confirmCallBack"
      :is-fields-search="false"
      :is-footer-show="false"
      :loading="loadingState"
      :loading-text="LoadingText"
      :visible.sync="visible"
      class="p-stk-warn-list"
      height="500px"
      title="已报警型号"
      width="870px"
      @close="()=>{$emit('close')}">
      <znl-gridmodule
        :max-height="450"
        ref="grid"
        :columns="columns"
        :has-znl-actions="false"
        :has-znl-btns="false"
        :is-btn="false"
        :is-fields-search="false"
        :is-footer-show="false"
        :is-init="true"
        :is-multi-rows-check="true"
        :is-multi-rows-check-fixed="false"
        :item-source="itemSource"
        :on-init="onInit"
        :on-page-changed="onBindData"
        :on-search="onSearch"
        :page-index="pageIndex"
        :page-size="pageSize"
        :show-add-row-right-menu="false"
        :show-delete-row-right-menu="false"
        :table-handle="false"
        :total-count="totalCount"
        gridtype="action"
        role="1"
        @selection-changed="(d)=>{$emit('selection-changed', d)}">
        <div slot="action-form" class="znl-component-input">
          <znl-input
            v-model="searchFields.Model"
            :border="true"
            :clearable="true"
            class="box_input_checkbox"
            from-type="input"
            inp-perc="100%"
            layout="left"
            placeholder="请输入型号"
            size="mini"
            type="text"
            width="200px"
            @keyup.enter.native="onSearch(1)"
          ></znl-input>
          <znl-input
            v-model="searchFields.Brand"
            :border="true"
            :clearable="true"
            class="box_input_checkbox"
            from-type="input"
            inp-perc="100%"
            layout="left"
            placeholder="请输入品牌"
            size="mini"
            type="text"
            width="200px"
            @keyup.enter.native="onSearch(1)"
          ></znl-input>
          <znl-input
            v-model="searchFields.Remark"
            :border="true"
            :clearable="true"
            class="box_input_checkbox"
            from-type="input"
            inp-perc="100%"
            layout="left"
            placeholder="请输入备注"
            size="mini"
            title-width="30px"
            type="text"
            width="200px"
            @keyup.enter.native="onSearch(1)"
          ></znl-input>
          <znl-button
            :height="22"
            :width="70"
            class="box-left-btn search-btn"
            style-type="mac"
            type="multiple">
            <znl-button-menu @click="onBindData()">
            <span>
              <i class="iconfont icon-search_ic"></i>搜索
            </span>
            </znl-button-menu>
          </znl-button>
          <znl-button
            :height="22"
            :width="90"
            class="box-left-btn search-btn"
            style-type="mac"
            type="multiple">
            <znl-button-menu @click="onStaticStk()">
            <span>
              <i class="iconfont icon-sales-ticket_btn_ic"></i>统计库存
            </span>
            </znl-button-menu>
          </znl-button>
          <znl-button
            :height="22"
            :width="70"
            class="box-left-btn search-btn"
            style-type="mac"
            type="multiple">
            <znl-button-menu @click="onExport()">
            <span>
              <i class="iconfont icon-leading-out-wh_btn_i"></i>导出
            </span>
            </znl-button-menu>
          </znl-button>
          <znl-button
            :disabled="isSaveShow"
            :height="22"
            :width="70"
            class="box-left-btn search-btn"
            style-type="mac"
            type="multiple">
            <znl-button-menu @click="onSave()">
            <span>
              <i class="iconfont iconfont icon-save_btn_ic"></i>保存
            </span>
            </znl-button-menu>
          </znl-button>
        </div>
      </znl-gridmodule>
    </znl-dialog>
  </div>

</template>
<script>
import {mixin as znlMethodsMinix} from '@c_common/znlGrid/scripts/znlMethods'
import {mixin as getCommonDataMixin} from '~assets/scripts/methods/getCommonData'
import {exportData} from "@scripts/methods/common";

const listConfig = [
  {
    CTitle: '型号',
    BindField: 'Model',
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 100,
    DisplayIndex: 5,
    DataType: 1,
    IsSystem: false,
    IsRequired: true,
    IsExprot: true
  },
  {
    CTitle: '品牌',
    BindField: 'Brand',
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 100,
    DisplayIndex: 30,
    DataType: 1,
    IsSystem: false,
    IsRequired: true,
    IsExprot: true
  },
  {
    CTitle: '最小数量',
    BindField: 'LowerQty',
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DisplayIndex: 0,
    DataType: 2,
    IsSystem: false,
    IsRequired: true,
    IsExprot: true
  },
  {
    CTitle: '最大数量',
    BindField: 'UpperQty',
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 80,
    DisplayIndex: 2,
    DataType: 2,
    IsSystem: false,
    IsRequired: true,
    IsExprot: true
  },
  {
    CTitle: '当前数量',
    BindField: 'CurQty',
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 60,
    DisplayIndex: 3,
    DataType: 2,
    IsSystem: false,
    IsRequired: true,
    IsExprot: true
  },
  {
    CTitle: '备注',
    BindField: 'Remark',
    IsShow: true,
    IsReadonly: false,
    DisplayIndex: 4,
    DataType: 1,
    IsSystem: false,
    IsRequired: true,
    IsExprot: true
  },
  {
    CTitle: '预警时间',
    BindField: 'WarnTime',
    IsShow: true,
    IsReadonly: true,
    ColumnWidth: 120,
    DisplayIndex: 5,
    DataType: 1,
    IsSystem: false,
    IsRequired: true,
    IsExprot: true
  }
]

export default {
  name: "stkWarnList",
  mixins: [znlMethodsMinix, getCommonDataMixin, exportData],
  data() {
    return {
      columns: [],
      itemSource: [],
      fieldsFilter: {},
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      loadingState: false,
      LoadingText: "数据加载中,请稍后...",
      companyStr: [],
      searchFields: {
        Model: "",
        Brand: "",
        Remark: "",
        IsWarning: true
      },
      visible: false,
      isSaveShow: false
    };
  },
  methods: {
    async onOpen() {
      this.visible = true
    },
    async onInit() {
      this.columns = await this.flexGridColumnsHandler(listConfig)
      await this.onBindData(1)
    },
    async onSearch(pageIndex) {
      await this.onBindData(pageIndex)
    },
    async onBindData(pageIndex = 1) {
      const {searchFields} = this
      const params = {
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }
      Object.keys(searchFields).forEach(key => params[key] = searchFields[key])
      const data = await this.$post('StkStockWarning/Search', params)
      this.pageIndex = data.PageIndex
      this.totalCount = data.TotalCount
      this.itemSource = data['ResultList']
    },
    confirmCallBack() {

    },
    async onSave() {
      const rows = this.$refs.grid.getSelectedRows()

      this.isSaveShow = true
      if (rows.length === 0) {
        this.$message({
          message: '请选择一条数据',
          type: 'warning'
        })
        this.isSaveShow = false
        return false
      }

      try {
        await this.$post('StkStockWarning/BatchSave', rows)

        this.$message({
          message: '保存成功',
          type: 'success'
        })
      } finally {
        this.isSaveShow = false
      }


    },
    async onStaticStk() {
      await this.$post('StkStockWarning/StatisticsCurInvQty', {})

      this.$message({
        message: '统计成功',
        type: 'success'
      })
      await this.onSearch(1)
    },
    async onExport() {
      this.loading = true;
      const {searchFields} = this
      const params = {
        PageSize: this.pageSize
      }
      Object.keys(searchFields).forEach(key => params[key] = searchFields[key])
      console.log(this.columns)
      this.loading = await this.exportData(
        "StkStockWarning",
        params,
        this.columns,
        "预警库存"
      );
    }
  },
  created() {
    // this.onInit()
  },
  mounted() {
    // this.onInit()
  }
};
</script>

<style lang="scss">
.p-stk-warn-list {
  .isFieldsSearch {
    //display: none;
  }

  .znl-action-form {
    padding-left: 0;
  }
}
</style>

