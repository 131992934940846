<template>
  <div class="p-sts-shipped">
    <znl-dialog title="未出货统计"
                subhead=""
                :visible="visible"
                height="400zpx"
                width="500px"
                @close="()=>{$emit('close')}">
      <znl-gridmodule ref="flexGrid"
                      height="100%"
                      :on-init="onInit"
                      :columns="columns"
                      :item-source="itemSource"
                      :on-page-changed="onBindData"
                      :role="role"
                      :loading="loading"
                      :page-size="pageSize"
                      :page-index="pageIndex"
                      :total-count="totalCount"
                      :is-fields-search="isShowFiedsSearch"
                      :search-fields="searchModel"
                      :on-search="onSearch">
        <div slot="heading-slot">
          <span>超过</span>
          <znl-input form-type="input"
                     :border="true"
                     placeholder=""
                     width="50px"
                     size="mini"
                     inp-perc="100%"
                     v-model="overDays"
                     :clearable="true"
                     @keyup.enter.native="$refs.flexGrid.search()"
                     type="text"></znl-input>
          <span>天未出货 </span>
          <znl-button :height="22"
                      style-type="mac"
                      class="box-left-btn search-btn"
                      style="margin-left:10px;"
                      @click="$refs.flexGrid.search()">
            <i class="iconfont icon-search_ic"></i>
            <span>统计</span>
          </znl-button>
          <!-- <znl-button type="multiple"
            :height="22"
            style-type="mac"
            class="box-left-btn search-btn">
            <znl-button-menu @click="$refs.flexGrid.search()">
              <i class="iconfont icon-left_nav_show_btn"></i>
              <span>统计</span>
            </znl-button-menu>
            <znl-button-menu :width="12"
              tip="高级搜索"
              @click="onShowFiedsSearch()">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button> -->
          <!-- :btns="exportBtns" -->
          <znl-button style-type="mac"
                      :height="22"
                      @click="onExport"
                      tip="按搜索条件">
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>导出</span>
          </znl-button>
        </div>
        <export-column :visible="setExportColumnVisible"
                       :addColumns="addColumnsList"
                       :addConfigColumns="setConfigColumns"
                       :saveConfigURL="setSaveConfigURL"
                       v-if="setExportColumnVisible"
                       title="设置导出列"
                       @close="()=>{setExportColumnVisible=false}"
                       @confirm="(v)=>{setExportColumnVisible=false}"></export-column>
      </znl-gridmodule>

      <div slot="footer">
        <znl-button style-type="mac" @click="()=>{$emit('close')}" :height="30" :width="50">
          <span>关闭</span>
        </znl-button>
      </div>
    </znl-dialog>
  </div>


</template>

<script>
import ExportColumn from '@c_modules/Crm/setExportColumn'
import * as urls from '~/lib/urls'
const CONFIG = {
  configURL: 'StsUnshipped/GetConfig',
  saveConfigURL: 'StsUnshipped/SaveConfig',
  resetConfigURL: 'StsUnshipped/ResetConfig',
  searchURL: 'StsUnshipped/Search',
  apiBaseUrl: urls.getErpApiBaseUrl()
}
export default {
  name: 'stsUnshipped',
  config: CONFIG,
  components: {
    ExportColumn
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    },
    initData: {
      type: Boolean,
      default: true
    },
    visible: Boolean
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      searchFields: {},
      isShowFiedsSearch: false,
      overDays: null,
      role: '1',
      loading: false,
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      exportBtns: [
        {
          name: '设置导出列',
          click: () => {
            this.showExportColumn()
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setConfigColumns: {},
      setSaveConfigURL: ''
    }
  },
  methods: {
    async onInit () {
      this.columns = [
        {
          binding: 'Model',
          name: 'Model',
          header: '型号',
          visible: true,
          dataType: 1,
          width: 170,
          isReadOnly: false,
          isFieldSearch: true,
          isSystem: false,
          IsExprot: true
        },
        {
          binding: 'LastInTime',
          name: 'LastInTime',
          header: '最后入库时间',
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: false,
          IsExprot: true,
          isFieldSearch: true,
          isSystem: false
        },
        {
          binding: 'LastOutTimeStr',
          name: 'LastOutTimeStr',
          header: '最后出库时间',
          visible: true,
          dataType: 4,
          width: 100,
          isReadOnly: false,
          IsExprot: true,
          isFieldSearch: true,
          isSystem: false
        },
        {
          binding: 'UnShippedDays',
          name: 'UnShippedDays',
          header: '至今未出库天数',
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: false,
          IsExprot: true,
          isFieldSearch: true,
          isSystem: false
        }
      ]
      if (this.initData) {
        await this.onBindData()
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    async onBindData (pageIndex = 1) {
      this.loading = true
      let paramData = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
          pageIndex: pageIndex,
          pageSize: this.pageSize
        },
        { UnShippedDays: this.overDays }
      )
      this.$post(CONFIG.searchURL, paramData, (datas, logic) => {
        if (logic.code === 200 && datas) {
          this.pageIndex = pageIndex
          this.totalCount = datas.TotalCount
          this.itemSource = datas.ResultList
          this.loading = false
        }
      })
    },
    onSearch (params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      this.onBindData(1)
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    onCalc () {
      this.onBindData(1)
    },
    onShowFiedsSearch () {
      this.isShowFiedsSearch = !this.isShowFiedsSearch
    },
    showExportColumn () {
      this.setExportColumnVisible = true
      this.addColumnsList = this.columns
      this.setConfigColumns = this.defaultConfig
      this.setSaveConfigURL = this.$options.config.saveConfigURL
    },
    isClient () {
      return window.cefQuery !== undefined
    },
    onExport () {
      this.loading = true
      let exportColumns = []
      let selectedColumns = _.filter(this.columns, function (item, index) {
        return (
          !item.isSystem &&
          (_.isUndefined(item.hasRole) || item.hasRole) &&
          item.IsExprot
        )
      })
      _.each(selectedColumns, item => {
        exportColumns.push({
          Name: item.binding,
          DisplayName: item.header
        })
      })
      let conditions = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.filterFields),
          PageIndex: this.pageIndex,
          PageSize: this.pageSize,
          OverDays: this.overDays
        }
      )
      let postData = {
        Name: 'StsUnshipped',
        Conditions: conditions,
        Columns: exportColumns
      }
      let that = this
      this.pageLoading = true
      this.$post('export/excel', postData, (data, logic) => {
        if (logic.code === 200) {
          this.loading = false
          that.downloadUrl =
            `${CONFIG.apiBaseUrl}export/download?id=` + data.Id
          if (that.isClient()) {
            that.clientDownLoadFile(data.Id, that.downloadUrl)
          } else {
            window.open(that.downloadUrl) // 下载文件
          }
          that.pageLoading = false
        }
      })
        .catch(e => {
          this.loading = false
          that.pageLoading = false
        })
        .finally(() => {
          this.loading = false
          this.pageLoading = false
        })
    }
  },
  mounted () {},
  created () {}
}
</script>

<style lang='scss'>
.p-sts-shipped {
  .znl-table-body-wrapper {
    overflow: auto;
  }
  .table-container {
    overflow: auto;
  }
}
</style>
