<template>
  <div class="p-sts-recycle">
    <znl-dialog
      :visible="visible"
      height="550px"
      width="800px"
      v-loading="dialogLoading"
      element-loading-text="数据加载中,请稍后..."
      title="库存回收站"
      :is-footer-show="false"
      @close="()=>{$emit('close')}">
      <znl-gridmodule
        ref="flexGrid"
        :is-init="true"
        :is-mini="true"
        height="490px"
        :columns="columns"
        :item-source="itemSource"
        :is-multi-rows-check="true"
        :search-fields="searchModel"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :role="role"
        :is-show-rightbtns="false"
        :users="$store.state.users"
        :on-init="onInit"
        :on-search="onSearch"
        @selection-changed="d=>{this.stkRecycleSelectItem=d,$emit('selection-changed',d)}"
        :on-page-changed="onBindData">
        <div slot="heading-slot">
          <znl-input
            form-type="input"
            :disabled="false"
            placeholder="请输入型号"
            size="mini"
            layout="left"
            width='120px'
            inp-perc="100%"
            :clearable="true"
            :border="true"
            v-model="inputModel"
            @keyup.enter.native="$refs.flexGrid.search()"
            type="text">
          </znl-input>
          <znl-input
            form-type="input"
            :disabled="false"
            placeholder="请输入品牌"
            size="mini"
            :clearable="true"
            :border="true"
            layout="left"
            width='120px'
            inp-perc="100%"
            v-model="inputBrand"
            @keyup.enter.native="$refs.flexGrid.search()"
            type="text">
          </znl-input>
          <znl-input
            form-type="input"
            :disabled="false"
            placeholder="请输入供应商名称 "
            size="mini"
            :clearable="true"
            :border="true"
            layout="left"
            width='150px'
            inp-perc="100%"
            v-model="inputSupplieName"
            @keyup.enter.native="$refs.flexGrid.search()"
            type="text">
          </znl-input>
          <znl-button class='minor-self-btn' style-type="mac" :height='22' :width='60' @click="$refs.flexGrid.search()" >
            <span><i class="iconfont icon-search_ic"></i> 搜索</span>
          </znl-button>
          <znl-button class='minor-self-btn' style-type="mac" :height='22' :width='80' @click="onRecovery" >
            <span><i class="iconfont icon-revoke_btn_ic"></i>还原库存</span>
          </znl-button>
          <znl-button class='minor-self-btn' style-type="mac" :height='22' :width='80' @click="onDelete" >
            <span><i class="iconfont icon-delete_btn_ic"></i>彻底删除</span>
          </znl-button>
        </div>
      </znl-gridmodule>
    </znl-dialog>
  </div>

</template>
<script>

export default {
  name: 'StkStockRecycle',
  components: {
  },
  data () {
    return {
      actionBtns: [
      ],
      stkRecycleSelectItem: [], // 选中的数据
      inputModel: '', // 需要查询的型号
      inputBrand: '', // 需要查询的品牌
      inputSupplieName: '', // 查询的供应商名称
      stkRecycleIds: [], // 需要批量操作的ID集合
      pickerOptions0: {
      },
      config: {
        configURL: 'StkStockDel/GetConfig',
        saveConfigURL: 'StkStockDel/SaveConfig',
        resetConfigURL: 'StkStockDel/ResetConfig',
        default: null,
        searchURL: 'StkStockDel/Search',
        onDeleteURL: 'StkStockDel/DeleteStkDel',
        onReductionURL: 'StkStockDel/ResumeStocks'
      },
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: [],
      dialogLoading: true
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    },
    isRefresh: Boolean, // 刷新页面
    visible: Boolean
  },
  watch: {
    isRefresh (value) {
      this.inputModel = this.inputBrand = this.inputSupplieName = ''
      this.onBindData()
    },
    itemSource () {
      _.delay(() => {
        this.dialogLoading = false
      }, 150)
    }
  },
  methods: {
    onInit: async function () {
      let config = await this.$post(this.config.configURL)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.initData && this.onBindData()
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
        PartNo: this.inputModel,
        Brand: this.inputBrand,
        SupplierName: this.inputSupplieName
      })
      return this.$post(this.config.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onRefresh () {
      return this.onBindData(this.pageIndex)
    },
    onSaveSetting (columns, pageSize) {
      return this.$post(this.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.config.default))
    },
    onResetSetting () {
      return this.$post(this.config.resetConfigURL)
    },
    onSelectRow () { // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length === 0) {
        if (this.stkRecycleSelectItem.length === 0) { // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          guid.push(this.stkRecycleSelectItem.SID)
        }
      } else {
        guid = _.map(rows, item => { return item.SID })
      }
      return guid
    },
    onRecovery () { // 恢复
      let guid = this.onSelectRow()
      if (guid.length > 0) {
        this.$post(this.config.onReductionURL, { SIDs: guid }, (datas) => {
          if (datas > 0) {
            this.$message({ message: '还原成功', type: 'success' })
            this.onBindData(this.pageIndex)
          } else if (datas === -1) {
            this.$message({ message: '没有找到需要还原的保留库存', type: 'error' })
          } else {
            this.$message({ message: '还原失败', type: 'error' })
          }
          this.$refs.flexGrid.clearSelection()
        })
      }
    },
    onDelete () { // 删除
      let guid = this.onSelectRow()
      if (guid.length > 0) {
        this.$confirm('您确定要彻底删除选中的' + guid.length + '条库存?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$post(this.config.onDeleteURL, { SIDs: guid }, (datas) => {
            if (datas > 0) {
              this.$message({ message: '删除成功', type: 'success' })
              this.onBindData(this.pageIndex)
            } else if (datas === -1) {
              this.$message({ message: '没有找到需要删除的保留库存', type: 'error' })
            } else {
              this.$message({ message: '删除失败', type: 'error' })
            }
            this.$refs.flexGrid.clearSelection()
          })
        })
      }
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page.Code === code)
    },
    onCancel () {
      this.$emit('confirm', false)
    }
  }
}
</script>

<style lang="scss" >

.p-sts-recycle {
  .znl-table-body-wrapper {
    overflow: auto;
  }
  .table-container {
    overflow: auto;
  }
}
</style>
