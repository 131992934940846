<template>
  <znl-dialog
      :visible="visible"
      title="拆分库存"
      class="split_box"
      height="400px"
      width="550px"
      :loading="saveloading"
      :loading-text="elementLoadingText"
      @close="()=>{$emit('close')}">
    <znl-gridmodule
        gridtype="action"
        ref="flexGrid"
        height="165"
        :max-height="300"
        slot="znl-dialog"
        layout-type="other"
        :from-height="150"
        :has-znl-btns="false"
        :from-btns="fromBtns"
        :columns="columns"
        :item-source="itemSource"
        :hasDefaultBeforeNewRow="true"
        :edit-row-index="editRowIndex"
        :column-colors="columnColors"
        :is-fields-search="false"
        :is-multi-rows-check="false"
        :show-add-row-right-menu="false"
        :search-fields="searchModel"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :on-init="onInit"
        @selection-changed="d=>{$emit('selection-changed',d)}">
        <div slot="from-module" class="_action_search">
              <el-row type="flex">
                <!-- left -->
                <el-col :span="8">
                  <!-- 左1 -->
                    <znl-input title-width="60px"
                      form-type="input"
                      placeholder=" "
                      size="mini"
                      layout="left"
                      :disabled="true"
                      :is-must-fill="false"
                      title="型号："
                      :clearable="true"
                      :border="true"
                      width="100%"
                      v-model="StkStockSplit.Model"
                      type="text">
                    </znl-input>
                  <el-row>
                    <znl-input title-width="60px"
                      form-type="input"
                      :disabled="true"
                      :is-must-fill="false"
                      placeholder=""
                      size="mini"
                      layout="left"
                      :border="true"
                      title="封装："
                      :clearable="true"
                      width="100%"
                      v-model="StkStockSplit.Packaging"
                      type="text">
                    </znl-input>
                  </el-row>
                  <!-- 左3 -->
                  <el-row>
                    <znl-input title-width="60px"
                      form-type="input"
                      placeholder=""
                      size="mini"
                      layout="left"
                      :disabled="true"
                      :is-must-fill="false"
                      title="可用量："
                      :clearable="true"
                      :border="true"
                      width="100%"
                      v-model="StkStockSplit.InvQty"
                      type="text">
                    </znl-input>
                  </el-row>
                </el-col>
                <el-col :span="7"
                  :offset="1">
                  <!-- 中1 -->
                  <el-row>
                    <znl-input title-width="60px"
                      form-type="input"
                      placeholder=""
                      size="mini"
                      layout="left"
                      :disabled="true"
                      :is-must-fill="false"
                      title="品牌："
                      :clearable="true"
                      :border="true"
                      width="100%"
                      v-model="StkStockSplit.Brand"
                      type="text">
                    </znl-input>
                  </el-row>
                  <!-- 中2 -->
                  <el-row>
                    <znl-input title-width="60px"
                      form-type="input"
                      placeholder=""
                      size="mini"
                      layout="left"
                      :disabled="true"
                      :is-must-fill="false"
                      title="库存类型："
                      :clearable="true"
                      :border="true"
                      width="100%"
                      v-model="StkStockSplit.InvType"
                      type="text">
                    </znl-input>
                  </el-row>
                  <el-row></el-row>
                </el-col>
                <!-- right -->
                <el-col :span="7"
                  :offset="1">
                  <!-- 右1 -->
                  <el-row>
                      <znl-input title-width="60px"
                        form-type="input"
                        placeholder=""
                        size="mini"
                        layout="left"
                        :disabled="true"
                        :is-must-fill="false"
                        title="年份："
                        :clearable="true"
                        width="100%"
                        :border="true"
                        v-model="StkStockSplit.MakeYear"
                        type="text">
                      </znl-input>
                  </el-row>
                  <!-- 右2 -->
                  <el-row>
                      <znl-input title-width="60px"
                        form-type="input"
                        placeholder=""
                        size="mini"
                        layout="left"
                        :disabled="true"
                        :is-must-fill="false"
                        title="位置："
                        :clearable="true"
                        :border="true"
                        width="100%"
                        v-model="StkStockSplit.StorageName"
                        type="text">
                      </znl-input>
                  </el-row>
                  <el-row></el-row>
                </el-col>
              </el-row>
        </div>
    </znl-gridmodule>
    <el-row slot="footer">
      <znl-button :height='30' :width='60' @click="onSubmit()" style="margin-left: 8px;" style-type="main">确定
      </znl-button>
    </el-row>
  </znl-dialog>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'

const detailModel = {
  'StkGUID': '', // guid
  'Model': '', // 型号
  'Brand': '', // 品牌
  'Packaging': '', // 封装
  'MakeYear': '', // 年份
  'InvType': '', // 库存类型
  'InvPosition': '', // 仓库
  'StorageName': '', // 位置
  'InvQty': 0// 可用量
}
export default {
  name: 'StkStockSplit',
  mixins: [znlMethodsMixin],
  components: {
  },
  data () {
    return {
      StkStockSplit: {  // 入库单对象
        StkGUID: '', // guid
        Model: '', // 型号
        Brand: '', // 品牌
        Packaging: '', // 封装
        MakeYear: '', // 年份
        InvType: '', // 库存类型
        InvPosition: '', // 仓库
        StorageName: '', // 位置
        InvQty: 0, // 可用量
        listStkstocks: [] // 保存入库详情明细行,
      },
      saveloading: false,
      elementLoadingText: '正在保存数据,请稍后...',
      config: {
        configURL: 'StkStockSplit/GetConfig',
        onSubmitURL: 'StkStockSplit/Submit',
        SearchByGUID: 'StkStockSplit/SearchByGUID'
      },
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      usableUser: [],
      role: '1',
      fromBtns: [
        {
          name: '新增一行',
          iconName: 'icon-add_btn_ic',
          click: () => {
            this.$refs.flexGrid.addRow(true)
          }
        },
        {
          name: '删除选择行',
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.onDelRow()
          }
        }
      ]
    }
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: Boolean,
    height: String,
    stkGUID: String,
    isRefresh: Boolean,
    visible: Boolean
  },
  watch: {
    isRefresh: {
      handler (value) {
        if (!_.isEmpty(this.stkGUID)) {
          this.LoadData(this.stkGUID)
        }
      },
      immediate: true
    },
    stkGUID: {
      handler (value) {
        if (!_.isEmpty(value)) {
          this.LoadData(value)
        }
      },
      immediate: true
    }
  },
  methods: {
    onInit: async function (config) {
      config = [
        {
          CTitle: '新位置',
          BindField: 'StorageName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 180,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        {
          CTitle: '拆分数量',
          BindField: 'InvQty',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 180,
          DisplayIndex: 1,
          DataType: 2,
          IsSystem: false,
          IsRequired: false }
      ]
      let columns = this.flexGridColumnsHandler(config)
      this.defaultConfig = config
      this.columns = columns
    },
    onCancel () {
      this.p_dialogVisible = false
      this.$emit('confirm', false)
    },

    onDeletedRows () {
      _.each(this.$refs.flexGrid.getSelectedRows(), data => {
        _.each(this.itemSource, (item, i) => {
          if (
            _.isEqual(
              _.pick(item, value => {
                return !_.isUndefined(value)
              }),
              _.pick(data, value => {
                return !_.isUndefined(value)
              })
            )
          ) {
            this.itemSource.splice(i, 1)
            return
          }
        })
      })
    },
    onSubmit () { // 提交
      if (this.itemSource.length < 1) {
        return this.$message({ message: '请添加明细详情', type: 'error' })
      }

      this.StkStockSplit.listStkstocks = this.itemSource

      _.some(this.StkStockSplit.listStkstocks, item => {
        if (!isFinite(item.InvQty) || item.InvQty < 0) {
          return this.$message({ message: '拆分数量必须为大于0的数字类型', type: 'error' })
        } else {
          this.saveloading = true
          return this.$post(this.config.onSubmitURL, this.StkStockSplit, (data) => {
            this.saveloading = false
            if (data > 0) {
              this.$message({ message: '拆分成功', type: 'success' })
              this.$emit('confirm', true)
            } else if (data === -1) {
              this.$message({ message: '拆分的新位置包含与现位置相同的项,不符合拆分原则！', type: 'error' })
            } else if (data === -2) {
              this.$message({ message: '拆分数量不能大于可用数量！', type: 'error' })
            } else if (data === -3) {
              this.$message({ message: '您还没有填写拆分信息或者填写的数量为零！', type: 'error' })
            } else if (data === -4) {
              this.$message({ message: '拆分库存不能少于1条!', type: 'error' })
            } else if (data === -5) {
              this.$message({ message: '拆分数量不能含0!', type: 'error' })
            } else {
              this.$message({ message: '拆分失败', type: 'error' })
            }
          })
        }
      })
    },
    LoadData: async function (guid) { // 根据入库单GUID加载实体
      let stkStock = await this.$post(this.config.SearchByGUID, { StkGUIDS: JSON.parse(guid) })
      this.$nextTick(function () {
        this.StkStockSplit = _.extend({}, detailModel, stkStock)
        this.StkStockSplit.listStkstocks = []
        this.itemSource = []
      })
    },
    onDelRow () {
      _.each(this.$refs.flexGrid.getSelectedRows(), data => {
        _.each(this.itemSource, (item, i) => {
          if (_.isEqual(item, data)) {
            this.itemSource.splice(i, 1)
            return
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
.split_box {
  .col-label-right {
    text-align: right;
  }
  .znl-component-input {
    padding-top: 8px;
  }
  .znl-input-title {
    text-align: right;
  }
  .submitBtn {
    width: 80px;
  }
  .el-dialog__title {
    font-size: 18px;
  }
  .znl-header-text {
    display: none;
  }
  .el-dialog .el-row {
    margin-top: 0;
  }
}
</style>

