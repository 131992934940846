<template>
<div>
    <znl-detail-module ref="flexGrid"
                header="发运单明细"
                :height="height"
                :columns="columns"
                :item-source="itemSource"
                :column-colors="columnColors"
                :is-multi-rows-check="true"
                :row-colors="rowColors"
                :summary-columns="summaryColumns"
                :search-fields="searchModel"
                :page-size="pageSize"
                :page-index="pageIndex"
                :total-count="totalCount"
                :role="role"
                :on-init="onInit"
                :on-refresh="onRefresh"
                :on-search="onSearch"
                :on-page-changed="onBindData"
                :on-save-setting="onSaveSetting"
                :on-save-config='onSaveConfig'
                :on-reset-setting="onResetSetting"
                @selection-changed="d=>{$emit('selection-changed',d)}">
    </znl-detail-module>
</div>
</template>

<script>
import {mixin as znlMethodsMinix} from '@c_common/znlGrid/scripts/znlMethods'
const Config = {
  configURL: 'ExpressOrderLine/GetConfig',
  saveConfigURL: 'ExpressOrderLine/SaveConfig',
  resetConfigURL: 'ExpressOrderLine/ResetConfig',
  searchURL: 'ExpressOrderLine/Search'
}
export default {
  name: 'ZNLExpressOrderLine',
  mixins: [znlMethodsMinix],
  config: Config,
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      stkOutAddVisible: false, // 是否显示出库编辑页面
      onrefreshAdd: true, // 刷新添加页面
      stkOutGUIDLine: [],
      resourceList: [],
      role: '1'
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: String,
      default: () => {
        return '46%'
      }
    }
  },
  methods: {
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        EOGUID: this.searchModel.EOGUID,
        PageIndex: pageIndex,
        PageSize: this.pageSize
      })
      return this.$post(Config.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.alterVer{
  color: #f00

}
</style>
