<template>
  <znl-gridmodule ref="flexGrid"
              gridtype="action"
              :header="header"
              height="100%"
              layoutType="other"
              :minHeight="minHeight"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
               :btn-mouse-enter-menus="btnMouseEnterMenus"
              :summary-columns="summaryColumns"
              :is-fields-search="true"
              :is-multi-rows-check="true"
              :show-save-row-right-menu="showSaveRowRightMenu"
              :show-add-row-right-menu="showAddRowRightMenu"
              :show-delete-row-right-menu="showDeleteRowRightMenu"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :has-znl-actions="false"
              :action-btns="actionBtns"
              :on-init="onInit"
              :is-init="true"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :show-summary="true"
              :summary-method="billBySum"
              :on-save-row="onSaveRows"
              :on-save-rows="onSaveRows"
              :on-delete-row="onDeleteRow"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData"
              :isShowPagination="isShowPagination"
              :isGridContentChange="isGridContentChange"
              :users="$store.state.users"
              :grid-show="gridShow"
              @has-base-flex-grid="(val)=>{$emit('has-base-flex-grid', val)}"
              @page-changed="onBindData"
              @page-loading="d=>{$emit('page-loading', d)}"
              @selection-changed="d=>{this.stkstockSelectItem=d,$emit('selection-changed',d),onCheckChanged(d)}"
              @event-start="row=>{onCheckChanged(row)}"
              checkboxBindingKey="StkGUID"
             @edit:cell-ended="onCellEdited">

  <div>
    <!--日志dialog-->
  <StkStockLog ref="StkStockLog"
                slot="znl-dialog"
                v-if="showStkstocklog"
                :visible='showStkstocklog'
                :stkGUID="stockGuid"
                @close="()=>{showStkstocklog=false}"
                :showstockLog="refreshStklog"></StkStockLog>
  <!-- 库存调货 -->
  <StkTranferList v-if="showStktransferList"
                  :visible='showStktransferList'
                  ref="StkTranferList"
                  :stkGUIDS="stockGuids"
                  :showstkTransfer="refreshStkTransfer"
                  @update:visible="(val)=>{showStktransferList=val}"
                  @close="()=>{showStktransferList=false}"
                  @confirm="(val)=>{val&&(showStktransferList=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkTranferList>
  <!--库存合并列表-->
  <StkStockMerge ref="StkStockMerge"
                  :stkGUIDS="stockGuids"
                  v-if='showstkMerge'
                  :title='headTitle(stockGuids)'
                  :visible='showstkMerge'
                  :isRefresh="refreshStkMerge"

                  :markingCode="markingCode"
                  @close="()=>{showstkMerge=false}"
                  @confirm="(val)=>{val&&(showstkMerge=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockMerge>
  <!--库存预警列表-->
  <StkStockWarning ref="StkStockWarning"
                    :isRefresh="refreshStkWarning"
                    :visible='showstkWarning'
                    :openFrom="warningFrom"
                    v-if="showstkWarning"
                    @close="()=>{showstkWarning=false}"

                    @confirm="(val)=>{val&&(showstkWarning=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockWarning>

  <StkExtList ref="StkExtList"
              :visible='showstkExt'
              :stkGUIDS="stockGuids"
              :isRefresh="refreshStkExt"
              v-if="showstkExt"
              height="50%"
              @close="()=>{showstkExt=false}"
              @confirm="(val)=>{val&&(showstkExt=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkExtList>

  <!--共享库存列表-->
  <StkSharedList ref="StkSharedList"
              :visible='showstkShared'
              :stkGUIDS="stockGuids"
              :isRefresh="refreshStkShared"
                v-if="true"

              height="50%"
              @close="()=>{showstkShared=false}"
              @confirm="(val)=>{val&&(showstkShared=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkSharedList>

  <!--库存回收站-->
  <StkStockRecycle ref="StkStockRecycle"
                    :isRefresh="refreshStkRecycle"
                    :visible='showstkRecycle'
                    v-if="showstkRecycle"
                    @close="()=>{showstkRecycle=false}"

                    @confirm="(val)=>{showstkRecycle=false,val&&this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockRecycle>

  <!--拆分库存-->
  <StkStockSplit  v-if="showstkSplit"
                  :visible='showstkSplit'
                  :is-footer-show="false"
                  ref="StkStockSplit"
                  :stkGUID="stockGuids"
                  height="50%"
                  :isRefresh="refreshStkSplit"
                  @close="()=>{showstkSplit=false}"
                  @confirm="(val)=>{val&&(showstkSplit=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockSplit>
  <!--打标记-->
  <StkStockMarking ref="StkStockMarking"
                    :visible="showstkMarking"
                    :stkGUID="stockMarkGuids"
                    :stockMarkParam="stockMarkParam"
                    markingType="stock"
                    height="50%"

                    v-if="showstkMarking"
                    :isRefresh="refreshStkMarking"
                    @close="()=>{showstkMarking=false}"
                    @confirm="(val)=>{val&&(showstkMarking=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockMarking>
  <!--预警编辑页面-->
    <StkWarningAdd ref='StkWarningAdd'
                  :visible="stkWarningAddVisible"

                  :stkModelAndBrand="stkStockMandB"
                  :isRefresh="refreshWarningAdd"
                  v-if="stkWarningAddVisible"
                  @close="()=>{stkWarningAddVisible=false}"
                  @confirm="(val)=>{val&&(stkWarningAddVisible=false),this.$refs.flexGrid.clearSelection()}"></StkWarningAdd>

  <!--备货编辑界面-->
  <StkStockReserveAdd ref='StkStockReserveAdd'
                      :visible="stkReserveAddVisible"
                      v-if="stkReserveAddVisible"
                      @close="()=>{stkReserveAddVisible=false}"
                      :stkInfo="stkReserveModel"
                      :isLoadByStk="refreshStkReserve"

                      @confirm="(val)=>{val&&(stkReserveAddVisible=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockReserveAdd>
  <!--采购订货-->
  <StkStockPurchaseAdd ref='StkStockPurchaseAdd'
                        :stkInfo="stkPurchaseModel"
                        :visible="stkPurchaseAddVisible"
                        title="编辑订货"
                        v-if="stkPurchaseAddVisible"

                        :isLoadByStk="refreshStkPurchase"
                        @close="()=>{stkPurchaseAddVisible=false}"
                        @confirm="(val)=>{val&&(stkPurchaseAddVisible=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockPurchaseAdd>
  <!--销售备货-->
  <StkStockReserveDetail ref='StkStockReserveDetail'
                          :stkguid="stkReserveDetailModel"

                          v-if="stkReserveDetailVisible"
                          :visible='stkReserveDetailVisible'
                          :isRefresh="refreshStkReserveDetail"
                          @close="()=>{stkReserveDetailVisible=false}"
                          @confirm="(val)=>{val&&(stkReserveDetailVisible=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockReserveDetail>
  <!--订货编辑界面-->
  <StkStockPurchaseDetail ref='StkStockPurchaseDetail'
                          :stkInfo="stkPurchaseDetailModel"
                          v-if="stkPurchaseDetailVisible"
                          :visible='stkPurchaseDetailVisible'

                          :isRefresh="refreshStkPurchaseDetail"
                          @close="()=>{stkPurchaseDetailVisible=false}"
                          @confirm="(val)=>{val&&(stkPurchaseDetailVisible=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockPurchaseDetail>

  <stk-in-edit
    :title="StkInAddTitle"
    :editvisible="stkInEditVisible"
    v-if="stkInEditVisible"
    :edit-type="editType"
    height="500px"
    @confirm="stkInEditVisible=false"
    @close="stkInEditVisible=false"
  >
  </stk-in-edit>
  <bom-master-detail-edit
    :title="BomAddTitle"
    :editvisible="bomMasterDetailEditVisible"
    v-if="bomMasterDetailEditVisible"
    :edit-type="editType"
    height="500px"
    @confirm="bomMasterDetailEditVisible=false"
    @close="bomMasterDetailEditVisible=false"
  >
  </bom-master-detail-edit>
  <stk-out-edit
    :title="StkOutAddTitle"
    :editvisible="stkOutEditVisible"
    v-if="stkOutEditVisible"
    :edit-type="editType"
    height="500px"
    @confirm="stkOutEditVisible=false"
    @close="stkOutEditVisible=false"
  ></stk-out-edit>
  </div>
  <div slot="znl-footer-slot"  class="StsSalesOut-footer">
    <el-row>
      <el-col :span="12">
        <span>
          <span v-if="this.hasViewBuyPrice()">库存总成本:<span class="stsPurchaseIn-amount">{{StatisticSearch.CostTotal}} 元</span>&nbsp;&nbsp;</span>
          总数量:<span class="stsPurchaseIn-amount">{{StatisticSearch.QuantityTotal}}</span>
        </span>
      </el-col>
    </el-row>
  </div>

  <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkStockLog from '@c_modules/Stk/StkStockLog'
import StkTranferList from '@c_modules/Stk/StkTransferList'
import StkExtList from '@c_modules/Stk/StkExtList'
import StkSharedList from '@c_modules/Stk/StkSharedList'
import StkStockMerge from '@c_modules/Stk/StkStockMerge'
import StkStockWarning from '@c_modules/Stk/StkStockWarning'
import StkStockRecycle from '@c_modules/Stk/StkStockRecycle'
import StkStockSplit from '@c_modules/Stk/StkStockSplit'
import StkStockMarking from '@c_modules/Stk/StkStockMarking'
import StkWarningAdd from '@c_modules/Stk/StkStockWarningAdd'
import StkStockReserveAdd from '@c_modules/Stk/StkStockReserveAdd'
import StkStockPurchaseAdd from '@c_modules/Stk/StkStockPurchaseAdd'
import StkStockReserveDetail from '@c_modules/Stk/StkStockReserveDetail'
import StkStockPurchaseDetail from '@c_modules/Stk/StkStockPurchaseDetail'
import StkInEdit from '@c_modules/Stk/StkInEdit'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'

const CONFIG = {
  PK: 'StkGUID',
  configURL: 'StkStock/GetConfig',
  saveConfigURL: 'StkStock/SaveConfig',
  resetConfigURL: 'StkStock/ResetConfig',
  searchURL: 'StkStock/Search',
  DeleteURL: 'StkStock/Delete',
  saveURL: 'StkStock/Save',
  saveRowsURL: 'StkStock/BatchSave',
  checkStkMergeURL: 'StkStockMerge/CheckData',
  checkAllowImport: 'StkStock/checkAllowImport', // 检查是否允许导入库存
  getStatisticSearchURL: 'StkStock/StatisticSearch', // 我的库存的总成本和总数量
  getStockWarningURL: 'StkStock/StockWarning', // 已预警的型号条数
  default: null
}

export default {
  name: 'StkStock',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
    StkStockLog,
    StkTranferList,
    StkExtList,
    StkSharedList,
    StkStockMerge,
    StkStockWarning,
    StkStockRecycle,
    StkStockSplit,
    StkStockMarking,
    StkWarningAdd,
    StkStockReserveAdd,
    StkStockPurchaseAdd,
    StkStockReserveDetail,
    StkStockPurchaseDetail,
    StkInEdit,
    StkOutEdit
  },
  computed: {
    specialResource () {
      return this.selectOptionsSpecialResource
    }
  },
  data () {
    return {
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      StkInAddTitle: '新增入库单',
      BomAddTitle: '新增客户需求',
      stkInEditVisible: false,
      bomMasterDetailEditVisible: false,
      stkOutEditVisible: false,
      parameterConfig: {},
      actionBtns: [
      ],
      Isdisabled: !this.$store.state.accountInfo.IsMainAccount,
      gridShow: false,
      stkStock: {
        searchModel: {
          data: {},
          extra: {}
        }
      },
      pickerOptions0: {
      },
      stkInquiry: {// 询价参数传递
        SupplierName: '', // 供应商名称
        Model: '', // Model
        Brand: '',
        Packaging: '',
        MakeYear: '',
        Quality: '',
        DeliveryDate: '',
        InvQty: 0,
        Qty: 0,
        MPQ: '',
        Price: 0
      },
      ParamJosn: [],
      IsOutQty: true,
      checkQty: true,
      stockMarkGuids: [],
      stockMarkParam: {},
      stkstockSelectItem: [], // 选中的库存数据
      stockInfo: {},
      stockLine: [], // 传递的库存对象
      stockGuid: '', // 传递库存的GUID
      stockGuids: '', // 传递多个库存的GUID
      markingCode: '',
      showInAdd: false, // 是否显示入库单
      refreshStkin: true, // 是否刷新入库页面
      refreshStkout: true, // 是否刷新出库页面
      refreshStklog: true, // 是否刷新库存日志页面
      refreshStkTransfer: true, // 是否刷新库存调动页面
      refreshStkMerge: true, // 是否刷新合并的库存页面
      refreshStkWarning: true, // 是否刷新库存预警
      refreshStkRecycle: true, // 是否刷新回收站
      refreshStkCustomerRequest: true, // 是否刷新询价列表
      refreshStkSplit: true, // 是否刷新拆分
      refreshStkMarking: true, // 是否刷新标记页面
      refreshWarningAdd: true, // 是否刷新编辑页面
      refreshStkReserve: true, // 是否刷新备货编辑页面
      refreshStkPurchase: true, // 是否刷新订货编辑页面
      refreshStkReserveDetail: true, // 刷新备货详情
      refreshStkPurchaseDetail: true, // 刷新订货详情
      refreshStkExt: true, // 是否刷新推广库存列表
      refreshStkShared: true, // 是否刷新共享库存列表
      showOutAdd: false, // 是否显示出库单
      showStkstocklog: false, // 是否显示日志
      showStktransferList: false, // 是否显示调出库存列表
      showstkMerge: false, // 是否显示库存合并列表
      showstkWarning: false, // 是否显示库存预警
      showstkRecycle: false, // 是否显示库存回收站
      showstkCustomerRequest: false, // 是否显示询价列表
      showstkSplit: false, // 是否显示拆分页面
      showstkMarking: false, // 收显示打标记页面
      stkWarningAddVisible: false, // 是否显示库存预警编辑页面
      stkReserveAddVisible: false, // 是否显示备货编辑页面
      stkPurchaseAddVisible: false, // 是否显示订货编辑页面
      stkReserveDetailVisible: false, // 是否显示备货详情
      stkPurchaseDetailVisible: false, // 是否显示订货详情
      showstkExt: false, // 是否显示推广库存列表
      showstkShared: false, // 是否共享库存列表
      isNegative: false, // 负库存
      isZero: false, // 零库存
      isSureMerge: false, // 可合并库存
      isAlreadyExt: false, // 是否已推广
      matchOperation: 'Like', // 型号匹配方式 默认设为模糊
      model: '', // 型号
      stkStockMandB: '', // 库存的型号和品牌
      stkReserveModel: '', // 传递库存guid
      stkPurchaseModel: '', // 传递库存guid
      stkReserveDetailModel: '', // 传递StkGUID到备货详情
      stkPurchaseDetailModel: '', // 传递型号和品牌到订货详情
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      pageSize: 10,
      btnMouseEnterMenus: {
        List: []
      },
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      // 绑定列表中的下拉框
      dataMapSourse: {
        UnitSource: [], // 单位列表数据源
        InvTypeSource: [], // 库存类型列表数据源
        WarehouseOptions: [] // 仓库列下拉框数据 {WarehouseId:1, WarehouseName:''}
      },
      showSaveRowRightMenu: false,
      showAddRowRightMenu: false,
      showDeleteRowRightMenu: false,
      resourceList: [],
      // specialResource: [],
      options: [{
        value: '模糊',
        label: '模糊',
        key: 'Like'
      }, {
        value: '左边精准',
        label: '左边精准',
        key: 'LeftMatch'
      }, {
        value: '精确',
        label: '精确',
        key: 'Equal'
      }],
      value: '模糊',
      isShowPagination: true,
      isGridContentChange: false,
      IscontinueSave: false,
      StatisticSearch: {
        CostTotal: '',
        QuantityTotal: '' },
      StockWarning: 0,
      warningFrom: 'set',

      columnColors: [],
      rowColors: [],
      summaryColumns: []
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
          return {}
        }
    },
    tagName: String,

    header: {
      type: String,
      default: () => {
        return ''
      }
    },
    isMini: {
      type: Boolean,
      default: false
    },
    isShowFiedsSearch:
    {
      type: Boolean,
      default: false
    },
    isFieldsSearch: {
      type: Boolean,
      default: true
    },
    parentPageSize: {
      type: Number,
      default: 0
    },
    height: String,
    minHeight: String,
    isVisibleAdopt: {// 是否显示采购列
      type: Boolean,
      default: false
    },
    hasOperationColumn: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    // searchModel (val) {
    //   console.log('watch search model')
    //   this.onBindData(1)
    // },

    matchOperation (value) {
      this.onBindData(this.pageIndex)
    },
    IscontinueSave (val) {
      this.$refs.StkStockReserveAdd = val
    },
    selectOptionsQtyUnit (val) {
      _.each(this.columns, column => {
        if (column.binding === 'Unit') {
          this.$set(column, 'dataMap', val)
        }
      })
    },
    selectStorageType (val) {
      _.each(this.columns, column => {
        if (column.binding === 'StorageType ') {
          this.$set(column, 'dataMap', val)
        }
      })
    },
    selectOptionsWarehouseOptions (val) {
      _.each(this.columns, column => {
        if (column.binding === 'WarehouseOptions ') {
          this.$set(column, 'dataMap', val)
        }
      })
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('stkStock_v2', CONFIG.configURL)
        // config = await this.$post(CONFIG.configURL)
      }
      this.ParamJosn = config.ParamJosn
      let IsOutQty = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === 'IsOutQty') {
          return paramjosn.ParamValue
        }
      })
      if (IsOutQty && IsOutQty.ParamValue === '0') { this.IsOutQty = false }
      this.resourceList = config.ResourceList
      this.actionBtns = [
        {
          name: '报价',
          isShow: this.hasRes('Quote'),
          iconName: 'icon-quoted-price_btn_ic',
          click: () => { this.stkCustomerRequest() }
        },
        {
          name: '询价',
          isShow: this.hasRes('Inquiry'),
          iconName: 'icon-ask-price_btn_ic',
          click: () => { this.onStkInquiry() }
        },
        {
          name: '销售出货',
          isShow: this.hasRes('StkOutAdd'),
          iconName: 'icon-sell-wh_btn_ic',
          click: () => { this.stkOutAddRows() }
        },
        {
          name: '采购入库',
          iconName: 'icon-buy-wh_btn_ic',
          isShow: this.hasRes('StkInAdd'),
          click: () => { this.stkInAddRows() }
        },
        {
          name: '库存调货',
          iconName: 'icon-transfer-wh_btn_ic',
          isShow: this.hasRes('StkTransfer'),
          click: () => { this.stkTransfer() }
        },
        {
          name: '库存合并',
          isShow: this.hasRes('StkMerge'),
          iconName: 'icon-combine-wh_btn_ic',
          click: () => { this.stkMerge() }
        },
        {
          name: '打标记',
          isShow: this.hasRes('StkSign'),
          iconName: 'icon-tag_btn_ic',
          click: () => { this.onstkSign() }
        },
        {
          name: '删除',
          isShow: this.hasRes('Delete'),
          iconName: 'icon-delete_btn_ic',
          click: () => { this.onMulitDeleteRow() }
        },
        {
          name: '拆分',
          iconName: 'icon-part_btn_ic',
          isShow: this.hasRes('StkSplit'),
          click: () => { this.onStkSplit() }
        },
        {
          name: '库存预警',
          iconName: 'icon-alarm-wh_btn_ic',
          isShow: this.hasRes('StkWarning'),
          click: () => { this.stkWarning('set') }
        },
        {
          name: '库存回收站',
          isShow: this.hasRes('StkRecycle'),
          iconName: 'icon-recycle-wh_btn_ic',
          click: () => { this.stkRecycle() }
        },
        {
          name: '导入自有库存',
          isShow: this.hasRes('ImportStock'),
          iconName: 'icon-leading-in-wh_btn_ic',
          click: () => { this.onstkImport() }
        },
        {
          name: '导出(按查询条件)',
          isShow: this.hasRes('ExportStock'),
          iconName: 'icon-leading-in-wh_btn_ic',
          click: () => { this.onstkExport() }
        },
        {
          name: '上传到推广库存',
          isShow: this.hasRes('Upload'),
          iconName: 'icon-uploading-wh_btn_ic',
          click: () => { this.onstkExt() }
        },
        {
          name: '上传到共享库存',
          // isShow: true,
          isShow: this.hasRes('OnStkShared'),
          iconName: 'icon-share_add_btn',
          click: () => { this.onstkShared() }
        }
      ]
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      columns.unshift({
        name: 'operate',
        dataType: 5,
        binding: 'operate',
        width: 36,
        header: '操作',
        visible: true,
        isReadOnly: true,
        isFieldsSearch: false,
        isSystem: true,
        align: 'left',
        className: 'znlerp-operate znl-text-center',
        buttons: [
          {
            content: '<i class="iconfont icon-left_nav_show_btn"></i>',
            className: 'znl-table-btn',
            click: () => { }
          }
        ]
      })

      this.btnMouseEnterMenus = {
        List: [
          {
            name: '销售出货',
            isShow: this.hasRes('StkOutAdd'),
            click: row => {
              let rows = []
              rows.push(row)
              this.onStkOutadd(rows)
            }
          },
          {
            name: '采购入库',
            isShow: this.hasRes('StkInAdd'),
            click: row => {
              let rows = []
              rows.push(row)
              this.onStkInadd(rows)
            }
          },
          {
            name: '给客户报价',
            isShow: this.hasRes('Quote'),
            click: row => {
              let rows = []
              rows.push(row)
              this.onBomAdd(rows)
            }
          },
          {
            name: '销售备货',
            isShow: this.hasRes('BtnStkReserve'),
            click: row => {
              let guid = []
              guid.push(row.StkGUID)
              this.onAddStkReserve(guid)
            }
          },
          {
            name: '采购订货',
            isShow: this.hasRes('BtnStkPurchase'),
            click: row => {
              let guid = []
              guid.push(row.StkGUID)
              this.onAddStkPurchase(guid)
            }
          },
          {
            name: '库存预警',
            isShow: this.hasRes('StkWarning'),
            click: row => {
              this.onAddStkWarning(row)
            }
          },
          {
            name: '删除库存',
            isShow: this.hasRes('Delete'),
            click: row => {
              let guid = []
              guid.push(row.StkGUID)
              this.onDeleteOne(guid)
            }
          },
          {
            name: '设置推广',
            isShow: this.hasRes('Upload'),
            click: row => {
              let guid = []
              guid.push(row.StkGUID)
              this.stockGuids = JSON.stringify(guid)
              this.showstkExt = true// 是否显示推广库存页面
              this.refreshStkExt = !this.refreshStkExt
            }
          },
          // {
          //   name: '共享',
          //   isShow: this.hasRes('OnStkShared'),
          //   iconName: 'iconfont icon-table_share_btn',
          //   click: row => {
          //     let guid = []
          //     guid.push(row.StkGUID)
          //     this.stockGuids = JSON.stringify(guid)
          //     this.showstkShared = true// 是否显示共享库存页面
          //     this.refreshStkShared = !this.refreshStkShared
          //   }
          // },
          {
            name: '查看日志',
            isShow: !this.hasSeeLogRes(),
            click: row => {
              this.stockGuid = row.StkGUID
              this.showStkstocklog = true
              this.refreshStklog = !this.refreshStklog// 改变状态进行刷新
            }
          }
        ],
        Binding: 'operate'
      }

      // 锁定量，在途量，供应商
      _.each(columns, column => {
        if (column.binding === 'ReserveLockQty') {
          // console.log(column.binding, '123')
          column.buttons = [{
            'content': (row) => {
              return row.ReserveLockQty || ''
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.onLoadStkReserveDetail(row)
            }
          }]
          column.dataType = 5
        }
        if (column.binding === 'TransitQty') {
          column.buttons = [{
            'content': (row) => {
              return row.TransitQty || ''
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              this.onLoadStkPurchaseDetail(row)
            }
          }]
          column.dataType = 5
        }
        if (column.binding === 'SupplierName') {
          column.buttons = [{
            'content': (row) => {
              return row.SupplierName || ''
            },
            'className': 'znl-table-btn-success',
            'when': true,
            'click': (row) => {
              // console.log(row)
              this.onInquiry(row)
            }
          }]
          column.dataType = 5
        }
      })

      // 下拉列表
      let QtyUnitDropDown = this.selectOptionsQtyUnit

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )

      // 采购员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'BuyerName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 更新人
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'UpdatedByName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 库存类型
      // let StorageType = this.selectStorageType
      let StorageType = [{ 'key': '自有', 'value': '自有' }, { 'key': '补货', 'value': '补货' }]
      // console.log(StorageType, 'StorageType')
      let stkTypeColumn = _.find(columns, column => column.name === 'InvType')
      stkTypeColumn && _.extend(stkTypeColumn, {
        dataMap: StorageType, showDropDown: true
      })
      let AdoptQtyColumns = _.find(
        columns,
        column => column.name === 'AdoptQty'
      )
      _.extend(AdoptQtyColumns, { isReadOnly: true, visible: false })
      // 仓库
      let WarehouseOptions = this.selectOptionsWarehouseOptions
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'InvPosition'
        }),
        { dataMap: WarehouseOptions, showDropDown: true }
      )
      // 库存统计
      this.$post(CONFIG.getStatisticSearchURL, data => {
        this.StatisticSearch = data
      })
      // 获取预警数量
      this.$post(CONFIG.getStockWarningURL, (data, code) => {
        this.StockWarning = data
      })
      // await this.selectOptionsSpecialResource()
      // 特殊权限， 判断是否可以查看采购价
      if (!this.hasViewBuyPrice()) {
        let taxBuyPriceCol = _.find(columns, m => m.binding === 'TaxBuyPrice')
        if (!_.isUndefined(taxBuyPriceCol)) { taxBuyPriceCol.hasRole = false }
        let buyPriceCol = _.find(columns, m => m.binding === 'BuyPrice')
        if (!_.isUndefined(buyPriceCol)) { buyPriceCol.hasRole = false }
      }
      // 特殊权限， 判断是否可以查看销售
      if (!this.hasViweSalesPrice()) {
        let salesPrice = _.find(columns, m => m.binding === 'SalesPrice')
        if (!_.isUndefined(salesPrice)) { salesPrice.hasRole = false }
        let followPrice = _.find(columns, m => m.binding === 'FollowPrice')
        if (!_.isUndefined(followPrice)) { followPrice.hasRole = false }
      }
      this.parameterConfig = config.ParamJosn
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.showDeleteRowRightMenu = this.hasRes('Delete')
      this.showSaveRowRightMenu = this.hasRes('Save')
      await this.onBindData()
      // console.log('123454', this.columns)
      // this.$emit()
    },
    ContinueBind (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
        isNegative: this.isNegative, // 负库存
        isZero: this.isZero, // 零库存
        isSureMerge: this.isSureMerge, // 可合并库存
        isAlreadyExt: this.isAlreadyExt, // 是否已经推广库存
        matchOperation: this.matchOperation, // 型号匹配方式
        model: this.model
      })
      this.stkstockSelectItem = []
      return this.$post(CONFIG.searchURL, data, (datas) => {
        this.pageIndex = pageIndex
        this.itemSource = datas.ResultList
        this.totalCount = datas.TotalCount
        // console.log('条' + this.totalCount)
        // console.log(datas)
        this.$refs.flexGrid.checkedRows = []
        this.$refs.flexGrid.checkedRowsCount = 0
      })
    },
    billBySum (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value)) && (column.property === 'InvQty')) { // Amount：金额
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return ''
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    confirmCallBack () {
      if (this.$refs.StkExtList.hasService === 0) {
        return this.$message({ message: '你尚未开通库存推广服务，请联系正能量客服人员', type: 'error' })
      }
      let rows = this.$refs.StkExtList.itemSource
      // console.log(rows)
      return this.$post(this.$refs.StkExtList.config.stkExtSaveURL, { LineList: rows }, (datas) => {
        if (datas > 0) {
          this.$message({ message: '上传推广库存成功', type: 'success' })
          this.$emit('confirm', true)
          this.showstkExt = false
        } else {
          this.$message({ message: '上传推广库存失败', type: 'error' })
          this.showstkExt = false
        }
      })
    },
    confirmCallBackStkStockRecycle () {
      this.showstkRecycle = false
      this.onRefresh()
      this.$refs.flexGrid.clearSelection()
    },
    headTitle (stkGUIDS) {
      return '待合并库存列表：(共选择了' + JSON.parse(stkGUIDS).length + '条库存)'
    },
    onSearch (params) {
      // console.log('onsearch')
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
        isNegative: this.searchModel.isNegative, // 负库存
        isZero: this.searchModel.isZero, // 零库存
        isSureMerge: this.searchModel.isSureMerge, // 可合并库存
        isAlreadyExt: this.searchModel.isAlreadyExt, // 是否已经推广库存
        matchOperation: this.searchModel.matchOperation, // 型号匹配方式
        model: this.searchModel.Model
      })
      this.stkstockSelectItem = []
      return this.$post(CONFIG.searchURL, data, (datas) => {
        this.pageIndex = pageIndex
        this.itemSource = datas.ResultList
        this.totalCount = datas.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    onBeforeAddRow () {
      let nullPK = {}
      nullPK[CONFIG.PK] = null
      return _.extend({}, nullPK)
    },
    onRefresh () {
      return this.onBindData(this.pageIndex)
    },
    onSaveRows (rows) {
      if (rows.length > 0) {
        return this.$post(CONFIG.saveRowsURL, rows, (data) => {
          if (data >= 0) {
            this.$message({ message: '保存成功', type: 'success' })
            this.isGridContentChange = false
            // this.onBindData(this.pageIndex)
            this.$refs.flexGrid.$refs.actionFlexGrid.saveScueess()
          } else {
            this.$message({ message: '保存失败', type: 'error' })
          }
        })
        // return rows
      }
      // znlMethodsMixin.methods(rows, CONFIG)
    },
    onDeleteRow (deleteData) { // 删除行
      let guid = []
      guid.push(deleteData.StkGUID)
      this.onPostDelete(guid)
    },
    onSelectRow () { // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (this.stkstockSelectItem.length === 0) { // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          guid.push(this.stkstockSelectItem.StkGUID)
        }
      } else {
        guid = _.map(rows, item => { return item.StkGUID })
      }
      return guid
    },
    clearSelectedRows () {
      this.$refs.flexGrid.clearSelection()
    },
    onMulitDeleteRow () { // 删除多条库存
      let guid = this.onSelectRow()
      if (guid.length > 0) {
        this.$confirm('删除确认, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          return this.onPostDelete(guid)
        })
      }
    },
    onDeleteOne (guid) { // 删除单条
      this.$confirm('删除确认, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return this.onPostDelete(guid)
      })
    },
    onPostDelete (guid) { // 删除请求
      return this.$post(CONFIG.DeleteURL, { StkGUIDS: guid }, (data) => {
        if (data) {
          this.$message({ message: '删除成功', type: 'success' })
          // this.refreshCheckedCount(guid)
          this.onRefresh()
          this.$refs.flexGrid.clearSelection()
        } else { this.$message({ message: '删除失败', type: 'error' }) }
      })
    },
    onBomAdd (rows) {
      this.stockLine = rows
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.bomMasterDetailEditVisible = true
      this.$nextTick(function () {
        this.bomMasterDetailEditVisible = true
      })
    },

    onStkInadd (rows) { // 采购入库传值方法
      this.$store.state.visibled = false
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true
      })
    },
    onStkOutadd (rows) { // 销售出库传值方法
      this.stockLine = rows
      let order = { stockLine: rows, checkQty: true, IsOutQty: this.IsOutQty }
      this.$store.state.visibled = false
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        // this.editType = 'addNew'
        this.stkOutEditVisible = true
      })
    },
    stkInAddRows () { // 批量采购入库
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onStkInadd(rows)
        this.clearSelectedRows()
      }
    },
    stkOutAddRows () { // 批量销售出库
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onStkOutadd(rows)
        this.clearSelectedRows()
      }
    },
    stkTransfer () { // 库存调货
      let rows = this.onSelectRow()
      if (rows.length > 0) {
        this.stockGuids = JSON.stringify(rows)
        this.showStktransferList = true
        this.refreshStkTransfer = !this.refreshStkTransfer
        // console.log(this.refreshStkTransfer)
        this.clearSelectedRows()
      }
    },
    stkMerge () { // 库存合并
      let rows = this.onSelectRow()
      if (rows.length > 0) {
        let result = this.$post(CONFIG.checkStkMergeURL, { StkGUIDS: rows }, (data) => {
          // console.log(' 库存合并', data)
          if (data === -1) {
            this.$message({ message: '合并操作,必须选择2条及以上的我的库存！', type: 'error' })
          } else if (data === -2) {
            this.$message({ message: '选择的库存且可用量不能为0！', type: 'error' })
          } else if (data === 0) {
            this.stockGuids = JSON.stringify(rows)
            // this.markingCode = this.$refs.flexGrid.getSelectedRows()[0].MarkingCode
            let dataRows = this.$refs.flexGrid.getSelectedRows()
            this.markingCode = ''
            _.some(dataRows, code => {
              if (!_.isEmpty(code.MarkingCode)) {
                this.markingCode = code.MarkingCode
                return true
              }
            })
            this.showstkMerge = true
            this.refreshStkMerge = !this.refreshStkMerge
          }
        })
        // this.clearSelectedRows()
        return result
      }
    },
    onstkExt () { // 上传到推广库存
      let rows = this.onSelectRow()
      if (rows.length > 0) {
        this.stockGuids = JSON.stringify(rows)
        this.showstkExt = true// 是否显示推广库存页面
        this.refreshStkExt = !this.refreshStkExt
        this.clearSelectedRows()
      }
    },
    onstkShared () { // 上传到共享库存
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (this.stkstockSelectItem.length === 0) { // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        }
      }
      let guid = _.map(rows, item => { return item.StkGUID })
      if (guid.length > 0) {
        this.stockGuids = JSON.stringify(guid)
        this.showstkShared = true// 是否显示共享库存页面
        this.refreshStkShared = !this.refreshStkShared
        this.clearSelectedRows()
      }
    },
    stkWarning (from) { // 库存预警
      this.warningFrom = from
      this.showstkWarning = true
      this.refreshStkWarning = !this.refreshStkWarning
      this.clearSelectedRows()
    },
    stkRecycle () { // 库存回收站
      this.showstkRecycle = true
      this.refreshStkRecycle = !this.refreshStkRecycle
      this.clearSelectedRows()
    },
    stkCustomerRequest () { // 报价
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onBomAdd(rows)
        this.clearSelectedRows()
      }
    },
    onSelectRowGuid (openType) {
      let guid = []
      let rows = []
      let lists = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(lists[0])) {
        if (this.stkstockSelectItem.length === 0) { // 选中行
          // return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          rows.push(this.stkstockSelectItem.StkGUID)
        }
      } else {
        rows = _.map(lists, item => { return item.StkGUID })
      }
      // return guid

      if (rows[0] + '' === 'undefined') { // 选中行
        if (openType === 'BomMasterDetailAdd') {
          this.$store.state.visibled = false
          // this.$store.commit('removeTab', 'Ord/BomMasterAdd')
          this.$nextTick(function () {
            this.bomMasterDetailEditVisible = true
          })
        } else if (openType === 'StkOutAdd') {

        } else if (openType === 'StkInAdd') {
          this.$store.state.visibled = false
          this.$nextTick(function () {
            // this.editType = 'addNew'
            this.stkInEditVisible = true
          })
        } else {
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        }
      }
      if (rows.length > 0) {
        _.each(rows, row => {
          guid.push(row)
        })
      }
      return guid
    },
    onCheckChanged (row) {
      let rows = this.$refs.flexGrid.getSelectedRows()
      // console.log(rows)
      this.$emit('get-checked-rows', rows)
    },
    onStkSplit () { // 拆分
      // console.log(this.isChange, 'ischange')
      let rows = this.onSelectRow()
      if (rows.length > 0) {
        this.stockGuids = JSON.stringify(rows)
        this.showstkSplit = true
        this.refreshStkSplit = !this.refreshStkSplit
        this.clearSelectedRows()
      }
    },
    searchParam () { // 查询条件
      return _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter)
      }, {
          // 外部搜索条件
        isNegative: this.isNegative, // 负库存
        isZero: this.isZero, // 零库存
        isSureMerge: this.isSureMerge, // 可合并库存
        isAlreadyExt: this.isAlreadyExt, // 是否已经推广库存
        matchOperation: this.matchOperation, // 型号匹配方式
        model: this.model
      })
    },
    onstkSign () { // 打标记
      let guids = this.onSelectRow()
      if (guids.length > 0) {
        this.$nextTick(function () {
          this.stockMarkGuids = guids              // 传递给标记的库存
          this.stockMarkParam = this.searchParam()// 传递给标记的条件
          this.showstkMarking = true
          this.refreshStkMarking = !this.refreshStkMarking
          this.clearSelectedRows()
        })
      }
    },
    onAddStkWarning (row) { // 添加库存预警
      this.stkStockMandB = row.Model + ',' + row.Brand
      this.stkWarningAddVisible = true
      this.refreshWarningAdd = !this.refreshWarningAdd
    },
    onStkInquiry: async function () { // 询价
      let row = this.$refs.flexGrid.getSelectedRows()
      if (row.length > 0) {
        this.onInquiry(row[0])
        this.clearSelectedRows()
      } else {
        return this.$message({ message: '请选择一条数据', type: 'error' })
      }
    },
    onInquiry: async function (data) { // 询价
      // console.log(data)
      this.$nextTick(function () {
        this.stkInquiry.SupplierName = data.SupplierName
        this.stkInquiry.Model = data.Model
        this.stkInquiry.Brand = data.Brand
        this.stkInquiry.Packaging = data.Packaging
        this.stkInquiry.MakeYear = data.MakeYear
        this.stkInquiry.Quality = data.Quality
        // this.stkInquiry.DeliveryDate = data.DeliveryDate
        this.stkInquiry.InvQty = data.InvQty
        this.stkInquiry.MPQ = data.MPQ
        this.stkInquiry.Qty = data.InvQty
        this.stkInquiry.Price = data.BuyPrice
        // console.log(this.stkInquiry)
        this.addInquire(this.stkInquiry, function (msg) {
          if (msg === '不能向自己询价') {
            this.$message({ message: msg, type: 'warning' })
          }
        })
      })
    },
    onstkImport: async function () { // 导入自有库存
      // 先做验证，库存量已超出最大库存量，不能再导入
      let IsAllowImport = await this.$post(CONFIG.checkAllowImport)
      this.$nextTick(function () {
        if (!IsAllowImport) {
          return this.$message({ message: '您的库存量已超出最大库存量，不能再导入', type: 'error' })
        }
        this.importData({ importType: 'stock' })
        this.clearSelectedRows()
      })
    },
    onstkExport: async function () { // 导出（按查询条件）
      let newfieldsFilter = this.fieldsFilter
      if (newfieldsFilter.UpdatedTime !== '') {
        newfieldsFilter.UpdatedTime = ''
      }
      if (newfieldsFilter.StkInTime !== '') {
        newfieldsFilter.StkInTime = ''
      }
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(newfieldsFilter)
      }, {
          // 外部搜索条件
        isNegative: this.isNegative, // 负库存
        isZero: this.isZero, // 零库存
        isSureMerge: this.isSureMerge, // 可合并库存
        isAlreadyExt: this.isAlreadyExt, // 是否已经推广库存
        matchOperation: this.matchOperation, // 型号匹配方式
        model: this.model
      })
      // console.log(data)
      // console.log(this.columns)
      this.$nextTick(function () {
        this.exportStock({ url: '', whereJson: data, dgvName: this.columns, stockType: '1' })
      })
    },
    async onMarketSearch () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length === 0 || _.isUndefined(rows[0])) {
        return this.$message({ message: '请勾选一个或者多个型号，再点击市场查货操作', type: 'warning' })
      }
      let request = []
      _.each(rows, item => {
        request.push({
          // SourceGuid: item.BDLineGUID, // 需求明细GUID，
          Model: item.Model, // 型号，
          // SourceId: item.BDLineID, // 需求明细ID，
          Brand: item.Brand // 品牌，
          // Pack: item.Packaging, // 封装，
          // MakeYear: item.MakeYear, // 年份，
          // Qty: item.Qty, // 数量，
          // Remark: item.Remark // 需求说明
        })
      })

      this.$nextTick(function () {
        this.openMarketSearch({ Requests: request, ClearRequests: false })
      })
    },
    onAddStkReserve (row) { // 添加库存备货
      this.stkReserveAddVisible = true
      this.refreshStkReserve = !this.refreshStkReserve
      this.stkReserveModel = JSON.stringify(row)
    },
    onAddStkPurchase (row) { // 添加库存订货
      this.stkPurchaseAddVisible = true
      this.refreshStkPurchase = !this.refreshStkPurchase
      this.stkPurchaseModel = JSON.stringify(row)
    },
    onLoadStkReserveDetail (row) { // 显示备货详情
      this.stkReserveDetailVisible = true
      this.stkReserveDetailModel = row.StkGUID
      this.refreshStkReserveDetail = !this.refreshStkReserveDetail
    },
    onLoadStkPurchaseDetail (row) { // 显示订货详情
      this.stkPurchaseDetailVisible = true
      this.stkPurchaseDetailModel = row.Model + ',' + row.Brand + ',' + row.StkGUID
      this.refreshStkPurchaseDetail = !this.refreshStkPurchaseDetail
    },
    hasRes (code) {
      // console.log('1111', code, _.some(this.resourceList, page => page.Code === code))
      return _.some(this.resourceList, page => page.Code === code)
    },
    async hasSeeLogRes () {
      let isnotSee = _.some(this.specialResource, item => item.Code === 'NoSeeStockLog')
      return isnotSee
    },
    hasViewBuyPrice () { // 特殊权限，查看采购价
      let isnotSee = _.some(this.specialResource, item => item.Code === 'ViewBuyPrice')
      return isnotSee
    },
    hasViweSalesPrice () { // 特殊权限，查看销售价
      let isnotSee = _.some(this.specialResource, item => item.Code === 'ViewSalesPrice')
      return isnotSee
    },
    getCheckedKeys () {
      var keys = this.$refs.flexGrid.getCheckedKeys()
      return keys
    },
    getCheckedRows () {
      var rows = this.$refs.flexGrid.getCheckedRows()
      return rows
    },
    cancelAllChecked () {
      this.$refs.flexGrid.cancelAllChecked()
    },
    refreshCheckedCount (keys) {
      this.$refs.flexGrid.refreshCheckedCount(keys)
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stkStock_v2')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    async onResetSetting () {
      localStorage.removeItem('stkStock_v2')
      await this.$post(this.$options.config.resetConfigURL)
    },
    onSaveConfig (column) {
      localStorage.removeItem('stkStock_Mini')
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      )
    },
    onAdopt (StkGUID) {
      // console.log('按钮', StkGUID)
      this.$emit('ChkStkGUID', StkGUID)
    },
    onAdoptEnd (stkGUID) { // 采纳结束后前台显示值改变
      // console.log('传参数', stkGUID)
      this.searchModel.AdoptstkGUID = stkGUID
    },
    onCellEdited (aftervalue, beforevalue, colname, gridobj, rowindex, colindex) { // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // 采购价(未税)
      if (colname === 'BuyPrice') {
        this.itemSource[rowindex]['BuyPrice'] = this.toFloat(aftervalue, 6)
      }
      // 成本金额
      if (colname === 'TotalCostAmount') {
        this.itemSource[rowindex]['TotalCostAmount'] = this.toFloat(aftervalue, 2)
      }
      // 销售定价
      if (colname === 'SalesPrice') {
        this.itemSource[rowindex]['SalesPrice'] = this.toFloat(aftervalue, 6)
      }
      // 采购价(含税)
      if (colname === 'TaxBuyPrice') {
        this.itemSource[rowindex]['TaxBuyPrice'] = this.toFloat(aftervalue, 6)
      }
      // 建议销售价
      if (colname === 'FollowPrice') {
        this.itemSource[rowindex]['FollowPrice'] = this.toFloat(aftervalue, 6)
      }
      // 库存量
      if (colname === 'InvQty') {
        this.itemSource[rowindex]['InvQty'] = this.toFloat(aftervalue, 2)
      }
      // 采纳数量
      if (colname === 'AdoptQty') {
        this.itemSource[rowindex]['AdoptQty'] = this.toFloat(aftervalue, 2)
      }
      // 锁定量
      if (colname === 'ReserveLockQty') {
        this.itemSource[rowindex]['ReserveLockQty'] = this.toFloat(aftervalue, 2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.znl-search {
  .el-input {
    width: 240px;
  }
}
</style>
