 <template>
    <!-- :class="isTabsHide?'tabs_show':'tabs_hide'" -->
  <el-tabs v-model="activeName" class="stock-all-batches-tabs tabs_hide tab_maxbox stockall-box" @tab-click="handleClick">
    <el-tab-pane name="first" v-if="stkSummaryRightdefault">
      <span slot="label"  class="znl-header-text header-title">
        · 汇总库存
        <i :class="['iconfont_module', !summaryStockActivity?'icon-table_unfold_n1':'icon-table_pack-up_btn_n1']" @click="switchActivity('summaryStock')">
        </i>
      </span>
         <div v-show="summaryStockActivity" v-bind:style="{height:height+'px'}">
           <znl-layout-leftright leftWidth="170px">
                <div slot="znlLeft" class="znlLeft">
                  <stk-summary-stock-tree
                      @folder-change="folderChange"
                     :re-fresh-tree-data="reFreshTreeData"
                      @trigger-search="$refs.flexGrid.search()">
                  </stk-summary-stock-tree>
                </div>
                <div slot="znlRight" class="znlRight">
                  <znl-stock-summary
                    ref="summaryStock"
                    :is-init="true"
                    :is-fields-search="false"
                    :is-activity="true"
                    :init-data="true"
                    :computedheight="computedheight"
                    :match-operation="ssSearchModel.MatchOperation"
                    @page-loading="val=>{pageLoading('sumStock',val)}"
                    @searchfields-change="btnStockSummaryModel"
                    @refresh-marks="()=>{this.reFreshTreeData=this.reFreshTreeData===true?'on':'off'}"
                    @switch-to-hide="val=>{switchToHide(val, 2, 'summaryStockActivity')}"
                    @cell-click="onCellClick"
                    :search-model.sync="ssSearchModel">
                    </znl-stock-summary>
                </div>
           </znl-layout-leftright>
         </div>
    </el-tab-pane>
    <el-tab-pane name="second" v-if="stkBatchRight">
      <span slot="label" :style="{'margin-left': stkSummaryRight? '0': '-10px' }" class="znl-header-text header-title">
        · 批次库存
        <i :class="['iconfont_module', !batchesStockActivity?'icon-table_unfold_n1':'icon-table_pack-up_btn_n1']" @click="switchActivity('batchesStock')">
        </i>
      </span>
      <div class="stock-all-batches"  v-show="batchesStockActivity"  v-bind:style="{height:height+'px'}">
        <stk-batches-stock ref="batchesStock"
          header=''
          :is-fields-search="false"
          :is-init="true"
          :init-data="initBatchStockData"
          :is-activity="true"
          :is-multi-rows-check="true"
          :computedheight="computedheight"
          :match-operation="bsSearchModel.MatchOperation"
          @save-stkIn-succes="$emit('save-stkIn-succes')"
          @save-stkOut-succes="$emit('save-stkOut-succes')"
          @page-loading="val=>{pageLoading('batchStock',val)}"
          @switch-to-hide="val=>{switchToHide(val, 1, 'batchesStockActivity')}"
          @cell-click="onCellClick"
          @QuotePriceDetial="(val)=>{$emit('QuotePriceDetial', val)}"
          :search-model.sync="bsSearchModel">
          </stk-batches-stock>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import StkBatchesStock from '@c_modules/Stk/StkBatchesStock'
import ZnlStockSummary from '@c_modules/StkSum/StockSummary'
import stkSummaryStockTree from '@c_modules/StkSum/stkSummaryStockTree'

export default {
  name: 'MBatchesAndSummaryStock',
  components: {
    StkBatchesStock,
    ZnlStockSummary,
    stkSummaryStockTree
  },
  props: {
    isRowHide: {
      type: Boolean,
      default: true
    },
    computedheight: {
      type: Number
    },
    height: {
      type: Number,
      default: 475
    },
    stockSearchModel: {
      Model: '',
      isAnalyzer: false,
      MatchOperation: 'Like'
    },
    stkBatchRight: false, // 批次库存模块权限
    // 汇总库存模块权限
    stkSummaryRight: {
      default: false
    }
  },
  data () {
    return {
      bsSearchModel: {
        isAnalyzer: false,
        MatchOperation: 'Like',
        MarkNames: '',
        MarkValue: '',
        Model: '',
        activeState: true
      },
      ssSearchModel: {
        isAnalyzer: false,
        MatchOperation: 'Like',
        MarkNames: '',
        MarkValue: '',
        Model: '',
        activeState: true
      },
      TreeSearchModel: {
        Model: ''
      },
      activeName: this.stkSummaryRight ? 'second' : 'first',
      activeState: true,
      isTabsHide: false,
      batchesStockActivity: true,
      summaryStockActivity: true,
      quoteForm: {
        Model: '',
        Qty: 0,
        Brand: '',
        Package: '',
        Quality: '',
        DeliveryDate: ''
      },
      reFreshTreeData: '',
      stockSummaryValue: '',
      initBatchStockData: false, // 是否初始化批次库存数据
      stkSummaryRightdefault: false // 初始化汇总库存不加载，如果用init-data方案异常
    }
  },
  watch: {
    stkSummaryRight: {
      handler: function (val) {
        if (!val) { // 如果没有汇总库存的权限
          this.activeName = 'second'
          this.initBatchStockData = true
        } else {
          this.activeName = 'first'
          this.stkSummaryRightdefault = this.stkSummaryRight
        }
      }
    }
  },
  methods: {
    onSearch (value) {
      this.reFreshTreeData = value.Model
      this.handleClick()
    },
    sumStockSelectionChanged (item) {
      this.$emit('selection-changed', item)
    },
    onCellClick (item) {
      this.$emit('cell-click', item)
    },
    btnStockSummaryModel (value) {
      this.stockSummaryValue = value
    },
    folderChange (node) {
      if (this.hasValue(node.MarkValue)) {
        this.ssSearchModel.MarkValue = node.MarkValue
        this.ssSearchModel.MarkNames = ''
      } else {
        this.ssSearchModel.MarkNames = node.MarkerName
        this.ssSearchModel.MarkValue = ''
      }
      if (this.hasValue(this.stockSummaryValue)) {
        this.stockSearchModel.Model = this.stockSummaryValue
      } else {
        this.stockSearchModel.Model = ''
      }
      this.copySearchOptions(this.ssSearchModel, 1)
    },
    handleClick (tab, event) {
      if (this.activeName === 'second' && !_.isEqual(this.stockSearchModel, this.bsSearchModel)) {
        this.initBatchStockData = true
        this.copySearchOptions(this.bsSearchModel, tab)
      } else if (this.activeName === 'first' && !_.isEqual(this.stockSearchModel, this.ssSearchModel)) {
        this.copySearchOptions(this.ssSearchModel, tab)
      }
    },
    copySearchOptions (dest, state) {
      let source = this.stockSearchModel
      if (dest.Model !== source.Model && dest.Model !== '' && state !== undefined && this.activeState) {
        dest.Model = dest.Model
      } else {
        dest.Model = source.Model
      }
      dest.MatchOperation = source.MatchOperation
      dest.isAnalyzer = source.isAnalyzer
      this.activeState = state !== undefined
      dest.activeState = this.activeState
    },
    switchActivity (type) {
      return this.$refs[type].toggleTableShow()
    },
    QuotePriceDetial (row) {
      this.quoteForm.Model = row.Model
      if (row.InvQty > 0) {
        this.quoteForm.Qty = row.InvQty
      } else if (row.InvQty < 0) {
        this.quoteForm.Qty = -row.InvQty
      } else {
        this.quoteForm.Qty = ''
      }
      this.quoteForm.Brand = row.Brand
      this.quoteForm.Packaging = row.Packaging
      this.quoteForm.Quality = row.Quality
      this.quoteForm.DeliveryDate = row.DeliveryDate
    },
    switchToHide (isHide, index, typeData) {
      this.$emit('switch-to-hidestate', isHide, typeData)
      if (typeData) {
        this[typeData] = isHide
      }
      if (index === 2) {
        this.isTabsHide = isHide
      }
      if (index === 1) { // 批次库存
        this.$refs.batchesStock.saveStatus(isHide)
      }
      if (index === 2) { // 汇总库存
        this.$refs.summaryStock.saveStatus(isHide)
      }
    },
    pageLoading (source, val) {
      if (source === 'sumStock') {
        this.$emit('page-loading', val)
      } else if (source === 'batchStock' && !this.stkSummaryRight) {
        this.$emit('page-loading', val)
      }
    }
  },
  computed: {}
}
</script>
<style lang="scss">
.el-tabs__nav-wrap::after {
  position: inherit;
}
.tabs_hide {
  .el-tabs__header {
    background-color: #e6e6e6 !important;
  }
}
.tabs_show {
  .el-tabs__header {
    background-color: #e6e6e6 !important;
  }
}
.znl-header-text {
  .iconfont {
    font-size: 12px;
  }
}
.stock-all-batches-tabs {
  .el-tabs__header {
    margin: 0;
  }
  #tab-first {
    padding-left: 0px;
  }
  .znl-layout-leftright .znlLeft {
    padding-right: 0 !important;
  }
  .znl-layout-leftright {
    height: 100% !important;
  }
  .znl-layout-leftright .znlRight {
    padding-left: 0;
  }
}

.tab_maxbox .el-tabs__header {
  padding-left: 10px;
}

.iconfont_module {
  font-size: 12px;
  font-family: "iconfont" !important;
  font-size: 10px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.summaryStock .znl-table-body-wrapper {
  border-left: 1px solid #d8d8d8;
}
</style>
