<template>

  <!-- <div>
    <znl-stock-vmi v-if='hasRight("StockVMI")'
                   :searchModel="StockVMI" height="97%">
    </znl-stock-vmi>
  </div> -->

   <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
          <znl-stock-vmi
          slot="znlLayoutSpa"
          class="znlLayoutSpa"
           @page-loading="(d)=>{pageLoading=d}"
          ></znl-stock-vmi>
       </znl-layout-spa>
</template>

<script>
import ZnlStockVmi from '@c_modules/Stk/StockVMI'

export default {
  name: 'VStockVMI',
  components: {
    ZnlStockVmi
  },
  data () {
    return {
      StockVMI: {},
      pageData: [],
      pageLoading: true
    }
  },
  methods: {
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    },
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VStockVMI'})
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>
