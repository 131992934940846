<template>
  <div slot="action-form" style="padding: 5px 0">
    <div class="stkStock-action-form">
      <znl-input form-type="input"
                 :disabled="false"
                 placeholder="请输入型号"
                 width='120px'
                 size="mini"
                 :height="26"
                 class="ml5"
                 layout="left"
                 v-model="Model"
                 :clearable="true"
                 @keyup.enter.native="onSearch"
                 type="text">
      </znl-input>
      <span class="znl-line">|</span>
      <znl-input layout="left"
                 form-type="select"
                 :disabled="false"
                 width='85px'
                 :height="26"
                 class="popperClass"
                 :clearable="true"
                 popper-class="popperClass"
                 :select-options="options"
                 v-model="matchOperation">
      </znl-input>
    </div>
    <!-- <div class="stkStock-action-form action-form2"> -->
    <div slot="heading-slot">
      <znl-button style-type='main' @click="onSearch" :height='22'>
        <span><i class="iconfont icon-search_ic"></i> 搜索</span>
      </znl-button>

      <!-- <znl-button type="multiple" :height='22'>
                 <znl-button-menu
                 @click="onSearch">
                 <span><i class="iconfont icon-search_ic"></i>搜索</span>
                 </znl-button-menu>
               </znl-button> -->


      <znl-button :height='22' @click="onMarketSearch">
        <span><i class="iconfont icon-search_ic"></i> 市场查货</span>
      </znl-button>

      <el-checkbox v-model="isNegative"
                   size="small">仅查负库存
      </el-checkbox>
      <el-checkbox v-model="isZero"
                   size="small">包含零库存
      </el-checkbox>
      <el-checkbox v-model="isSureMerge"
                   size="small">可合并库存
      </el-checkbox>
      <el-checkbox v-model="isAlreadyExt"
                   size="small">是否已推广
      </el-checkbox>
      <el-button style="background-color: crimson; margin-left:10px;" size="mini" v-if="this.StockWarning>0"
                 type="primary"
                 :isShow='true'
                 @click="()=>{
                  this.stkWarning('warning')
                  }">
        有{{StockWarning}}个型号已报警
      </el-button>
    </div>
    <!--库存预警列表-->
    <stk-stock-warning ref="StkStockWarning"
                       :isRefresh="refreshStkWarning"
                       :visible='showstkWarning'
                       :openFrom="warningFrom"
                       v-if="showstkWarning"
                       @close="()=>{showstkWarning=false}"
                       @confirm="(val)=>{val&&(showstkWarning=false)}"></stk-stock-warning>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkStockWarning from '@c_modules/Stk/StkStockWarning'

const CONFIG = {
  PK: 'StkGUID'
}

export default {
  name: 'StkStock',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
    StkStockWarning
  },
  data () {
    return {
      gridShow: false,
      Model: '',
      isNegative: false, // 负库存
      isZero: false, // 零库存
      isSureMerge: false, // 可合并库存
      isAlreadyExt: false, // 是否已推广
      StockWarning: 0, // 已报警型号数
      showstkWarning: false, // 是否显示库存预警
      refreshStkWarning: true, // 是否刷新库存预警
      warningFrom: 'set',
      options: [{
        value: '模糊',
        label: '模糊',
        key: 'Like'
      }, {
        value: '左边精准',
        label: '左边精准',
        key: 'LeftMatch'
      }, {
        value: '精确',
        label: '精确',
        key: 'Equal'
      }],
      matchOperation: 'Like' // 型号匹配方式 默认设为模糊
    }
  },
  props: {
    stockCheckedRows: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    matchOperation (value) {
      this.onSearch()
    },
    IscontinueSave (val) {
      // this.$refs.StkStockReserveAdd = val
    },
    isNegative (value) {
      this.onSearch()
    },
    isZero (value) {
      this.onSearch()
    },
    isSureMerge (value) {
      this.onSearch()
    },
    isAlreadyExt (value) {
      this.onSearch()
    }
  },
  methods: {
    onInit: function (config) {
      // this.$emit('page-loading', false)
      this.gridShow = true
      this.$emit('grid-show', true)
    },
    onSearch () {
      let value = {
        'isNegative': this.isNegative,
        'isZero': this.isZero,
        'isSureMerge': this.isSureMerge,
        'isAlreadyExt': this.isAlreadyExt,
        'matchOperation': this.matchOperation,
        'Model': this.Model
      }
      // console.log('on search', value)
      this.$emit('on-search', value)
    },
    async onMarketSearch () {
      let rows = this.stockCheckedRows
      if (_.isUndefined(rows) || rows.length === 0 || _.isUndefined(rows[0])) {
        return this.$message({ message: '请勾选一个或者多个型号，再点击市场查货操作', type: 'warning' })
      }
      let request = []
      _.each(rows, item => {
        request.push({
          SourceGuid: item.BDLineGUID, // 需求明细GUID，
          Model: item.Model, // 型号，
          SourceType: 1, // 型号，
          SourceId: item.BDLineID, // 需求明细ID，
          Brand: item.Brand, // 品牌，
          // Pack: item.Packaging, // 封装，
          // MakeYear: item.MakeYear, // 年份，
          Qty: item.Qty, // 数量，
          // Remark: item.Remark // 需求说明
        })
      })

      this.$nextTick(function () {
        this.openMarketSearch({ Requests: request, ClearRequests: false })
      })
    },
    stkWarning (from) { // 库存预警
      this.warningFrom = from
      this.showstkWarning = true
      this.refreshStkWarning = !this.refreshStkWarning
      // this.clearSelectedRows()
    }
  },
  created () {
  },
  mounted () {
    // console.log(this.downloadFile)
  },
  destroyed () {
  }
}
</script>

<style lang="scss">
.stkStock-action-form {
  display: flex;
  position: relative;
  float: left;
  line-height: 25px;
  .ml5 {
    margin-left: 5px;
  }
  .znl-line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 120px;
    color: #ccc;
  }
}

.action-form2 {
  padding-top: 4px;
}

.action-form2 > .el-checkbox {
  margin-left: 5px;
}

.stsPurchaseIn-amount {
  color: rgb(255, 0, 0);
}
</style>
