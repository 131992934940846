<template>
  <znl-layout-topbottom top-height="62%" v-if="stkInOrderInitData">
    <stk-in-order
      slot="znlTop"
      class="znlTop"
      :init-data="stkInOrderInitData"
      @page-loading="
        d => {
          pageLoading = d;
        }
      "
      @selection-changed="stkInSelectionChange"
      ref="StkIn"
    ></stk-in-order>
    <stk-in-order-line ref="stkInLine" slot="znlBottom" class="znlBottom"></stk-in-order-line>
  </znl-layout-topbottom>
</template>

<script>

import StkInOrder from '@c_modules/Stk/StkInOrder'
import StkInOrderLine from '@c_modules/Stk/StkInOrderLine'

export default {
  name: 'VIn',
  components: {
    StkInOrder,
    StkInOrderLine
  },
  watch: {
    pageLoading (val) {
      if (!val) {
        this.$el.classList.add('finishLoading')
      } else {
        this.$el.classList.remove('finishLoading')
      }
    }
  },
  data () {
    return {
      gridShow: false,

      pickerOptions0: {
      },
      StkIn: {
        searchModel: {
          data: {},
          extra: {}
        }
      },
      StkInLine: {
        searchModel: {}
      },
      pageData: [],
      pageLoading: true,
      hasPageLoading: true,
      stkInOrderInitData: true
    }
  },
  methods: {
    stkInSelectionChange (item) {
      // console.log('进入点击事件。')
      this.$refs.stkInLine.onHeadSearch(item.StkInGUID)
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    },
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VIn'})
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      if (!this.hasRight('StkIn')) {
        this.hasPageLoading = false
      }
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
