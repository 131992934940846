<template>
  <znl-dialog title="出库计划统计"
    subhead=""
    :visible="visible"
    height="440zpx"
    width="720px"
    :footer-height="0"
    @close="()=>{$emit('close')}">
    <znl-gridmodule ref="flexGrid"
      gridtype="action"
      height="100%"
      :on-init="onInit"
      :columns="columns"
      :show-save-btn="false"
      :item-source="itemSource"
      :on-refresh="onRefresh"
      :on-page-changed="onPageChanged"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :is-fields-search="isShowFiedsSearch"
      :is-multi-rows-check="false"
      :role="role"
      :loading="loading"
      :search-fields="searchModel"
      :on-search="onSearch">
      <div slot="heading-slot">
        <el-checkbox @change="onCheckChanged"
          v-model="isOnlyUnqualified">仅显示未达标的型号</el-checkbox>

        <znl-button type="multiple"
          :height="22"
          style-type="mac"
          class="box-left-btn search-btn">
          <znl-button-menu @click="$refs.flexGrid.search()">
            <!-- <i class="iconfont icon-search_ic"></i> -->
            <i class="iconfont icon-left_nav_show_btn"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="12"
            tip="高级搜索"
            @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>

        <znl-button style-type="mac"
          :height="22"
          @click="onAddRow">
          <i class="iconfont icon-add_btn_ic"></i>
          <span>添加</span>
        </znl-button>

        <znl-button style-type="mac"
          :height="22"
          @click="onSave"
          tip="保存">
          <i class="iconfont icon-save_btn_ic"></i>
          <span>保存</span>
        </znl-button>

        <!-- <znl-button style-type="mac"
          :height="22"
          @click="onDelete"
          tip="删除">
          <i class="iconfont icon-save_btn_ic"></i>
          <span>删除</span>
        </znl-button> -->

        <znl-button style-type="mac"
          :height="22"
          @click="onCountByStkOut"
          tip="统计出货数量">
          <i class="iconfont icon-left_nav_show_btn"></i>
          <span>统计出货数量</span>
        </znl-button>

        <znl-button style-type="mac"
          :height="22"
          @click="onImport">
          <i class="iconfont icon-leading-in-wh_btn_ic"></i>
          <span>导入</span>
        </znl-button>

        <znl-button style-type="mac"
          :height="22"
          @click="onExport">
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
      </div>
    </znl-gridmodule>
    <model-out-planning-import :visible="planImportVisible"
      @close="()=>{planImportVisible=false}"></model-out-planning-import>
  </znl-dialog>
</template>

<script>
import * as urls from '~/lib/urls'
import ModelOutPlanningImport from '@c_modules/Sts/ModelOutPlanningImport'
const _ = require('underscore')
const CONFIG = {
  configURL: 'StsShipment/GetConfig',
  saveConfigURL: 'StsShipment/SaveConfig',
  resetConfigURL: 'StsShipment/ResetConfig',
  searchURL: 'StsShipment/Search',
  saveURL: 'StsShipment/Save',
  delURL: 'StsShipment/Del',
  statisticsOutURL: 'StsShipment/StatisticsOut',
  apiBaseUrl: urls.getErpApiBaseUrl()
}
export default {
  name: 'StsShipment',
  config: CONFIG,
  components: { ModelOutPlanningImport },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    visible: Boolean
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      loading: false,
      role: '1',
      searchModel: {},
      isOnlyUnqualified: false,
      isShowFiedsSearch: false,
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      searchFields: {},
      planningCycleOption: [
        { key: '1w', value: '一周' },
        { key: '2w', value: '两周' },
        { key: '1m', value: '一个月' },
        { key: '2m', value: '两个月' },
        { key: '3m', value: '三个月' },
        { key: '6m', value: '半年' },
        { key: '1y', value: '一年' }
      ],
      isFinishOptions: [
        { key: 'true', value: '是' },
        { key: 'false', value: '否' }
      ],
      planImportVisible: false
    }
  },
  methods: {
    async onInit () {
      this.columns = [
        {
          binding: 'ASGUID',
          name: 'ASGUID',
          header: 'ASGUID',
          visible: false,
          dataType: 1,
          width: 100,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: false,
          IsExprot: false
        },
        {
          binding: 'Model',
          name: 'Model',
          header: '型号',
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: false,
          isFieldSearch: true,
          isSystem: false,
          IsExprot: true,
          IsUpperCase: true
        },
        {
          binding: 'PlanningCycle',
          name: 'PlanningCycle',
          header: '计划周期',
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: false,
          isFieldSearch: true,
          isSystem: false,
          IsExprot: true,
          dataMap: this.planningCycleOption,
          showDropDown: true
        },
        {
          binding: 'PlannedShipment',
          name: 'PlannedShipment',
          header: '计划出货量',
          visible: true,
          dataType: 2,
          width: 100,
          isReadOnly: false,
          isFieldSearch: true,
          isSystem: false,
          IsExprot: true
        },
        {
          binding: 'ActualShipment',
          name: 'ActualShipment',
          header: '实际出货量',
          visible: true,
          dataType: 2,
          width: 100,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: false,
          IsExprot: true
        },
        {
          binding: 'Completeness',
          name: 'Completeness',
          header: '完成率',
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: false,
          IsExprot: true
        },
        {
          binding: 'IsFinish',
          name: 'IsFinish',
          header: '达标?',
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: true,
          isFieldSearch: false,
          IsExprot: true,
          renderCell (h, obj) {
            let isfinish = obj.row.IsFinish ? '√' : 'X'
            return h('div', { domProps: { innerHTML: isfinish } })
          }
        },
        {
          binding: 'StatisticalTime',
          name: 'StatisticalTime',
          header: '统计时间',
          visible: true,
          dataType: 4,
          width: 100,
          isReadOnly: true,
          isFieldSearch: true,
          isSystem: false,
          IsExprot: true
        }
      ]
      if (this.initData) {
        await this.onBindData()
      }
    },
    onBindData (pageIndex = 1) {
      this.loading = true
      let postData = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
          pageIndex: pageIndex,
          pageSize: this.pageSize
        },
        { isOnlyUnqualified: this.isOnlyUnqualified }
      )
      this.$post(CONFIG.searchURL, postData, (datas, logic) => {
        if (logic.code === 200 && datas) {
          this.pageIndex = pageIndex
          this.totalCount = datas.TotalCount
          this.itemSource = datas.ResultList
          this.loading = false
        }
      })
      this.loading = false
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    onSearch (params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      this.onBindData(this.pageIndex)
    },
    onPageChanged (pageIndex) {
      this.onBindData(pageIndex)
    },
    onCheckChanged () {
      this.onBindData(this.pageIndex)
    },
    onShowFiedsSearch () {
      this.isShowFiedsSearch = !this.isShowFiedsSearch
    },
    onAddRow () {
      this.$refs.flexGrid.addRow()
    },
    onSave () {
      this.$post(CONFIG.saveURL, this.itemSource, (datas, logic) => {
        if (logic.code === 200 && datas) {
          if (datas > 0) {
            this.$message({ message: '已保存更新!', type: 'success' })
            this.onBindData(this.pageIndex)
          } else {
            this.$message({ message: '没有要更新的内容！', type: 'warning' })
          }
        }
      })
    },
    onDelete () {
      var rows = this.$refs.flexGrid.getSelectedRows()
      if (rows && rows.length > 0) {
        this.$post(CONFIG.delURL, rows, (datas, logic) => {
          if (logic.code === 200) {
            this.$message({ message: '已删除' + datas + '条统计内容！', type: 'success' })
          } else {
            console.log('删除异常！', logic)
            this.$message({ message: '删除异常！', type: 'warning' })
          }
        })
      }
    },
    onCountByStkOut () {
      let postData = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        },
        { isOnlyUnqualified: this.isOnlyUnqualified }
      )
      this.$post(CONFIG.statisticsOutURL, postData, (datas, logic) => {
        if (logic.code === 200 && datas) {
          this.$message({ message: '已更新统计数据!', type: 'success' })
          this.onBindData(this.pageIndex)
        } else {
          this.$message({ message: '统计数据异常!', type: 'warning' })
        }
      })
    },
    onImport () {
      this.planImportVisible = !this.planImportVisible
    },
    onExport () {
      this.loading = true
      let exportColumns = []
      let selectedColumns = _.filter(this.columns, function (item, index) {
        return (
          !item.isSystem &&
          (_.isUndefined(item.hasRole) || item.hasRole) &&
          item.IsExprot
        )
      })
      _.each(selectedColumns, item => {
        exportColumns.push({
          Name: item.binding,
          DisplayName: item.header
        })
      })
      let conditions = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        },
        { isOnlyUnqualified: this.isOnlyUnqualified }
      )
      let postData = {
        Name: 'StsShipment',
        Conditions: conditions,
        Columns: exportColumns
      }
      let that = this
      this.pageLoading = true
      this.$post('export/excel', postData, (data, logic) => {
        if (logic.code === 200) {
          this.loading = false
          that.downloadUrl =
            `${CONFIG.apiBaseUrl}export/download?id=` + data.Id
          if (that.isClient()) {
            that.clientDownLoadFile(data.Id, that.downloadUrl)
          } else {
            window.open(that.downloadUrl) // 下载文件
          }
          that.pageLoading = false
        }
      }).finally(() => {
        this.loading = false
        this.pageLoading = false
      })
    },
    isClient () {
      return window.cefQuery !== undefined
    }
  },
  // watch: {
  //   'itemSource': {
  //     handler: function (newVal, oldVal) {
  //       console.log('itemSource', newVal)
  //     }
  //   },
  //   deep: true
  // },
  mounted () {},
  created () {}
}
</script>

<style lang='scss'>
</style>
