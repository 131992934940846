<template>
 <znl-dialog
  :visible="visible"
  width='600px'
  height='500px'
  :footer-height="0"
  class="dialog-purchase-stock-box dialog-grid-footer-box"
  title="采购明细"
  @close="()=>{$emit('close')}">
  <znl-gridmodule
        ref="flexGrid"
        gridtype="base"
        :columns="columns"
        :grid-show="true"
        :has-znl-actions="false"
        :has-znl-btns='false'
        :item-source="itemSource"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :is-multi-rows-check="false"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :users="$store.state.users"
        :on-init="onInit"
        :is-init="true"
        :on-refresh="onRefresh"
        :on-search="onSearch"
        :on-before-add-row="onBeforeAddRow"
        :on-page-changed="onBindData"
        @page-loading="d=>{$emit('page-loading', d)}"
        @selection-changed="d=>{$emit('selection-changed',d)}">
       </znl-gridmodule>
          <el-row slot="footer" style="display: none;">
  </el-row>
</znl-dialog>
</template>
<script>
import {mixin as znlMethodsMinix} from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  configURL: 'StkStockPurchase/GetDetailConfig',
  saveConfigURL: 'StkStockPurchase/SaveDetailConfig',
  resetConfigURL: 'StkStockPurchase/ResetDetailConfig',
  default: null,
  searchURL: 'StkStockPurchase/SearchDetail'
}

export default {
  name: 'StkStockPurchaseDetail',
  config: CONFIG,
  mixins: [znlMethodsMinix],
  components: {
  },
  data () {
    return {
      inputModel: '',
      inputBrand: '',
      inputStkGUID: '',
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: []
    }
  },
  computed: {
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    },
    height: String,
    isRefresh: Boolean, // 刷新页面
    stkInfo: String, // 库存信息
    visible: Boolean
  },
  watch: {
    isRefresh: {
      handler (value) {
        if (!_.isEmpty(this.stkInfo)) {
          this.LoadByStk(this.stkInfo)
        }
      },
      immediate: true
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        // config = await this.$post(CONFIG.configURL)
        config = await this.isSaveStorage('stkStockPurchaseDetail', CONFIG.configURL)
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
     // this.initData && this.onBindData()
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
        Model: this.inputModel,
        Brand: this.inputBrand,
        StkGUID: this.inputStkGUID
      })
      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onRefresh () {
      return this.onBindData(this.pageIndex)
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stkStockPurchaseDetail')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.config.default))
    },
    onResetSetting () {
      localStorage.removeItem('stkStockPurchaseDetail')
      return this.$post(CONFIG.resetConfigURL)
    },
      // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stkStockPurchaseDetail')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, this.config.default))
    },
    LoadByStk (stkInfo) {
      this.$nextTick(function () {
        this.inputModel = stkInfo.split(',')[0].toString()
        this.inputBrand = stkInfo.split(',')[1].toString()
        this.inputStkGUID = stkInfo.split(',')[2].toString()
        this.onRefresh()
      })
    }
  }
}
</script>

<style lang="scss">
.dialog-purchase-stock-box{
   .znl-dialog.el-dialog{
     transform: none !important;
     margin-top: -250px!important;
     margin-left: -400px!important;
   }
}
</style>
