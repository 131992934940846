<template>
  <!-- 订阅库存列表 -->
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('PubSStockFriend')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          placeholder="型号"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.Model"
          type="text"
        ></znl-input>
        <znl-input
          form-type="input"
          placeholder="订阅供应商"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="searchFields.CompanyName"
          :border="true"
          type="text"
        ></znl-input>
        <znl-button class="minor-self-btn" style-type="mac" :width="50" @click="onSearch()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button>
        <znl-button style-type="main" @click="onSubSetting()" v-if="hasRes('OnSubscriptionSet')">
          <i class="iconfont icon-admin_user_ic"></i>
          <span>申请订阅库存</span>
        </znl-button>
      </div>
    </znl-table>

    <stk-out-edit
      :editvisible="stkOutEditVisible"
      v-if="stkOutEditVisible"
      edit-type="edit"
      @confirm="stkOutEditVisible=false"
      @close="stkOutEditVisible=false"
    ></stk-out-edit>

    <!-- 新增客户需求 -->
    <znl-bom-master-edit
      :editvisible="bomMasterDetailEditVisibleTwo"
      v-if="bomMasterDetailEditVisibleTwo"
      :adddata="bomMasterDetail"
      edit-type="addNew"
      @confirm="bomMasterDetailEditVisibleTwo=false"
      @close="bomMasterDetailEditVisibleTwo=false"
    ></znl-bom-master-edit>
  </div>
</template>
<script>

import StkOutEdit from '@c_modules/Stk/StkOutEdit'
import ZnlBomMasterEdit from '@c_modules/BOM/BomMasterEdit'
import { erpTableSetHandler } from '@scripts/methods/common'

const CONFIG = {
  configURL: 'PubSStockFriend/GetConfig',
  searchURL: 'PubSStockFriend/GetFriendStock'
}
export default {
  name: 'StockFriendDetails',
  mixins: [erpTableSetHandler],
  config: CONFIG,
  components: {
    StkOutEdit,
    ZnlBomMasterEdit
  },
  data() {
    return {
      resourceList: [],
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false,
      stkOutEditVisible: false,
      isShowFiedsSearch: false,
      searchFields: {
        CompanyName: '',
        Model: ''
      },
      bomMasterDetailEditVisibleTwo: false,
      bomMasterDetail: [],
      bomaiVisible: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
    }
  },
  props: {
    initData: {
      tayp: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => { return { data: {}, extra: {} } }
    }
  },
  async created() {
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function (config) {
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('stockFriend', CONFIG.configURL)
      }
      if (this.hasValue(config.ResourceList) && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code)
      }
      let tempConfig = [{
        width: 30,
        type: 'button',
        title: '报',
        btnTxt: '报',
        visible: true,
        isDeal: false,
        click: row => {
          this.onQuotedPrice([row])
        }
      }, {
        width: 30,
        type: 'button',
        title: '出',
        btnTxt: '出',
        visible: true,
        isDeal: false,
        click: row => {
          this.onStkOutadd([row])
        }
      }]
      let columns = config.ColumnConfigs
      columns = tempConfig.concat(columns)

      this.columns = columns
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      this.$refs.table.init()
      if (this.initData) {
        await this.onBindData()
      }
      this.loading = false
      this.$emit('page-loading', false)
    },

    hasRes(code) {
      return _.some(this.resourceList, rcode => rcode === code)
    },
    async onBindData(pageIndex) {
      //this.$refs.table.cleanSort()
      this.loading = true
      let data = _.extend({},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        }
      )
      let _this = this
      return await this.$post(CONFIG.searchURL, data, (data, logic) => {
        if (logic.code !== 200 && this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: 'error' })
        } else if (_.isUndefined(data.ResultList)) {
          this.itemSource = []
          this.pageIndex = 1
          this.totalCount = 0
        } else {
          this.itemSource = data.ResultList
          this.pageIndex = pageIndex
          this.totalCount = data.TotalCount
        }
        _this.loading = false
        this.$emit('page-loading', false)
      })
    },
    async onHeadSearch(params) {
      if (this.hasValue(params) && this.hasValue(params.Model)) {
        this.searchFields.Model = params.Model
      }
      await this.onBindData(1)
    },
    async onSearch(params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      await this.onBindData(1)
    },

    async onRefresh() {
      await this.onBindData(this.pageIndex)
    },
    async onResetSetting() {
      localStorage.removeItem('stockFriend')
      await this.$post(this.$options.config.resetConfigURL)
    },
    async onSaveSetting(columns, pageSize) {
      localStorage.removeItem('stockFriend')
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      )
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem('stockFriend')
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      )
    },
    onStkOutadd(rows) {
      // 销售出库传值方法
      this.stockLine = rows
      let order = { stockLine: rows, checkQty: true, IsOutQty: this.IsOutQty }
      this.$store.state.visibled = false
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkOutEditVisible = true
      })
    },
    onQuotedPrice(rows) {
      if (rows !== null && rows.length > 0) {
        this.bomMasterDetailEditVisibleTwo = true
        this.$nextTick(() => {
          this.bomMasterDetail = rows
        })
      } else {
        this.$emit('QuotePriceDetial', rows)
      }
    },
    // 订阅设置
    async onSubSetting() {
      let isLogBomAI = await this.LogBomAi()
      console.log('isLogBomAI', isLogBomAI)
      if (isLogBomAI) {
        this.openSubSetting()
      } else {
        // 打开扫码界面
        this.bomaiVisible = true
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("PubSStockFriend", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("PubSStockFriend", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("PubSStockFriend", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("PubSStockFriend")
    }
  },
}
</script>
<style  lang="scss">
</style>

