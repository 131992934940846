<template>
 <znl-dialog
  :visible="visible"
  width='920px'
  height='500px'
  :footer-height="0"
  class="dialog-reserve-stock-box dialog-grid-footer-box"
  title="备货库存明细"
  @close="()=>{$emit('close')}">
 <znl-gridmodule
        ref="flexGrid"
        gridtype="base"
        :columns="columns"
        :grid-show="true"
        :has-znl-actions="false"
        :has-znl-btns='false'
        :item-source="itemSource"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :is-multi-rows-check="false"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :users="$store.state.users"
        :on-init="onInit"
        :is-init="true"
        :on-refresh="onRefresh"
        :on-search="onSearch"
        :on-before-add-row="onBeforeAddRow"
        :on-page-changed="onBindData"
        @page-loading="d=>{$emit('page-loading', d)}"
        @selection-changed="d=>{$emit('selection-changed',d)}">
    </znl-gridmodule>
      <el-row slot="footer" style="display: none;">
  </el-row>
</znl-dialog>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
const CONFIG = {
  searchURL: 'Reserve/Search'
}

export default {
  name: 'StkReserveStockDetial',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {},
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      filterFields: {},
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    StkGUID: String,
    height: String,
    onlyCountUnReceive: Boolean,
    stkCustomerName: String, // 库存对象
    startTime: String, // 开始时间
    endTime: String, // 结束时间
    showstsDetail: Boolean, // 是否需要刷新页面
    visible: Boolean
  },
  watch: {
  },
  methods: {
    onInit: async function () {
      let gridConfig = [
        {
          CTitle: 'StockGUID',
          BindField: 'StockGUID',
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true },
        {
          CTitle: 'SOLineGUID',
          BindField: 'SOLineGUID',
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 0,
          DataType: 1,
          IsSystem: true,
          IsRequired: true },
        { CTitle: '销售订单号',
          BindField: 'BillNo',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: '下单时间',
          BindField: 'CreatedTime',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 72,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: '客户名称',
          BindField: 'CustomerName',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 180,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: '型号',
          BindField: 'Model',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 100,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: '品牌',
          BindField: 'Brand',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: '封装',
          BindField: 'Packaging',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 3,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: '年份',
          BindField: 'MakeYear',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 4,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },

        { CTitle: '仓库',
          BindField: 'InvPosition',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: '库位',
          BindField: 'StorageName',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 60,
          DisplayIndex: 7,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: '销售参考价',
          BindField: 'Price',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: 72,
          DisplayIndex: 8,
          DataType: 2,
          IsSystem: false,
          IsRequired: false },
        // {CTitle: '可用量',
        //   BindField: 'UsableQty',
        //   IsShow: true,
        //   IsReadonly: true,
        //   ColumnWidth: 100,
        //   DisplayIndex: 9,
        //   DataType: 2,
        //   IsSystem: false,
        //   IsRequired: false},
        { CTitle: '备货量',
          BindField: 'Qty',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 70,
          DisplayIndex: 10,
          DataType: 2,
          IsSystem: false,
          IsRequired: false }
      ]

      this.columns = this.flexGridColumnsHandler(gridConfig)
      _.each(this.columns, item => {
        // 处理特殊权限 不看客户资料。
        if (
          this.getSpecialResourceByCode('NoSeeCustomer') &&
          item.binding === 'CustomerName' > -1
        ) {
          item.hasRole = false
        }
      })
      _.delay(() => {
        this.onBindData()
      }, 150)
    },
    onBindData (pageIndex = 1) {
      let data = _.extend(
        {},
        {
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          StockGUID: this.StkGUID
        }
      )
      return this.$post(CONFIG.searchURL, data, datas => {
        this.pageIndex = pageIndex
        this.itemSource = datas.ResultList
        this.totalCount = datas.TotalCount
      })
    }
  }
}
</script>

<style lang="scss">
.dialog-reserve-stock-box {
  .znl-dialog.el-dialog {
    transform: none !important;
    margin-top: -250px !important;
    margin-left: -400px !important;
  }
}
</style>
