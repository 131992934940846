<template>
<!-- 供应商库存列表 -->
  <znl-gridmodule
          :gridtype="gridtype"
              ref="flexGrid"
              layoutType="other"
             :height="height"
              loading-type="animation"
              :header="header"
              :is-activity="isActivity"
              :grid-show="gridShow"
              :columns="columns"
              :item-source="itemSource"
              :column-colors="columnColors"
              :row-colors="rowColors"
              :summary-columns="summaryColumns"
              :is-fields-search="isShowFiedsSearch"
              :is-multi-rows-check="true"
              :btn-mouse-enter-menus="btnMouseEnterMenus"
              :show-save-row-right-menu="showSaveRowRightMenu"
              :show-add-row-right-menu="showAddRowRightMenu"
              :show-delete-row-right-menu="showDeleteRowRightMenu"
              :search-fields="searchModel"
              :page-size="pageSize"
              :page-index="pageIndex"
              :total-count="totalCount"
              :role="role"
              :has-znl-actions="false"
              :on-init="onInit"
              :is-init="true"
              :loading="true"
              :is-multi-rows-check-fixed="false"
              :on-refresh="onRefresh"
              :on-search="onSearch"
              :on-save-row="onSaveRows"
              :on-save-rows="onSaveRows"
              :on-delete-row="onDeleteRow"
              :on-save-setting="onSaveSetting"
              :on-save-config='onSaveConfig'
              :on-reset-setting="onResetSetting"
              :on-page-changed="onBindData"
              :pagination-position="pagePosition"
              :users="$store.state.users"
              @page-changed="onBindData"
              @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
              @selection-changed="d=>{this.stkstockSelectItem=d,$emit('selection-changed',d)}"
              checkboxBindingKey="StkGUID"
               @edit:cell-ended="onCellEdited">
             <div slot="heading-slot">
              <znl-input
                form-type="input"
                placeholder="型号"
                :clearable="true"
                @keyup.enter.native="$emit('update:searchModel', searchFields)"
                :is-upper-case="true"
                width='120px'
                size="mini"
                layout="left"
                inp-perc="100%"
                v-model="searchFields.Model"
                :border="true"
                type="text">
              </znl-input>
              <znl-input
                form-type="input"
                placeholder="供应商名称"
                :clearable="true"
                @keyup.enter.native="onSearch()"
                width='120px'
                size="mini"
                layout="left"
                inp-perc="100%"
                v-model="SupplierName"
                :border="true"
                type="text">
              </znl-input>
            <znl-button style-type="mac" type="multiple" :height='22'>
              <znl-button-menu  @click="$emit('update:searchModel', searchFields)"><span><i class="iconfont icon-search_ic"></i>搜索</span></znl-button-menu>
              <znl-button-menu :width='7' tip="高级搜索" @click="onShowFiedsSearch()"><i class="iconfont icon-arrow-drop-down_ic"></i></znl-button-menu>
            </znl-button>
            <znl-button style-type="mac" :height='22' @click="QuotedPrice()" v-if="hasRes('Quote')">
              <span> 给客户报价</span>
            </znl-button>
            <znl-float-list :lists="moreBtns" v-show="isShowMoreBtn(moreBtns)" style="width:70px;">
              <znl-button :height='22' style-type="mac">
                <span><i class="iconfont icon-left_nav_show_btn"></i>更多</span>
              </znl-button>
            </znl-float-list>
            </div>
              <div>
                  <!--备货编辑页面-->
                  <StkStockReserveAdd
                  v-if="showstkReserveAdd"
                  :stkVMIInfo="stkVMIReserveModel"
                  :isRefreshVMI="refreshStkReserve"
                  :visible="showstkReserveAdd"
                  @close="()=>{showstkReserveAdd=false}"
                  :IsShowLock="false"
                  @confirm="(val)=>{val&&(showstkReserveAdd=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}">
                </StkStockReserveAdd>

                  <!--打标记-->
                  <stk-in-edit
                    :title="StkInAddTitle"
                    :editvisible="stkInEditVisible"
                    v-if="stkInEditVisible"
                    :edit-type="editType"
                    height="500px"
                    @confirm="stkInEditVisible=false"
                    @close="stkInEditVisible=false"
                  >
                  </stk-in-edit>
                  <stk-out-edit
                    :title="StkOutAddTitle"
                    :editvisible="stkOutEditVisible"
                    v-if="stkOutEditVisible"
                    :edit-type="editType"
                    height="500px"
                    @confirm="stkOutEditVisible=false"
                    @close="stkOutEditVisible=false"
                  ></stk-out-edit>
                    <!-- 新增客户需求 -->
                  <bom-master-edit
                    title="新增客户需求111"
                    :editvisible="bomMasterDetailEditVisibleTwo"
                    v-if="bomMasterDetailEditVisibleTwo"
                    :adddata="bomMasterDetail"
                    edit-type="addNew"
                    @confirm="bomMasterDetailEditVisibleTwo=false"
                    @close="bomMasterDetailEditVisibleTwo=false, onSearch()">
                  </bom-master-edit>
              </div>
    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </znl-gridmodule>
</template>

<script>
import StkStockReserveAdd from '@c_modules/Stk/StkStockReserveAdd'
import StkStockMarking from '@c_modules/Stk/StkStockMarking'
import { mixin as znlMethodsMixin, watchMixin as znlMethodswatch } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkInEdit from '@c_modules/Stk/StkInEdit'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'
import BomMasterEdit from '@c_modules/BOM/BomMasterEdit'
import { openLinkAll } from '@scripts/methods/common'

const CONFIG = {
  PK: 'VMGUID',
  configURL: 'StockVMI/GetConfig',
  saveConfigURL: 'StockVMI/SaveConfig',
  resetConfigURL: 'StockVMI/ResetConfig',
  searchURL: 'StockVMI/Search',
  addURL: 'StockVMI/Add',
  saveRowsURL: 'StockVMI/BatchSave',
  deleteURL: 'StockVMI/Del',
  DropDownURL: 'DictItem/GetItemValue',
  checkAllowImport: 'StockVMI/checkAllowImport'// 检查供应商库存是否超出最大拥有供应商数量
}

export default {
  name: 'StockVMI',
  mixins: [znlMethodsMixin, znlMethodswatch, getCommonDataMixin, openLinkAll],
  config: CONFIG,
  components: {
    // ZnlModule,
    StkStockReserveAdd,
    StkStockMarking,
    StkInEdit,
    BomMasterEdit,
    StkOutEdit
  },
  data () {
    return {
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      StkInAddTitle: '新增入库单',
      stkInEditVisible: false,
      stkOutEditVisible: false,
      showInAdd: false,
      showOutAdd: false,
      moreBtns: [], // 更多操作
      showstkCustomerRequest: false,
      showstkReserveAdd: false,
      showstkVMIMarking: false,
      refreshStkin: false,
      refreshStkout: false,
      refreshStkCustomerRequest: false,
      isShowFiedsSearch: false,
      refreshStkReserve: false,
      refreshStkVMIMarking: true, // 刷新标记页面
      stkVMIReserveModel: {}, // 需要备货的供应商库存
      VMGUIDS: [], // 多个guid
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      filterFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      currentItem: [],
      role: '1',
      showSaveRowRightMenu: false,
      showAddRowRightMenu: false,
      showDeleteRowRightMenu: false,
      SupplierName: '', // 供应商名称
      // inputModel: '', // 型号
      stkInquiry: {}, // 询价参数传递
      stockVMIMarkParam: {},
      resourceList: [],
      btnMouseEnterMenus: {
        List: []
      },
      Status: true,
      searchFields: {
        Model: ''
      },
      gridShow: false,
      gridtype: 'action', // 表格类型。 action: 可编辑， base: 不可编辑
      bomMasterDetailEditVisibleTwo: false,
      bomMasterDetail: []
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isShow: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'auto'
    },
    isActivity: Boolean,
    pagePosition: String,
    header: {
      type: String,
      default: () => {
        return '供应商库存'
      }
    },
    isFieldsSearch: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    selectOptionsWarehouseOptions (val) {
      _.each(this.columns, column => {
        if (column.binding === 'WarehouseOptions ') {
          this.$set(column, 'dataMap', val)
        }
      })
    },
    selectOptionsQtyUnit (val) {
      _.each(this.columns, column => {
        if (column.binding === 'Unit') {
          this.$set(column, 'dataMap', val)
        }
      })
    }
  },
  methods: {
    hasRes (code) {
      return _.some(this.resourceList, page => page.Code === code)
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.searchFields),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }
        // , {
        //     // 外部搜索条件
        //   InputSupplierName: this.SupplierName,
        //   InputModel: this.searchFields.Model
        // }
      )
      this.stkstockSelectItem = []
      return this.$post(CONFIG.searchURL, data, (datas) => {
        this.pageIndex = pageIndex
        this.itemSource = datas.ResultList
        this.totalCount = datas.TotalCount
        // console.log('条' + this.totalCount)
        // console.log(datas)
        this.$refs.flexGrid.checkedRows = []
        this.$refs.flexGrid.checkedRowsCount = 0
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    async onSearch (params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      return this.onBindData(1)
    },
    onInit: async function () {
      let config = await this.isSaveStorage('stockVMI_v2', CONFIG.configURL)
      // let config = await this.$post(CONFIG.configURL)
      // 获取模块打开或者关闭的状态
      this.GetStatus(config.Status)
      this.resourceList = config.ResourceList
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      _.each(columns, (column, index) => {
        if (column.binding === 'Model' ||
          column.binding === 'SupplierName' ||
          column.binding === 'CustomerName' ||
          column.binding === 'BillNo') {
          _.extend(column,
            {
              className: 'znl-linkAll-column',
              renderCell: this.openLinkAll(column)
            })
        }
      })
      this.moreBtns = [
        {
          name: '销售出货',
          isShow: this.hasRes('StorageOut'),
          click: () => {
            this.stkVMIOutAddRows()
          }
        },
        {
          name: '采购入库',
          isShow: this.hasRes('StorageIn'),
          click: () => {
            this.stkVMIInAddRows()
          }
        },
        {
          name: '导入',
          isShow: this.hasRes('Import'),
          click: () => {
            this.onstkVMIImport()
          }
        },
        {
          name: '导出',
          isShow: this.hasRes('Export'),
          click: () => {
            this.onstkExport()
          }
        },
        {
          name: '删除',
          isShow: this.hasRes('Delete'),
          click: () => {
            this.onMulitDeleteRow()
          }
        }
      ]
      columns.unshift({
        name: 'operate',
        dataType: 5,
        binding: 'operate',
        width: 36,
        header: '操作',
        visible: true,
        isReadOnly: true,
        isFieldsSearch: false,
        isSystem: true,
        align: 'left',
        className: 'znlerp-operate znl-text-center',
        buttons: [
          {
            content: '<i class="iconfont icon-left_nav_show_btn"></i>',
            className: 'znl-table-btn',
            click: () => { }
          }
        ]
      })

      this.btnMouseEnterMenus = {
        List: [
          {
            name: '给客户报价',
            isShow: this.hasRes('Quote'),
            click: row => {
              this.onQuotedPrice(row)
            }
          },
          {
            name: '销售出货',
            isShow: this.hasRes('StorageOut'),
            click: row => {
              let guid = []
              guid.push(row.VMGUID)
              this.onStkOutadd(guid)
            }
          },
          {
            name: '采购入库',
            isShow: this.hasRes('StorageIn'),
            click: row => {
              let rows = []
              rows.push(row)
              this.onStkInadd(rows)
            }
          },
          // {
          //   name: '销售备货',
          //   isShow: this.hasRes('BtnStkReserve'),
          //   click: row => {
          //     this.onAddStkReserve(row)
          //   }
          // },
          {
            name: '询价',
            isShow: this.hasRes('Inquiry'),
            click: row => {
              console.log(row)
              this.onStkVMIInquiry(row)
            }
          },

          {
            name: '删除库存',
            isShow: this.hasRes('Delete'),
            click: row => {
              let guid = []
              guid.push(row.VMGUID)
              this.onDeleteRow(guid)
            }
          }
        ],
        Binding: 'operate'
      }
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      // 上传人
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'UploadByName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Buyer'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 仓库
      let WarehouseOptions = this.selectOptionsWarehouseOptions
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'InvPosition'
        }),
        { dataMap: WarehouseOptions, showDropDown: true }
      )
      // 单位
      let QtyUnitDropDown = this.selectOptionsQtyUnit
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )
      this.showSaveRowRightMenu = this.hasRes('Save')
      this.defaultConfig = config
      // 单价权限控制UnitPrice
      let Jurisdiction = !this.getSpecialResourceByCode('ViewBuyPrice')
      _.each(columns, (item, index) => {
        if (Jurisdiction && item.binding === 'UnitPrice') {
          item.hasRole = false
        }
      })

      let onlyEditModelQty = false
      if (!this.hasRes('Save')) {
        if (this.hasRes('EditModelQty')) { // 修改型号&数量
          onlyEditModelQty = true
        } else {
          this.gridtype = 'base'
        }
      }

      // 权限控制 SalesPrice销售价和FollowPrice 建议销售价
      let JurisdictionViewSalesPrice = !this.getSpecialResourceByCode('ViewSalesPrice')
      _.each(columns, (item, index) => {
        if (JurisdictionViewSalesPrice && item.binding === 'SalesPrice' || JurisdictionViewSalesPrice && item.binding === 'FollowPrice') {
          item.hasRole = false
        }
        // 看备注1 没有这个权限隐藏列
        if (item.binding === 'Remark') {
          item.hasRole = this.hasRes('ViewRemark1')
        }
        // 看备注2 没有这个权限隐藏列
        if (item.binding === 'Remark1') {
          item.hasRole = this.hasRes('ViewRemark2')
        }
        // 看备注3  没有这个权限隐藏列
        if (item.binding === 'Remark2') {
          item.hasRole = this.hasRes('ViewRemark3')
        }
        // 只能编辑型号&数量
        if (onlyEditModelQty && item.binding !== 'Model' && item.binding !== 'InvQty') {
          item.isReadOnly = true
        }
      })

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode('NoSeeSupplier')
      _.each(columns, (item, index) => {
        if (noSeeSupplier && item.binding === 'SupplierName') {
          item.hasRole = false
        }
      })

      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.initData && this.onSearch()
    },
    QuotedPrice () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onQuotedPrice(rows)
        this.clearSelectedRows()
      } else {
        this.$message({ message: '请选择一条数据进行操作', type: 'error' })
      }
    },
    onQuotedPrice (rows) {
      if (rows !== null && rows.length > 1) {
        this.bomMasterDetailEditVisibleTwo = true
        this.$nextTick(() => {
          this.bomMasterDetail = rows
        })
      } else {
        this.$emit('QuotePriceDetial', rows)
      }
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stockVMI_v2')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    onResetSetting () {
      localStorage.removeItem('stockVMI_v2')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stockVMI_v2')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    onSaveRows (rows) {
      if (rows.length > 0) {
        return this.$post(CONFIG.saveRowsURL, rows, (data) => {
          if (data >= 0) {
            this.$message({ message: '保存成功', type: 'success' })
            // this.onBindData(this.pageIndex)
          } else {
            this.$message({ message: '保存失败', type: 'error' })
          }
        })
        // return rows
      }
    },
    onDeleteRow (guid) {
      if (guid.length < 1) {
        return this.$message({ message: '请选择一条数据进行删除', type: 'error' })
      }
      this.$confirm('删除确认, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$post(CONFIG.deleteURL, { VMGUIDS: guid }, (datas) => {
          if (datas > 0) {
            this.$message({ message: '删除成功', type: 'success' })
            this.$refs.flexGrid.clearSelection()
            this.onSearch()
            this.currentItem = []
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        })
      })
    },
    isShowMoreBtn (btns) {
      let isShow = false
      _.each(btns, btn => {
        if (btn.isShow) {
          isShow = true
          return true
        }
      })
      return isShow
    },
    onSelectRow () { // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()
      console.log(this.currentItem, 'this.currentItem')
      if (_.isUndefined(rows[0])) {
        if (this.currentItem.length === 0) { // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          guid.push(this.currentItem.VMGUID)
        }
      } else {
        guid = _.map(rows, item => { return item.VMGUID })
      }
      return guid
    },
    clearSelectedRows () {
      this.$refs.flexGrid.clearSelection()
    },
    onStkInadd (rows) { // 采购入库传值方法
      this.$store.state.visibled = false
      // 从供应商库存点击入库 不需要数量
      _.each(rows, items => {
        this.$set(items, 'InvQty', '')
        this.$set(items, 'Qty', '')
        this.$set(items, 'UsableQty', '')
      })
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true
      })
    },
    onStkOutadd (rows) { // 销售出库传值方法
      let order = { VMGUIDS: rows }
      this.$store.commit('setParamOrder', order)
      this.$store.state.visibled = false
      this.$nextTick(function () {
        this.$refs.flexGrid.clearSelection()
        // this.editType = 'addNew'
        this.stkOutEditVisible = true
      })
    },
    onMulitDeleteRow () {
      let guid = this.onSelectRow()
      if (guid.length > 0) this.onDeleteRow(guid)
    },
    stkVMIOutAddRows () { // 供应商库存批量出库
      let guids = this.onSelectRow()
      if (guids.length > 0) {
        this.onStkOutadd(guids)
        this.cancelAllChecked()
      }
    },
    stkVMIInAddRows () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onStkInadd(rows)
        this.clearSelectedRows()
      }
    },
    onOfferPrice (guids) { // 报价
      this.VMGUIDS = guids
      if (guids[0] + '' === 'undefined') { // 选中行
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }
      let order = { VMGUIDS: guids }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.$refs.flexGrid.clearSelection()
        this.bomMasterDetailEditVisible = true
      })
    },
    // 获取模块状态
    GetStatus (val) {
      this.Status = val !== 0
      this.Status ? this.$refs.flexGrid.toggleTableShow(true)
        : this.$refs.flexGrid.toggleTableShow(false)
    },
    // 保存模块状态
    saveStatus (val) {
      let Status = val ? 1 : 0
      let defaultConfig = this.defaultConfig
      if (defaultConfig !== undefined && Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    },
    onMulitOfferPrice () { // 批量报价
      let guids = this.getCheckedKeys()
      if (guids.length === 0) {
        guids = this.onSelectRow()
      }
      if (guids[0] + '' === 'undefined') { // 选中行
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }
      if (guids.length > 0) {
        this.onOfferPrice(guids)
      }
    },
    onAddStkReserve (row) { // 备货
      this.stkVMIReserveModel = row
      this.showstkReserveAdd = true
      this.refreshStkReserve = !this.refreshStkReserve
    },
    onStkVMIInquiry (rows) { // 询价
      let selectRow = []
      if (rows.length === 0) {
        if (this.currentItem.length === 0) { // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          selectRow.push(this.currentItem)
        }
      } else {
        selectRow.push(rows)
      }

      let data = selectRow[0]
      this.stkInquiry.SupplierName = data.SupplierName
      this.stkInquiry.Model = data.Model
      this.stkInquiry.Brand = data.Brand
      this.stkInquiry.Packaging = data.Packaging
      this.stkInquiry.MakeYear = data.MakeYear
      this.stkInquiry.Quality = data.Quality
      this.stkInquiry.InvQty = data.InvQty
      this.stkInquiry.Qty = data.InvQty
      this.stkInquiry.MPQ = data.MPQ
      this.stkInquiry.Qty = data.InvQty
      this.stkInquiry.DeliveryDate = data.Leadtime
      // this.stkInquiry.Price = data.BuyPrice
      this.stkInquiry.QuantityPhrase = data.InvQty

      this.addInquire(this.stkInquiry, function (msg) {
        if (msg === '不能向自己询价') {
          this.$message({ message: msg, type: 'warning' })
        }
      })
    },
    searchParam () {
      return _.extend({}, {
        FieldWhereString: JSON.stringify(this.searchFields)
      }, {
        InputSupplierName: this.SupplierName,
        InputModel: this.searchModel.Model
      })
    },
    onstkVMISign () { // 打标记
      let rows = []
      let chkRows = this.$refs.flexGrid.getSelectedRows()
      if (chkRows.length === 0) {
        if (this.currentItem.length === 0) { // 选中行
        } else {
          rows.push(this.currentItem.VMGUID)
        }
      } else {
        rows = _.map(chkRows, item => { return item.VMGUID })
      }
      this.$nextTick(function () {
        this.VMGUIDS = rows                        // 多条数据
        this.stockVMIMarkParam = this.searchParam()// 传递给标记页面的参数
        this.showstkVMIMarking = true
        this.refreshStkVMIMarking = !this.refreshStkVMIMarking
      })
    },
    onstkVMIImport: async function () { // 导入供应商库存
      // 先做验证，库存量已超出最大库存量，不能再导入
      let IsAllowImport = await this.$post(CONFIG.checkAllowImport)
      this.$nextTick(function () {
        if (!IsAllowImport) {
          return this.$message({ message: '您的供应商库存量已超出最大库存量，不能再导入！', type: 'error' })
        }
        this.importData({ importType: 'stkvmi' })
      })
    },
    getCheckedKeys () {
      var keys = this.$refs.flexGrid.getCheckedKeys()

      if (keys.length === 0 || keys[0] + '' === 'undefined') { // 选中行
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }
      var guids = []
      keys.forEach((item) => {
        guids.push(item.VMGUID)
      })
      return guids
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    cancelAllChecked () {
      this.$refs.flexGrid.cancelAllChecked()
    },
    refreshCheckedCount (keys) {
      this.$refs.flexGrid.refreshCheckedCount(keys)
    },
    onstkExport () {
    },
    onCellEdited (aftervalue, beforevalue, colname, gridobj, rowindex, colindex) { // 1.修改后值 2.修改前值 3.列明 4.表格对象 5.行索引 6.列索引
      // 建议销售价
      if (colname === 'FollowPrice') {
        this.itemSource[rowindex]['FollowPrice'] = this.toFloat(aftervalue, 6)
      }
      // 销售价
      if (colname === 'SalesPrice') {
        this.itemSource[rowindex]['SalesPrice'] = this.toFloat(aftervalue, 6)
      }
    }
  },
  mounted () {
    !this.isShow && this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>

<style lang="scss">
</style>

