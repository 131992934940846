<template>
<!-- 订阅库存列表 -->
  <znl-gridmodule ref="flexGrid"
    :header="header"
    height="100%"
    :min-height="24"
    layoutType="other"
    :columns="columns"
    class="PubStockFriend"
    :item-source="itemSource"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :is-multi-rows-check="false"
    :summary-columns="summaryColumns"
    :search-fields="searchModel"
    :is-fields-search="isShowFiedsSearch"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :on-init="onInit"
    :is-init="isInit"
    :loading="loading"
    :users="$store.state.users"
    :is-multi-rows-check-fixed="false"
    :is-show-rightbtns="false"
    :on-search="onSearch"
    :on-refresh="onRefresh"
    :on-save-setting="onSaveSetting"
    :on-save-config='onSaveConfig'
    :on-reset-setting="onResetSetting"
    :on-page-changed="onBindData"
    :is-activity="isActivity"
    :pagination-position="pagePosition"
    @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
    @ondblclick="val=>$emit('ondblclick', val)">
    <div slot="heading-slot">
      <znl-input form-type="input"
        placeholder="型号"
        :clearable="true"
        @keyup.enter.native="onSearch()"
        width='120px'
        size="mini"
        layout="left"
        inp-perc="100%"
        :border="true"
        v-model="searchFields.Model"
        type="text">
      </znl-input>
      <znl-input form-type="input"
        placeholder="订阅供应商"
        :clearable="true"
        @keyup.enter.native="onSearch()"
        width='120px'
        size="mini"
        layout="left"
        inp-perc="100%"
        v-model="searchFields.CompanyName"
        :border="true"
        type="text">
      </znl-input>
      <znl-button style-type="mac" class="box-left-btn search-btn" :height='22' :width='50' @click="onSearch()">
        <i class="iconfont icon-search_ic"></i><span>搜索</span>
      </znl-button>
      <!-- 申请商友库存  -->
      <znl-button style-type="main" :height='22' @click="onSubSetting()" v-if="hasRes('OnSubscriptionSet')">
       <i class="iconfont icon-admin_user_ic"></i><span>申请订阅库存</span>
      </znl-button>
    </div>

    <stk-in-edit :title="StkInAddTitle"
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      :edit-type="editType"
      height="500px"
      @confirm="stkInEditVisible=false"
      @close="stkInEditVisible=false">
    </stk-in-edit>

    <stk-out-edit :title="StkOutAddTitle"
      :editvisible="stkOutEditVisible"
      v-if="stkOutEditVisible"
      :edit-type="editType"
      height="500px"
      @confirm="stkOutEditVisible=false"
      @close="stkOutEditVisible=false">
    </stk-out-edit>

    <bom-ai-login :visible="bomaiVisible"
      @close="()=>{bomaiVisible=false}"
      @confirm="()=>{bomaiVisible=false,openSubSetting()}">
    </bom-ai-login>

    <!-- 新增客户需求 -->
    <znl-bom-master-edit
        title="新增客户需求"
        :editvisible="bomMasterDetailEditVisibleTwo"
        v-if="bomMasterDetailEditVisibleTwo"
        :adddata="bomMasterDetail"
        edit-type="addNew"
        @confirm="bomMasterDetailEditVisibleTwo=false"
        @close="bomMasterDetailEditVisibleTwo=false">
   </znl-bom-master-edit>

  </znl-gridmodule>
</template>
<script>
import {
  mixin as znlMethodsMixin,
  watchMixin as znlMethodswatch
} from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkInEdit from '@c_modules/Stk/StkInEdit'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'
import { getCookie } from '~assets/scripts/utils/dom'
import * as urls from '~/lib/urls'
import { openLinkAll } from '@scripts/methods/common'
import { BOMAICOOLIENAME } from '@scripts/config'
import BomAiLogin from '@c_modules/Account/BomAILogin'
import ZnlBomMasterEdit from '@c_modules/BOM/BomMasterEdit'
const CONFIG = {
  configURL: 'PubSStockFriend/GetConfig',
  saveConfigURL: 'PubSStockFriend/SaveConfig',
  resetConfigURL: 'PubSStockFriend/ResetConfig',
  searchURL: 'PubSStockFriend/GetFriendStock'
  // searchURL: 'PubSStock/Search' // 我的共享库存查询
}
export default {
  name: 'PubStkStockFriend',
  mixins: [znlMethodsMixin, znlMethodswatch, getCommonDataMixin, openLinkAll], // 第二个方法需要添加后才能监控到外部条件改变也可以刷新页面
  config: CONFIG,
  components: {
    StkInEdit,
    StkOutEdit,
    BomAiLogin,
    ZnlBomMasterEdit
  },
  data () {
    return {
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      StkInAddTitle: '新增入库单',
      resourceList: [],
      columns: [],
      itemSource: [],
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      Size: 30,
      Status: true,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      loading: false,
      inputModel: '',
      stkInEditVisible: false,
      stkOutEditVisible: false,
      isShowFiedsSearch: false,
      searchFields: {
        CompanyName: '',
        Model: ''
      },
      bomMasterDetailEditVisibleTwo: false,
      bomMasterDetail: [],
      bomaiVisible: false
    }
  },
  computed: {
    pageSize: {
      get () {
        return this.Size
      },
      set (val) {
        this.Size = val
      }
    }
  },
  props: {
    initData: {
      tayp: Boolean,
      default: true
    },
    isInit: Boolean,
    isActivity: Boolean,
    pagePosition: {
      type: String,
      defaule: 'bottom'
    },
    header: {
      type: String,
      defaule: '订阅库存'
    },
    searchModel:
    {
      type: Object,
      default: () => { return { data: {}, extra: {} } }
    },
    isMini: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: true
    },

    height: {
      type: String,
      default: 'auto'
    }

  },
  watch: {
    selectOptionsWarehouseOptions (val) {
      _.each(this.columns, column => {
        if (column.binding === 'WarehouseOptions ') {
          this.$set(column, 'dataMap', val)
        }
      })
    }
  },
  methods: {

    onInit: async function () {
      this.loading = true
      let config = await this.isSaveStorage('stockFriend', CONFIG.configURL)
      // 获取模块打开或者关闭的状态
      this.GetStatus(config.Status)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 列的初始化
      this.settingColumns(columns)
      // 操作按钮初始化
      this.settingActionBtn(columns)
      this.resourceList = config.ResourceList.map(item => item.Code)
      this.defaultConfig = config

      this.genColStepPrice(_.find(columns, col => col.binding === 'StepPrice')) // 阶梯价

      this.columns = columns

      this.role = config.Role
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      console.log(this.initData, 'this.initData ')

      if (this.initData) {
        await this.onBindData()
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    // 列的初始化
    settingColumns (columns) {
      _.each(columns, (column, index) => {
        if (column.binding === 'Model' ||
          column.binding === 'SupplierName' ||
          column.binding === 'CustomerName' ||
          column.binding === 'BillNo') {
          _.extend(column,
            {
              className: 'znl-linkAll-column',
              renderCell: this.openLinkAll(column)
            })
        }
      })

      // 仓库
      let warehouseOptions = this.selectOptionsWarehouseOptions
      warehouseOptions &&
        _.extend(
          _.find(columns, function (column) {
            return column.binding === 'WareHouse'
          }),
          { dataMap: warehouseOptions, showDropDown: true }
        )
    },
    // 操作按钮初始化
    settingActionBtn (columns) {
      columns.unshift({
        name: 'quote',
        dataType: 5,
        binding: 'quote',
        width: 30,
        header: '报',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '报',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              let rows = []
              rows.push(row)
              this.onQuotedPrice(rows)
            }
          }
        ]
      })

      !this.onlySalesPurchaseOrder && columns.unshift({
        name: 'stkOut',
        dataType: 5,
        binding: 'stkOut',
        width: 30,
        header: '出',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '出',
            className: 'znl-table-btn-success',
            when: true,
            click: (row) => {
              this.onStkOutadd([row])
            }
          }
        ]
      })
    },
    hasRes (code) {
      return _.some(this.resourceList, rcode => rcode === code)
    },
    onBindData (pageIndex) {
      this.loading = true
      let data = _.extend({},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        }
      )
      let _this = this
      return this.$post(CONFIG.searchURL, data, (data, logic) => {
        if (logic.code !== 200 && this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: 'error' })
        } else if (_.isUndefined(data.ResultList)) {
          this.itemSource = []
          this.pageIndex = 1
          this.totalCount = 0
        } else {
          this.itemSource = data.ResultList
          this.pageIndex = pageIndex
          this.totalCount = data.TotalCount
        }
        _this.loading = false
        this.$emit('page-loading', false)
      })
    },
    async onHeadSearch (params) {
      this.searchFields.Model = params.Model
      await this.onBindData(1)
    },
    async onSearch (params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      await this.onBindData(1)
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    async onResetSetting () {
      localStorage.removeItem('stockFriend')
      await this.$post(this.$options.config.resetConfigURL)
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stockFriend')
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      )
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stockFriend')
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      )
    },
    stkInAddRows () {
      // 批量采购入库
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onStkInadd(rows)
        this.clearSelectedRows()
      } else {
        this.$message({ message: '请选择一条数据进行操作', type: 'warning' })
      }
    },
    stkOutAddRows () {
      // 批量销售出库
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onStkOutadd(rows)
        this.clearSelectedRows()
      } else {
        this.$message({ message: '请选择一条数据进行操作', type: 'warning' })
      }
    },
    onStkInadd (rows) {
      // 采购入库传值方法
      this.$store.state.visibled = false
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true
      })
    },
    onStkOutadd (rows) {
      // 销售出库传值方法
      this.stockLine = rows
      let order = { stockLine: rows, checkQty: true, IsOutQty: this.IsOutQty }
      this.$store.state.visibled = false
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkOutEditVisible = true
      })
    },
    QuotedPrice () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onQuotedPrice(rows[0])
        this.clearSelectedRows()
      } else {
        this.$message({ message: '请选择一条数据进行操作', type: 'error' })
      }
    },
    onQuotedPrice (rows) {
      if (rows !== null && rows.length > 0) {
        this.bomMasterDetailEditVisibleTwo = true
        this.$nextTick(() => {
          this.bomMasterDetail = rows
        })
      } else {
        this.$emit('QuotePriceDetial', rows)
      }
    },
    ShoppingTrolley (row) {
      console.log(row)
    },
    clearSelectedRows () {
      this.$refs.flexGrid.clearSelection()
    },

    // 获取模块状态
    GetStatus (val) {
      this.Status = val !== 0
      this.Status ? this.$refs.flexGrid.toggleTableShow(true)
        : this.$refs.flexGrid.toggleTableShow(false)
    },
    // 保存模块状态
    saveStatus (val) {
      let Status = val ? 1 : 0
      let defaultConfig = this.defaultConfig
      if (defaultConfig !== undefined && Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    },
    // 订阅设置
    async onSubSetting () {
      let isLogBomAI = await this.LogBomAi()
      console.log('isLogBomAI', isLogBomAI)
      if (isLogBomAI) {
        this.openSubSetting()
      } else {
        // 打开扫码界面
        this.bomaiVisible = true
      }
    },
    // 打开BOMAI个人中心
    openSubSetting () {
      let bomAiToken = getCookie(BOMAICOOLIENAME, this.$store)
      if (this.hasValue(bomAiToken)) {
        let url = urls.getBomaiAvailableSubscriptionUrl() + '?token=' + bomAiToken
        url += '&hidetop=1&hidebottom=1'
        window.open(url)
      } else {
        this.$message({ message: '登录BOMAI平台失败!请联系系统管理员', type: 'warning' })
      }
    },
    genColStepPrice (column) {
      if (column) {
        column.buttons = [{
          'content': (row) => {
            if (!this.hasValue(row.StepPrice)) {
              return ''
            }
            var list = JSON.parse(row.StepPrice)
            let str = ''
            if (list.length > 0) {
              _.each(list, (item) => {
                if (str !== '') {
                  str += ''
                }
                if (item.IsStandQuote === true) {
                  str += '<i class="znl-icon iconfont icon-transfer-stand_price"></i>'
                }
                str += '<span class="price-color">¥' + this.toFloat(item.Price, 6) + '</span><span class="qty-color" style="color:#888">(' + this.toFloat(item.Qty, 2) + ')</span>&nbsp;&nbsp;&nbsp;'
              })
              return '<div>' + str + '</div>'
            }
            return ''
          },
          'className': 'znl-table-btn-success o-price no-underline',
          'when': true
        }]
        column.dataType = 5
      }
    }
  },
  mounted () {
    !this.isShow && this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>
<style  lang="scss">
.hover-set {
  color: #0000ff;
}
.price-color {
  color: #e20f0f;
}
.qty-color {
  color: blue;
}
.color-ccc {
  color: #ccc;
}
.no-underline {
  text-decoration: none;
}
</style>

