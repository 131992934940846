<template>
  <!--<znl-stkStockpurchase v-if='hasRight("StkStockPurchase")' :searchModel="stkstockpurchase" height="97%">
  </znl-stkStockpurchase>-->
   <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
          <znl-stkStockpurchase
          slot="znlLayoutSpa"
          class="znlLayoutSpa"
          @page-loading="(d)=>{pageLoading=d}"
          ></znl-stkStockpurchase>
       </znl-layout-spa>
</template>

<script>
import ZnlStkStockpurchase from '@c_modules/Stk/StkStockPurchase'

export default {
  name: 'VStkStockPurchase',
  components: {
    ZnlStkStockpurchase
  },
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      stkstockpurchase: { },
      pageData: [],
      pageLoading: true
    }
  },
  methods: {
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    },
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VStkStockPurchase'})
    }
  },
  async created () {

    this.getPageData().then((data) => {
      this.pageData = data
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  },
  mounted () {
    !this.isShow && this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>
