<template>
  <!-- 批次库存 -->
  <div>
    <znl-gridmodule ref="flexGrid"
      :gridtype="gridtype"
      loading-type="animation"
      :summary-columns="summaryColumns"
      :header="header"
      height="100%"
      :min-height="maxHeight"
      :columns="columns"
      class="znl-batches-stock-module"
      :item-source="itemSource"
      :on-init="onInit"
      :on-refresh="onRefresh"
      :on-page-changed="onBindData"
      :pagination-position="pagePosition"
      :btn-mouse-enter-menus="btnMouseEnterMenus"
      :is-multi-rows-check-fixed="false"
      :is-init="isInit"
      :is-mini="true"
      :has-znl-btns="true"
      :role="role"
      :on-delete-row="onDeleteRow"
      :on-save-rows="onSaveRows"
      :is-activity="isActivity"
      :is-fields-search="isShowFiedsSearch"
      :is-multi-rows-check="true"
      :loading="loading"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :search-fields="searchModel"
      :on-save-setting="onSaveSetting"
      :on-save-config='onSaveConfig'
      :on-reset-setting="onResetSetting"
      :on-reset-params="onResetParams"
      @selection-changed="d=>{$emit('selection-changed',d)}"
      @cell-click="onCellClick"
      checkboxBindingKey="StkGUID"
      :on-search="onSearch">
      <div slot="heading-slot"
        class="batchesStockHeaderSlot">
        <znl-input from-type="input"
          :disable="false"
          placeholder="型号"
          size="mini"
          layout="left"
          width="120px"
          :height="26"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          v-model="searchFields.Model"
          @keyup.enter.native="$emit('update:searchModel', searchFields)"
          type="text">
        </znl-input>
        <znl-button style-type="main"
          type="multiple"
          :height='22'>
          <znl-button-menu @click="$emit('update:searchModel', searchFields)"><span> <i class="iconfont icon-search_ic"></i>搜索</span></znl-button-menu>
          <znl-button-menu :width='7'
            tip="高级搜索"
            @click="onShowFiedsSearch()"> <i class="iconfont icon-arrow-drop-down_ic"></i> </znl-button-menu>
        </znl-button>
        <el-checkbox v-model="isZero"
          @change="onIsZeroSettingSave()"
          size="small">包含零库存</el-checkbox>
        <el-checkbox v-model="isNegative"
          size="small">仅查负库存</el-checkbox>
        <znl-button style-type="mac"
          :height='22'
          @click="onMarketSearch()"
          v-if="true">
          <span>
            <i class="iconfont icon-search_ic"></i> 市场查货</span>
        </znl-button>
        <znl-button style-type="mac"
          :height='22'
          @click="QuotedPrice()"
          v-if="hasRes('Quote')">
          <span> 给客户报价</span>
        </znl-button>
        <znl-float-list :lists="moreBtns"
          v-show="isShowMoreBtn(moreBtns)"
          style="width:70px;">
          <znl-button :height='22'
            style-type="mac"><span><i class="iconfont icon-left_nav_show_btn"></i>更多</span>
          </znl-button>
        </znl-float-list>
      </div>

      <div slot="znl-footer-slot"
        class="total-data-footer">
        <el-row>
          <el-col>
            <span>
              <!-- <el-tooltip placement="top"  effect="light">
              <div slot="content">按照搜索条件查询出来的所有结果集汇总<br />库存可用量 = 可用量的总和<br/> 总成本      = (成本均价 x 可用量) 总和</div>
            </el-tooltip> -->
              <a @click="onSumData()"
                style="text-decoration:underline; margin-left:3px;margin-right:5px;"
                title="按搜索条件">统计库存 (按搜索条件) </a>
            </span>
            <span v-show="this.isShowCost && this.getSpecialResourceByCode('ViewBuyPrice')">
              <span>
                库存总成本: <span class="stsPurchaseIn-amount">{{this.totalCost}} </span>&nbsp;&nbsp;
                <span>|&nbsp;&nbsp;</span>
              </span>
            </span>
            <span v-show="this.isShowCost">
              <span>
                总数量: <span class="stsPurchaseIn-amount">{{this.totalQty}} </span>&nbsp;&nbsp;
                <span>&nbsp;&nbsp;</span>
              </span>
            </span>
          </el-col>
        </el-row>
      </div>

      <stk-in-edit :title="StkInAddTitle"
        :editvisible="stkInEditVisible"
        v-if="stkInEditVisible"
        :edit-type="editType"
        @save-succes="$emit('save-stkIn-succes'),$refs.flexGrid.search()"
        @confirm="stkInEditVisible=false"
        @close="stkInEditVisible=false">
      </stk-in-edit>

      <stk-out-edit :title="StkOutAddTitle"
        :editvisible="stkOutEditVisible"
        v-if="stkOutEditVisible"
        :edit-type="editType"
        @save-succes="$emit('save-stkOut-succes'),$refs.flexGrid.search()"
        @close="stkOutEditVisible=false">
      </stk-out-edit>

      <!--日志dialog-->

      <stk-stock-log ref="StkStockLog"
        v-if="showStkstocklog"
        :visible='showStkstocklog'
        :stkGUID="stockGuid"
        @close="()=>{showStkstocklog=false}"
        :showstockLog="refreshStklog">
      </stk-stock-log>

      <!-- 库存调货 -->
      <stk-tranfer-list v-if="showStktransferList"
        :visible='showStktransferList'
        ref="StkTranferList"
        :stkGUIDS="stockGuids"
        :showstkTransfer="refreshStkTransfer"
        @update:visible="(val)=>{showStktransferList=val}"
        @close="()=>{showStktransferList=false}"
        @confirm="(val)=>{val&&(showStktransferList=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></stk-tranfer-list>
      <!--库存合并列表-->
      <stk-stock-merge ref="StkStockMerge"
        :stkGUIDS="stockGuids"
        v-if='showstkMerge'
        :title='headTitle(stockGuids)'
        :visible='showstkMerge'
        :isRefresh="refreshStkMerge"
        :markingCode="markingCode"
        @close="()=>{showstkMerge=false}"
        @confirm="(val)=>{val&&(showstkMerge=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></stk-stock-merge>
      <!--拆分库存-->
      <StkStockSplit v-if="showstkSplit"
        :visible='showstkSplit'
        :is-footer-show="false"
        ref="StkStockSplit"
        :stkGUID="stockGuids"
        height="50%"
        :isRefresh="refreshStkSplit"
        @close="()=>{showstkSplit=false}"
        @confirm="(val)=>{val&&(showstkSplit=false),this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></StkStockSplit>
      <!--库存回收站-->
      <stk-stock-recycle ref="StkStockRecycle"
        :isRefresh="refreshStkRecycle"
        :visible='showstkRecycle'
        v-if="showstkRecycle"
        @close="()=>{showstkRecycle=false}"
        @confirm="(val)=>{showstkRecycle=false,val&&this.onRefresh(),this.$refs.flexGrid.clearSelection()}"></stk-stock-recycle>

      <!-- 新增客户需求 -->
      <znl-bom-master-edit title="新增客户需求"
        :editvisible="bomMasterDetailEditVisibleTwo"
        v-if="bomMasterDetailEditVisibleTwo"
        :adddata="bomMasterDetail"
        edit-type="addNew"
        @confirm="bomMasterDetailEditVisibleTwo=false"
        @close="bomMasterDetailEditVisibleTwo=false, onSearch()">
      </znl-bom-master-edit>

      <!-- 导出库存格式 -->
      <export-format :columns="setColumns"
        :saveConfigURL="setSaveConfigURL"
        :defaultConfigColumns="setDefaultConfigColumns"
        :visible="exportFormatVisible"
        @close="()=>{exportFormatVisible=false}"
        @confirm="(v)=>{exportFormatVisible=false}">
      </export-format>
      <!--上传图片-->
      <image-upload :visible="imageUploadVisible"
        :imageStkGUID='this.imageStkGUID'
        @close="()=>{this.imageUploadVisible = false}"
        @confirm="()=>{this.imageUploadVisible = false,this.onBindData(this.pageIndex)}"></image-upload>

      <!-- 显示图片 -->
      <show-image :visible="imageBoxVisible"
        :image-list="imageList"
        :title="imageTitle"
        :has-upload="true"
        :imageStkGUID='this.imageStkGUID'
        :has-delete="true"
        @close="(imgGuids)=>{onShowImageClose(imgGuids)}"></show-image>
      <!-- 显示备货明细 -->
      <stk-reserve-stock-detial ref="StkStockLog"
        v-if="reserveDetailVisable"
        :StkGUID='stockGuid'
        :visible='reserveDetailVisable'
        @close="()=>{reserveDetailVisable=false}"
        :reserveDetailVisable="refreshReserveDetail">
      </stk-reserve-stock-detial>
    </znl-gridmodule>

    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </div>

</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkStockLog from '@c_modules/Stk/StkStockLog'
import StkInEdit from '@c_modules/Stk/StkInEdit'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'
import StkStockMerge from '@c_modules/Stk/StkStockMerge'
import StkTranferList from '@c_modules/Stk/StkTransferList'
import StkStockRecycle from '@c_modules/Stk/StkStockRecycle'
import StkStockSplit from '@c_modules/Stk/StkStockSplit'
import ZnlBomMasterEdit from '@c_modules/BOM/BomMasterEdit'
import ExportFormat from '@c_modules/StkSum/ExportFormat'
import { openLinkAll } from '@scripts/methods/common'
import ImageUpload from '@c_modules/StkSum/ImageUpload'
import ShowImage from '@c_modules/StkSum/ShowImage'
import StkReserveStockDetial from '@c_modules/Stk/StkReserveStockDetial'

const CONFIG = {
  PK: 'StkGUID',
  configURL: 'StkStock/GetConfig',
  saveConfigURL: 'StkStock/SaveConfig',
  resetConfigURL: 'StkStock/ResetConfig',
  searchURL: 'StkStock/Search',
  DeleteURL: 'StkStock/Delete',
  saveURL: 'StkStock/Save',
  saveRowsURL: 'StkStock/BatchSave',
  checkAllowImport: 'StkStock/checkAllowImport', // 检查是否允许导入库存
  checkStkMergeURL: 'StkStockMerge/CheckData',
  summaryCostURL: 'StkStock/SummaryCost', // 统计库存成本
  SavePageParamURL: 'PageParam/Save', // 保存页面参数配置
  resetParamConfigURL: 'StkStock/ResetParamConfig',
  default: null
}
export default {
  name: 'MBatchesStock',
  mixins: [znlMethodsMixin, getCommonDataMixin, openLinkAll],
  config: CONFIG,
  components: {
    StkStockLog,
    StkInEdit,
    StkOutEdit,
    StkStockMerge,
    StkTranferList,
    StkStockRecycle,
    StkStockSplit,
    ZnlBomMasterEdit,
    ExportFormat,
    ImageUpload,
    ShowImage,
    StkReserveStockDetial
  },
  props: {
    header: {
      type: String,
      default: '批次库存'
    },
    height: {
      type: String,
      default: '100%'
    },
    maxHeight: Number,
    isActivity: Boolean,
    pagePosition: String,
    isFieldsSearch: {
      type: Boolean,
      default: true
    },
    matchOperation: {
      type: String,
      default: 'Like'
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    initData: {
      type: Boolean,
      default: true
    },
    isInit: {},
    ReceiveisZero: Boolean,
    ReceiveisNegative: Boolean
  },
  watch: {
    isNegative (value) {
      this.pageIndex = 1
      this.$refs.flexGrid.clearSelection()
      this.$refs.flexGrid.search()
    },
    isZero (value) {
      this.pageIndex = 1
      this.$refs.flexGrid.clearSelection()
      this.$refs.flexGrid.search()
    },
    selectOptionsQtyUnit (val) {
      _.each(this.columns, column => {
        if (column.binding === 'Unit') {
          this.$set(column, 'dataMap', val)
        }
      })
    },
    selectStorageType (val) {
      _.each(this.columns, column => {
        if (column.binding === 'StorageType ') {
          this.$set(column, 'dataMap', val)
        }
      })
    },
    selectOptionsAllUserURL (val) {
      _.each(this.columns, column => {
        if (column.binding === 'CreatedName') {
          this.$set(column, 'dataMap', val)
        }
      })
    },
    selectOptionsWarehouseOptions (val) {
      _.each(this.columns, column => {
        if (column.binding === 'WarehouseOptions ') {
          this.$set(column, 'dataMap', val)
        }
      })
    },
    itemSource () {
      // 监听itemSource数据源渲染完成后 向外抛出值
      // this.$nextTick(function (e) {
      //   this.rendeRing++
      //   if (this.rendeRing > 1) { this.$emit('page-loading', false) }
      // })
    },
    ReceiveisZero () {
      this.isZero = this.ReceiveisZero
      this.onIsZeroSettingSave()
    },
    ReceiveisNegative () {
      this.isNegative = this.ReceiveisNegative
    }
  },
  data () {
    return {
      Status: true,
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      StkInAddTitle: '新增入库单',
      stkInEditVisible: false,
      stkOutEditVisible: false,
      showStkstocklog: false, // 是否显示日志
      showStktransferList: false, // 是否显示调出库存列表
      showstkMerge: false, // 是否显示库存合并列表
      showstkRecycle: false, // 是否显示库存回收站
      refreshStklog: true, // 是否刷新库存日志页面
      showstkSplit: false, // 是否显示拆分页面
      refreshStkRecycle: true, // 是否刷新回收站
      stockGuid: '', // 传递库存的GUID
      summaryColumns: [],
      containZeroStock: false,
      onlyZeroStock: false,
      role: '1',
      loading: false,
      columns: [],
      itemSource: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      searchFields: {
        Model: ''
      },
      bomMasterDetail: [],
      currentItem: [],
      isShowFiedsSearch: false,
      moreBtns: [], // 更多操作
      btnMouseEnterMenus: {
        // 操作列按钮
        List: []
      },
      stkstockSelectItem: [], // 选中的库存数据
      stkInquiry: {
        // 询价参数传递
        SupplierName: '', // 供应商名称
        PartNo: '', // Model
        Brand: '',
        Packaging: '',
        MakeYear: '',
        Quality: '',
        DeliveryDate: '',
        InvQty: 0,
        Qty: 0,
        MPQ: '',
        Price: 0
      },

      isNegative: false, // 负库存
      isZero: false, // 零库存
      gridtype: 'action', // 表格类型。 action: 可编辑， base: 不可编辑
      bomMasterDetailEditVisibleTwo: false,
      rendeRing: 0,
      exportFormatVisible: false,
      setColumns: [],
      setDefaultConfigColumns: {},
      setSaveConfigURL: '',
      reserveDetailVisable: false,
      refreshReserveDetail: false,
      // 左下角数据
      totalCost: 0, // 库存总成本
      totalQty: 0, // 总数量
      isShowCost: false, // 是否显示库存总成本
      imageUploadVisible: false, // 上传图片
      imageBoxVisible: false, // 是否显示型号的图片
      imageList: [], // 待显示的图片
      imageTitle: '', //
      imageStkGUID: '', // 库存GUID
      beEditedSummary: false, // 批次库存 可编辑权限
      parameterConfig: [],
      ParamJosn: [],
      IsQueryZeroInventory: false, // 默认搜索零库存 0 否 1 是
      IsQueryBarStatus: false // 搜索栏状态 0关闭 1打开
    }
  },
  computed: {},
  methods: {
    onInit: async function (config) {
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('batchesStock', CONFIG.configURL)
      }
      // 获取参数配置
      this.ParamJosn = config.ParamJosn
      this.paramConfiguration()

      // 获取模块打开或者关闭的状态
      this.GetStatus(config.Status)
      this.resourceList = config.ResourceList
      this.moreBtns = [
        {
          name: '销售出货',
          isShow: this.hasRes('StkOutAdd'),
          click: () => {
            this.stkOutAddRows()
          }
        },
        {
          name: '采购入库',
          isShow: this.hasRes('StkInAdd'),
          click: () => {
            this.stkInAddRows()
          }
        },
        {
          name: '库存合并',
          isShow: this.hasRes('StkMerge'),
          click: () => {
            this.stkMerge()
          }
        },

        {
          name: '库存调货',
          isShow: this.hasRes('StkTransfer'),
          click: () => {
            this.stkTransfer()
          }
        },
        {
          name: '删除库存',
          isShow: this.hasRes('Delete'),
          click: () => {
            this.onMulitDeleteRow()
          }
        },
        {
          name: '导入库存',
          // isShow: true,
          isShow: this.hasRes('ImportStock'),
          click: () => {
            this.onstkImport()
          }
        },
        {
          name: '回收站',
          isShow: this.hasRes('StkRecycle'),
          click: () => {
            this.stkRecycle()
          }
        }
      ]

      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 下拉列表
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: this.selectOptionsQtyUnit, showDropDown: true }
      )

      // 采购员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (
        obj,
        index
      ) {
        return { key: obj.value, value: obj.value }
      })

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'BuyerName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 更新人
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'UpdatedByName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 库存类型
      let stkTypeColumn = _.find(columns, column => column.name === 'InvType')
      stkTypeColumn &&
        _.extend(stkTypeColumn, {
          dataMap: this.selectStorageType,
          showDropDown: true
        })
      let AdoptQtyColumns = _.find(
        columns,
        column => column.name === 'AdoptQty'
      )
      _.extend(AdoptQtyColumns, { isReadOnly: true, visible: false })
      // 仓库
      let WarehouseOptions = this.selectOptionsWarehouseOptions
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'InvPosition'
        }),
        { dataMap: WarehouseOptions, showDropDown: true }
      )

      this.btnMouseEnterMenus = {
        List: [
          // {
          //   name: '加入购物车',
          //   isShow: false,// this.hasRes('StkShoping')
          //   click: row => {
          //     this.ShoppingTrolley([row])
          //   }
          // },
          {
            name: '给客户报价',
            isShow: this.hasRes('Quote'),
            click: row => {
              let rows = []
              rows.push(row)
              this.onQuotedPrice(rows)
            }
          },
          {
            name: '销售出货',
            isShow: this.hasRes('StkOutAdd'),
            click: row => {
              let rows = []
              rows.push(row)
              this.onStkOutadd(rows)
            }
          },
          {
            name: '采购入库',
            isShow: this.hasRes('StkInAdd'),
            click: row => {
              let rows = []
              rows.push(row)
              this.onStkInadd(rows)
            }
          },
          {
            name: '询价',
            isShow: this.hasRes('Inquiry'),
            click: row => {
              let rows = []
              rows.push(row)
              this.onStkInquiry(rows)
            }
          },
          {
            name: '删除库存',
            isShow: this.hasRes('Delete'),
            click: row => {
              let guid = []
              guid.push(row.StkGUID)
              this.onDeleteOne(guid)
            }
          },
          {
            name: '拆分库存',
            isShow: this.hasRes('StkSplit'),
            click: row => {
              let guid = []
              guid.push(row.StkGUID)
              this.onStkSplit(guid)
            }
          },
          {
            name: '查看日志',
            isShow: !this.getSpecialResourceByCode('NoSeeStockLog'), // 特殊权限 查看日志
            click: row => {
              this.stockGuid = row.StkGUID
              this.showStkstocklog = true
              this.refreshStklog = !this.refreshStklog // 改变状态进行刷新
            }
          }
        ],
        Binding: 'operate'
      }
      columns.unshift({
        name: 'operate',
        dataType: 5,
        binding: 'operate',
        width: 36,
        header: '操作',
        visible: true,
        isReadOnly: true,
        isFieldsSearch: false,

        isSystem: true,
        align: 'left',
        className: 'znlerp-operate znl-text-center ',
        buttons: [
          {
            content: '<i class="iconfont icon-left_nav_show_btn"></i>',
            className: 'znl-table-btn',
            click: () => {}
          }
        ]
      })

      this.genColImage(_.find(columns, col => col.binding === 'StockImage')) // 库存图片

      // 成本价权限控制BuyPrice 采购价未税BuyPrice 和TaxBuyPrice 采购价(含税)  和 成本金额TotalCostAmount
      let Jurisdiction = !this.getSpecialResourceByCode('ViewBuyPrice')

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode('NoSeeSupplier')

      _.each(columns, (item, index) => {
        if (item.binding === 'SalesReserveQty') {
          item.visible = true
        }
        if (item.binding === 'StockImage') {
          item.visible = true
        }
        if (
          (Jurisdiction && item.binding === 'BuyPrice') ||
          (Jurisdiction && item.binding === 'TaxBuyPrice') ||
          (Jurisdiction && item.binding === 'TotalCostAmount')
        ) {
          item.hasRole = false
        }
        if (noSeeSupplier && item.binding === 'SupplierName') {
          item.hasRole = false
        }
      })

      let onlyEditModelQty = false
      if (!this.hasRes('Save')) {
        if (this.hasRes('EditModelQty')) {
          // 修改型号&数量
          onlyEditModelQty = true
        } else {
          this.gridtype = 'base'
        }
      }

      // 成本价权限控制 SalesPrice 销售定价 FollowPrice 建议销售价
      let ViewSalesPriceJurisdiction = !this.getSpecialResourceByCode(
        'ViewSalesPrice'
      )
      _.each(columns, (item, index) => {
        if (
          (ViewSalesPriceJurisdiction && item.binding === 'SalesPrice') ||
          (ViewSalesPriceJurisdiction && item.binding === 'FollowPrice')
        ) {
          item.hasRole = false
        }
        // 看备注1 没有这个权限隐藏列
        if (item.binding === 'Remark') {
          item.hasRole = this.hasRes('ViewRemark1')
        }
        // 看备注2 没有这个权限隐藏列
        if (item.binding === 'Remark1') {
          item.hasRole = this.hasRes('ViewRemark2')
        }
        // 看备注3  没有这个权限隐藏列
        if (item.binding === 'Remark2') {
          item.hasRole = this.hasRes('ViewRemark3')
        }
        // 不看替代型号，有这个权限隐藏列
        if (item.binding === 'ReplaceModel') {
          item.hasRole = !this.hasRes('NoSeeReplaceModel')
        }
        // 只能编辑型号&数量
        if (
          onlyEditModelQty &&
          item.binding !== 'Model' &&
          item.binding !== 'InvQty'
        ) {
          item.isReadOnly = true
        }
      })

      _.each(columns, (item, index) => {
        if (
          item.binding === 'Model' ||
          item.binding === 'SupplierName' ||
          item.binding === 'CustomerName' ||
          item.binding === 'BillNo'
        ) {
          _.extend(item, {
            className: 'znl-linkAll-column',
            renderCell: this.openLinkAll(item)
          })
        }
      })

      _.each(columns, column => {
        if (column.binding === 'SalesReserveQty') {
          column.buttons = [
            {
              content: row => {
                return row.SalesReserveQty || ''
              },
              className: 'znl-table-btn-success',
              when: true,
              click: row => {
                if (row.SalesReserveQty > 0) {
                  this.stockGuid = row.StkGUID
                  this.reserveDetailVisable = true
                  this.refreshReserveDetail = !this.refreshReserveDetail // 改变状态进行刷新
                } else {
                  return
                }
              }
            }
          ]
          column.dataType = 5
        }
      })

      let value = this.getCompanySetupValue('AllowDirectlyUpdateStock')
      if (value === '1') {
        // 批次库存 1=不可编辑
        _.each(columns, item => {
          if (!item.isReadOnly) {
            item.isReadOnly = !this.beEditedSummary
          }
        })
      }
      this.parameterConfig = config.ParamJosn
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.defaultConfig = config
      if (this.initData) {
        await this.onBindData()
      }
      this.loading = false
      this.$emit('page-loading', false)
    },

    genColImage (column) {
      column.renderCell = (h, obj) => {
        let content =
          '<span class="show-hand icon iconfont icon-pic_btn_ic color-ccc"></span>'
        let _this = this
        let row = obj.row
        let hasImage = false
        if (row.StockImages && row.StockImages.length > 0) {
          hasImage = true
          content =
            '<span class="show-hand icon iconfont icon-pic_btn_ic color-green"></span>'
        }
        return h('div', {
          domProps: {
            innerHTML: content,
            title: hasImage ? '点击查看图片' : ''
          },
          on: {
            click () {
              if (hasImage) {
                _this.imageList = row.StockImages
                _this.imageBoxVisible = true
                _this.imageTitle = row.Model
                _this.imageStkGUID = row.StkGUID
              } else {
                _this.imageStkGUID = row.StkGUID
                _this.imageUploadVisible = true
              }
            }
          }
        })
      }
    },

    async onSearch (params) {
      this.isShowCost = false
      this.searchFields = _.extend({}, this.searchFields, params)
      this.onBindData(1)
    },
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'IsQueryBarStatus') {
          return paramjosn
        }
      })
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isShowFiedsSearch ? '1' : '0'
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: 'StkStock', ParamJson: [datas] },
          data => {
            localStorage.removeItem('batchesStock')
          }
        )
      }
    },

    onIsZeroSettingSave () {
      let datas = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn && paramjosn.ParamCode === 'IsQueryZeroInventory') {
          return paramjosn
        }
      })
      if (this.hasValue(datas)) {
        datas.ParamValue = this.isZero ? '1' : '0'
        this.$post(
          CONFIG.SavePageParamURL,
          { GCode: 'StkStock', ParamJson: [datas] },
          data => {
            localStorage.removeItem('batchesStock')
          }
        )
      }
    },
    onBindData (pageIndex = 1) {
      this.loading = true
      let filterFields = {
        // 外部搜索条件
        isNegative: this.isNegative, // 负库存
        isZero: this.isZero, // 零库存
        MatchOperation: this.matchOperation
      }
      let data = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields),
          pageIndex: pageIndex,
          pageSize: this.pageSize
        },
        filterFields
      )
      return this.$post(CONFIG.searchURL, data, (datas, logic) => {
        if (logic.code === 200) {
          this.pageIndex = pageIndex
          this.itemSource = datas.ResultList
          this.totalCount = datas.TotalCount
        }
      }).finally(() => {
        this.loading = false
        this.gridShow = true
        this.$emit('sendisZero', this.isZero)
        this.$emit('sendisNegative', this.isNegative)
        this.$emit('page-loading', false)
      })
    },
    // 获取参数配置
    paramConfiguration () {
      // 默认搜索零库存 0 否 1 是
      let paramIsQueryZeroInventory = _.find(this.ParamJosn, function (
        paramjosn
      ) {
        if (paramjosn.ParamCode === 'IsQueryZeroInventory') {
          return paramjosn.ParamValue
        }
      })
      if (paramIsQueryZeroInventory.ParamValue === '1') {
        this.isZero = true
      }
      // 搜索栏状态 0关闭 1打开
      let paramIsQueryBarStatus = _.find(this.ParamJosn, function (paramjosn) {
        if (paramjosn.ParamCode === 'IsQueryBarStatus') {
          return paramjosn.ParamValue
        }
      })
      if (paramIsQueryBarStatus.ParamValue === '1') {
        this.isShowFiedsSearch = true
      }
    },
    // 刷新库存统计
    async onSumData () {
      this.$refs.flexGrid.clearSelection()
      let filterFields = {
        // 外部搜索条件
        isNegative: this.isNegative, // 负库存
        isZero: this.isZero, // 零库存
        MatchOperation: this.matchOperation
      }
      let param = _.extend(
        {},
        {
          fieldWhereString: JSON.stringify(this.searchFields)
        },
        filterFields
      )
      let _this = this
      this.$post(CONFIG.summaryCostURL, param, data => {
        _this.isShowCost = true
        console.log(data)
        _this.totalCost = _this.toFloat(data.TotalCost, 2)
        _this.totalQty = _this.toFloat(data.TotalQty, 2)
      })
    },

    QuotedPrice () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length > 0) {
        this.onQuotedPrice(rows)
      } else {
        this.$message({ message: '请选择一条数据进行操作', type: 'error' })
      }
      this.clearSelectedRows()
    },
    headTitle (stkGUIDS) {
      return (
        '待合并库存列表：(共选择了' + JSON.parse(stkGUIDS).length + '条库存)'
      )
    },
    onStkInadd (rows) {
      // 采购入库传值方法
      // this.$store.state.visibled = false //操作store禁止直接改变值
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true
      })
    },
    onStkOutadd (rows) {
      // 销售出库传值方法
      // this.stockLine = rows
      // this.$store.state.visibled = false
      // debugger
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkOutEditVisible = true
      })
    },
    stkInAddRows () {
      // 批量采购入库
      let rows = this.$refs.flexGrid.getSelectedRows()
      this.onStkInadd(rows)
      // if (rows.length > 0) {
      //   this.onStkInadd(rows)
      // } else {
      //   return this.$message({ message: '请选择一条数据进行操作', type: 'error' })
      // }
      this.clearSelectedRows()
    },
    onstkImport: async function () {
      // 导入自有库存
      // 先做验证，库存量已超出最大库存量，不能再导入
      let IsAllowImport = await this.$post(CONFIG.checkAllowImport)
      this.$nextTick(function () {
        if (!IsAllowImport) {
          return this.$message({
            message: '您的库存量已超出最大库存量，不能再导入',
            type: 'error'
          })
        }
        this.importData({ importType: 'stock' })
      })
    },
    onstkExport: async function () {
      // 导出（按查询条件）
      this.exportFormatVisible = true
      this.setColumns = this.columns
      this.setDefaultConfigColumns = this.defaultConfig
      this.setSaveConfigURL = this.$options.config.saveConfigURL
      // let newfieldsFilter = this.searchModel
      // // if (newfieldsFilter.UpdatedTime !== '') {
      // //   newfieldsFilter.UpdatedTime = ''
      // // }
      // // if (newfieldsFilter.StkInTime !== '') {
      // //   newfieldsFilter.StkInTime = ''
      // // }
      // console.log('searchModel2', this.searchModel)
      // let data = _.extend({}, {
      //   FieldWhereString: JSON.stringify(newfieldsFilter)
      // }, {
      //     // 外部搜索条件
      //   model: this.searchFields.Model, // 负库存
      //   isZero: this.isZero, // 零库存
      //   matchOperation: this.matchOperation // 型号匹配方式
      // })
      // console.log('data', data)

      // this.$nextTick(function () {
      //   this.exportStock({ url: '', whereJson: data, dgvName: this.columns, stockType: '1' })
      // })
    },
    stkOutAddRows () {
      // 批量销售出库
      let rows = this.$refs.flexGrid.getSelectedRows()
      this.onStkOutadd(rows)
      // if (rows.length > 0) {
      //   this.onStkOutadd(rows)
      // } else {
      //   return this.$message({ message: '请选择一条数据进行操作', type: 'error' })
      // }
      this.clearSelectedRows()
    },
    stkTransfer () {
      // 库存调货
      let rows = this.onSelectRow()
      if (rows.length > 0) {
        this.stockGuids = JSON.stringify(rows)
        this.showStktransferList = true
        this.refreshStkTransfer = !this.refreshStkTransfer
      }
      this.clearSelectedRows()
    },
    onStkSplit (guid) {
      // 拆分
      if (guid.length > 0) {
        this.stockGuids = JSON.stringify(guid)
        this.showstkSplit = true
        this.refreshStkSplit = !this.refreshStkSplit
        this.clearSelectedRows()
      }
    },
    onMulitDeleteRow () {
      // 删除多条库存
      let guid = this.onSelectRow()
      if (guid.length > 0) {
        this.$confirm('删除确认, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          return this.onPostDelete(guid)
        })
      }
    },
    stkRecycle () {
      // 库存回收站
      this.showstkRecycle = true
      this.refreshStkRecycle = !this.refreshStkRecycle
      this.clearSelectedRows()
    },
    onSelectRow () {
      // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (this.stkstockSelectItem.length === 0) {
          // 选中行
          return this.$message({
            message: '请至少选择一条数据进行操作',
            type: 'error'
          })
        } else {
          guid.push(this.stkstockSelectItem.StkGUID)
        }
      } else {
        guid = _.map(rows, item => {
          return item.StkGUID
        })
      }
      return guid
    },
    stkMerge () {
      // 库存合并
      let rows = this.onSelectRow()
      if (rows.length > 0) {
        let result = this.$post(
          CONFIG.checkStkMergeURL,
          { StkGUIDS: rows },
          data => {
            // console.log(' 库存合并', data)
            if (data === -1) {
              this.$message({
                message: '合并操作,必须选择2条及以上的我的库存！',
                type: 'error'
              })
            } else if (data === -2) {
              this.$message({
                message: '选择的库存且可用量不能为0！',
                type: 'error'
              })
            } else if (data === 0) {
              this.stockGuids = JSON.stringify(rows)
              let dataRows = this.$refs.flexGrid.getSelectedRows()
              this.markingCode = ''
              _.some(dataRows, code => {
                if (!_.isEmpty(code.MarkingCode)) {
                  this.markingCode = code.MarkingCode
                  return true
                }
              })
              this.showstkMerge = true
              this.refreshStkMerge = !this.refreshStkMerge
              this.clearSelectedRows()
            }
          }
        )
        return result
      }
    },
    // 获取模块状态
    GetStatus (val) {
      this.Status = val !== 0
      this.Status
        ? this.$refs.flexGrid.toggleTableShow(true)
        : this.$refs.flexGrid.toggleTableShow(false)
    },
    // 保存模块状态
    saveStatus (val) {
      let Status = val ? 1 : 0
      if (Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    },
    hasSeeLogRes () {
      let isnotSee = _.some(
        this.specialResource,
        item => item.Code === 'NoSeeStockLog'
      )
      return isnotSee
    },
    onQuotedPrice (rows) {
      // let rows = this.$refs.flexGrid.getSelectedRows()
      // console.log('onquotedprice:', this.onSelectRow(), rows)
      if (rows !== null && rows.length > 1) {
        this.bomMasterDetailEditVisibleTwo = true
        this.$nextTick(() => {
          this.bomMasterDetail = rows
        })
      } else {
        this.$emit('QuotePriceDetial', rows[0])
      }
    },
    ShoppingTrolley (row) {
      console.log(row)
    },
    clearSelectedRows () {
      this.$refs.flexGrid.clearSelection()
    },
    isShowMoreBtn (btns) {
      let isShow = false
      _.each(btns, btn => {
        if (btn.isShow) {
          isShow = true
          return true
        }
      })
      return isShow
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page.Code === code)
    },
    onSaveRows (rows) {
      if (rows.length > 0) {
        return this.$post(CONFIG.saveRowsURL, rows, data => {
          if (data >= 0) {
            this.$message({ message: '保存成功', type: 'success' })
            // this.onBindData(this.pageIndex)
            this.$refs.flexGrid.$refs.actionFlexGrid.saveScueess()
          } else {
            this.$message({ message: '保存失败', type: 'error' })
          }
        })
      }
    },
    onDeleteRow (deleteData) {
      // 删除行
      let guid = []
      guid.push(deleteData.StkGUID)
      this.onPostDelete(guid)
    },
    onDeleteOne (guid) {
      // 删除单条
      this.$confirm('删除确认, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return this.onPostDelete(guid)
      })
    },
    onStkInquiry: async function (row) {
      // 询价
      if (row.length > 0) {
        this.onInquiry(row[0])
        this.clearSelectedRows()
      } else {
        return this.$message({ message: '请选择一条数据', type: 'error' })
      }
    },
    onInquiry: async function (data) {
      // 询价
      this.stkInquiry.SupplierName = data.SupplierName
      this.stkInquiry.Model = data.Model
      this.stkInquiry.Brand = data.Brand
      this.stkInquiry.Packaging = data.Packaging
      this.stkInquiry.MakeYear = data.MakeYear
      this.stkInquiry.Quality = data.Quality
      // this.stkInquiry.DeliveryDate = data.DeliveryDate
      this.stkInquiry.InvQty = data.InvQty
      this.stkInquiry.MPQ = data.MPQ
      this.stkInquiry.Qty = data.InvQty
      // this.stkInquiry.Price = data.BuyPrice
      this.addInquire(this.stkInquiry, function (msg) {
        if (msg === '不能向自己询价') {
          this.$message({ message: msg, type: 'warning' })
        }
      })
    },
    onPostDelete (guid) {
      // 删除请求
      return this.$post(CONFIG.DeleteURL, { StkGUIDS: guid }, data => {
        if (data) {
          this.$message({ message: '删除成功', type: 'success' })
          this.onRefresh()
          this.$refs.flexGrid.clearSelection()
        } else {
          this.$message({ message: '删除失败', type: 'error' })
        }
      })
    },
    toggleTableShow () {
      return this.$refs.flexGrid.toggleTableShow()
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('batchesStock')
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(
          columns,
          pageSize,
          this.defaultConfig,
          this.parameterConfig
        )
      )
    },
    async onResetSetting () {
      localStorage.removeItem('batchesStock')
      await this.$post(this.$options.config.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(
          [column],
          this.pageSize,
          this.defaultConfig,
          this.parameterConfig
        )
      )
    },
    onResetParams (val) {
      localStorage.removeItem('batchesStock')
      return this.$post(CONFIG.resetParamConfigURL)
    },
    mounted () {
      console.log('-----------end 批次库存');
      this.$refs.flexGrid.toggleTableShow(false)
    },
    // 关闭图片走马灯时执行
    onShowImageClose (deletedImgGuids) {
      this.imageBoxVisible = false
      if (deletedImgGuids && deletedImgGuids.length > 0) {
        _.each(this.itemSource, item => {
          _.each(deletedImgGuids, imgGuid => {
            let index = _.findIndex(
              item.StockImages,
              m => m.ImgGuid === imgGuid
            )
            if (index > -1) {
              item.StockImages.splice(index, 1)
            }
          })
        })
      }
    },
    async onMarketSearch () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      let marketRows = []
      if (
        (this.searchFields.Model === null || this.searchFields.Model === '') &&
        (rows === null || rows.length === 0 || _.isUndefined(rows[0]))
      ) {
        return this.$message({
          message: '请输入搜索条件或勾选需要市场查货的汇总库存',
          type: 'warning'
        })
      } else if (
        (this.searchFields.Model !== null || this.searchFields.Model !== '') &&
        (rows === null || rows.length === 0 || _.isUndefined(rows[0]))
      ) {
        const regex = /,|，/
        let modeList = (this.searchFields.Model === null
          ? ''
          : this.searchFields.Model
        ).split(regex)
        _.each(modeList, item => {
          marketRows.push({
            Model: item
          })
        })
      } else {
        marketRows = rows
      }
      let request = []
      console.log(marketRows)
      _.each(marketRows, item => {
        request.push({
          Model: item.Model, // 型号，
          Brand: item.Brand, // 品牌，
          Pack: item.Packaging, // 封装，
          MakeYear: item.MakeYear, // 年份，
          Qty: item.InvQty, // 数量，
          Remark: item.Remark // 需求说明
        })
      })
      console.log(request)
      this.$nextTick(function () {
        this.openMarketSearch({ Requests: request, ClearRequests: false })
      })
    },
    onCellClick (row, column, cell, event) {
      if (column.columnKey === 'Model') {
        this.$emit('cell-click', row)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
