<template>
  <znl-dialog
    title="型号计划出货导入"
    subhead
    :visible="visible"
    width="528px"
    :footer-height="0"
    @close="()=>{$emit('close')}"
  >
    <znl-gridmodule
      ref="flexGrid"
      gridtype="action"
      height="380px"
      :on-init="onInit"
      :columns="columns"
      :show-save-btn="false"
      :item-source="itemSource"
      :on-refresh="onRefresh"
      :on-page-changed="onPageChanged"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :is-fields-search="false"
      :show-delete-row-right-menu="true"
      :is-multi-rows-check="true"
      :role="role"
      :loading="loading"
    >
      <div slot="action-form">
        <span style="color: #8492a6;">计划周期只是：一周、两周、一个月、两个月、三个月、半年、一年，其它无效。</span>
      </div>

      <div slot="heading-slot">
        <vue-xlsx-container @on-select-file="handleSelectedFile">从Excel导入</vue-xlsx-container>
        <!-- <znl-input :border="true"
          style-type="mac"
          v-model="pasteText"
          :height="22"
          :width="80"
          :clearable="true"
          @change="pasteFromExcel"
          placeholder="从Excel粘贴">
        </znl-input>-->
        <znl-button style-type="mac" :height="22" @click="onAddRow">
          <i class="iconfont icon-add_btn_ic"></i>
          <span>添加行</span>
        </znl-button>
        <znl-button style-type="mac" :height="22" @click="onDelRow">
          <i class="iconfont icon-delete_btn_ic"></i>
          <span>删除行</span>
        </znl-button>
        <znl-button style-type="mac" :height="22" @click="onImport">
          <i class="iconfont icon-leading-in-wh_btn_ic"></i>
          <span>导入</span>
        </znl-button>
      </div>
    </znl-gridmodule>
  </znl-dialog>
</template>

<script>
import vueXlsxContainer from '@c_common/excel/ExcelTable'
const CONFIG = {
  saveURL: 'StsShipment/Save'
}
export default {
  name: 'StsShipmentImport',
  config: CONFIG,
  components: { vueXlsxContainer },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    visible: Boolean
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      loading: false,
      role: '1',
      planningCycleOption: [
        { key: '1w', value: '一周' },
        { key: '2w', value: '两周' },
        { key: '1m', value: '一个月' },
        { key: '2m', value: '两个月' },
        { key: '3m', value: '三个月' },
        { key: '6m', value: '半年' },
        { key: '1y', value: '一年' }
      ],
      pasteText: '',
      fileList: [],
      uploadFile: {}
    }
  },
  methods: {
    async onInit() {
      this.columns = [
        {
          binding: 'ASID',
          name: 'ASID',
          header: 'ASID',
          visible: false,
          dataType: 1,
          width: 100,
          isReadOnly: false,
          isFieldSearch: true,
          isSystem: false,
          IsExprot: false
        },
        {
          binding: 'Model',
          name: 'Model',
          header: '型号',
          visible: true,
          dataType: 1,
          width: 180,
          isReadOnly: false,
          isFieldSearch: true,
          isSystem: false,
          IsExprot: true,
          IsUpperCase: true
        },
        {
          binding: 'PlanningCycle',
          name: 'PlanningCycle',
          header: '计划周期',
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: false,
          isFieldSearch: true,
          isSystem: false,
          dataMap: this.planningCycleOption,
          showDropDown: true
        },
        {
          binding: 'PlannedShipment',
          name: 'PlannedShipment',
          header: '计划出货量',
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: false,
          isFieldSearch: true,
          isSystem: false,
          IsExprot: true
        },
        {
          binding: 'ImportStatus',
          name: 'ImportStatus',
          header: '导入状态',
          visible: true,
          dataType: 1,
          width: 100,
          isReadOnly: true,
          isFieldSearch: true,
          isSystem: false,
          IsExprot: true
        }
      ]
      this.onRefresh()
    },
    onRefresh() {
      this.itemSource = []
      this.totalCount = this.itemSource.length
      // for (let index = 0; index < 10; index++) {
      //   this.itemSource.push({
      //     ASID: index,
      //     Model: '',
      //     PlanningCycle: '',
      //     PlannedShipment: '',
      //     ImportStatus: ''
      //   })
      // }
    },
    onPageChanged() { },
    onAddRow() {
      this.$refs.flexGrid.addRow()
    },
    onDelRow() {
      let that = this
      var rows = this.$refs.flexGrid.getSelectedRows()
      _.each(rows, row => {
        let dindex = that.itemSource.findIndex(item => {
          return _.isEqual(row, item)
        })
        if (dindex > -1) {
          that.itemSource.splice(dindex, 1)
          that.totalCount = that.itemSource.length
        }
      })
    },
    onImport() {
      // console.log('onImport', this.itemSource)
      this.$post(CONFIG.saveURL, this.itemSource, (datas, logic) => {
        if (logic.code === 200 && datas) {
          if (datas > 0) {
            this.$message({ message: '已导入!', type: 'success' })
            this.onRefresh()
          } else {
            this.$message({ message: '没有要导入的内容！', type: 'warning' })
          }
        }
      })
    },
    handleSelectedFile(convertedData) {
      // let that = this
      // console.log(convertedData)
      let itemSource = []
      if (!convertedData || convertedData.body.length <= 0) {
        return
      }
      let header = convertedData.header
      _.each(convertedData.body, item => {
        // 当前导入型号不重复 保留顺序靠前的
        if (
          !_.find(itemSource, ite => {
            return ite['Model'] === item[header[0]]
          })
        ) {
          itemSource.push({
            Model: item[header[0]],
            PlanningCycle: item[header[1]],
            PlannedShipment: item[header[2]],
            ImportStatus: '未导入'
          })
        }
      })
      this.itemSource = itemSource
      this.pageSize = itemSource.length
      this.totalCount = itemSource.length
    }
    //  v-if="this.hasRes('ImportStock')"
    // hasRes (code) {
    //   return _.some(this.resourceList, page => page.Code === code)
    // }
  },
  mounted() { },
  created() { }
}
</script>

<style lang='scss'>
</style>
