<template>
  <znl-dialog :close-on-click-modal="false"
              :visible="visible"
              class="p-stk-stock-warning"
              height="500px"
              title="库存预警"
              width="580px"
              @close="()=>{$emit('close')}">
    <znl-gridmodule ref="flexGrid"
                    slot="znl-dialog"
                    :action-btns="actionBtn"
                    :column-colors="columnColors"
                    :columns="columns"
                    :has-znl-actions="true"
                    :has-znl-btns="true"
                    :is-init="true"
                    :is-multi-rows-check="true"
                    :item-source="itemSource"
                    :on-delete-checked-rows="onDeletedRows"
                    :on-init="onInit"
                    :on-page-changed="onBindData"
                    :on-save-row="onSaveRows"
                    :on-save-rows="onSaveRows"
                    :on-search="onSearch"
                    :page-index="pageIndex"
                    :page-size="pageSize"
                    :role="role"
                    :row-colors="rowColors"
                    :search-fields="searchModel"
                    :show-add-row-right-menu="false"
                    :show-delete-row-right-menu="false"
                    :summary-columns="summaryColumns"
                    :total-count="totalCount"
                    :users="$store.state.users"
                    checkboxBindingKey="SWID"
                    gridtype="action"
                    height="400px"
                    :max-height="400"
                    layoutType="spa"
                    @selection-changed="d=>{this.stkWarningSelectItem=d,$emit('selection-changed',d)}">
      <div slot="action-form"
           class="_action_search">
        <znl-input v-model="inputModel"
                   :clearable="true"
                   :disabled="false"
                   border
                   form-type="input"
                   inp-perc="100%"
                   layout="left"
                   placeholder="请输入型号"
                   size="mini"
                   type="text"
                   width='120px'
                   @keyup.enter.native="$refs.flexGrid.search()">
        </znl-input>
        <znl-input v-model="inputBrand"
                   :clearable="true"
                   :disabled="false"
                   border
                   form-type="input"
                   inp-perc="100%"
                   layout="left"
                   placeholder="请输入品牌 "
                   size="mini"
                   type="text"
                   width='120px'
                   @keyup.enter.native="$refs.flexGrid.search()">
        </znl-input>
        <znl-button :height='22' style-type='main' @click="$refs.flexGrid.search()">
          <span><i class="iconfont icon-search_ic"></i> 搜索</span>
        </znl-button>
        <el-radio-group v-model="AfterWarning" class="form-group" @change="$refs.flexGrid.search()">
          <el-radio label="0">所有</el-radio>
          <el-radio label="1">已报警</el-radio>
          <el-radio label="2">未报警</el-radio>
        </el-radio-group>
        <StkWarningAdd v-if="stkWarningAddVisible"
                       ref='StkWarningAdd'
                       v-move-to.body
                       :isRefresh="onrefreshAdd"
                       :swid="stkWarningGUID"
                       :visible="stkWarningAddVisible"
                       @close="()=>{stkWarningAddVisible=false}"
                       @confirm="onChangeEnd">
        </StkWarningAdd>
        <ConfirmPassword
          v-if="stkCheckPwdVisible"
          ref='ConfirmPassword'
          v-move-to.body
          :isRefresh="stkCheckPwdVisible"
          :visible="stkCheckPwdVisible"
          @close="()=>{stkCheckPwdVisible=false}"
          @confirm="onChangeEndCheckPwd"></ConfirmPassword>
      </div>
    </znl-gridmodule>
    <div slot="footer">
      <el-button class="znl-btn-auxiliary" @click="()=>{$emit('close')}">关闭</el-button>
    </div>
  </znl-dialog>
</template>
<script>

import {mixin as znlMethodsMixin} from '@c_common/znlGrid/scripts/znlMethods'
import StkWarningAdd from '@c_modules/Stk/StkStockWarningAdd'
import ConfirmPassword from '@c_modules/Stk/ConfirmPassword'

export default {
  mixins: [znlMethodsMixin],
  name: 'StkStockWarning',

  components: {
    StkWarningAdd,
    ConfirmPassword
  },
  data() {
    return {
      actionBtn: [
        {
          name: '新增',
          iconName: 'icon-add_btn_ic',
          click: () => {
            this.$refs.flexGrid.addRow()
          }
        },
        {
          name: '删除',
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.onDeleteWarning()
          }
        },
        {
          name: '清除',
          iconName: 'icon-delete_btn_ic',
          click: () => {
            this.onClear()
          }
        },
        {
          name: '统计库存',
          iconName: 'icon-sales-ticket_btn_ic',
          click: () => {
            this.onCountStk()
          }
        }
      ],
      stkWarningGUID: -1, // 需要传递给编辑页面的ID
      inputModel: '', // 需要查询的型号
      inputBrand: '', // 需要查询的品牌
      AfterWarning: '1', // 已报警
      NoWarning: false, // 未报警
      isWarning: 0, // 预警传值 0 标识不过滤 1 标识已预警 2 标识未预警
      stkWarningIds: [], // 需要批量操作的ID集合
      stkWarningSelectItem: [], // 选中的数据
      stkWarningAddVisible: false, // 是否显示出库编辑页面
      onrefreshAdd: true, // 刷新添加页面
      isRefreshCheckPwd: true, // 是否刷新密码验证页面
      stkCheckPwdVisible: false, // 是否显示密码验证页面
      pickerOptions0: {},
      config: {
        configURL: 'StkStockWarning/GetConfig',
        saveConfigURL: 'StkStockWarning/SaveConfig',
        resetConfigURL: 'StkStockWarning/ResetConfig',
        default: null,
        searchURL: 'StkStockWarning/Search',
        onDeleteURL: 'StkStockWarning/DeleteStkWarning',
        onClearURL: 'StkStockWarning/Clear',
        onCountStkURL: 'StkStockWarning/StatisticsCurInvQty',
        stkWarningSave: 'StkStockWarning/BatchSave'
      },
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: [],


    }
  },
  computed: {},
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    },
    openFrom: String,
    visible: Boolean,
    height: String,
    isRefresh: Boolean // 刷新页面
  },
  watch: {
    isRefresh(value) {
      this.inputModel = this.inputBrand = ''
      this.onBindData()
    },
    AfterWarning(value) {
      this.GetIsWarning()
    },
    NoWarning(value) {
      this.GetIsWarning()
    },
    openFrom(val) {
      if (val === 'warning') {
        this.AfterWarning = '1'
      } else {
        this.AfterWarning = '0'
      }
    }
  },
  methods: {
    onInit: async function () {
      let configs = await this.$post(this.config.configURL)
      let columns = this.flexGridColumnsHandler(configs.ColumnConfigs)
      let curQtyCol = _.find(columns, item => item.name === 'CurQty')
      curQtyCol.isReadOnly = true
      this.defaultConfig = configs
      this.columns = columns
      this.role = configs.Role
      this.initData && this.onBindData()
    },
    onBindData(pageIndex = 1) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          Model: this.inputModel,
          Brand: this.inputBrand,
          WarningCode: this.AfterWarning
        }
      )
      return this.$post(this.config.searchURL, data, data => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    },
    onSearch(params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onSelectionChange(currentItem) {
      this.$emit('selection-change', currentItem)
    },
    onSaveRows(rows) {
      if (rows.length > 0) {
        return this.$post(this.config.stkWarningSave, rows, data => {
          if (data >= 0) {
            this.$message({message: '保存成功', type: 'success'})
            this.isGridContentChange = false
            this.onBindData(this.pageIndex)
          } else {
            this.$message({message: '保存失败', type: 'error'})
          }
        })
      }
    },

    onRefresh() {
      return this.onBindData(this.pageIndex)
    },
    onSaveSetting(columns, pageSize) {
      return this.$post(
        this.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.config.default)
      )
    },
    onResetSetting() {
      return this.$post(this.config.resetConfigURL)
    },
    onStkWarningAdd() {
      this.onOpenAddDialog(true)
    },
    onOpenAddDialog(isAdd, swID = -1) {
      // 传值id值为0，显示页面
      this.stkWarningAddVisible = true // 显示页面
      this.stkWarningGUID = swID
      this.onrefreshAdd = !this.onrefreshAdd
    },
    onSelectRow() {
      // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (this.stkWarningSelectItem.length === 0) {
          // 选中行
          return this.$message({message: '请至少选择一条数据进行操作', type: 'error'})
        } else {
          guid.push(this.stkWarningSelectItem.SWID)
        }
      } else {
        guid = _.map(rows, item => {
          return item.SWID
        })
      }
      return guid
    },


    onDeleteWarning() {
      const list = this.$refs.flexGrid.getSelectedRows()
      if(list.length === 0) {
        this.$message({message: '请至少选择一条数据进行操作', type: 'error'})
        return
      }
      this.$refs.flexGrid.deleteCheckedRows()
    },

    onPostDelete(sWid) {
      this.$post(this.config.onDeleteURL, {SWIDs: sWid}, data => {
        if (data > 0) {
          this.$message({message: '删除成功', type: 'success'})
          this.onBindData(this.pageIndex)
        } else {
          this.$message({message: '删除失败', type: 'error'})
        }
      })
    },
    onMulDelete() {
      // 多条删除
      let guid = this.onSelectRow()
      if (guid.length > 0) {
        this.onPostDelete(guid)
      }
    },
    onDeletedRows(rows) {
      this.loading = true
      if (rows.length > 0) {
        let lists = []
        let deleteNews = []
        _.each(rows, item => {
          if (item['_PKIndex'] !== -1) {
            lists.push(item)
          } else {
            deleteNews.push(item)
          }
        })
        _.each(deleteNews, item => {
          this.deleteCheckedRows(item)
          this.loading = false
        })
        if (lists.length === 0) return
        this.onMulDelete()
      } else {
        this.loading = false
        return this.$message({message: '请至少选择一条数据进行操作', type: 'error'})
      }
    },
    deleteCheckedRows(callBack) {
      _.each(this.$refs.flexGrid.getSelectedRows(), data => {
        _.each(this.itemSource, (item, i) => {
          if (_.isEqual(item, data)) {
            this.itemSource.splice(i, 1)
            return false
          }
        })
      })
      _.isFunction(callBack) && callBack()
    },
    onChangeEnd(val) {
      val && (this.stkWarningAddVisible = false)
      this.onBindData(this.pageIndex)
    },
    GetIsWarning() {
      // 获取库存预警
      if (
        (this.NoWarning && this.AfterWarning) ||
        (!this.NoWarning && !this.AfterWarning)
      ) {
        this.isWarning = 0
      } else if (!this.NoWarning && this.AfterWarning) {
        this.isWarning = 1
      } else if (this.NoWarning && !this.AfterWarning) {
        this.isWarning = 2
      }
    },
    onClear() {
      // 清除
      return this.$confirm('删除所有库存预警之后不可恢复，您确定要删除?', '清除库存预警', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.stkCheckPwdVisible = true
        // this.isRefreshCheckPwd = !this.isRefreshCheckPwd

        this.onChangeEndCheckPwd(true)
      })
    },
    onCountStk() {
      // 统计库存
      this.$post(this.config.onCountStkURL, {}, data => {
        if (data > 0) {
          this.$message({message: '统计成功', type: 'success'})
          this.onBindData(this.pageIndex)
        } else {
          this.$message({message: '统计失败', type: 'error'})
        }
      })
    },
    onChangeEndCheckPwd(val) {
      // this.stkCheckPwdVisible = false
      if (val) {
        this.$post(this.config.onClearURL, {}, data => {
          if (data > 0) {
            this.$message({message: '清除成功', type: 'success'})
            this.onBindData(this.pageIndex)
          } else {
            this.$message({message: '清除失败', type: 'error'})
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.p-stk-stock-warning {
  ._form_height_10 {
    height: 10px;
  }

  .znl-dialog.el-dialog .el-dialog__body {
    padding-top: 0px;
  }

  .znl-action-form {
    padding-left: 0;
  }

  .znl-dialog__footer {
    height: 50px !important;
    line-height: 50px !important;
  }

  .form-group {
    margin-left: 15px;
    .el-radio__label {
      display: inline-block;
      padding-left: 5px;
      margin-right: 10px;
    }
  }
}
</style>
