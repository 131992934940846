<template>
  <znl-layout-multiple
    ref="layout"
    :multiplerows="multiplerows"
    v-bind:class="[{ activeConfigHide: Pattern }, 'stockall-box']"
    :layout-config="layoutConfig"
    v-loading="pageLoading"
    element-loading-text="页面加载中,请稍后..."
    @draging-change="dragingChange"
  >
    <!--搜索模块-->
    <multiple name="SearchComponent" :fixed="true" v-show="!pageLoading">
      <stk-stock-all-search-bar
        v-if="!pageLoading"
        :show-recently-search="showRecentlySearch"
        @bar-search="onSearch"
      ></stk-stock-all-search-bar>

      <page-grid-config
        v-if="pageGridVisible"
        :visible="pageGridVisible"
        :config-info="pageConfig"
        :on-save-config="(val)=>{onSaveConfig(val)}"
        :on-reset-setting="onResetSetting"
        @close="pageGridVisible=false"
      ></page-grid-config>
      <a class="setPageGrid" v-show="!pageLoading" @click="setPageGird()">页面设置</a>
      <a class="old-edition" v-show="!pageLoading" @click="oldEdition()" v-if="isErpClient">切换到旧版</a>
    </multiple>

    <!-- 快速报价 -->
    <multiple name="InputQuotedPrice" v-show="!pageLoading">
      <easy-quote
        :quote-data="this.quoteData"
        :class="multiplerows[1].isHide?'module_hide':'module_show'"
        @switch-to-hide="val=>{switchToHide(val, 1)}"
        @bar-search="onSearch"
      ></easy-quote>
    </multiple>

    <!-- 批次库存，汇总库存 -->
    <multiple name="StockSummary" v-show="!pageLoading">
      <stk-batches-summary-stcok
        ref="stkBatchesSummaryStcok"
        :stock-search-model="bs_SearchModel"
        :stk-batch-right="stkBatchRight"
        :stk-summary-right="stkSummaryRight"
        :height="stockSetHeight"
        :computedheight="computedheight"
        @save-stkIn-succes="$refs.PastStkIn.$refs.flexGrid.search()"
        @save-stkOut-succes="$refs.PastStkOut.$refs.flexGrid.search()"
        @page-loading="(d)=>{pageLoadingMain=d}"
        @cell-click="tabChangeItem"
        @switch-to-hidestate="(val,obj)=>{switchToHide(val,2,obj)}"
        @QuotePriceDetial="(val)=>{this.QuotePriceDetial(val)}"
      ></stk-batches-summary-stcok>
    </multiple>

    <!-- row 布局形式 start-->
    <!-- 订阅库存 -->
    <multiple name="PubSStockFriend" v-if="!this.Pattern" v-show="!pageLoading">
      <pub-stock-friend
        ref="PubSStockFriend"
        :is-fields-search="false"
        :is-mini="true"
        :is-show="true"
        :is-activity="true"
        :is-init="true"
        :init-data="false"
        page-position="top"
        :is-multi-rows-check="false"
        :class="multiplerows[3].isHide?'module_hide':'module_show'"
        :page-loading="false"
        :search-model.sync="fs_SearchModel"
        @QuotePriceDetial="(val)=>{this.QuotePriceDetial(val)}"
        @switch-to-hide="val=>{switchToHide(val, 3)}"
      ></pub-stock-friend>
    </multiple>

    <!-- 供应商库存 -->
    <multiple name="StockVMI" v-if="!this.Pattern" v-show="!pageLoading">
      <stock-vmi
        ref="StockVMI"
        :is-fields-search="false"
        :is-mini="true"
        :is-show="true"
        :is-activity="true"
        :is-init="false"
        :init-data="false"
        page-position="top"
        :is-multi-rows-check="false"
        :class="multiplerows[4].isHide?'module_hide':'module_show'"
        :page-loading="false"
        :search-model.sync="vs_SearchModel"
        @QuotePriceDetial="(val)=>{this.QuotePriceDetial(val)}"
        @switch-to-hide="val=>{switchToHide(val, 4)}"
      ></stock-vmi>
    </multiple>

    <!-- 询价记录 -->
    <multiple name="StkInquireRecord" v-if="!this.Pattern" v-show="!pageLoading">
      <znl-stk-inquire-record
        :searchModel="inq_SearchModel"
        :is-fields-search="false"
        ref="StkInquireRecord"
        :is-show-grid="false"
        page-position="top"
        :hasSearchCondition="true"
        :is-multi-rows-check="false"
        :is-init="true"
        :is-show-operate="true"
        :init-data="false"
        :is-activity="true"
        :is-mini="true"
        :is-adopt="false"
        :is-analyzer="false"
        :page-loading="false"
        @QuotePriceDetial="(val)=>{this.QuotePriceDetial(val)}"
        :class="multiplerows[5].isHide?'module_hide':'module_show'"
        @switch-to-hide="val=>{switchToHide(val, 5)}"
      ></znl-stk-inquire-record>
    </multiple>

    <!-- 给客户的报价记录 -->
    <multiple v-show="!pageLoading" v-if="!this.Pattern" name="BomMastLineSelect">
      <znl-quote-record
        ref="BomMastLineSelect"
        :is-show-btn="true"
        :class="multiplerows[10].isHide?'module_hide':'module_show'"
        :searchModel="quoteRecordSearchModel"
        @switch-to-hide="val=>{switchToHide(val,10)}"
      ></znl-quote-record>
    </multiple>

    <!-- 销售发货记录 -->
    <multiple name="PastStkOut" v-if="!this.Pattern" v-show="!pageLoading">
      <stk-out-record
        ref="PastStkOut"
        :is-mini="true"
        :is-show="false"
        :is-show-btn="true"
        :is-activity="true"
        :is-init="false"
        :is-analyzer="false"
        page-position="top"
        :init-data="false"
        :searchModel="out_SearchModel"
        :is-multi-rows-check="false"
        :class="multiplerows[6].isHide?'module_hide':'module_show'"
        @switch-to-hide="val=>{switchToHide(val, 6)}"
      ></stk-out-record>
    </multiple>

    <!-- 采购入库记录 -->
    <multiple name="PastStkIn" v-if="!this.Pattern" v-show="!pageLoading">
      <stk-in-record
        ref="PastStkIn"
        :is-fields-search="false"
        :is-mini="true"
        :is-show="false"
        :is-activity="true"
        :grid-show="gridShow"
        :is-init="true"
        :is-show-btn="true"
        :init-data="false"
        page-position="top"
        :is-multi-rows-check="false"
        :page-loading="false"
        :class="multiplerows[7].isHide?'module_hide':'module_show'"
        @switch-to-hide="val=>{switchToHide(val, 7)}"
        :searchModel="in_SearchModel"
      ></stk-in-record>
    </multiple>

    <multiple name="TabMultiple" v-if="this.Pattern" v-show="!pageLoading">
      <!-- tab 布局形式 start-->
      <!-- <div  > -->
      <el-tabs
        v-show="!pageLoading"
        class="nav-common-ui tab-btnstyle-box"
        v-bind:style="{height:stockTabHeight}"
        v-model="activeName"
        @tab-click="pageSearch"
      >
        <el-tab-pane
          label="订阅库存 (按型号)"
          name="PubSStockFriend"
          height="100%"
          v-if="hasVisible('PubSStockFriend')"
        >
          <pub-stock-friend
            ref="PubSStockFriend"
            :is-fields-search="false"
            :is-mini="true"
            :is-show="true"
            :height="setHeightType"
            :is-activity="true"
            :is-init="true"
            :init-data="false"
            page-position="top"
            :is-multi-rows-check="false"
            :class="multiplerows[3].isHide?'module_hide':'module_show'"
            :page-loading="false"
            :search-model.sync="fs_SearchModel"
            @QuotePriceDetial="(val)=>{this.QuotePriceDetial(val)}"
            @switch-to-hide="val=>{switchToHide(val, 3)}"
          ></pub-stock-friend>
        </el-tab-pane>

        <el-tab-pane
          v-if="hasVisible('StockVMI')"
          label="供应商库存 (按型号)"
          name="StockVMI"
          height="100%"
        >
          <stock-vmi
            ref="StockVMI"
            :is-fields-search="false"
            :is-mini="true"
            :is-show="true"
            :is-activity="true"
            :height="setHeightType"
            :is-init="false"
            :init-data="false"
            page-position="top"
            :is-multi-rows-check="false"
            :class="multiplerows[4].isHide?'module_hide':'module_show'"
            :page-loading="false"
            :search-model.sync="vs_SearchModel"
            @QuotePriceDetial="(val)=>{this.QuotePriceDetial(val)}"
            @switch-to-hide="val=>{switchToHide(val, 4)}"
          ></stock-vmi>
        </el-tab-pane>

        <el-tab-pane
          label="询价记录 (按型号)"
          name="StkInquireRecord"
          height="100%"
          v-if="hasVisible('StkInquireRecord')"
        >
          <znl-stk-inquire-record
            header="询价记录"
            :searchModel="inq_SearchModel"
            :is-fields-search="false"
            ref="StkInquireRecord"
            :is-show-grid="false"
            page-position="top"
            :height="setHeightType"
            :hasSearchCondition="true"
            :is-multi-rows-check="false"
            :is-init="false"
            :is-show-operate="true"
            :init-data="false"
            :is-activity="true"
            :is-mini="true"
            :is-adopt="false"
            :is-analyzer="true"
            :page-loading="false"
            @QuotePriceDetial="(val)=>{this.QuotePriceDetial(val)}"
            :class="multiplerows[5].isHide?'module_hide':'module_show'"
            @switch-to-hide="val=>{switchToHide(val, 5)}"
          ></znl-stk-inquire-record>
        </el-tab-pane>

        <el-tab-pane
          label="报价记录 (按型号)"
          name="BomMastLineSelect"
          height="100%"
          v-if="hasVisible('BomMastLineSelect')"
        >
          <znl-quote-record
            ref="BomMastLineSelect"
            :is-show-btn="true"
            :height="setHeightType"
            :class="multiplerows[10].isHide?'module_hide':'module_show'"
            :searchModel="quoteRecordSearchModel"
            pagePosition="top"
            @switch-to-hide="val=>{switchToHide(val,10)}"
          ></znl-quote-record>
        </el-tab-pane>

        <el-tab-pane
          label="出库记录 (按型号)"
          name="PastStkOut"
          height="100%"
          v-if="hasVisible('PastStkOut')"
        >
          <stk-out-record
            ref="PastStkOut"
            header="出库记录"
            :is-mini="true"
            :is-show="false"
            :is-show-btn="true"
            :is-activity="true"
            :is-init="false"
            :is-analyzer="true"
            page-position="top"
            :height="setHeightType"
            :init-data="false"
            :searchModel="out_SearchModel"
            :is-multi-rows-check="false"
            :class="multiplerows[6].isHide?'module_hide':'module_show'"
            @switch-to-hide="val=>{switchToHide(val, 6)}"
          ></stk-out-record>
        </el-tab-pane>

        <el-tab-pane
          label="入库记录 (按型号)"
          name="PastStkIn"
          height="100%"
          v-if="hasVisible('PastStkIn')"
        >
          <stk-in-record
            ref="PastStkIn"
            :is-fields-search="false"
            :is-mini="true"
            :is-show="false"
            :is-activity="true"
            :grid-show="gridShow"
            :height="setHeightType"
            :is-init="true"
            :is-show-btn="true"
            :init-data="false"
            page-position="top"
            :is-multi-rows-check="false"
            :page-loading="false"
            :class="multiplerows[7].isHide?'module_hide':'module_show'"
            @switch-to-hide="val=>{switchToHide(val, 7)}"
            :searchModel="in_SearchModel"
          ></stk-in-record>
        </el-tab-pane>
      </el-tabs>
      <!-- </div> -->
      <!-- tab 布局形式 end-->
    </multiple>
  </znl-layout-multiple>
  <!-- row 布局形式 end-->
</template>
<script>

import StockVmi from '@c_modules/Stk/StockVMI_v2'
import ZnlStkInquireRecord from '@c_modules/Stk/StkInquireRecord'
import StkInRecord from '@c_modules/Stk/StkInRecord'
import StkOutRecord from '@c_modules/Stk/StkOutByModel'
import PubStockFriend from '@c_modules/Pub/PubStockFriend'
import EasyQuote from '@c_modules/Quote/EasyQuote'
import StkBatchesSummaryStcok from '@c_modules/Stk/StkBatchesSummaryStock'
import StkStockAllSearchBar from '@c_modules/Stk/StkStockAllSearchBar'

import ZnlQuoteRecord from '@c_modules/Quote/QuoteRecord'
import { mixin as common } from '~assets/scripts/methods/common'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import pageGridConfig from '@c_common/znlGrid/commonComponents/pageGridConfig'
import { switchToLegacyPage } from '~/lib/native'
import { isClient } from "~/lib/runtime";

export default {
  name: 'VStockAll',
  mixins: [common, getCommonDataMixin],
  components: {
    StockVmi,
    ZnlStkInquireRecord,
    StkInRecord,
    StkOutRecord,
    PubStockFriend,
    EasyQuote,
    StkBatchesSummaryStcok,
    StkStockAllSearchBar,
    // ZnlStockPurchase,
    // ZnlStockReserve,
    ZnlQuoteRecord,
    pageGridConfig,
    switchToLegacyPage
  },
  props: {},
  data() {
    return {
      layoutConfig: [],
      multiplerows: [
        {
          hasRight: true,
          isHide: false
        },
        {
          hasRight: true,
          isHide: false
        },
        {
          hasRight: true,
          isHide: false
        },
        {
          hasRight: true,
          isHide: false
        },
        {
          hasRight: true,
          isHide: false
        },
        {
          hasRight: true,
          isHide: false
        },
        {
          hasRight: true,
          isHide: false
        },
        {
          hasRight: true,
          isHide: false
        },
        {
          hasRight: true,
          isHide: false // 采购订货
        },
        {
          hasRight: true,
          isHide: false  // 销售备货
        },
        {
          hasRight: true,
          isHide: false  // 给客户报价记录
        }],
      stkBatchRight: false, // 是否有批次库存模块权限
      stkSummaryRight: { // 是否有汇总库存模块权限
        type: Boolean,
        default: false
      },
      gridShow: false,
      pageData: [],
      pageLoadingMain: true,
      tabState: true,
      entryState: false,
      bs_SearchModel: {
        Model: '',
        isAnalyzer: false,
        MatchOperation: ''
      },
      vs_SearchModel: {
        Model: ''
      },
      inq_SearchModel: {  // 供应商报价记录
        Model: ''
      },
      in_SearchModel: {  // 入库记录
        Model: ''
      },
      out_SearchModel: {  // 出库记录
        Model: ''
      },
      fs_SearchModel: {
        Model: ''
      },
      quoteRecordSearchModel: { // 报价记录
        Model: ''
      },
      isTabsHide: false,
      batchesStockActivity: true,
      summaryStockActivity: true,
      stockSetHeight: 475,
      setHeightType: '100%',
      quoteData: {},
      hmSelectOptions: [],
      activeTabValue: 'first',
      showRecentlySearch: true, // 是否显示最近搜索型号下拉框
      Pattern: true,
      tabObject: {},
      stockTabHeight: '233px',
      activeName: 'PubSStockFriend',
      quotePattern: false,
      pageGridVisible: false,
      pageConfig: {
        menus: [],
        type: ''
      },
      pageconfigsave: {},
      computedheight: 0,
      isErpClient: false
    }
  },
  methods: {
    onSearch(model, isLikeSearch) {
      // 逗号 空格 换行符
      const regex = /,|，|\s+|\n/
      let sklist = (model === null ? '' : model).split(regex)
      let matchOperation = isLikeSearch === true ? 'Like' : 'Equal'

      // 库存查询
      this.bs_SearchModel.Model = model
      this.bs_SearchModel.MatchOperation = matchOperation
      sklist.length > 1 && (this.bs_SearchModel.isAnalyzer = false)

      this.$refs.stkBatchesSummaryStcok.onSearch(this.bs_SearchModel)
      // 子模块查询
      this.tabChangeItem({ Model: model })
    },

    // 库存 行改变事件
    tabChangeItem(row) {
      if (!this.Pattern) {   // 模式一
        this.tiledTypeSearch(row)
      } else {  // 模式二
        this.tabObject = row
        this.tabTypeSearch(row)
      }
    },

    // tab 模式
    tabTypeSearch(row) {
      if (this.activeName === 'PubSStockFriend' && this.fs_SearchModel.Model !== row.Model) {
        !this.multiplerows[3].isHide && (this.fs_SearchModel.Model = row.Model)  // 友商库存
      }
      if (this.activeName === 'StockVMI' && this.vs_SearchModel.Model !== row.Model) {
        !this.multiplerows[4].isHide && (this.vs_SearchModel.Model = row.Model)  // 供应商库存
      }
      if (this.activeName === 'StkInquireRecord' && this.inq_SearchModel.Model !== row.Model) {
        !this.multiplerows[5].isHide && (this.inq_SearchModel.Model = row.Model)  // 供应商报价记录
      }
      if (this.activeName === 'PastStkOut' && this.out_SearchModel.Model !== row.Model) {
        !this.multiplerows[6].isHide && (this.out_SearchModel.Model = row.Model)  // 销售记录
      }
      if (this.activeName === 'PastStkIn' && this.in_SearchModel.Model !== row.Model) {
        !this.multiplerows[7].isHide && (this.in_SearchModel.Model = row.Model) // 采购记录
      }
      if (this.activeName === 'BomMastLineSelect' && this.quoteRecordSearchModel.Model !== row.Model) {
        !this.multiplerows[10].isHide && (this.quoteRecordSearchModel.Model = row.Model) // 报价记录
      }
    },

    // 平铺模式
    tiledTypeSearch(row) {
      if (this.fs_SearchModel.Model !== row.Model) {
        !this.multiplerows[3].isHide && (this.fs_SearchModel.Model = row.Model)  // 友商库存
      }
      if (this.vs_SearchModel.Model !== row.Model) {
        !this.multiplerows[4].isHide && (this.vs_SearchModel.Model = row.Model)  // 供应商库存
      }
      if (this.inq_SearchModel.Model !== row.Model) {
        !this.multiplerows[5].isHide && (this.inq_SearchModel.Model = row.Model)  // 供应商报价记录
      }
      if (this.out_SearchModel.Model !== row.Model) {
        !this.multiplerows[6].isHide && (this.out_SearchModel.Model = row.Model)  // 销售记录
      }
      if (this.in_SearchModel.Model !== row.Model) {
        !this.multiplerows[7].isHide && (this.in_SearchModel.Model = row.Model) // 采购记录
      }
      if (this.quoteRecordSearchModel.Model !== row.Model) {
        !this.multiplerows[10].isHide && (this.quoteRecordSearchModel.Model = row.Model) // 报价记录
      }
    },

    QuotePriceDetial(row) {
      this.quoteData = row
    },
    hasRight(code) {
      return _.some(this.pageData, page => page.code === code)
    },
    switchToHide(isHide, index, Obj) {
      if (index === 1 && this.Pattern) {
        this.entryState = isHide
        if (isHide) {
          if (this.tabState) {
            this.stockTabHeight = (233 - 60) + 'px' // 11
          } else {
            this.stockTabHeight = (this.getWindowHeigth() - 130) + 'px' // 11
          }
        } else {
          if (this.tabState) {
            this.stockTabHeight = 233 + 'px'
          } else {
            this.stockTabHeight = (this.getWindowHeigth() - 130) + 'px' // 11
          }
        }
      }
      if (index === 2 && this.Pattern) {
        this.tabState = isHide
        if (this.entryState) {
          if (!isHide) {
            this.stockTabHeight = (this.getWindowHeigth() - 130 - 38) + (this.quotePattern ? 52 : 0) + 'px'
          } else {
            this.stockTabHeight = (this.getWindowHeigth() - this.stockSetHeight - 104 - 48 - 38) + (this.quotePattern ? 52 : 0) + 'px'
          }
        } else {
          if (!isHide) {
            this.stockTabHeight = (this.getWindowHeigth() - 130) + (this.quotePattern ? 38 : 0) + 'px'
          } else {
            this.stockTabHeight = (this.getWindowHeigth() - this.stockSetHeight - 130) + (this.quotePattern ? 38 : 0) + 'px'
          }
        }
      }
      if (!isHide) {
        this.multiplerows[index].isHide = true
      } else {
        this.multiplerows[index].isHide = false
      }

      if (index === 3) { // 订阅库存
        this.$refs.PubSStockFriend.saveStatus(isHide)
      }
      if (index === 4) { // 供应商库存
        this.$refs.StockVMI.saveStatus(isHide)
      }
      if (index === 5) { // 询价记录
        this.$refs.StkInquireRecord.saveStatus(isHide)
      }
      if (index === 6) { // 销售发货记录
        this.$refs.PastStkOut.saveStatus(isHide)
      }
      if (index === 7) { // 采购入库记录
        this.$refs.PastStkIn.saveStatus(isHide)
      }

      if (index === 10) { // 给客户报价记录
        this.$refs.BomMastLineSelect.saveStatus(isHide)
      }
    },

    // async getPageData () {
    //   let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
    //   return await this.$post(pageUrl, { PageCode: 'VStockAll' })
    // },
    pageSearch() {
      this.tabTypeSearch(this.tabObject)
    },
    // 初始化页面权限
    initRight() {
      this.multiplerows[1].hasRight = this.hasRight('InputQuotedPrice') // 快速报价
      this.multiplerows[3].hasRight = this.hasRight('PubSStockFriend') // 订阅库存
      this.multiplerows[4].hasRight = this.hasRight('StockVMI') // 供应商库存
      this.multiplerows[5].hasRight = this.hasRight('StkInquireRecord') // 询价记录
      this.multiplerows[6].hasRight = this.hasRight('PastStkOut') // 销售发货记录
      this.multiplerows[7].hasRight = this.hasRight('PastStkIn') // 采购入库记录
      this.multiplerows[10].hasRight = this.hasRight('BomMastLineSelect') // 给客户报价记录
      this.stkBatchRight = this.hasRight('StkStock') // 批次库存
      this.stkSummaryRight = this.hasRight('StockSummary') // 汇总库存
      this.showRecentlySearch = this.stkBatchRight // 有批次库存权限，则显示最近搜索下拉框
      // this.pageLoadingMain = false
      if (!this.stkBatchRight) {
        this.pageLoadingMain = false
      }
    },

    async onSaveConfig(configs) {
      // 调用commmon.js 保存页面配置信息
      this.onSavePageConfig(configs, 'VStockAll')
      // this.created()
    },
    // 平铺模式的模块拖动按钮方法
    dragingChange(val) {
      let configs = this.pageconfigsave
      if (val === false) {
        let cfgs = {
          Type: configs.Type,
          Menus: _.map(configs.Menu, item => {
            return {
              name: item.name,
              visible: item.visible,
              title: item.title,
              index: item.index
            }
          })
        }
        this.onSavePageConfig(cfgs, 'VStockAll')
      }
    },
    // 重置页面配置
    async onResetSetting() {
      let newCfg = await this.onResetPageConfig('VStockAll')
      this.layoutConfig = _.sortBy(newCfg.Menu, (item) => { return item.index })
      this.pageConfig.type = newCfg.Type
      this.filterPageConfig()
      this.setInitHeight() // 初始化时设置tab切换的高度
      let initheight = document.getElementsByClassName('el-table__body-wrapper')
      for (let i = 0; i < initheight.length; i++) {
        initheight[i].style.height = 'calc(100% - 28px)'
      }
    },
    hasVisible(code) {
      let visible = false
      _.each(this.layoutConfig, item => {
        if (item.name === code && item.hasRight) {
          visible = item.visible
        }
      })
      return visible
    },
    filterPageConfig() {
      // if (this.pageConfig.type !== 0) { this.Pattern = false }
      _.each(this.layoutConfig, item => {
        item.hasRight = this.hasRight(item.name)
        item.allowDeploy = this.hasRight(item.name)
        // item.allowDeploy = true
        if (item.name === 'StockSummary') {
          item.allowDeploy = item.hasRight = this.hasRight('StockSummary') || this.hasRight('StkStock')
        }
        // 搜索模块所有人都有权限，不能拖动，不能隐藏
        if (item.name === 'SearchComponent' || item.name === 'TabMultiple'
        ) {
          item.hasRight = true
          item.allowDrag = false
          item.allowDeploy = false
        }
        // 库存模块 不可以隐藏
        if (item.name === 'StockSummary') {
          item.allowDeploy = false
        }
        // 38 是 录入报价模块 关闭下的高度
        if (item.name === 'InputQuotedPrice' && !this.hasRight('InputQuotedPrice')) {  // 判断是否有报价录入权限有改变高度
          // 若是平铺形式,则默认库存的高度=483,不自动计算
          if (this.pageConfig.type !== 1) {
            this.stockSetHeight = this.getWindowHeigth() - 233 - 130 + 38
          }
          this.quotePattern = true
          item.hasRight = false
        }
      })
    },
    setInitHeight() {
      this.stockSetHeight = 483
      this.Pattern = false
      if (this.pageConfig.type !== 1) {
        this.Pattern = true
        this.stockSetHeight = this.getWindowHeigth() - 233 - 130
      }
    },
    async oldEdition() {
      await switchToLegacyPage('Stock')
    },
    setPageGird() {
      // this.layoutConfigsss =
      // this.menusList = JSON.parse(JSON.stringify(this.layoutConfig))
      this.pageConfig.menus = this.layoutConfig
      this.pageGridVisible = true
    }
  },
  computed: {
    pageLoading() {
      return this.pageLoadingMain
    }
  },
  async created() {
    console.log('进入库存页面')
    this.isErpClient = isClient();
    // let pageCfgs = await this.onGetPageConfig('VStockAll')
    this.onGetPageConfig('VStockAll', true).then(data => {
      this.pageconfigsave = data
      this.$nextTick(() => {
        this.layoutConfig = data.Menu
        this.pageData = data.RoleMenu
        this.pageConfig.type = data.Type
        this.initRight()
        this.setInitHeight() // 初始化时设置tab切换的高度
        this.filterPageConfig()
      })
    }).catch(e => { })
  },
  mounted() {

  }
}
</script>

<style lang="scss">
$bgcolor: #e6e6e6;
$bgcolor-active: #e6e6e6;
.tab-btnstyle-box.stockall-box {
  min-height: 100px;
  // border-bottom: 5px solid #dbdbdb;
  padding-right: 0;
  padding-bottom: 0px;
  border-bottom: 0px;
  // .znl-content {
  //   border-bottom: none;
  // }
  .el-tabs__content .el-tab-pane {
    border-bottom: none;
  }
  .znl-content .base-flex-grid .el-table .el-table__body-wrapper {
    height: 0 !important;
  }
}
.znl-layout-multiple.stockall-box {
  height: 100% !important;
  .el-tabs__item {
    /*height: 35px;*/
    /*line-height: 35px;*/
  }
  .znl-header-text.bottom-line {
    border-bottom: 0;
  }

  .module_show {
    .znl-content > .isFieldsSearch,
    .znl-header-pagination .el-pagination__jump .el-input {
      background-color: $bgcolor-active;
    }
  }
  .interval {
    height: 5px;
    background-color: $bgcolor;
  }
  .znl-header-text {
    .iconfont {
      font-size: 12px;
    }
  }
  .el-tabs__item {
    /*padding-right: 0px;*/
  }
  .el-tabs__active-bar {
    background-color: #666;
    width: 80px !important;
  }
  .el-tabs__active-bar .is-top {
    width: 83px;
  }
  // .el-table__empty-block {
  //   min-height: 30px;
  // }
  .el-table__body-wrapper {
    height: calc(100% - 28px);
    overflow-x: auto;
  }
  .el-tabs .el-tabs__header {
    display: block;
  }
  // .znl-layout-multiple_scroll {
  //   padding-right: 5px;
  // }
}
</style>

