<template>
  <!-- 采购订货 -->
  <div class="module-wrapper m-buyer-manage">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      :page-index="pageIndex"
      :total-count="totalCount"
      :show-title-menus="true"
      box-class="m-buyer-manage"
      :header-menus="getResetTableHeaderMenu('StkStockPurchase')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          placeholder="型号"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.Model"
          type="text"
        ></znl-input>

        <znl-input
          form-type="input"
          placeholder="供应商名称"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="searchFields.SupplierName"
          :border="true"
          type="text"
        ></znl-input>

        <znl-input
          form-type="select"
          placeholder="订货人"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :select-options="selectOptionsPurchaser"
          v-model="searchFields.Purchaser"
          :border="true"
          type="text"
        ></znl-input>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="订货时间起"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          v-model="searchFields.StartPurchaseTime"
          :clearable="true"
        ></znl-input>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="订货时间止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          v-model="searchFields.EndPurchaseTime"
          inp-perc="100%"
        ></znl-input>

        <el-checkbox v-model="IsCompleted" @change="SetCompletedDefault" size="small">完成</el-checkbox>
        <el-checkbox v-model="IsUnCompleted" @change="SetUnCompletedDefault" size="small">未完成</el-checkbox>

        <znl-button class="minor-self-btn" style-type="mac" :width="50" @click="onSearch()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button>
        <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="60"
          @click="onStkReverseAdd()"
          v-if="this.hasRes('Add')"
        >
          <i class="iconfont icon-add_btn_ic"></i>
          <span>增加</span>
        </znl-button>

        <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="60"
          @click="onstkPurchaseImport()"
          v-if="this.hasRes('ImportPurchase')"
        >
          <i class="iconfont icon-leading-in-wh_btn_ic"></i>
          <span>导入</span>
        </znl-button>

        <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="63"
          v-if="this.hasRes('Export')"
          :btns="printznlbtns"
          @click="onExport()"
          tip="按搜索条件"
        >
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
      </div>
    </znl-table>

    <StkStockPurchaseAdd
      v-if="stkPurchaseAddVisible"
      :visible="stkPurchaseAddVisible"
      ref="StkStockPurchaseAdd"
      :isRefresh="onrefreshAdd"
      :PurchaseGUID="stkPurchaseGUID"
      @confirm="onChangeEnd"
      @close="()=>{stkPurchaseAddVisible=false}"
    ></StkStockPurchaseAdd>
    <stk-in-edit
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      edit-type="edit"
      @confirm="stkInEditVisible=false"
      @save-success="onSaveSuccess"
      @close="stkInEditVisible=false"
    ></stk-in-edit>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="setConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>

    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </div>
</template>
<script>

import StkStockPurchaseAdd from '@c_modules/Stk/StkStockPurchaseAdd'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkInEdit from '@c_modules/Stk/StkInEdit'
import ExportColumn from '@c_modules/Crm/setExportColumn'
import { exportData, erpTableSetHandler } from '@scripts/methods/common'

const CONFIG = {
  configURL: 'StkStockPurchase/GetConfig',
  saveConfigURL: 'StkStockPurchase/SaveConfig',
  searchURL: 'StkStockPurchase/Search',
  onDeleteURL: 'StkStockPurchase/DeleteStkPurchase',
  checkAllowImport: 'StkStock/checkAllowImport', // 检查是否允许导入
  DropDownURL: 'DictItem/GetItemValue',
}
export default {
  name: 'StockBuyerManage',
  config: CONFIG,
  mixins: [getCommonDataMixin, exportData, erpTableSetHandler],
  components: {
    StkStockPurchaseAdd,
    StkInEdit,
    ExportColumn
  },
  data() {
    return {
      stkInEditVisible: false,
      stkPurchaseGUID: '', // 需要传递给编辑页面的ID
      stkPurchaseAddVisible: false, // 是否显示备货编辑页面
      onrefreshAdd: true, // 刷新添加页面
      columns: [],
      itemSource: [],
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      resourceList: [],
      searchFields: {
        Model: '',
        SupplierName: '',
        Purchaser: '',
        StartPurchaseTime: '',
        EndPurchaseTime: '',
        Packaging: '',
        Brand: '',
        MakeYear: ''
      },
      IsCompleted: false,
      IsUnCompleted: true,
      selectOptionsPurchaser: [],
      loading: false,
      printznlbtns: [
        {
          name: '设置导出列',
          click: () => {
            this.showExportColumn()
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setSaveConfigURL: '',
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    }
  },
  async created() {
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function (config) {
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('stkStockPurchase', CONFIG.configURL)
      }
      if (this.hasValue(config.ResourceList) && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code)
      }
      // 订货员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index
      ) {
        return { key: obj.value, value: obj.value }
      })
      this.selectOptionsPurchaser = UserAllowUsers

      let tempConfig = []
      if (this.hasRes('StorageIn')) {
        tempConfig.push({
          width: 30,
          type: 'button',
          title: '入',
          btnTxt: '入',
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: row => {
            this.onStkInadd(row)
          }
        })
      }
      if (this.hasRes('Edit')) {
        tempConfig.push({
          width: 30,
          type: 'button',
          title: '改',
          btnTxt: '改',
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: row => {
            this.onOpenAddDialog(row.SPGUID)
          }
        })
      }
      if (this.hasRes('Delete')) {
        tempConfig.push({
          width: 30,
          type: 'button',
          title: '删',
          btnTxt: '删',
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: row => {
            this.onDelete([row.SPGUID])
          }
        })
      }
      let columns = config.ColumnConfigs
      this.defaultConfig = config
      if (!this.IsMainAccount) {
        this.setHideColumns(columns)
      }
      columns = tempConfig.concat(columns)
      this.columns = columns
      this.$refs.table.init()
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      if (this.initData) {
        await this.onBindData()
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    // 列的初始化
    setHideColumns(columns) {
      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode('NoSeeSupplier')
      _.remove(columns, item => {
        if (noSeeSupplier && item.BindField === 'SupplierName') {
          return true;
        }
      })

      // 看备注1
      if (!this.hasRes('ViewRemark1')) {
        _.remove(columns, item => {
          if (item.BindField === 'Remark1') {
            return true;
          }
        })
      }
      // 看备注2
      if (!this.hasRes('ViewRemark2')) {
        _.remove(columns, item => {
          if (item.BindField === 'Remark2') {
            return true;
          }
        })
      }
      // 看备注3
      if (!this.hasRes('ViewRemark3')) {
        _.remove(columns, item => {
          if (item.BindField === 'Remark3') {
            return true;
          }
        })
      }
    },

    async onBindData(pageIndex = 1) {
      this.loading = true
      let data = this.getSearchWhere(pageIndex);
      return await this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      }).finally(() => {
        this.$emit('page-loading', false)
        setTimeout(() => {
          this.loading = false
        }, 800);
      })
    },
    getSearchWhere(pageIndex) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          IsCompleted: this.IsCompleted,
          IsUnCompleted: this.IsUnCompleted
        }
      )
      return data
    },
    // 导出
    async onExport() {
      this.loading = true
      this.loading = (await this.exportData("StkStockPurchase", this.getSearchWhere(1), this.columns, "采购订货"))
    },
    SetCompletedDefault() {
      if (this.IsCompleted) {
        this.IsUnCompleted = false
      }
    },
    SetUnCompletedDefault() {
      if (this.IsUnCompleted) {
        this.IsCompleted = false
      }
    },
    onHeadSearch(params) {
      if (this.hasValue(params)) {
        this.searchFields.Model = params.Model
        this.searchFields.Packaging = params.Packaging
        this.searchFields.Brand = params.Brand
        this.searchFields.MakeYear = params.MakeYear
      }
      return this.onBindData(1)
    },
    onSearch(params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      return this.onBindData(1)
    },
    onStkReverseAdd() {
      this.onOpenAddDialog()
    },
    onOpenAddDialog(swID = '') {
      // 传值id值为0，显示页面
      this.stkPurchaseAddVisible = true // 显示页面
      this.onrefreshAdd = !this.onrefreshAdd
      this.stkPurchaseGUID = swID
    },
    onStkReserveEdit(swid) {
      this.onOpenAddDialog(swid)
    },
    onstkPurchaseImport: async function () {
      // 导入
      // 先做验证，库存量已超出最大库存量，不能再导入
      let IsAllowImport = await this.$post(CONFIG.checkAllowImport)
      this.$nextTick(function () {
        if (!IsAllowImport) {
          return this.$message({
            message: '您的库存量已超出最大库存量，不能再导入',
            type: 'error'
          })
        }
        this.importData({ importType: 'stkpurchase' })
        this.clearSelectedRows()
      })
    },

    onStkInadd(row) {
      let order = { StkPurchaseGuid: row.SPGUID }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true
      })
    },
    onDelete(SRGuids) {
      if (SRGuids.length > 0) {
        this.$confirm('删除确认, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$post(CONFIG.onDeleteURL, { SPGUIDS: SRGuids }, data => {
            if (data > 0) {
              this.$message({ message: '删除成功', type: 'success' })
              this.onBindData(this.pageIndex)
            } else {
              return this.$message({ message: '删除失败', type: 'error' })
            }
          })
        })
      }
    },
    hasRes(code) {
      return _.includes(this.resourceList, code)
    },
    clearSelectedRows() {
      this.$refs.table.cleanAllCheck()
    },

    onChangeEnd(val) {
      val && (this.stkPurchaseAddVisible = false)
      this.onBindData(this.pageIndex)
    },
    // 采购单入库刷新问题
    onSaveSuccess() {
      this.onBindData(this.pageIndex)
    },
    isClient() {
      return window.cefQuery !== undefined
    },
    showExportColumn() {
      this.addColumnsList = this.columns
      this.setConfigColumns = this.defaultConfig
      this.setSaveConfigURL = CONFIG.saveConfigURL
      this.setExportColumnVisible = true
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StkStockPurchase", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("StkStockPurchase", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("StkStockPurchase", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StkStockPurchase")
    }
  }
}
</script>

<style lang="scss">
</style>
