<template>
  <!-- 销售备货 -->
  <znl-gridmodule ref="flexGrid"
    layoutType="other"
    height="100%"
    :header="header"
    :is-activity="isActivity"
    :grid-show="gridShow"
    :columns="columns"
    :pagination-position="pagePosition"
    :item-source="itemSource"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :is-multi-rows-check-fixed="false"
    :summary-columns="summaryColumns"
    :is-fields-search="isShowFiedsSearch"
    :is-multi-rows-check="true"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :users="$store.state.users"
    :has-znl-actions="false"
    :on-init="onInit"
    :loading="loading"
    :is-show-rightbtns="false"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :on-save-setting="onSaveSetting"
    :on-save-config='onSaveConfig'
    :on-reset-setting="onResetSetting"
    :on-page-changed="onBindData"
    @page-changed="onBindData"
    @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
    @selection-changed="d=>{this.stkstockSelectItem=d,$emit('selection-changed',d)}">
    <div slot="heading-slot">
      <znl-input form-type="input"
        placeholder="型号"
        :clearable="true"
        @keyup.enter.native="onSearch()"
        width='120px'
        size="mini"
        layout="left"
        inp-perc="100%"
        :border="true"
        v-model="searchFields.Model"
        type="text">
      </znl-input>

      <znl-input form-type="input"
        placeholder="客户名称"
        :clearable="true"
        @keyup.enter.native="onSearch()"
        width='120px'
        size="mini"
        layout="left"
        inp-perc="100%"
        v-model="searchFields.CustomerName"
        :border="true"
        type="text">
      </znl-input>

      <znl-input form-type="select"
        placeholder="备货人"
        :clearable="true"
        @keyup.enter.native="onSearch()"
        width='120px'
        size="mini"
        layout="left"
        inp-perc="100%"
        :select-options="selectOptionsReserver"
        v-model="searchFields.Reserver"
        :border="true"
        type="text">
      </znl-input>

      <znl-input form-type="datepicker"
        :disabled="false"
        placeholder="备货时间起"
        value-format="yyyy-MM-dd"
        size="mini"
        layout="left"
        width='100px'
        :border="true"
        v-model="searchFields.StartReserveTime"
        :clearable="true">
      </znl-input>

      <znl-input form-type="datepicker"
        :disabled="false"
        placeholder="备货时间止"
        value-format="yyyy-MM-dd"
        size="mini"
        layout="left"
        width='100px'
        :border="true"
        :clearable="true"
        v-model="searchFields.EndReserveTime"
        inp-perc="100%">
      </znl-input>

      <el-checkbox v-model="isUnReserve"
        @change="SetUnReserveDefault"
        size="small">未备</el-checkbox>
      <el-checkbox v-model="isReserve"
        @change="SetReserveDefault"
        size="small">已备</el-checkbox>
      <el-checkbox v-model="isStkOut"
        size="small">已发货</el-checkbox>

      <znl-button style-type='mac' class="box-left-btn search-btn" :height='22' :width='50' @click="onSearch()">
        <i class="iconfont icon-search_ic"></i><span>搜索</span>
      </znl-button>

      <znl-button class='minor-self-btn'
        style-type="mac"
        :height='22'
        :width='50'
        @click="onStkReverseAdd()"
        v-if="this.hasRes('Add')">
        <i class="iconfont icon-add_btn_ic"></i><span>增加</span>
      </znl-button>

      <znl-button class='minor-self-btn'
        style-type="mac"
        :height='22'
        :width='74'
        @click="stkReserve()"
        v-if="this.hasRes('SignReserve')">
        <i class="iconfont icon-labelling_btn"></i><span>标记备货</span>
      </znl-button>

      <znl-button class='minor-self-btn'
        style-type="mac"
        :height='22'
        :width='50'
        @click="onstkReserveImport()"
        v-if="this.hasRes('ImportReserve')">
        <i class="iconfont icon-leading-in-wh_btn_ic"></i><span>导入</span>
      </znl-button>

      <znl-button class='minor-self-btn'
        style-type="mac"
        :height='22'
        :width='63'
        :btns='printznlbtns'
        @click="onstkReserveExport()"
        v-if="true">
        <i class="iconfont icon-leading-out-wh_btn_i"></i><span>导出</span>
      </znl-button>

    </div>
    <div>
      <StkStockReserveAdd ref='StkStockReserveAdd'
        v-if="stkReserveAddVisible"
        :Srguid="stkReserveGUID"
        :visible="stkReserveAddVisible"
        :isRefresh="onrefreshAdd"
        @confirm="onChangeEnd"
        @close="()=>{stkReserveAddVisible=false}"></StkStockReserveAdd>
      <stk-out-edit :title="StkOutAddTitle"
        :editvisible="stkOutEditVisible"
        v-if="stkOutEditVisible"
        :edit-type="editType"
        @confirm="stkOutEditVisible=false"
        @close="stkOutEditVisible=false"></stk-out-edit>
    </div>
    <export-column :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="setConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}">
    </export-column>

    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </znl-gridmodule>
</template>
<script>
import { mixin as znlMethodsMinix } from '@c_common/znlGrid/scripts/znlMethods'
import StkStockReserveAdd from '@c_modules/Stk/StkStockReserveAdd'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'
import ExportColumn from '@c_modules/Crm/setExportColumn'
import * as urls from '~/lib/urls'
const CONFIG = {
  configURL: 'StkStockReserve/GetConfig',
  saveConfigURL: 'StkStockReserve/SaveConfig',
  resetConfigURL: 'StkStockReserve/ResetConfig',
  default: null,
  searchURL: 'StkStockReserve/Search',
  reserveURL: 'StkStockReserve/ReserveCompleted',
  checkAllowImport: 'StkStock/checkAllowImport', // 检查是否允许导入
  onDeleteURL: 'StkStockReserve/DeleteStkReserve',
  DropDownURL: 'DictItem/GetItemValue',
  apiBaseUrl: urls.getErpApiBaseUrl()
}
export default {
  name: 'StkStockReserve',
  config: CONFIG,
  mixins: [znlMethodsMinix, getCommonDataMixin],
  components: {
    StkStockReserveAdd,
    StkOutEdit,
    ExportColumn
  },
  data () {
    return {
      isShowFiedsSearch: false,
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      Status: true,
      stkReserveGUID: '', // 需要传递给编辑页面的ID
      stockReserveLine: '', // 传递给出库页面的详情内容
      inputModel: '', // 需要查询的型号
      inputBrand: '', // 需要查询的品牌
      inputCustomer: '', // 需要查询的客户名称
      UnReserve: true, // 未备货
      AlReserve: false, // 已备货
      AlOut: false, // 已出库

      stkReserveIds: [], // 需要批量操作的ID集合
      stkReserveSelectItem: [], // 选中的数据
      stkReserveAddVisible: false, // 是否显示备货编辑页面
      stkOutEditVisible: false, // 是否显示发货单编辑页面
      showOutAddVisible: false, // 是否显示出库页面
      onrefreshAdd: true, // 刷新添加页面
      refershReserve: true, // 根据备货刷新
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {
        Model: '',
        CustomerName: '',
        Reserver: '',
        StartReserveTime: '',
        EndReserveTime: ''
      },
      isStkOut: false, // 是否发货
      isUnReserve: true, // 未备货
      isReserve: false, // 已备货

      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      Size: 30,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      gridShow: false,
      resourceList: [],
      selectOptionsReserver: [],
      loading: false,
      printznlbtns: [
        {
          name: '设置导出列',
          // iconName: 'icon-table_set_btn_n',
          click: () => {
            this.showExportColumn()
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setSaveConfigURL: '',
      searchCondition: {}
    }
  },
  computed: {
    pageSize: {
      get () {
        return this.isMini ? 5 : this.Size
      },
      set (val) {
        this.Size = val
      }
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    isMini: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: () => {
        return '销售备货'
      }
    },
    isInit: Boolean,
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    },
    pagePosition: String,
    isActivity: Boolean,
    height: {
      type: String,
      default: 'auto'
    },
    isRefresh: Boolean // 刷新页面
  },
  watch: {
    UnReserve (value) {
      this.pageIndex = 1
      this.$refs.flexGrid.clearSelection()
      this.$refs.flexGrid.search()
    },
    AlReserve (value) {
      this.pageIndex = 1
      this.$refs.flexGrid.clearSelection()
      this.$refs.flexGrid.search()
    },
    AlOut (value) {
      this.pageIndex = 1
      this.$refs.flexGrid.clearSelection()
      this.$refs.flexGrid.search()
    }
  },
  methods: {
    onInit: async function (config) {
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('stkStockReserve', CONFIG.configURL)
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      //  // 获取模块打开或者关闭的状态
      //   this.GetStatus(config.Status)
      this.resourceList = config.ResourceList.map(item => item.Code)
      // 列的初始化
      this.settingColumns(columns)

      // 操作按钮初始化
      this.settingActionBtn(columns)
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize

      if (this.initData) {
        await this.this.searchCondition()
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    // 列的初始化
    settingColumns (columns) {
      if (
        _.find(columns, function (column) {
          return column.binding === 'ReserveStateStr'
        })
      ) {
        _.extend(
          _.find(columns, function (column) {
            return column.binding === 'ReserveStateStr'
          }),
          {
            dataMap: [
              { key: '未备货,未出库', value: '未备货,未出库' },
              { key: '未备货,已出库', value: '未备货,已出库' },
              { key: '已备货,未出库', value: '已备货,未出库' },
              { key: '已备货,已出库', value: '已备货,已出库' }
            ],
            showDropDown: true
          }
        )
      }
      // 单位
      let QtyUnitDropDown = this.selectOptionsQtyUnit
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )
      if (
        _.find(columns, function (column) {
          return column.binding === 'IsCompleteLabel'
        })
      ) {
        _.extend(
          _.find(columns, function (column) {
            return column.binding === 'IsCompleteLabel'
          }),
          {
            dataMap: [{ key: '0', value: '否' }, { key: '1', value: '是' }],
            showDropDown: true
          }
        )
      }
      if (
        _.find(columns, function (column) {
          return column.binding === 'IsLockLabel'
        })
      ) {
        _.extend(
          _.find(columns, function (column) {
            return column.binding === 'IsLockLabel'
          }),
          {
            dataMap: [{ key: '0', value: '否' }, { key: '1', value: '是' }],
            showDropDown: true
          }
        )
      }

      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (
        obj,
        index
      ) {
        return { key: obj.value, value: obj.value }
      })
      this.selectOptionsReserver = UserAllowUsers
      // 备货员
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Reserver'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'LastStkInReserver'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )

      let hideCols = ',CustomerName,Contact,Telephone,Email,Address,QQ,'
      _.each(columns, (item, index) => {
        if (
          this.getSpecialResourceByCode('NoSeeCustomer') &&
          hideCols.indexOf(item.binding) > 0
        ) {
          item.hasRole = false
        }
      })
    },
    // 操作按钮初始化
    settingActionBtn (columns) {
      !this.hasRes('Delete')
        ? ''
        : columns.unshift({
          name: 'delete',
          dataType: 5,
          binding: 'delete',
          width: 30,
          header: '删',
          visible: true,
          allowSorting: false,
          isFieldsSearch: false,
          isSystem: true,
          isReadOnly: true,
          isFixed: false,
          buttons: [
            {
              content: '删',
              className: 'znl-table-btn-success',
              when: true,
              click: row => {
                let guid = []
                guid.push(row.SRGUID)
                this.onDeleteOne(guid, row.Model, row.IsLockStk)
              }
            }
          ]
        })
      this.hasRes('Edit') && !this.onlySalesPurchaseOrder ? columns.unshift({
          name: 'edit',
          dataType: 5,
          binding: 'edit',
          width: 30,
          header: '改',
          visible: true,
          allowSorting: false,
          isFieldsSearch: false,
          isSystem: true,
          isReadOnly: true,
          isFixed: false,
          buttons: [
            {
              content: '改',
              className: 'znl-table-btn-success',
              when: true,
              click: row => {
                this.onOpenAddDialog(row.SRGUID)
              }
            }
          ]
        }) : ''

      this.hasRes('StorageOut') && !this.onlySalesPurchaseOrder ? columns.unshift({
          name: 'stkOut',
          dataType: 5,
          binding: 'stkOut',
          width: 30,
          header: '出',
          visible: true,
          allowSorting: false,
          isFieldsSearch: false,
          isSystem: true,
          isReadOnly: true,
          isFixed: false,
          buttons: [
            {
              content: '出',
              className: 'znl-table-btn-success',
              when: true,
              click: row => {
                this.onStkOut(row)
              }
            }
          ]
        }) : ''
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onBindData (pageIndex = 1) {
      this.loading = true
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          IsStkOutCompleted: this.isStkOut, // 是否发货
          IsUnReserve: this.isUnReserve, // 未备货
          IsReserve: this.isReserve // 已备货
        }
      )
      this.searchCondition = data
      this.stkWarningSelectItem = []
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.loading = false
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    SetReserveDefault () {
      if (this.isReserve) {
        this.isUnReserve = false
      }
    },
    SetUnReserveDefault () {
      if (this.isUnReserve) {
        this.isReserve = false
      }
    },
    onHeadSearch (params) {
      this.searchFields.Model = params.Model
      return this.onBindData(1)
    },
    onSearch (params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      return this.onBindData(1)
    },
    onSelectionChange (currentItem) {
      this.$emit('selection-change', currentItem)
    },
    onStkReverseAdd () {
      this.onOpenAddDialog()
    },
    onOpenAddDialog (SrGUID = '') {
      // 传值id值为0，显示页面
      this.stkReserveAddVisible = true // 显示页面
      this.onrefreshAdd = !this.onrefreshAdd
      this.stkReserveGUID = SrGUID
    },
    onstkReserveImport: async function () {
      // 导入
      // 先做验证，库存量已超出最大库存量，不能再导入
      let IsAllowImport = await this.$post(CONFIG.checkAllowImport)
      this.$nextTick(function () {
        if (!IsAllowImport) {
          return this.$message({
            message: '您的库存量已超出最大库存量，不能再导入',
            type: 'error'
          })
        }
        this.importData({ importType: 'stkreserve' })
        this.clearSelectedRows()
      })
    },
    onstkReserveExport () {
      this.loading = true
      let exportColumns = []
      let selectedColumns = _.filter(this.columns, function (item, index) {
        return (
          !item.isSystem &&
          (_.isUndefined(item.hasRole) || item.hasRole) &&
          item.IsExprot
        )
      })
      _.each(selectedColumns, item => {
        exportColumns.push({
          Name: item.binding,
          DisplayName: item.header
        })
      })
      let postData = {
        Name: 'StkStockReserve',
        Conditions: this.searchCondition,
        Columns: exportColumns
      }
      let that = this
      this.$post('export/excel', postData, (data, logic) => {
        if (logic.code === 200) {
          let downloadUrl = `${CONFIG.apiBaseUrl}export/download?id=` + data.Id
          if (that.isClient()) {
            that.clientDownLoadFile(data.Id, downloadUrl)
          } else {
            window.open(downloadUrl) // 下载文件
          }
          that.loading = false
        }
      }).finally(e => {
        this.loading = false
      })
    },
    onSelectRowGuid () {
      let guid = []
      let rows = this.getCheckedKeys()
      if (rows.length === 0) {
        rows = this.onSelectRow()
      }
      if (rows[0] + '' === 'undefined') {
        // 选中行
        return this.$message({
          message: '请至少选择一条数据进行操作',
          type: 'error'
        })
      }
      if (rows.length > 0) {
        _.each(rows, row => {
          guid.push(row.SrGUID)
        })
      }
      return guid
    },
    getCheckedKeys () {
      var keys = this.$refs.flexGrid.getCheckedKeys()
      return keys
    },
    onSelectRow () {
      // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()

      if (_.isUndefined(rows[0])) {
        if (
          _.isUndefined(this.stkWarningSelectItem) ||
          this.stkWarningSelectItem.length === 0
        ) {
          // 选中行
          this.$message({
            message: '请至少选择一条数据进行操作',
            type: 'error'
          })
        } else {
          guid.push(this.stkWarningSelectItem.SRGUID)
        }
      } else {
        guid = _.map(rows, item => {
          return item.SRGUID
        })
      }
      return guid
    },
    onStkReserveEdit () {
      let SRGuids = this.onSelectRow()
      if (SRGuids.length > 1) {
        return this.$message({
          message: '只能选中一条数据进行编辑',
          type: 'error'
        })
      } else if (SRGuids.length === 0) {
        return this.$message({ message: '请选择一条数据', type: 'error' })
      } else {
        this.onOpenAddDialog(SRGuids[0])
      }
    },
    onChangeEnd (val) {
      val && (this.stkReserveAddVisible = false)
      this.onBindData(this.pageIndex)
    },
    async onDelete (SRGuids, showConfirm = true) {
      if (SRGuids.length > 0) {
        // let ok
        // if (showConfirm) {
        //   ok = await this.$confirm('删除确认, 是否继续?', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   })
        // }
        // if (ok) {
        this.$post(CONFIG.onDeleteURL, { SRGUIDS: SRGuids }, data => {
          if (data > 0) {
            this.$message({ message: '删除成功', type: 'success' })
            this.onBindData(this.pageIndex)
            this.$refs.flexGrid.clearSelection()
          } else {
            return this.$message({ message: '删除失败', type: 'error' })
          }
        })
        // }
      }
    },

    onStkOutadd (rows) {
      // 批量销售出库传值方法
      this.stockLinePL = rows
      let order = { stockLinePL: rows, checkQty: true }
      this.$store.commit('setParamOrder', order)
      this.$store.state.visibled = false
      this.$nextTick(function () {
        this.stkOutEditVisible = true
      })
    },

    clearSelectedRows () {
      this.$refs.flexGrid.clearSelection()
    },
    stkOutAddRows () {
      // 批量销售出库
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()

      if (_.isUndefined(rows[0])) {
        if (this.stkWarningSelectItem.length === 0) {
          // 选中行
          return this.$message({
            message: '请至少选择一条数据进行操作',
            type: 'error'
          })
        } else {
          guid.push(this.stkWarningSelectItem.SRGUID)
        }
      } else {
        guid = _.map(rows, item => {
          return item.SRGUID
        })
      }
      // 判断选中行的客户是否一致
      var lastCustomerName = rows[0].CustomerName
      var isDiff = false
      _.each(rows, r => {
        if (lastCustomerName !== r.CustomerName) {
          isDiff = true
        }
      })
      if (isDiff) {
        return this.$message({
          message: '请选择相同客户的数据进行操作',
          type: 'error'
        })
      }

      let SRGUID = guid

      if (SRGUID.length >= 1) {
        this.onStkOutadd(SRGUID)
        this.clearSelectedRows()
      } else {
        return this.$message({ message: '请选择要出库的数据', type: 'error' })
      }
    },
    stkReserve () {
      // 标记为已备货
      let SRGuids = this.onSelectRow()
      if (SRGuids.length < 1) {
        return this.$message({ message: '请选择要标记的数据', type: 'error' })
      } else {
        this.$post(CONFIG.reserveURL, { SRGUIDS: SRGuids }, data => {
          if (data > 0) {
            this.$message({ message: '标记成功', type: 'success' })
            this.onBindData(this.pageIndex)
            this.$refs.flexGrid.clearSelection()
          } else {
            return this.$message({ message: '标记失败', type: 'error' })
          }
        })
      }
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stkStockReserve')
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, CONFIG.default)
      )
    },
    async onDeleteOne (guid, model, isLockStk) {
      // if (isLockStk) {
      //   this.$message({ message: '本条型号【' + model + '】已被锁定，无法删除', type: 'error' })
      //   return false
      // }
      // 单条删除
      let ok = await this.$confirm(
        '确定要删除本条型号【' + model + '】为的备货吗?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
      if (ok) {
        this.onDelete(guid, false)
      }
    },
    onStkOut (row) {
      // 出库
      this.stockReserveLine = row
      let order = { stockReserveLine: [row] }
      this.$store.commit('setParamOrder', order)
      this.$store.state.visibled = false
      this.$nextTick(function () {
        this.stkOutEditVisible = true
      })
    },
    // 获取模块状态
    // GetStatus (val) {
    //   this.Status = val !== 0
    //   this.Status ? this.$refs.flexGrid.toggleTableShow(true)
    //   : this.$refs.flexGrid.toggleTableShow(false)
    // },
    // 保存模块状态
    saveStatus (val) {
      let Status = val ? 1 : 0
      let defaultConfig = this.defaultConfig
      if (defaultConfig !== undefined && Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    },
    hasRes (code) {
      // 权限
      return _.includes(this.resourceList, code)
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stkStockReserve')
      await this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.defaultConfig)
      )
    },
    async onResetSetting () {
      localStorage.removeItem('stkStockReserve')
      await this.$post(this.$options.config.resetConfigURL)
    },
    isClient () {
      return window.cefQuery !== undefined
    },
    showExportColumn () {
      this.setExportColumnVisible = true
      this.addColumnsList = this.columns
      this.setConfigColumns = this.defaultConfig
      this.setSaveConfigURL = this.$options.config.saveConfigURL
    },
    clientDownLoadFile (fileName, url) {
      if (!url) {
        return
      }
      this.downFile({ FileName: '销售备货.xls', FileUrl: url }, function (res) {
        // console.log(res)
        this.dialogShow = false
      })
    }
  },
  mounted () {
    // this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>
<style lang="scss">
</style>
