<template>
  <znl-layout-topbottom
    top-height="60%"
    :page-loading="hasPageLoading && $store.state.pageLoading && pageLoading"
    znl-loading-text="页面加载中,请稍后..."
  >
    <znl-stk-out
      slot="znlTop"
      class="znlTop"
      :init-data="true"
      @selection-changed="stkOutSelectionChange"
      @page-loading="(val) => {pageLoading = val}"
      ref="StkOut"
    ></znl-stk-out>
    <znl-stk-out-line ref="stkOutLine" slot="znlBottom" class="znlBottom"></znl-stk-out-line>
  </znl-layout-topbottom>
</template>

<script>
import ZnlStkOut from '@c_modules/Stk/StkOutOrder'
import ZnlStkOutLine from '@c_modules/Stk/StkOutOrderLine'

export default {
  name: 'VOut',
  components: {
    ZnlStkOut,
    ZnlStkOutLine
  },
  watch: {
    pageLoading (val) {
      // console.log(this.$refs.znlStkOut.$refs.flexGrid.$refs.baseFlexGrid.hasBaseFlexGrid)
      if (!val) {
        this.$el.classList.add('finishLoading')
      } else {
        this.$el.classList.remove('finishLoading')
      }
    }
  },
  data () {
    return {
      gridShow: false,
      stkOut: {
        searchModel: {
          data: {},
          extra: {}
        },
        lineList: {}
      },
      pickerOptions0: {
      },
      stkOutLine: {
        searchModel: {
        }
      },
      pageData: [],
      pageLoading: true,
      hasPageLoading: true
    }
  },
  methods: {
    stkOutSelectionChange (item) {
      this.$refs.stkOutLine.onHeadSearch(item.StkOutGUID)
    },
    lineLoaded (rows) {
      this.stkOut.lineList = rows
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    },
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, { PageCode: 'VOut' })
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      if (!this.hasRight('StkOut')) {
        this.hasPageLoading = false
      }
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss">
</style>
