<template>
  <div class="stock-all-search-box" >
    <div class="znl-component-input m-accurate-checbox">
    <znl-input
       form-type="autocomplete"
      :fetch-suggestions="querySearch"
      :disable="false"
      placeholder="多个型号搜索以逗号隔开"
      size="mini"
      layout="left"
      width="250px"
      :height="36"
      class="box_input_checkbox"
      ref="autocompleteRef"
      :clearable="true"
      :border="true"
      :is-upper-case="true"
      v-model="searchKeys"
      @keyup.enter.native="e => {onSearch(), $refs.autocompleteRef.blur()}"
      type="text">
    </znl-input>
      <el-checkbox class="accurate-checbox" size="small" @change="onChange" v-model="accurateType">精确</el-checkbox>
    </div>

    <znl-button class="box-left-btn" :height='22' style-type="main" @click="onSearch">
      <span> <i class="iconfont icon-search_ic"></i>搜索</span>
    </znl-button>

    <znl-input size="mini"
      name="hmselect"
      width='160px'
      layout="left"
      form-type="select"
      popper-class="popperClass"
      :clearable="true"
      :border="true"
      v-model="hmSelectVal"
      :select-options="hmSelectOptions"
      @change="hmSelectChange"
      placeholder="最近搜索型号">
    </znl-input>
  </div>

</template>

<script>
import { switchToLegacyPage } from '~/lib/native'
export default {
  name: 'MStkStockAllSearchBar',
  components: {
    switchToLegacyPage
  },
  props: {
    showRecentlySearch: false // 是否显示最近搜索型号
  },
  data () {
    return {
      accurateType: false,
      searchKeys: '',
      hmSelectOptions: [],
      hmSelectVal: '',
      startTime: 0,
      endTime: 0,
      isLikeSearch: true,
      baseKeyWords: [],
      currencyKeyWords: [],
      firstPageSize: 100,
      nextPageSize: 20,
      returnSize: 0
    }
  },
  watch: {},
  methods: {
    onSearch () {
      this.$emit('bar-search', this.searchKeys, this.isLikeSearch)
      // if (this.searchKeys !== null && this.searchKeys.length > 0) {
      //   this.hmSelectOptions.unshift({key: Math.random() + '_' + this.searchKeys, value: this.searchKeys + '-5分钟内'})
      // }
      this.getSelectOptions()
    },
    onChange (val) {
      if (val) {
        this.isLikeSearch = false
      } else {
        this.isLikeSearch = true
      }
      // this.$emit('bar-search-type', this.searchKeys, this.isLike)
    },
    async hmSelectChange (sval) {
      this.searchKeys = sval.key.split('_')[1]
      this.onSearch()
    },
    // async getAutoCompleteKeys (preKey, cb) {
    //   this.$post('SearchKeysHistory/GetAutoCompleteKeys', {
    //     AutoCompleteKey: preKey,
    //     PageIndex: 1,
    //     PageSize: this.firstPageSize
    //   }, (data) => {
    //     let resulist = []
    //     _.each(data.ResultList, item => {
    //       resulist.push({
    //         key: item.SLogID,
    //         value: item.KeyWords
    //       })
    //       cb(resulist)
    //     })
    //   })
    // },
    // querySearch (queryString, cb) {
    //   if (queryString.length > 1) {
    //     this.startTime = new Date()
    //     _.delay(() => {
    //       this.endTime = new Date()
    //       if (this.endTime - this.startTime >= 800) {
    //         this.getAutoCompleteKeys(queryString, cb)
    //       }
    //     }, 800)
    //   } else {
    //     cb([])
    //   }
    // },
    async getAutoCompleteKeys () {
      let _this = this
      _this.$post('SearchKeysHistory/GetAutoCompleteKeys', {
        PageIndex: 1,
        PageSize: 1000
      }, (data, logic) => {
        if (logic.code === 200) {
          _.each(data.ResultList, item => {
            _this.baseKeyWords.push({
              key: item.SLogID,
              value: item.KeyWords
            })
          })
        }
      })
    },
    querySearch (queryString, cb) {
      this.keysFilter(queryString)
      cb(this.currencyKeyWords)
    },
    keysFilter (keyStr) {
      this.currencyKeyWords = []
      this.currencyKeyWords = _.filter(this.baseKeyWords, (item, index) => { return item.value.toLocaleLowerCase().indexOf(keyStr.toLocaleLowerCase()) !== -1 })
      // this.currencyKeyWords = _.filter(this.baseKeyWords, (item, index) => { return this.startwith(item.value.toLocaleLowerCase(), keyStr.toLocaleLowerCase()) })
    },
    startwith (origiStr, startStr) {
      var reg = new RegExp('^' + startStr)
      return reg.test(origiStr)
    },
    async getSelectOptions () {
      this.$post('SearchKeysHistory/GetRecentlySearch', (data) => {
        this.hmSelectOptions = []
        _.each(data, item => {
          this.hmSelectOptions.push({
            key: item.SLogID + '_' + item.KeyWords,
            value: item.KeysAndTimeStr
          })
        })
      })
    },
    onInit () {

    }
  },
  computed: {},

  created () {
    this.getSelectOptions()
   // this.getAutoCompleteKeys()
  }
}
</script>

<style lang="scss">

$bgcolor: #e6e6e6;
.stock-all-search-box {
  padding: 7px 10px;
  background-color: $bgcolor;
  .box-left-btn {
    margin-left: 2px;
    margin-right: 2px;
  }
  .old-edition{
    right: 30px;
  }
}

</style>
