<template>
  <div>
    <znl-edit-module type="edit"
                ref="flexGrid"
                header="编辑发运单"
                :columns="columns"
                :item-source="itemSource"
                :edit-row-index="editRowIndex"
                :column-colors="columnColors"
                :row-colors="rowColors"
                :summary-columns="summaryColumns"
                :is-fields-search="false"
                :is-multi-rows-check="true"
                :search-fields="searchModel"
                :page-size="pageSize"
                :page-index="pageIndex"
                :total-count="totalCount"
                :on-init="onInit"
                :is-alter-ver="true"
                :showAddRowButton="showAddRowButton"
                :showStocklistCheck="showStocklistCheck"
                :showAddRowRightMenu="showAddRowRightMenu"
                @stk-guids-check-list="stkguisChecklist"
                @selection-changed="d=>{$emit('selection-changed',d)}">
       <template slot="edit-buttons">
        <el-button type="primary"
                   size="mini"
                   @click="expressOrderSave">保存</el-button>
      </template>
    <div slot="action-form">
      <el-row class="formContainer" type="flex" justify="space-around">
        <el-col>
          <h3 class="subTitle">客户信息</h3>
          <el-row type="flex" class="form_Row_W100">
            <el-col>
              <div class="form_subtitle">发货日期:<span class="tagNote">（必填）</span></div>
              <el-date-picker type="date"
                              placeholder="发货日期"
                              v-model="ExpressOrder.ShipTime"
                              size="mini">
              </el-date-picker>
            </el-col>
            <el-col>
              <div class="form_subtitle">发货单号：</div>
              <el-input v-bind:disabled="true"
                        v-model="ExpressOrder.BillNo"
                        icon="edit"
                        size="mini">
              </el-input>
            </el-col>
          </el-row>
          <el-row type="flex" class="form_Row_W100">
            <el-col>
              <div class="form_subtitle">客户名称：<span class="tagNote">（必填）</span></div>
              <el-input v-model="ExpressOrder.CustomerName"
                        icon="edit"
                        size="mini"
                        :on-icon-click="SearchCompany">
              </el-input>
            </el-col>
            <el-col>
              <div class="form_subtitle">收货地址：</div>
              <el-input v-model="ExpressOrder.Address" size="mini"></el-input>
            </el-col>
          </el-row>
          <el-row type="flex" class="form_Row_W100">
            <el-col>
              <div class="form_subtitle">联系人：</div>
              <el-input v-model="ExpressOrder.Contact"
                              size="mini"></el-input>
            </el-col>
            <el-col>
              <div class="form_subtitle">联系电话：</div>
              <el-input v-model="ExpressOrder.Telephone"
                              size="mini"></el-input>
            </el-col>
          </el-row>
          <el-row type="flex" class="form_Row_W100">
            <el-col>
              <div class="form_subtitle">客户订单号：</div>
              <el-input v-model="ExpressOrder.CustomerOrderNo"
                            size="mini"></el-input>
            </el-col>
            <el-col>
              <div class="form_subtitle">手机号码：</div>
              <el-input v-model="ExpressOrder.Mobile"
                              size="mini"></el-input>
            </el-col>
          </el-row>
        </el-col>

        <el-col>
          <h3 class="subtitle">发运信息</h3>
          <el-row type="flex" class="form_Row_W100">
            <el-col>
              <div class="form_subtitle">发货员：<span class="tagNote">（必填）</span></div>
              <el-select v-model="ExpressOrder.ShipUserID" clearable
                          placeholder="请选择"
                          size="mini">
                <el-option v-for="item in BuyerOptions"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key">
                </el-option>
              </el-select>
            </el-col>
            <el-col>
              <div class="form_subtitle">备注：</div>
              <el-input v-model="ExpressOrder.Remark"
                        size="mini"></el-input>
            </el-col>
          </el-row>
          <el-row type="flex" class="form_Row_W100">
            <el-col>
              <div class="form_subtitle">快递公司：<span class="tagNote">（必填）</span></div>
              <el-select v-model="ExpressOrder.TransportCompany" clearable
                          placeholder="请选择"
                          size="mini">
                <el-option v-for="item in TransportCompanyOptions"
                            :key="item.key"
                            :label="item.value"
                            :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col>
              <div class="form_subtitle">快递单号：<span class="tagNote">（必填）</span></div>
              <el-input v-model="ExpressOrder.ShippingNo"
                        size="mini"></el-input>
            </el-col>
          </el-row>
             <el-row type="flex" class="form_Row_W100">
                 <el-col>
              <div class="form_subtitle">实际发货日期:<span class="tagNote">（必填）</span></div>
              <el-date-picker type="date"
                              placeholder="实际发货日期"
                              v-model="ExpressOrder.RealShipTime"
                              size="mini">
              </el-date-picker>
            </el-col>
            <el-col>
              <div class="form_subtitle">重量：</div>
              <el-input v-model="ExpressOrder.Weight"
                        size="mini"></el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      </div>
    </znl-edit-module>

    <d-company-contact-select :visible="dialogCompanySelectVisible" companyType="1,3"
                              title="选择客户名称"
                              @update:visible="val=>{dialogCompanySelectVisible=val}"
                              @confirm='companyChecked'></d-company-contact-select>
  </div>
</template>
<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as moveToMixin } from '~assets/scripts/directives/moveTo'
import VCompanySelect from '@c_modules/Crm/DCompanyContactSelect'
import dCompanyContactSelect from '../Crm/DCompanyContactSelect'

const CONFIG = {
  PK: 'EOLineID',
  configURL: 'ExpressOrderAdd/GetConfig',
  ExpressOrderSaveURL: 'ExpressOrderAdd/EditExpressOrder',
  ExpressOrderSearchById: 'ExpressOrderAdd/Search',
  DropDownURL: 'DictItem/GetItemValue',
  BuyerURL: 'CompanyAccount/GetUsableUser',
  getUserAllowUserURL: 'CompanyAccount/GetUserAllowCompanyUser',
  getCompanyParamByCode: 'CompanyParamSetup/GetStockOutQtyParam',
  CompanyParamSetup: 'CompanyParamSetup/IsAllowNegativeStock'
}
export default {
  name: 'ExpressOrderAdd',
  config: CONFIG,
  mixins: [znlMethodsMixin, moveToMixin],
  components: {
    VCompanySelect,
    dCompanyContactSelect
  },
  data () {
    return {
      currentUserName: this.$store.state.accountInfo.UserID, // 获取当前登录人名称
      dialogCompanySelectVisible: false,
      InTypes: '', // 1:客户，2：供应商，3:两者都是的公司
      ExpressOrder: {
        EOGUID: '',
        BillNo: '', // 销售单号
        ShipTime: new Date().toLocaleDateString(), // 出货日期
        RealShipTime: new Date().toLocaleDateString(), // 出货日期
        ShipUserID: this.$store.state.accountInfo.UserID, // 销售员ID
        Shipper: this.$store.state.accountInfo.UserName, // 销售员
        CustomerGUID: '', // 客户guid
        CustomerName: '', // 客户名称
        Address: '', // 收货地址
        Contact: '', // 联系人
        Telephone: '', // 联系电话
        Mobile: '', // 手机号码
        CustomerOrderNo: '', // 客户订单号
        TransportCompany: '', // 快递公司
        ShippingNo: '', // 快递单号
        Remark: '', // 销售备注
        Weight: '',
        LineList: [] // 出库单详情
      },  // 出库单对象
      BuyerOptions: [], // 采购员列表
      TransportCompanyOptions: [], // 快递公司
      // 绑定列表中的下拉框
      dataMapSourse: {
        UnitSource: [], // 单位列表数据源
        InvTypeSource: []// 库存类型列表数据源
      },
      disabledInput: true, // 禁用采购单
      IsManual: false, // 手动
      isAllow: true, // 是否允许选择手动框
      isPopup: false,
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      users: [],
      usableUser: [],
      role: '1',
      showAddRowButton: true,
      showStocklistCheck: true,
      showAddRowRightMenu: true,
      showDeleteRowButton: true
    }
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showOutAddBystock: Boolean, // 从库存中传入值判断是否刷新页面
    showOutAddBystkout: Boolean, // 从出库中出入值判断是否刷新页面
    showOutAddBystkoutLine: Boolean // 从出库明细中出入值判断是否刷新页面
  },
  watch: {
    IsManual (value) {
      // this.disabledInput = !value
      // if (!value) {
      //   // this.StkOut.BillNo = ''
      // }
    }
  },
  methods: {
    onInit: async function () {
      // console.log(2140)
      let config = await this.isSaveStorage('expressOrderAdd', CONFIG.configURL)
      // let config = await this.$post(CONFIG.configURL)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 下拉列表
      let QtyUnitDropDown = await this.$post(CONFIG.DropDownURL, { 'DictCode': 'QtyUnit' })
      let qtyUnitColumn = _.find(columns, column => column.name === 'Unit')
      qtyUnitColumn && _.extend(qtyUnitColumn, {
        dataMap: _.map(QtyUnitDropDown, items => { return { key: items.ItemValue, value: items.ItemName } }),
        showDropDown: true
      })
      let StorageTypeDropDown = await this.$post(CONFIG.DropDownURL, { 'DictCode': 'StorageType' })
      let invTypeColumn = _.find(columns, function (column) { return column.binding === 'InvType' })
      invTypeColumn && _.extend(invTypeColumn, {
        dataMap: _.map(StorageTypeDropDown, items => { return { key: items.ItemValue, value: items.ItemName } }),
        showDropDown: true
      })

      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      if (this.$store.state.paramOrder && this.$store.state.paramOrder.EOGUID) {
        // console.log(8952)
        let eoguid = this.$store.state.paramOrder.EOGUID
        // console.log(eoguid)
        this.$store.commit('setParamOrder', {})
        this.LoadEditData(eoguid)
      }
    },
    onBindData (pageIndex = 1) {
      this.itemSource = this.ExpressOrder.LineList
    },
    Save (isOut) { // 保存
      if (this.ExpressOrder.ShipTime === undefined || this.ExpressOrder.ShipTime === '') {
        return this.$message({ message: '请选择发货日期', type: 'error' })
      }
      if (this.ExpressOrder.RealShipTime === undefined || this.ExpressOrder.RealShipTime === '') {
        return this.$message({ message: '请选择实际发货日期', type: 'error' })
      }
      if (this.ExpressOrder.CustomerName === '') {
        return this.$message({ message: '请添加客户名称', type: 'error' })
      }
      if (this.ExpressOrder.ShipUserID === 0 || this.ExpressOrder.ShipUserID === undefined || this.ExpressOrder.ShipUserID === '') {
        return this.$message({ message: '请选择发货员', type: 'error' })
      }
      if (this.ExpressOrder.TransportCompany === undefined || this.ExpressOrder.TransportCompany === '') {
        return this.$message({ message: '请选择快递公司', type: 'error' })
      }
      if (this.ExpressOrder.ShippingNo === undefined || this.ExpressOrder.ShippingNo === '') {
        return this.$message({ message: '请填写快递单号', type: 'error' })
      }
      this.ExpressOrder.isAllowOut = isOut// 是否允许出库
      let AllowNegativeStock = true
      return this.$post(CONFIG.CompanyParamSetup, {}, (data) => {
        AllowNegativeStock = data.DontAllowNegativeStock
        let rows = this.$refs.flexGrid.getItemSource()
        if (rows.length < 1) {
          return this.$message({ message: '请添加出库单详情', type: 'error' })
        } else {
          let rowId = 0
          let messageStr = ''
          _.some(rows, item => {
            rowId = rowId + 1
            if (!item['StkGUID'] && AllowNegativeStock === false) {
              messageStr = '出库单详情第' + rowId + '行,不允许负库存出库'
              return true
            }
            if (!item['Model']) {
              messageStr = '出库单详情第' + rowId + '行型号不能为空'
              return true
            }
            if (!item['Qty']) {
              messageStr = '出库单详情第' + rowId + '行出货数量不能为零或空'
              return true
            }
          })
          if (messageStr.length > 0) {
            return this.$message({ message: messageStr, type: 'error' })
          }
        }
        this.ExpressOrder.LineList = rows
        if (this.ExpressOrder.LineList.length > 0) {
          return this.$post(CONFIG.ExpressOrderSaveURL, this.ExpressOrder, (data) => {
            if (data > 0) {
              this.$message({ message: '保存成功', type: 'success' })
              this.$store.commit('removeTab', 'Ord/ExpressOrderManage')
              this.$nextTick(function () {
                this.$store.commit('removeTab', 'Ord/ExpressOrderAdd')
                this.openTab('Ord/ExpressOrderManage', {})
              })
            } else {
              this.$message({ message: '保存失败', type: 'error' })
            }
          })
        }
      })
    },
    expressOrderSave () { // 保存
      this.Save(false)
    },
    LoadEditData: async function (guid) { // 根据出库单GUID加载实体
      let epOrder = await this.$post(CONFIG.ExpressOrderSearchById, { EOGUID: guid })
      this.ExpressOrder = epOrder
      this.$nextTick(function () {
        this.onBindData()
      })
    },
    ConvertNumber (num) { // 转出数据格式
      return _.isNaN(num) || _.isUndefined(num) || num == null ? (0).toFixed(2) : num
    },
    SearchCompany () {
      this.InTypes = '1,3'// 客户和供应商客户
      this.dialogCompanySelectVisible = true
    },
    companyChecked (item) {
      this.ExpressOrder.CustomerName = item.CompanyName
      this.ExpressOrder.CustomerGUID = item.CCompanyID
      this.ExpressOrder.Address = item.Address
      this.ExpressOrder.Contact = item.ContactName
      this.ExpressOrder.Telephone = item.Telephone
    },
    stkguisChecklist (item) { // 库存列表选择
      if (item) {
        this.$nextTick(function () {
          let itemsources = this.$refs.flexGrid.getItemSource()
          _.each(item, i => {
            this.$set(i, 'Qty', i.InvQty)
          })
          itemsources = item.reduce(function (coll, i) {
            let itemsourceModel = _.map(coll, item => { return item.Model })
            if (!_.includes(itemsourceModel, i.Model)) {
              coll.push(i)
            }
            return coll
          }, itemsources)
          this.ExpressOrder.LineList = itemsources
          this.onBindData()
        })
      } else {
        this.$message({ message: '加载库存失败，请刷新后重试', type: 'error' })
      }
    },
    onSaleChange () { // 销售员选中事件
      let buyer = _.find(this.BuyerOptions, item => item.key === this.ExpressOrder.ShipUserID)
      if (!_.isUndefined(buyer)) { this.ExpressOrder.Shipper = buyer.value }
    },
    onBillIconClick () {
      if (this.StkOut.IsAdd) {
        this.IsManual = !this.IsManual
        if (!this.IsManual) {
          this.StkOut.BillNo = ''
        }
      } else {
        this.$message({ message: '单据编号生成后不可以修改！', type: 'error' })
      }
    }
  },
  created () {
    // 快递公司
    this.$post(CONFIG.DropDownURL, { 'DictCode': 'TransportCompany' }, (data) => {
      this.TransportCompanyOptions = _.map(data, items => { return { key: items.ItemValue, value: items.ItemName } })
    })
    // 销售员列表
    this.$post(CONFIG.getUserAllowUserURL, {}, (data) => {
      var users = data.Users
      this.BuyerOptions = _.map(users, items => { return { key: items.UserID, value: items.UserName } })
    })
  }
}
</script>

<style lang="scss" scoped>
</style>

