<template>
  <znl-gridmodule ref="flexGrid"
                :header="header"
                height="100%"
                :has-znl-actions="false"
                :columns="columns"
                layout-type="other"
                :item-source="itemSource"
                :column-colors="columnColors"
                :is-fields-search="false"
                :is-multi-rows-check="false"
                :row-colors="rowColors"
                :summary-columns="summaryColumns"
                :search-fields="searchModel"
                :page-size="pageSize"
                :page-index="pageIndex"
                :total-count="totalCount"
                :role="role"
                :users="$store.state.users"
                :on-init="onInit"
                :is-init="true"
                :grid-show="gridShow"
                :on-refresh="onRefresh"
                :on-search="onSearch"
                element-loading-text=""
                @selection-changed="d=>{$emit('selection-changed',d)}">
  </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  default: null,
  searchURL: 'PubSStockCust/Search',
  AddOrColseURL: 'PubSStockCust/AddOrColseSharedCustByGuids',
  GetSharedCustURL: 'PubSStockCust/GetSharedCustByMy',
  GetOpenSharedURL: 'PubSStockCust/GetOpenSharedStockCust',
  PubSStockCustAdd: 'PubSStockCust/Add'
}

export default {
  name: 'PubSStockCust',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      PUBSStockCust: {
        SCustGuid: null,
        SCustName: ''
      },
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      gridShow: false
    }
  },
  watch: {
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String,
    header: String,
    SStatus: String,
    Urltype: String,
    ColComapnyWidth: String,
    ColOperWidth: String
  },
  methods: {
    onInit: async function () {
      let gridConfig = [
        { CTitle: '公司名称',
          BindField: 'SCustName',
          IsShow: true,
          IsReadonly: true,
          ColumnWidth: this.ColComapnyWidth,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: 'SCustGuid',
          BindField: 'SCustGuid',
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: 'ID',
          BindField: 'ID',
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true },
        { CTitle: 'Status',
          BindField: 'Status',
          IsShow: false,
          IsReadonly: true,
          ColumnWidth: 80,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: true }
      ]
      this.columns = this.flexGridColumnsHandler(gridConfig)

      this.Urltype != null && this.Urltype === '1' ? '' : this.columns.push({
        'name': 'IsextensionResult',
        'dataType': 5,
        'binding': 'IsextensionResult',
        'width': this.ColOperWidth,
        'header': '操作',
        'visible': true,
        'allowSorting': false,
        'isReadOnly': true,
        'isFieldsSearch': false,
        'isSystem': true,
        'buttons': [
          {
            'content': '取消',
            'className': (d) => { return d.SStatus === true ? 'znl-table-btn-success' : '' },
            'when': this.SStatus === '1',
            // 'when': this.hasRes('Cancel') && this.SStatus === '0',
            'click': (row) => {
              // console.log('row.SSCGuid', row.SSCGuid)
              this.changeState(row.ID, 2, row.SCustName)
            }
          }, {
            'content': '审核',
            'className': (d) => { return d.SStatus === true ? 'znl-table-btn-success' : '' },
            'when': this.SStatus === '0',
            // 'when': this.hasRes('Opened') && this.SStatus === '0',
            'click': (row) => {
              this.changeState(row.ID, 1, '')
            }
          },
          {
            'content': '拒绝',
            'className': (d) => { return d.SStatus === true ? 'znl-table-btn-success' : '' },
            'when': this.SStatus === '0',
            'click': (row) => {
              this.changeState(row.ID, 2, '')
            }
          },
          {
            'content': (row) => {
              return row.Status === 0 ? '申请' : '请求中'
            },
            'className': (d) => { return d.SStatus === true ? 'znl-table-btn-success' : '' },
            'when': this.SStatus === '2',
            'click': (row) => {
              if (row.Status === 1) {
                return this.$message({ message: '已经在申请中,不可再操作', type: 'warning' })
              } else {
                this.PubSStockCustAdd(row.SCustGuid, row.SCustName)
              }
            }

          }
        ]
      })
      this.initData && this.onBindData()
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({},
        {
          SStatus: this.SStatus,
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
      )
      let url = ''
      if (this.Urltype !== null && this.Urltype === '1') {
        url = CONFIG.GetSharedCustURL
        data = _.extend({}, { SStatus: 1 }, { IsActive: 2 })
      } else if (this.Urltype !== null && this.Urltype === '0' && this.SStatus !== null && this.SStatus === '2') {
        url = CONFIG.GetOpenSharedURL
      } else {
        url = CONFIG.searchURL
      }
      return this.$post(url, data, (data, logic) => {
        // if (logic.code === 200) {
        this.itemSource = data === null ? [] : data
        this.gridShow = true
        this.$emit('page-loading', false)
        // } else {
        //   this.$message({ message: logic.msg, type: 'warning' })
        // }
      })
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    PubSStockCustAdd (cCompanyGuid, cCompanyName) {
      if (cCompanyGuid === undefined || cCompanyGuid === null) {
        this.$message({ message: '请选择要加入的客户', type: 'warning' })
        return
      }
      this.PUBSStockCust.SCustGuid = cCompanyGuid
      this.PUBSStockCust.SCustName = cCompanyName
      this.$post(CONFIG.PubSStockCustAdd, this.PUBSStockCust, data => {
        if (data > 0) {
          this.$refs.flexGrid.clearSelection()
          this.$message({ message: '申请成功,请等待对方审核', type: 'success' })
        } else {
          this.$message({ message: '申请失败', type: 'error' })
        }
      }).then(() => {
        this.onRefresh()
      })
    },
    changeState (guid, state, custName) {
      if ((guid === null || guid === undefined) && custName === '') {
        this.$message({ message: '请选择要操作的客户', type: 'warning' })
        return
      }
      if (state === null || state === undefined) return
      var jsonData = _.extend({}, { SStatus: state }, { ID: [guid] }, { CustName: custName })
      console.log('jsonData', jsonData)
      this.$post(CONFIG.AddOrColseURL, jsonData, data => {
        if (data && data > 0) {
          this.$refs.flexGrid.clearSelection()
          this.$message({ message: '操作成功', type: 'success' })
        } else {
          this.$message({ message: '操作失败', type: 'error' })
        }
      }).then(() => {
        this.onRefresh()
      })
    },
    onRefresh () {
      return this.onBindData(this.pageIndex)
    }
  }

}
</script>

<style lang="scss">
</style>

