<template>
  <div>
    <znl-express-order v-if='hasRight("ExpressOrder")'
                 :searchModel="expressOrder.searchModel" height="380px"
                 @selection-changed="expressOrderSelectionChange"
                ></znl-express-order>
  
   
      <znl-express-order-line v-if='hasRight("ExpressOrderLine")'
                        :initData="false"
                        ref="linelistData"
                        :searchModel="expressOrderLine.searchModel"></znl-express-order-line>
                        
   
  </div>
</template>

<script>
import ZnlExpressOrder from '@c_modules/Ord/ExpressOrder'
import ZnlExpressOrderLine from '@c_modules/Ord/ExpressOrderLine'

export default {
  name: 'VExpressOrder',
  components: {
    ZnlExpressOrder,
    ZnlExpressOrderLine
  },
  data () {
    return {
      expressOrder: {
        searchModel: {
          data: {},
          extra: {}
        }
      },
      pickerOptions0: {
      },
      expressOrderLine: {
        searchModel: {
        }
      },
      pageData: []
    }
  },
  methods: {
    expressOrderSelectionChange (item) {
      this.$set(this.expressOrderLine.searchModel, 'EOGUID', item.EOGUID)
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    },
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VExpressOrder'})
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
