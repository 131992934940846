<template>
  <div>
    <znl-gridmodule
      ref="flexGrid"
      height="100%"
      :on-init="onInit"
      :columns="columns"
      header="录入报价"
      class="easy-quote"
      @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
      :is-activity="true"
    >
      <div slot="heading-slot" class="stock-all-quote">
        <el-row type="flex" class="row-bg" :gutter="5">
          <el-col :span="22" class="col-middle">
            <div class="grid-content bg-purple stock-all-quote-inputs">
              <el-row :gutter="3">
                <el-col :span="24">
                  <znl-input
                    from-type="input"
                    :disable="false"
                    class="m-txt-placeholder"
                    placeholder="✶需求型号"
                    size="mini"
                    layout="left"
                    width="120px"
                    :height="30"
                    inp-perc="100%"
                    :clearable="true"
                    :border="true"
                    :is-upper-case="true"
                    v-model="quoteForm.Model"
                    pattern="\S{1,}"
                    pattern-tip
                    @pattern-authend="val=>reg_model_status=val"
                    ref="Model"
                    @keyup.enter.native="e => {onSearch(), $refs.Model.blur()}"
                    type="text"
                  ></znl-input>

                  <znl-input
                    from-type="input"
                    :disable="false"
                    class="m-txt-placeholder"
                    placeholder="✶需求数量"
                    size="mini"
                    layout="left"
                    width="110px"
                    :height="30"
                    inp-perc="100%"
                    :clearable="true"
                    :border="true"
                    v-model="quoteForm.Qty"
                    pattern="^[0-9]*$"
                    pattern-tip="数量为正整数"
                    @pattern-authend="val=>reg_qty_status=val"
                    ref="Qty"
                    type="text"
                  ></znl-input>
                  <znl-input
                    from-type="input"
                    :disable="false"
                    class="m-txt-placeholder"
                    placeholder="✶报价"
                    size="mini"
                    layout="left"
                    width="110px"
                    :height="30"
                    inp-perc="100%"
                    :border="true"
                    :clearable="true"
                    v-model="quoteForm.NTQuotePrice"
                    pattern="^[1-9]\d*\,\d*|[1-9]\d*$"
                    pattern-tip="报价类型必须为数字"
                    @pattern-authend="val=>reg_price_status=val"
                    ref="NTQuotePrice"
                    type="text"
                  ></znl-input>

                  <znl-input
                    from-type="input"
                    :disable="false"
                    placeholder="客户电话"
                    size="mini"
                    layout="left"
                    width="110px"
                    :height="30"
                    inp-perc="100%"
                    :clearable="true"
                    :border="true"
                    v-model="quoteForm.Telephone"
                    type="text"
                  ></znl-input>

                  <znl-input
                    from-type="input"
                    :disable="false"
                    placeholder="客户QQ"
                    size="mini"
                    layout="left"
                    width="130px"
                    :height="30"
                    inp-perc="100%"
                    :clearable="true"
                    :border="true"
                    v-model="quoteForm.QQ"
                    type="text"
                  ></znl-input>

                  <znl-input
                    from-type="input"
                    :disable="false"
                    placeholder="客户名称"
                    size="mini"
                    layout="left"
                    :border="true"
                    width="200px"
                    :height="30"
                    inp-perc="100%"
                    :clearable="true"
                    v-model="quoteForm.CustomerName"
                    @change="customerNameChanged"
                    type="text"
                  >
                    <i slot="suffix" class="iconfont icon-edit_btn_ic" @click="SearchCompany"></i>
                  </znl-input>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="2" class="col-right">
            <div class="grid-content bg-purple stock-all-quote-save">
              <znl-button style-type="main" @click="onSave" :loading="true">
                <span>
                  <i class="iconfont"></i>保存报价
                </span>
              </znl-button>
            </div>
          </el-col>
        </el-row>
        <el-col>
          <a
            @click="showBomEdit()"
            style="text-decoration:underline; margin-left:3px;font-size:10px !important"
          >更多型号...</a>
        </el-col>
      </div>
      <div class="stock-all-quote stock-all-quote-hider">
        <el-row :gutter="3" class="el-row-1">
          <el-col :span="24">
            <znl-input
              from-type="input"
              :disable="false"
              placeholder="品牌"
              size="mini"
              layout="left"
              width="118px"
              :height="30"
              inp-perc="100%"
              :border="true"
              :clearable="true"
              :is-upper-case="true"
              v-model="quoteForm.Brand"
              type="text"
            ></znl-input>

            <znl-input
              from-type="input"
              :disable="false"
              :border="true"
              placeholder="封装"
              size="mini"
              layout="left"
              width="108px"
              style="margin-left:1.5px;"
              :height="30"
              inp-perc="100%"
              :clearable="true"
              v-model="quoteForm.Packaging"
              type="text"
            ></znl-input>

            <znl-input
              from-type="input"
              :disable="false"
              placeholder="品质"
              size="mini"
              layout="left"
              width="108px"
              :height="30"
              inp-perc="100%"
              style="margin-left:2px;"
              :border="true"
              :clearable="true"
              v-model="quoteForm.Quality"
              type="text"
            ></znl-input>

            <znl-input
              from-type="input"
              :disable="false"
              placeholder="货期"
              size="mini"
              layout="left"
              width="108px"
              :height="30"
              style="margin-left:2px;"
              inp-perc="100%"
              :clearable="true"
              :border="true"
              v-model="quoteForm.DeliveryDate"
              type="text"
            ></znl-input>

            <znl-input
              from-type="input"
              :disable="false"
              placeholder="备注"
              size="mini"
              layout="left"
              width="332px"
              :height="30"
              inp-perc="100%"
              style="margin-left:2px;"
              :clearable="true"
              :border="true"
              v-model="quoteForm.Remark"
              type="text"
            ></znl-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" v-loading="amasss_loading">
            <div class="stock-all-amass">
              <span>
                累积：报价
                <b>{{amass.quoteTimes}}</b>次
              </span>
              <span>
                成交
                <b>{{amass.volamcount}}</b>次
              </span>
              <span>
                金额
                <b>{{amass.amount}}</b>元
              </span>|
              <span>
                30天内：报价
                <b>{{amass.quoteTimes_in30days}}</b>次,
              </span>
              <span>
                成交
                <b>{{amass.volamcount_in30days}}</b>次,
              </span>
              <span>
                金额
                <b>{{amass.amount_in30days}}</b>元
              </span>
              <!-- |
              <span>欠款：
                <b>{{amass.arrears}}</b>元</span>
              <span>额度
                <b>{{amass.limit}}</b>万</span>
              <span>已用
                <b>{{amass.used}}</b>
              </span>-->
            </div>
          </el-col>
        </el-row>
      </div>
      <d-company-contact-select
        :visible="companyVisible"
        v-if="companyVisible"
        companyType="1,3"
        title="选择客户"
        @close="()=>{companyVisible=false}"
        @update:visible="val=>{companyVisible=val}"
        @confirm="companyChecked"
      ></d-company-contact-select>
    </znl-gridmodule>
    <div>
      <bom-master-edit
        title="新增客户需求"
        :editvisible="visibleBomEdit"
        v-if="visibleBomEdit"
        :edit-type="setBomEdit"
        @confirm="visibleBomEdit=false"
        @close="visibleBomEdit=false"
      ></bom-master-edit>
    </div>
  </div>
</template>
<script>
import dCompanyContactSelect from '@c_modules/Crm/DCompanyContactSelect'
import BomMasterEdit from '@c_modules/BOM/BomMasterEdit'

import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  saveUrl: 'BomMaster/Save',
  getBomStatisticsUrl: 'BomDetailLine/GetBomStatistics'
}
export default {
  name: 'EasyQuote',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  components: {
    dCompanyContactSelect,
    BomMasterEdit
  },
  props: {
    quoteData: Object
  },
  data() {
    return {
      editvisible: false,
      bomMasterDetail: [],
      setBomEdit: 'addNew',
      visibleBomEdit: false,
      quoteForm: {
        CCompanyID: '',
        Model: '',
        Qty: '',
        NTQuotePrice: '',
        CustomerName: '',
        Telephone: '',
        QQ: '',
        Brand: '',
        Package: '',
        Quality: '',
        DeliveryDate: '',
        Remark: ''
      },
      reg_qty_status: true,
      reg_model_status: true,
      reg_price_status: true,
      amass: {
        quoteTimes: 0,
        volamcount: 0,
        amount: '0.00',
        quoteTimes_in30days: 0,
        volamcount_in30days: 0,
        amount_in30days: '0.00',
        arrears: 0,
        limit: 0,
        used: '0%'
      },
      BomMaster: {
        // 客户需求单对象
        BOMGUID: '',
        BillNo: '',
        QQ: '', // QQ
        Qty: '',
        Email: '', // Email
        CustomerName: '', // 客户名称
        CustomerGUID: '', // 客户ID
        Contact: '', // 联系人
        Address: '', // 地址
        Telephone: '', // 联系电话
        RequestSource: '', // 需求来源
        TaxRate: 0, // 税点
        CurrencyCode: 'RMB', // 币种
        ExchangeRate: '1', // 汇率
        ReceiptType: '', // 收款方式
        IsAdd: true, // 是否新增
        IsSaveCustomerInfo: false,
        DetailList: [], // 保存需求单详情明细行
        BomName: '',
        BomUrl: null,
        CopyNumber: 1,
        FolderId: 0 // 文件夹id
      },
      MasterResult: {
        CustomerGUID: ''
      },
      companyVisible: false,
      columns: [],
      amasss_loading: false,
      loading: true
    }
  },
  watch: {
    quoteData() {
      this.setQuoteData()
    }
  },
  methods: {
    onSearch() {
      this.$emit('bar-search', this.quoteForm.Model, true)
    },
    SearchCompany() {
      this.InTypes = '1,3' // 客户和供应商客户
      this.companyVisible = true
    },
    checkData() {
      if (this.quoteForm.Model === undefined || this.quoteForm.Model === '') {
        this.reg_model_status = false
      }
      if (this.quoteForm.Qty === undefined || this.quoteForm.Qty === '') {
        this.reg_qty_status = false
      }
      if (this.quoteForm.NTQuotePrice === undefined || this.quoteForm.NTQuotePrice === '') {
        this.reg_price_status = false
      }
      if (!this.reg_model_status) {
        this.$message({ message: '报价型号不能为空', type: 'warning' })
        this.$refs.Model.handleFocus()
        return false
      }
      if (!this.reg_qty_status) {
        this.$message({ message: '报价数量必须为正整数', type: 'warning' })
        this.$refs.Qty.handleFocus()
        return false
      }
      if (!this.reg_price_status) {
        this.$message({ message: '单价必须为数字', type: 'warning' })
        this.$refs.NTQuotePrice.handleFocus()
        return false
      }
      let rows = []
      rows.push(this.quoteForm)
      this.BomMaster.Model = this.quoteForm.Model
      this.BomMaster.Qty = this.quoteForm.Qty
      this.BomMaster.Telephone = this.quoteForm.Telephone
      this.BomMaster.CustomerName = this.quoteForm.CustomerName
      this.BomMaster.QQ = this.quoteForm.QQ
      this.BomMaster.DetailList = rows
      return true
    },
    async onSave() {
      if (this.checkData()) {
        this.onSaveQuote()
      }
    },
    async onSaveQuote() {
      return this.$post(CONFIG.saveUrl, this.BomMaster, (data, logic) => {
        if (logic.code === 200) {
          this.$refs.flexGrid.toggleTableShow(false)
          this.$message({ message: '保存成功!请到配单模块里查看详情.', type: 'success' })
          this.onResetQuote()
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      },
        () => { }
      )
    },
    hasValue(val) {
      if (val === null || val === undefined || val === '') {
        return false
      }
      return true
    },
    async onResetQuote() {
      this.quoteForm.Model = ''
      this.quoteForm.Qty = ''
      this.quoteForm.NTQuotePrice = ''
      this.quoteForm.CustomerName = ''
      this.quoteForm.Telephone = ''
      this.quoteForm.QQ = ''
      this.quoteForm.Brand = ''
      this.quoteForm.Package = ''
      this.quoteForm.Packaging = ''
      this.quoteForm.DeliveryDate = ''
      this.quoteForm.Remark = ''
      this.quoteForm.Quality = ''
    },
    async companyChecked(item) {
      // 打开表格
      this.$refs.flexGrid.toggleTableShow(true)
      this.quoteForm.CustomerName = item.CompanyName // 客户名称
      this.quoteForm.CustomerGUID = item.CCompanyID // 客户ID

      if (this.hasValue(item.Telephone)) {
        this.quoteForm.Telephone = item.Telephone
      }
      if (this.hasValue(item.QQ)) {
        this.quoteForm.QQ = item.QQ
      }
      this.MasterResult.CustomerGUID = item.CCompanyID

      this.getBomStatistics()
    },
    async  getBomStatistics() {
      // this.elementLoadingText = '正在保存数据,请稍后...'
      // this.amasss_loading = true
      return this.$post(CONFIG.getBomStatisticsUrl, this.MasterResult, (data, logic) => {
        if (logic.code === 200) {
          this.setBomStatistics(data)
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      },
        () => { }
      )
    },
    async setBomStatistics(data) {
      if (data) {
        this.amass.quoteTimes = data.GrandQuoteTimes == null ? 0 : data.GrandQuoteTimes
        this.amass.volamcount = data.GrandReachCount == null ? 0 : data.GrandReachCount
        this.amass.amount = data.GrandAmount == null ? 0 : data.GrandAmount.toFixed(2)
        this.amass.quoteTimes_in30days = data.DaysQuoteTimes == null ? 0 : data.DaysQuoteTimes
        this.amass.volamcount_in30days = data.DaysReachCount == null ? 0 : data.DaysReachCount
        this.amass.amount_in30days = data.DaysAmount == null ? 0 : data.DaysAmount.toFixed(2)
        this.amass.arrears = data.Arrears == null ? 0 : data.Arrears
        this.amass.limit = data.Limit == null ? 0 : data.Limit
        this.amass.used = data.Used == null ? 0 : data.Used
      }
    },
    onInit: async function (params) {
      this.loading = false
    },
    setQuoteData() {
      if (this.quoteData !== undefined && this.quoteData !== null) {
        this.quoteForm.Model = this.quoteData.Model
        this.reg_model_status = true
        this.reg_qty_status = true
        if (this.quoteData.InvQty > 0) {
          this.quoteForm.Qty = this.quoteData.InvQty
        } else if (this.quoteData.InvQty < 0) {
          this.quoteForm.Qty = -this.quoteData.InvQty
        } else {
          this.quoteForm.Qty = ''
        }
        this.quoteForm.Brand = this.quoteData.Brand
        this.quoteForm.Packaging = this.quoteData.Packaging
        this.quoteForm.Quality = this.quoteData.Quality
        this.quoteForm.DeliveryDate = this.quoteData.DeliveryDate
        this.$refs.NTQuotePrice.handleFocus()
        this.$refs.flexGrid.toggleTableShow(true)
      }
    },
    customerNameChanged() { },
    showBomEdit() {
      this.visibleBomEdit = true
      console.log('this.editvisible', this.editvisible)
    }
  },
  mounted() {
    // 收起表格
    !this.isShowGrid && this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>

<style lang="scss">
$bgcolor: #f2f2f2;
.easy-quote {
  background-color: #e6e6e6;
  height: auto;
  .znl-pattern-tip {
    top: 0;
    display: none;
  }
  .stock-all-quote {
    .znl-header-text {
      padding: 5px 5px 5px 10px;
      .iconfont {
        font-size: 12px;
      }
    }
    .col-left {
      width: 70px;
    }
    .col-middle {
      width: 830px;
    }
    .col-rigth {
      width: 90px;
    }
    .stock-all-quote-inputs {
      .el-row {
        line-height: 32px;
        padding-left: 8px;
      }
      .znl-input-default {
        margin-right: 2px;
      }
    }
    .stock-all-amass {
      padding: 10px 0;
      span {
        padding: 5px 10px;
      }
      font-size: 12px;
    }
  }
  .stock-all-quote-hider {
    padding: 0 10px;
    .el-row-1 {
      padding-left: 106px;
    }
  }
}
</style>
