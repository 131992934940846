<template>
  <znl-layout-leftright
    leftWidth="15%"
    class="PubStockShared"
    v-loading="hasPageLoading && $store.state.pageLoading && pageLoading1 && pageLoading2 && pageLoading3"
    element-loading-text="页面加载中,请稍后..."
  >
    <div slot="znlLeft" class="znlLeft">
      <znl-layout-topbottom top-height="50%">
        <div slot="znlTop" class="znlTop">
          <!--全部共享客户-->
          <znl-pub-s-stock-cust
            @page-loading="(d)=>{pageLoading1 = d}"
            @selection-changed="pubStkSelectionChange"
            header="已共享给我的供应商"
            Urltype="1"
            ColComapnyWidth="240"
            ColOperWidth="0"
            v-show="!(pageLoading1 || pageLoading2 || pageLoading3)"
          ></znl-pub-s-stock-cust>
        </div>
        <!--可申请的库存-->
        <div slot="znlBottom" class="znlBottom">
          <znl-pub-s-stock-cust
            @page-loading="(d)=>{pageLoading2 = d}"
            ColComapnyWidth="200"
            ColOperWidth="45"
            header="可申请共享的供应商"
            SStatus="2"
            Urltype="0"
            v-show="!(pageLoading1 || pageLoading2 || pageLoading3)"
          ></znl-pub-s-stock-cust>
        </div>
      </znl-layout-topbottom>
    </div>
    <div slot="znlRight" class="znlRight">
      <!--共享库存列表-->
      <znl-pub-stock-shared
        :searchModel="PubSStockCust.searchModel"
        Urltype="1"
        gridtype="base"
        @page-loading="(d)=>{pageLoading3 = d}"
        header="共享给我的库存"
        v-show="!(pageLoading1 || pageLoading2 || pageLoading3)"
      ></znl-pub-stock-shared>
    </div>
  </znl-layout-leftright>
</template>
<script>
import ZnlPubSStockCust from '@c_modules/Pub/PubSStockCust'
import ZnlPubStockShared from '@c_modules/Pub/PubStockShared'

export default {
  name: 'VSharedStock',
  components: {
    ZnlPubSStockCust,
    ZnlPubStockShared
  },
  data() {
    return {
      gridShow: false,
      spmPubStkStock: {
        searchModel: {},
        pickerOptions0: {}
      },
      PubSStockCust: {
        searchModel: {}
      },
      pageData: [],
      pageLoading1: true,
      pageLoading2: true,
      pageLoading3: true,
      hasPageLoading: true
    }
  },
  methods: {
    pubStkSelectionChange(item) {
      // console.log('item', item)
      this.$set(this.PubSStockCust.searchModel, 'SCustGuid', item.SCustGuid)
    },
    async getPageData() {
    },
    hasRight(code) {
      return _.some(this.pageData, page => page.Code === code)
    }
  },
  async created() {
    this.getPageData()
      .then(data => {
        this.pageData = data
        this.$nextTick(() => {
          initData(this)
        })
      })
      .catch(e => {
        console.log(e)
      })

    const initData = component => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)

        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss">
@import "app/assets/styles/_variables.scss";
.PubStockShared {
}
</style>
