<template>
  <znl-layout-leftright
    leftWidth="15%"
    class="PubStockShared"
    v-loading="hasPageLoading && $store.state.pageLoading && pageLoading1 && pageLoading2 && pageLoading3"
    element-loading-text="页面加载中,请稍后..."
  >
    <div slot="znlLeft" class="znlLeft">
      <znl-layout-topbottom top-height="50%">
        <div slot="znlTop" class="znlTop">
          <!--供应商-->
          <znl-pub-my-s-stock-cust
            @page-loading="(d)=>{pageLoading1=d}"
            header="已审核的客户"
            SStatus="1"
            ColComapnyWidth="200"
            ColOperWidth="40"
            v-show="!(pageLoading1 || pageLoading2 || pageLoading3)"
          ></znl-pub-my-s-stock-cust>
        </div>
        <!--请求共享记录-->
        <div slot="znlBottom" class="znlBottom">
          <znl-pub-my-s-stock-cust
            @page-loading="(d)=>{pageLoading2=d}"
            header="请求共享的客户"
            SStatus="0"
            ColComapnyWidth="170"
            ColOperWidth="70"
            v-show="!(pageLoading1 || pageLoading2 || pageLoading3)"
          ></znl-pub-my-s-stock-cust>
        </div>
      </znl-layout-topbottom>
    </div>
    <div slot="znlRight" class="znlRight">
      <!--共享库存列表-->
      <znl-pub-my-stock-shared
        @page-loading="(d)=>{pageLoading3=d}"
        header="我的共享库存"
        gridtype="action"
        Urltype="0"
        v-show="!(pageLoading1 || pageLoading2 || pageLoading3)"
      ></znl-pub-my-stock-shared>
    </div>
  </znl-layout-leftright>
</template>
<script>
import ZnlPubMySStockCust from '@c_modules/Pub/PubSStockCust'
import ZnlPubMyStockShared from '@c_modules/Pub/PubStockShared'

export default {
  name: 'VMyStockShared',
  components: {
    ZnlPubMySStockCust,
    ZnlPubMyStockShared
  },
  data() {
    return {
      gridShow: false,
      spmPubStkStock: {
        searchModel: {},
        pickerOptions0: {}
      },
      SpmQuotedPrice: {
        searchModel: {}
      },
      pageData: [],
      pageLoading1: true,
      pageLoading2: true,
      pageLoading3: true,
      hasPageLoading: true
    }
  },
  methods: {
    async getPageData() {
      // let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      // return await this.$post(pageUrl, { PageCode: 'VPubStkStock' })
    },
    hasRight(code) {
      return _.some(this.pageData, page => page.Code === code)
    }
  },
  async created() {
    this.getPageData()
      .then(data => {
        this.pageData = data
        this.$nextTick(() => {
          initData(this)
        })
      })
      .catch(e => {
        console.log(e)
      })

    const initData = component => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)

        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}
</script>

<style lang="scss">
@import "app/assets/styles/_variables.scss";
.PubStockShared {
}
</style>
