<template>
  <znl-layout-topbottom
    :page-loading="pageLoading"
    height="100%"
    top-height="62px"
    element-loading-text="页面加载中,请稍后..."
  >
    <!-- 搜索条件模块 -->
    <znl-search-condition
      slot="znlTop"
      ref="ZnlSearchCondition"
      v-show="!pageLoading"
      :refresh-warning-count="refreshWarningCount"
      @on-search="
        (val) => {
          onSearch(val);
        }
      "
      @change-tab="changeTab"
    ></znl-search-condition>

    <el-tabs
      value="StsDataStatistics"
      class="nav-common-ui tab-btnstyle-box tab-stsdatastatics-box grid-footer-right oustslide_padding-right m-stock-tab-manage"
      v-model="activeName"
      v-show="!pageLoading"
      slot="znlBottom"
      @tab-click="pageSearch(false)"
    >
      <!-- **************************************************************************** -->
      <!-- **************************** v-table *************************************** -->
      <!-- **************************************************************************** -->
      <el-tab-pane
        label="批次库存"
        name="BatchesStock"
        v-if="hasRight('StkStock')"
      >
        <!-- v-if="stockInitData" -->
        <batch-stock
          ref="batchesStock"
          v-if="true"
          @page-loading="setClientSelectLoading"
        ></batch-stock>
      </el-tab-pane>

      <el-tab-pane
        label="汇总库存"
        name="SummaryStock"
        v-if="hasRight('StockSummary')"
      >
        <znl-stock-summary
          v-if="SummaryStockAdd"
          ref="summaryStock"
          :init-data="false"
          @get-search-param="onGetSearchParam"
          @page-loading="setClientSelectLoading"
        ></znl-stock-summary>
      </el-tab-pane>

      <el-tab-pane
        label="供应商库存"
        name="StockVMI"
        v-if="hasRight('StockVMI')"
      >
        <!-- v-if="stockVMIInitData" -->
        <stock-vmi
          ref="stockVMI"
          v-if="true"
          @page-loading="setClientSelectLoading"
        ></stock-vmi>
      </el-tab-pane>

      <!-- v-if="hasRight('SubscribeStock')" -->
      <el-tab-pane label="订阅库存" name="PubSStockFriend" v-if="false">
        <stock-friend-details
          ref="subscribeInv"
          v-if="openStockInitData"
          @page-loading="setClientSelectLoading"
        >
        </stock-friend-details>
      </el-tab-pane>

      <!--   v-if="inquireDetailsInitData" -->
      <el-tab-pane
        label="询价记录"
        name="StkInquireRecord"
        v-if="hasRight('StkInquireRecord')"
      >
        <inquire-details
          ref="inquireRecord"
          v-if="true"
          :formPage="'stockManage'"
          @page-loading="setClientSelectLoading"
        >
        </inquire-details>
      </el-tab-pane>

      <!-- v-if="bomDetailsInitData" -->
      <el-tab-pane
        label="给客户报价记录"
        name="BomMastLineSelect"
        v-if="hasRight('BomMastLineSelect')"
      >
        <bom-quote-details
          ref="quoteRecord"
          :is-show-header="false"
          v-if="true"
          @page-loading="setClientSelectLoading"
        ></bom-quote-details>
      </el-tab-pane>

      <!-- v-if="stkOutDetailsInitData" -->
      <el-tab-pane
        label="销售出货记录"
        name="PastStkOut"
        v-if="hasRight('PastStkOut')"
      >
        <stk-out-details
          ref="outRecord"
          v-if="true"
          @page-loading="setClientSelectLoading"
          height="100%"
        ></stk-out-details>
      </el-tab-pane>

      <!-- v-if="stkInDetailsInitData" -->
      <el-tab-pane
        label="采购入库记录"
        name="PastStkIn"
        v-if="hasRight('PastStkIn')"
      >
        <stk-in-details
          ref="inRecord"
          v-if="true"
          @page-loading="setClientSelectLoading"
        ></stk-in-details>
      </el-tab-pane>

      <!-- v-if="reserveManageInitData" -->
      <el-tab-pane
        label="销售备货"
        name="SalesStocking"
        v-if="hasRight('StkStockReserve')"
      >
        <reserve-manage
          ref="stockReserve"
          v-if="true"
          @page-loading="setClientSelectLoading"
        ></reserve-manage>
      </el-tab-pane>

      <!--  v-if="stockBuyerManageInitData" -->
      <el-tab-pane
        label="采购订货"
        name="PurchaseOrder"
        v-if="hasRight('StkStockPurchase')"
      >
        <stock-buyer-manage
          ref="stockPurchase"
          v-if="true"
          @page-loading="setClientSelectLoading"
        ></stock-buyer-manage>
      </el-tab-pane>

      <el-tab-pane
        class="large-order-purchase-el-tab-pane"
        label="大单求购"
        name="LargeOrderPurchase"
        v-if="hasRight('LargeOrderPurchase') || 1"
      >
        <LargeOrderPurchase
          ref="LargeOrderPurchase"
          @page-loading="setClientSelectLoading"
        ></LargeOrderPurchase>
      </el-tab-pane>
      <el-tab-pane
        label="资料库"
        name="FileLibrary"
        v-if="hasRight('FileLibrary')"
      >
        <FileLibrary
          @page-loading="setClientSelectLoading"
          ref="fileLibrary"
        ></FileLibrary>
      </el-tab-pane>
    </el-tabs>
  </znl-layout-topbottom>
</template>
<script>
import ZnlSearchCondition from "@c_modules/Stk/StockManageSearchCondition";
import ZnlStockSummary from "@c_modules/StkSum/StockSummary";

import BatchStock from "@c_modules/Stk/BatchStock";
import StockVmi from "@c_modules/Stk/StockVMI";
import StockFriendDetails from "@c_modules/Open/StockFriendDetails";
import InquireDetails from "@c_modules/RFQ/InquireDetails";
import BomQuoteDetails from "@c_modules/BOM/BomQuoteDetails";
import StkOutDetails from "@c_modules/Stk/StkOutDetails";
import StkInDetails from "@c_modules/Stk/StkInDetails";
import ReserveManage from "@c_modules/SPM/ReserveManage";
import StockBuyerManage from "@c_modules/PPM/StockBuyerManage";
import LargeOrderPurchase from "@c_modules/Stk/LargeOrderPurchase";
import FileLibrary from "@c_modules/Stk/FileLibrary";

// 窗口大小监控mixin
import Resize from "~assets/scripts/mixins/resize";
import { isClient } from "~/lib/runtime";

const tabs = [
  { name: "BatchesStock", ref: "batchesStock" },
  { name: "SummaryStock", ref: "summaryStock" },
  { name: "StockVMI", ref: "stockVMI" },
  { name: "PubSStockFriend", ref: "subscribeInv" },
  { name: "StkInquireRecord", ref: "inquireRecord" },
  { name: "BomMastLineSelect", ref: "quoteRecord" },
  { name: "PastStkOut", ref: "outRecord" },
  { name: "PastStkIn", ref: "inRecord" },
  { name: "SalesStocking", ref: "stockReserve" },
  { name: "PurchaseOrder", ref: "stockPurchase" },
];

export default {
  name: "StockManage",
  mixins: [Resize],
  inject: ["appVm"],
  components: {
    ZnlSearchCondition,
    ZnlStockSummary,
    BatchStock,
    StockVmi,
    StockFriendDetails,
    InquireDetails,
    BomQuoteDetails,
    StkOutDetails,
    StkInDetails,
    ReserveManage,
    StockBuyerManage,
    LargeOrderPurchase,
    FileLibrary,
  },

  data() {
    return {
      searchModel: { Model: "" },
      pubSStockFriendSearchModel: {}, // 订阅库存
      stkInquireRecordSearchModel: {}, // 询价记录
      bomMastLineSelectSearchModel: {}, // 给客户报价记录
      pastStkOutSearchModel: {}, // 出库记录
      pastStkInSearchModel: {}, // 入库记录
      salesStockingSearchModel: {}, // 销售备货
      purchaseSearchModel: {}, // 采购订货
      stockVMISearchModel: {}, // 供应商库存
      summaryStockSearchModel: {}, // 汇总库存
      batchesStockSearchModel: {}, // 批次库存
      pageData: [],
      pageLoading: true,
      activeName: "BatchesStock",
      // activeName: "LargeOrderPurchase",
      firstLoad: true,
      refreshWarningCount: true, // 标记刷新汇总库存预警数量
      stockInitData: false,
      sumStockInitData: false,
      stockVMIInitData: false,
      openStockInitData: false,
      inquireDetailsInitData: false,
      bomDetailsInitData: false,
      stkOutDetailsInitData: false,
      stkInDetailsInitData: false,
      reserveManageInitData: false,
      stockBuyerManageInitData: false,
      SummaryStockAddOk: false, // 汇总组件是否已挂载
      fileLibraryInitData: false,
    };
  },
  computed: {
    SummaryStockAdd() {
      let { SummaryStockAddOk, activeName } = this;

      if (this.SummaryStockAddOk) {
        return true;
      } else if (activeName === "SummaryStock") {
        this.SummaryStockAddOk = true;
        return true;
      }
    },
  },
  methods: {
    onresize() {
      // console.log('resizing...');
      //从store取得domRect，在计算
      const height = isClient()
        ? this.$store.state.domRect.clientHeight
        : this.$store.state.domRect.clientHeight - 50;

      const idx = tabs.findIndex((item) => item.name == this.activeName);
      if (idx > -1) {
        //当前tab处理
        if (this.$refs[tabs[idx].ref]) {
          //如果有flexGrid
          if (this.$refs[tabs[idx].ref].$refs.flexGrid) {
            this.$refs[tabs[idx].ref].$refs.flexGrid.gridHeightValue =
              height - 180;
            this.$refs[tabs[idx].ref].$refs.flexGrid.$forceUpdate();
          }
          //如果有table
          if (this.$refs[tabs[idx].ref].$refs.table) {
            this.$refs[tabs[idx].ref].$refs.table.boxHeight = height - 108;
            // this.$refs[tabs[idx].ref].$refs.table.init();
            this.$refs[tabs[idx].ref].$refs.table.$forceUpdate();
          }
        }
      }

      // if(this.$refs.batchesStock){
      //   this.$refs.batchesStock.$refs.table.boxHeight = height - 118
      // }
      // if(this.$refs.summaryStock){
      //   this.$refs.summaryStock.$refs.flexGrid.gridHeightValue = height - 180
      // }
      // if(this.$refs.stockVMI){
      //   this.$refs.stockVMI.$refs.table.boxHeight = height - 106
      // }
      // if(this.$refs.inquireRecord){
      //   this.$refs.inquireRecord.$refs.table.boxHeight = height - 106
      // }
      // if(this.$refs.quoteRecord){
      //   this.$refs.quoteRecord.$refs.table.boxHeight = height - 106
      // }
      // if(this.$refs.outRecord){
      //   this.$refs.outRecord.$refs.table.boxHeight = height - 106
      // }
      // if(this.$refs.inRecord){
      //   this.$refs.inRecord.$refs.table.boxHeight = height - 106
      // }
      // if(this.$refs.stockReserve){
      //   this.$refs.stockReserve.$refs.table.boxHeight = height - 106
      // }
      // if(this.$refs.stockPurchase){
      //   this.$refs.stockPurchase.$refs.table.boxHeight = height - 106
      // }
    },

    /* 大单求购处理 */
    largeOrderPurchaseHandler() {
      let query = this.$route.query;
      if (query.tagName && query.tagName === "LargeOrderPurchase") {
        this.activeName = "LargeOrderPurchase";
        this.$nextTick(() => {
          this.$refs.LargeOrderPurchase.onQuotePrice(query);
        });
      }
    },

    async onInit() {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl;
      return await this.$post(pageUrl, { PageCode: "VStock" }, (data) => {
        this.pageData = data;
      });
    },
    hasRight(code) {
      return _.some(this.pageData, (page) => page.Code === code);
    },
    setClientSelectLoading(val) {
      this.pageLoading = val;
    },
    onSearch(val) {
      this.searchModel = val;
      this.pageSearch(true);
      this.onresize();
    },
    onModuleSearch(module, param) {
      this.$nextTick(() => {
        module.onHeadSearch(param);
      });
    },
    pageSearch(isRefresh) {
      setTimeout(() => {
        // console.log('table 改变...');
        this.onresize();
      }, 300);
      if (this.activeName === "BatchesStock" && !this.firstLoad) {
        // 批次库存
        if (!this.stockInitData) {
          this.stockInitData = true;
        }
        if (
          isRefresh ||
          !_.isEqual(this.batchesStockSearchModel, this.searchModel)
        ) {
          this.batchesStockSearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.batchesStock.onHeadSearch(this.batchesStockSearchModel);
          });
        }
      } else if (this.activeName === "SummaryStock" && !this.firstLoad) {
        // 汇总库存
        if (!this.sumStockInitData) {
          this.sumStockInitData = true;
        }
        if (
          isRefresh ||
          !_.isEqual(this.summaryStockSearchModel, this.searchModel)
        ) {
          this.summaryStockSearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.summaryStock.onHeadSearch(this.summaryStockSearchModel);
          });
        }
      } else if (this.activeName === "StockVMI" && !this.firstLoad) {
        // 供应商库存
        if (!this.stockVMIInitData) {
          this.stockVMIInitData = true;
          this.$refs.stockVMI.$refs.table.init();
        }
        if (
          isRefresh ||
          !_.isEqual(this.stockVMISearchModel, this.searchModel)
        ) {
          this.stockVMISearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.stockVMI.onHeadSearch(this.stockVMISearchModel);
          });
        }
      } else if (this.activeName === "PubSStockFriend" && !this.firstLoad) {
        // 订阅库存
        if (!this.openStockInitData) {
          this.openStockInitData = true;
        }
        if (
          isRefresh ||
          !_.isEqual(this.pubSStockFriendSearchModel, this.searchModel)
        ) {
          this.pubSStockFriendSearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.subscribeInv.onHeadSearch(
              this.pubSStockFriendSearchModel
            );
          });
        }
      } else if (this.activeName === "StkInquireRecord" && !this.firstLoad) {
        // 询价记录
        if (!this.inquireDetailsInitData) {
          this.inquireDetailsInitData = true;
          this.$refs.inquireRecord.$refs.table.init();
        }
        if (
          isRefresh ||
          !_.isEqual(this.stkInquireRecordSearchModel, this.searchModel)
        ) {
          this.stkInquireRecordSearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.inquireRecord.onHeadSearch(
              this.stkInquireRecordSearchModel
            );
          });
        }
      } else if (this.activeName === "BomMastLineSelect" && !this.firstLoad) {
        // 给客户报价记录
        if (!this.bomDetailsInitData) {
          this.bomDetailsInitData = true;
          this.$refs.quoteRecord.$refs.table.init();
        }
        if (
          isRefresh ||
          !_.isEqual(this.bomMastLineSelectSearchModel, this.searchModel)
        ) {
          this.bomMastLineSelectSearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.quoteRecord.onHeadSearch(
              this.bomMastLineSelectSearchModel
            );
          });
        }
      } else if (this.activeName === "PastStkOut" && !this.firstLoad) {
        // 出库记录
        if (!this.stkOutDetailsInitData) {
          this.stkOutDetailsInitData = true;
          this.$refs.outRecord.$refs.table.init();
        }
        if (
          isRefresh ||
          !_.isEqual(this.pastStkOutSearchModel, this.searchModel)
        ) {
          this.pastStkOutSearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.outRecord.onHeadSearch(this.pastStkOutSearchModel);
          });
        }
      } else if (this.activeName === "PastStkIn" && !this.firstLoad) {
        // 入库记录
        if (!this.stkInDetailsInitData) {
          this.stkInDetailsInitData = true;
          this.$refs.inRecord.$refs.table.init();
        }
        if (
          isRefresh ||
          !_.isEqual(this.pastStkInSearchModel, this.searchModel)
        ) {
          this.pastStkInSearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.inRecord.onHeadSearch(this.pastStkInSearchModel);
          });
        }
      } else if (this.activeName === "SalesStocking" && !this.firstLoad) {
        // 销售备货
        if (!this.reserveManageInitData) {
          this.reserveManageInitData = true;
          this.$refs.stockReserve.$refs.table.init();
        }
        if (
          isRefresh ||
          !_.isEqual(this.salesStockingSearchModel, this.searchModel)
        ) {
          this.salesStockingSearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.stockReserve.onHeadSearch(this.salesStockingSearchModel);
          });
        }
      } else if (this.activeName === "PurchaseOrder" && !this.firstLoad) {
        // 采购订货
        if (!this.stockBuyerManageInitData) {
          this.stockBuyerManageInitData = true;
          this.$refs.stockPurchase.$refs.table.init();
        }
        if (
          isRefresh ||
          !_.isEqual(this.purchaseSearchModel, this.searchModel)
        ) {
          this.purchaseSearchModel = this.searchModel;
          this.$nextTick(() => {
            this.$refs.stockPurchase.onHeadSearch(this.purchaseSearchModel);
          });
        }
      } else if (this.activeName === "FileLibrary" && !this.firstLoad) {
        console.log("fileLibraryInitData is ", this.fileLibraryInitData);
        if (!this.fileLibraryInitData) {
          this.fileLibraryInitData = true;
          this.$refs.fileLibrary.$refs.table.init();
          this.$nextTick(() => {
            this.$refs.fileLibrary.onBindData();
          });
        }
      }
      this.firstLoad = false;
    },

    // 改变页签
    changeTab(tabName, searchCondition) {
      this.activeName = tabName;
      this.onSearch(searchCondition);
    },
    // 默认页签
    defaultInitData() {
      if (this.hasRight("StkStock")) {
        this.stockInitData = true;
        if (this.activeName !== "LargeOrderPurchase")
          this.activeName = "BatchesStock";
      } else if (this.hasRight("StockVMI")) {
        this.stockInitData = true;
        this.activeName = "StockVMI";
      } else if (this.hasRight("SubscribeStock")) {
        this.openStockInitData = true;
        this.activeName = "SubscribeStock";
      } else if (this.hasRight("StkInquireRecord")) {
        this.inquireDetailsInitData = true;
        this.activeName = "StkInquireRecord";
      } else if (this.hasRight("BomMastLineSelect")) {
        this.bomDetailsInitData = true;
        this.activeName = "BomMastLineSelect";
      } else if (this.hasRight("PastStkOut")) {
        this.stkOutDetailsInitData = true;
        this.activeName = "PastStkOut";
      } else if (this.hasRight("PastStkIn")) {
        this.stkInDetailsInitData = true;
        this.activeName = "PastStkIn";
      } else if (this.hasRight("StkStockReserve")) {
        this.reserveManageInitData = true;
        this.activeName = "StkStockReserve";
      } else if (this.hasRight("StkStockPurchase")) {
        this.stockBuyerManageInitData = true;
        this.activeName = "StkStockPurchase";
      }
    },

    // 获取搜索条件
    onGetSearchParam(callbackFn) {
      this.$refs.ZnlSearchCondition.getSearchParam(callbackFn);
    },
  },
  async created() {
    await this.onInit();
    ``;
    this.$nextTick(() => {
      this.defaultInitData();
    });
    this.appVm.setNavigateWatchEventConfig({
      source: "stkAll",
      path: "/stk/stockall",
      event: (v) => {
        if (!v.tagName || v.tagName === "") return;
        this.activeName = v.tagName;
        this.$nextTick(() => {
          this.$refs.LargeOrderPurchase.onQuotePrice(v);
        });
      },
    });
  },
  mounted() {
    this.largeOrderPurchaseHandler();

    if (0) {
      let time = setTimeout(() => {
        this.activeName = "LargeOrderPurchase";
        this.$nextTick(() => {
          this.$refs.LargeOrderPurchase.onQuotePrice({
            BGuid: "04e5116d-f6f9-481c-8dff-1085a613df71",
            FromBy: 2,
          });
        });
      }, 2000);
    }
  },

  watch: {},
};
</script>

<style lang="scss">
.m-stock-tab-manage {
  .el-tabs__item::before,
  .is-active::before {
    bottom: -1px !important;
  }
}
</style>

