
<template>
 <!-- // 页面的模块设置 -->
  <znl-dialog
    class="znl-layout-config-dialog"
    :visible="visible"
    title="页面配置"
    width="450px"
    @close="()=>{$emit('close')}">
        <el-tabs  class="" v-model="moduleName">
            <el-tab-pane label="模块设置" name="moduleParam" height="100%">
              <div >
                <div class="item title">
                  <span class="title">模块名称</span>
                  <span>是否显示</span>
                </div>
                <!-- 超出 滚动 -->
                <div style="height:320px;overflow-y: auto" >
                  <div v-for="(item) in configInfo.menus" :key="item.name" class="item" v-if="typeof item.allowDeploy === 'undefined' ? true : item.allowDeploy">
                    <span class="title">{{item.title || item.name}}</span>
                    <el-switch
                    v-model="item.visible"
                    active-color="#13ce66"
                    inactive-color = "#ccc">
                    </el-switch>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="参数设置" name="pageParam" height="100%">
              <div >
                <div class="item title">
                  <span class="title">参数名称</span>
                  <span>参数值</span>
                </div>
                 <!-- 超出 滚动 -->
                <div style="height:320px;overflow-y: auto" >
                  <div class="item">
                      <span >布局形式</span>
                      <span>
                        <znl-input
                          title-width="70px"
                          layout="left"
                          form-type="select"
                          width="100%"
                          placeholder="请选择"
                          popper-class="popperClass"
                          title=""
                          :border="true"
                          :is-must-fill="false"
                          :disabled="false"
                          :clearable="true"
                          :select-options="selectLayoutType"
                          v-model="configInfo.type">
                        </znl-input>
                      </span>
                  </div>
                </div>
              </div>
            </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="footer">
          <znl-button v-if="!!onResetSetting" type="text" @click.stop="resetSetting">重置配置</znl-button>
          <znl-button :height="30" :width="50" @click.stop="()=>{$emit('close')}">关闭</znl-button>
          <znl-button v-if="!!onSaveConfig" :height="30" :width="50" style-type="main" @click.stop="confirmCallBack">确认</znl-button>
      </div>
  </znl-dialog>
</template>

<script>
export default {
  name: 'PageGridConfig',
  props: {
    visible: Boolean,
    onSaveConfig: Function,
    onResetSetting: Function,
    configInfo: {
      menus: [],
      type: ''
    }
  },

  components: {},
  data () {
    return {
      value: false,
      moduleName: 'moduleParam',
      selectLayoutType: [
        {
          key: 0,
          value: '页签形式'
        },
        {
          key: 1,
          value: '平铺形式'
        }
      ]
    }
  },

  methods: {
    resetSetting () {
      if (typeof this.onResetSetting === 'function') {
        let ResetSetting = this.onResetSetting()
        if (ResetSetting && ResetSetting.then) {
          ResetSetting.then(() => {
            this.$emit('update:visible', false)
            this.$emit('close')
          })
        }
      }
    },

    async confirmCallBack () {
      let config = {
        Type: this.configInfo.type,
        Menus: _.map(this.configInfo.menus, item => {
          return {
            name: item.name,
            visible: item.visible,
            title: item.title,
            hasRight: item.hasRight,
            index: item.index
          }
        })}
      this.$emit('close')
      if (typeof this.onSaveConfig === 'function') {
        let saveConfig = this.onSaveConfig(config)
        if (saveConfig && saveConfig.then) {
          saveConfig.then(() => {
            this.$emit('update:visible', false)
          })
        }
      }
    }
  }
}
</script>
<style lang="scss">
.znl-layout-config-dialog{
  .znl-dialog__body{
    padding-top: 0;
    padding-bottom: 0;
  }
  .item{
    display: flex;
    justify-content: space-around;
    padding: 3px 0;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    align-items: center;
    &.title{
      font-weight: 600;
    }
    .title{
      width: 40%;
    }
    &:last-child{
      border-bottom: 1px solid #E0E0E0;
    }
  }
  .footer{
    .znl-button{
      margin-left: 8px;
    }
    .znl-btn-text{
      position: relative;
      bottom: -6px;
    }
  }
}
</style>

