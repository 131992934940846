<template>
  <znl-gridmodule
    gridtype="action"
    ref="flexGrid"
    layout-type="other"
    height="60px"
    :has-znl-btns="false"
    :columns="columns"
    :from-height="90"
    :is-show-frombtns="false"
    :is-init="true"
    :has-znl-actions="false"
    :isBtn="false"
    :item-source="itemSource"
    :hasDefaultBeforeNewRow="true"
    :is-fields-search="false"
    :is-multi-rows-check="false"
    :search-fields="searchModel"
    :show-right-menu="false"
    :role="role"
    :show-shortcut-column="false"
    :users="$store.state.users"
    :on-init="onInit"
    :cell-class-name="getCellClassName"
  >
    <div slot="from-module" style="padding:0; margin-bottom: 0;">
      <el-row>
        <el-col :span="5">
          <h3 style="font-size:16px;color:#4D4D4D;">合并后的库存信息:</h3>
        </el-col>
        <el-col :span="6">
          <znl-input
            title-width="80px"
            form-type="input"
            size="mini"
            layout="left"
            class="ipttop"
            placeholder
            :is-must-fill="false"
            :clearable="true"
            :border="true"
            title="销售指导价："
            width="100%"
            v-model="stock.followPrice"
            type="text"
          ></znl-input>
        </el-col>
        <el-col :span="6">
          <znl-input
            title-width="90px"
            form-type="input"
            class="ipttop"
            size="mini"
            layout="left"
            :clearable="true"
            :border="true"
            :is-must-fill="false"
            placeholder
            title="MarkingCode："
            width="100%"
            v-model="stock.markingCode"
            type="text"
          ></znl-input>
        </el-col>
        <el-col :span="6">
          <znl-input
            title-width="80px"
            form-type="input"
            size="mini"
            class="ipttop"
            layout="left"
            :clearable="true"
            :is-must-fill="false"
            :border="true"
            placeholder
            title="功能描述："
            width="100%"
            v-model="stock.explain"
            type="text"
          ></znl-input>
        </el-col>
      </el-row>
    </div>
  </znl-gridmodule>
</template>

<script>
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
export default {
  name: 'StkStockMerge',
  mixins: [getCommonDataMixin],
  components: {
  },
  data () {
    return {
      config: {
        saveMergeURL: 'StkStockMerge/Save'
      },
      stock: {// 库存对象
        followPrice: '',
        markingCode: '',
        explain: ''
      },
      columns: [],
      itemSource: [],
      columnColors: [],
      role: '1',
      btnState: true,
      taxBuyPriceIsPass: true
    }
  },
  computed: {
    taxBuyPriceCellClass () {
      return this.taxBuyPriceIsPass ? "stock-merge-after-bg-normal" : "stock-merge-after-bg-red";
    }
  },
  props: {
    height: String,
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    stockList: {
      type: Array,
      default: () => {
        return []
      }
    },
    markingCODE: String
  },
  watch: {
    stockList: {
      handler (value) {
        if (value !== undefined && value !== null) {
          this.onBindData()
        }
      },
      immediate: true
    }
  },
  methods: {
    onInit: async function () {
      let gtidConfig = [
        { CTitle: '产品类型',
          BindField: 'ProductCategory',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 100,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '型号',
          BindField: 'Model',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 150,
          DisplayIndex: 1,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '品牌',
          BindField: 'Brand',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 2,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '封装',
          BindField: 'Packaging',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 3,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '批号',
          BindField: 'MakeYear',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 4,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '库存量',
          BindField: 'InvQty',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 5,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '包装',
          BindField: 'MPQ',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 80,
          DisplayIndex: 6,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '位置',
          BindField: 'StorageName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 100,
          DisplayIndex: 7,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '采购价(未税)',
          BindField: 'BuyPrice',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 95,
          DisplayIndex: 8,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '采购价(含税)',
          BindField: 'TaxBuyPrice',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 95,
          DisplayIndex: 8,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '库存类型',
          BindField: 'InvType',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 60,
          DisplayIndex: 9,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '入库日期',
          BindField: 'StkInTime',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 72,
          DisplayIndex: 10,
          DataType: 4,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '采购员',
          BindField: 'BuyerName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 48,
          DisplayIndex: 11,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '供应商',
          BindField: 'SupplierName',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 180,
          DisplayIndex: 12,
          DataType: 1,
          IsSystem: false,
          IsRequired: false },
        { CTitle: '备注',
          BindField: 'Remark',
          IsShow: true,
          IsReadonly: false,
          ColumnWidth: 180,
          DisplayIndex: 13,
          DataType: 1,
          IsSystem: false,
          IsRequired: false }
      ]

      let columns = this.flexGridColumnsHandler(gtidConfig)
      // 下拉列表
      let stkTypeColumn = _.find(columns, column => column.name === 'InvType')
      console.log(await this.selectStorageType, 1234)
      stkTypeColumn && _.extend(stkTypeColumn, {
        dataMap: await this.selectStorageType,
        showDropDown: true
      })

      this.columns = columns
      this.onBindData()
    },
    onBindData () {
      if (this.stockList === null || this.stockList.length < 2) {
        return
      }
      let info = {
        ProductCategory: this.stockList[0].ProductCategory,
        Model: this.stockList[0].Model,
        Brand: this.stockList[0].Brand,
        Packaging: this.stockList[0].Packaging,
        MakeYear: this.stockList[0].MakeYear,
        MPQ: this.stockList[0].MPQ,
        StorageName: this.stockList[0].StorageName,
        InvPosition: this.stockList[0].InvPosition,
        BuyPrice: this.stockList[0].BuyPrice,
        TaxBuyPrice: this.stockList[0].TaxBuyPrice,
        FollowPrice: this.stockList[0].FollowPrice,
        MarkingCode: this.stockList[0].MarkingCode,
        Explain: this.stockList[0].Explain,
        Remark: this.stockList[0].Remark,
        InvType: this.stockList[0].InvType,
        BuyerName: this.stockList[0].BuyerName,
        SupplierName: this.stockList[0].SupplierName,
        Unit: this.stockList[0].Unit,
        SalesPrice: this.stockList[0].SalesPrice
      }
      let totalQty = 0
      let calTotalQty = 0//计算总数量（绝对值），用于计算进价
      let calTotalAmount = 0//计算总金额（绝对值），用于计算进价
      let currencyCode = "";
      let hasTaxBuyPrice = false; // 确保如果所有行的采购价含税都是空，最后计算出来的也是空，而不是0
      let totalTaxAmount = 0;
      _.each(this.stockList, item => {
        if (!this.hasValue(info.Partner)) {
          info.Partner = item.Partner
        }
        if (!this.hasValue(info.MarkingCode)) {
          info.MarkingCode = item.MarkingCode
        }
        if (!this.hasValue(info.PlatFormCodes)) {
          info.PlatFormCodes = item.PlatFormCodes
        }
        if (!this.hasValue(info.BuyerName)) {
          info.BuyerName = item.BuyerName
        }
        if (!this.hasValue(info.SupplierName)) {
          info.SupplierName = item.SupplierName
        }

        let qty = this.ConvertNumber(Math.abs(item.InvQty), 6);
        totalQty += item.InvQty
        calTotalQty += qty

        let buyPrice = 0;
        if (this.hasValue(item.BuyPrice)) {
          buyPrice = item.BuyPrice
        }
        let taxBuyPriceInfo = this.getStockBuyPrice(item.TaxBuyPrice);
        console.log(taxBuyPriceInfo, 34556);
        if (taxBuyPriceInfo && taxBuyPriceInfo.value != null) {
          if (!this.hasValue(currencyCode)) // 记录首次币种
            currencyCode = taxBuyPriceInfo.code;
          if (this.hasValue(taxBuyPriceInfo.code) && taxBuyPriceInfo.code != currencyCode) { // 如果币种不一致，标记计算失败
            this.taxBuyPriceIsPass = false;
            console.log('币种不一致', currencyCode);
          }
          totalTaxAmount = this.toFloat(Math.abs(taxBuyPriceInfo.value * qty), 6);
          hasTaxBuyPrice = true;
        } else if (this.hasValue(item.TaxBuyPrice)) { // 含税采购价有值，但是无法提取价格，标记计算失败
          this.taxBuyPriceIsPass = false;
        }
        calTotalAmount += this.toFloat(Math.abs(buyPrice) * qty)
      })

      info.InvQty = totalQty
      if (calTotalQty > 0) {
        info.BuyPrice = this.toFloat(calTotalAmount / this.ConvertNumber(calTotalQty), 6)
        if (this.taxBuyPriceIsPass) {
          this.$emit("changeSaveTips", "");
          if (hasTaxBuyPrice)
            info.TaxBuyPrice = this.toFloat(totalTaxAmount / calTotalQty, 6) + currencyCode;
        } else {
          this.$emit("changeSaveTips", "采购价(含税)计算失败，请手动确认");
          info.TaxBuyPrice = "";
        }
      }
      info.StkInTime = new Date().toLocaleDateString()
      let item = []
      item.push(info)
      this.itemSource = item
    },
    ConvertNumber (num) {
      return (_.isNaN(num) || _.isUndefined(num)) ? null : this.toFloat(num, 6)
    },
    onReset () {
      this.stock.followPrice = ''
      this.stock.markingCode = ''
      this.stock.explain = ''
    },
    onSave () { // 确认合并
      let row = this.itemSource
      row[0].FollowPrice = this.stock.followPrice
      row[0].MarkingCode = this.stock.markingCode
      row[0].Explain = this.stock.explain
      // 库存量
      if (!_.isNumber(Number(row[0].InvQty))) {
        return this.$message({ message: '库存量格式不正确,必须为数值!', type: 'warning' })
      }
      if (!_.isNumber(Number(row[0].BuyPrice))) {
        return this.$message({ message: '进价格式不正确,必须为数值!', type: 'warning' })
      }
      if (_.isNaN(Number(row[0].FollowPrice))) {
        return this.$message({ message: '销售指导价格式不正确,必须为数值!', type: 'warning' })
      }

      if (!this.hasValue(row[0].InvType)) {
        this.$message({ message: '库存类型为必选项!', type: 'warning' })
        return
      }

      let models = _.uniq(_.map(this.stockList, item => item.Model))
      if (models.length >= 2) {
        return this.$confirm('有型号不同的库存，确认要合并吗?', '合并库存', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.saveData(row)
        })
      } else {
        this.saveData(row)
      }
    },

    saveData (val) {
      let row = []
      _.each(this.stockList, item => {
        row.push(item)
      })
      row.unshift(val[0])
      this.$emit('page-loading', true)
      return this.$post(this.config.saveMergeURL, row, (datas) => {
        if (datas > 0) {
          this.$message({ message: '库存合并成功', type: 'success' })
          this.$emit('save-success')
        }
      }).finally(() => {
        this.$emit('page-loading', false)
      })
    },

    // 提取库存含税采购价
    getStockBuyPrice (taxBuyPrice) {
      let result = {
        code: "",
        value: null
      }
      if (!this.hasValue(taxBuyPrice))
      {
          return result;
      }
      taxBuyPrice = taxBuyPrice.trim();
      // 币别结尾
      var codeList = "RMB,USD,HKD".split(',');
      let numberExp = /^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/;
      for (let i = 0; i < codeList.length; i++) {
        const code = codeList[i];
        if (!taxBuyPrice.endsWith(code))
          continue;
        var v = taxBuyPrice.replace(code, "");
        if (this.hasValue(v) && numberExp.test(v)) {
          result.code = code;
          result.value = v;
          return result;
        }
      }

      // 货币符号开头
      if (taxBuyPrice.startsWith("￥")) {
          var v = taxBuyPrice.replace("￥", "");
          if (this.hasValue(v) && numberExp.test(v)) {
              result.code = "RMB";
              result.value = v;
              return result;
          }
      }

      if (taxBuyPrice.startsWith("$")) {
          var v = taxBuyPrice.replace("$", "");
          if (this.hasValue(v) && numberExp.test(v)){
              result.code = "USD";
              result.value = v;
              return result;
          }
      }

      // 尝试直接转换
      if (numberExp.test(taxBuyPrice)) {
          result.value = taxBuyPrice;
          return result;
      }

      return result;
    },
    getCellClassName (e) {
      let col = e.column
      if (col.columnKey === "TaxBuyPrice") {
        return this.taxBuyPriceCellClass;
      }
      return "";
      // console.log(arguments, 123456);
    },
    onSaveSetting (columns, pageSize) {
      return this.$post(this.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.config.default))
    },
    onResetSetting () {
      return this.$post(this.config.resetConfigURL)
    }
  }
}
</script>

<style lang="scss">
.ipttop {
  padding-top: 18px;
}
.stock-merge-after-bg-red {
  background-color: red !important;
  color: #fff !important;
}
.stock-merge-after-bg-normal {
  background-color: #fff;
  color: #000;
}
</style>
