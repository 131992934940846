<template>
  <znl-express-order-add :searchModel="ordExpressOrderAdd"></znl-express-order-add>
</template>

<script>
import znlExpressOrderAdd from '@c_modules/Ord/ExpressOrderAdd'

export default {
  name: 'VExpressOrderAdd',
  components: {
    znlExpressOrderAdd
  },
  data () {
    return {
      ordExpressOrderAdd: {}
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>

