import { render, staticRenderFns } from "./pageGridConfig.vue?vue&type=template&id=8af6fcac&"
import script from "./pageGridConfig.vue?vue&type=script&lang=js&"
export * from "./pageGridConfig.vue?vue&type=script&lang=js&"
import style0 from "./pageGridConfig.vue?vue&type=style&index=0&id=8af6fcac&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports