<template>
  <znl-dialog
    :visible="visible"
    width="720px"
    height="500px"
    :footer-height="0"
    class="dialog-reserve-stock-box dialog-grid-footer-box"
    title="销售备货详情"
    @close="()=>{$emit('close')}"
  >
    <znl-gridmodule
      ref="flexGrid"
      gridtype="base"
      :columns="columns"
      :grid-show="true"
      :has-znl-actions="false"
      :has-znl-btns="false"
      :item-source="itemSource"
      :column-colors="columnColors"
      :row-colors="rowColors"
      :summary-columns="summaryColumns"
      :is-multi-rows-check="false"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :users="$store.state.users"
      :on-init="onInit"
      :is-init="true"
      :on-refresh="onRefresh"
      :on-search="onSearch"
      :on-before-add-row="onBeforeAddRow"
      :on-page-changed="onBindData"
      @page-loading="d=>{$emit('page-loading', d)}"
      @selection-changed="d=>{$emit('selection-changed',d)}"
    ></znl-gridmodule>
    <el-row slot="footer" style="display: none;"></el-row>
  </znl-dialog>
</template>
<script>

export default {
  name: 'StkStockReserveDetail',
  components: {
  },
  data () {
    return {
      StkGUID: '', // stkguid
      config: {
        configURL: 'StkStockReserveDetail/GetConfig',
        saveConfigURL: 'StkStockReserveDetail/SaveConfig',
        resetConfigURL: 'StkStockReserveDetail/ResetConfig',
        default: null,
        searchURL: 'StkStockReserveDetail/SearchDetail'
      },
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: []
    }
  },
  computed: {},
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    },
    height: String,
    isRefresh: Boolean, // 刷新页面
    stkguid: String, // 库存信息
    visible: Boolean
  },
  watch: {
    isRefresh: {
      handler (value) {
        this.LoadByStk(this.stkguid)
      },
      immediate: true
    }
  },
  methods: {
    onInit: async function () {
      let configs = await this.$post(this.config.configURL)
      let columns = this.flexGridColumnsHandler(configs.ColumnConfigs)
      this.defaultConfig = configs
      this.columns = columns
      this.role = configs.Role
    },
    onBindData (pageIndex = 1) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          StkGUID: this.StkGUID
        }
      )
      return this.$post(this.config.searchURL, data, data => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onRefresh () {
      return this.onBindData(this.pageIndex)
    },
    onSaveSetting (columns, pageSize) {
      return this.$post(
        this.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.config.default)
      )
    },
    onResetSetting () {
      return this.$post(this.config.resetConfigURL)
    },
    onBeforeAddRow () { },
    LoadByStk (stkguid) {
      this.$nextTick(function () {
        this.StkGUID = stkguid
        this.onBindData(this.pageIndex)
      })
    }
  }
}
</script>

<style lang="scss">
</style>
