<template>
  <div slot="action-form">
    <!-- 库存管理搜索模块 -->
    <div class="StockManage-search-box">
      <el-row style="width: 1200px">
        <el-col :span="14">
          <el-row style="width: 700px">
            <el-col :span="8">
              <znl-input
                v-model="searchValue1"
                :border="true"
                :clearable="true"
                :disable="false"
                :height="26"
                :is-upper-case="true"
                from-type="input"
                inp-perc="100%"
                layout="left"
                placeholder="搜索条件一"
                size="mini"
                type="text"
                width="170px"
                @keyup.enter.native="onSearch"
              ></znl-input>
              <znl-input
                v-model="queryCondition1"
                :border="true"
                :clearable="false"
                :is-must-fill="false"
                :select-options="selectStockSearchField"
                form-type="select"
                layout="left"
                placeholder="请选择"
                title-width="0px"
                width="60px"
                @change="
                  (val) => {
                    changeOption(val);
                  }
                "
              ></znl-input>
            </el-col>
            <el-col :span="8">
              <znl-input
                v-model="searchValue2"
                :border="true"
                :clearable="true"
                :disable="false"
                :height="26"
                :is-upper-case="true"
                from-type="input"
                inp-perc="100%"
                layout="left"
                placeholder="搜索条件二"
                size="mini"
                type="text"
                width="170px"
                @keyup.enter.native="onSearch"
              ></znl-input>
              <znl-input
                v-model="queryCondition2"
                :border="true"
                :clearable="false"
                :is-must-fill="false"
                :select-options="selectStockSearchField"
                form-type="select"
                layout="left"
                placeholder="请选择"
                popper-class="popperClass"
                title-width="0px"
                width="60px"
                @change="
                  (val) => {
                    changeOption(val);
                  }
                "
              ></znl-input>
            </el-col>
            <el-col :span="8">
              <znl-input
                v-model="searchValue3"
                :border="true"
                :clearable="true"
                :disable="false"
                :height="26"
                :is-upper-case="true"
                from-type="input"
                inp-perc="100%"
                layout="left"
                placeholder="搜索条件三"
                size="mini"
                type="text"
                width="170px"
                @keyup.enter.native="onSearch"
              ></znl-input>
              <znl-input
                v-model="queryCondition3"
                :border="true"
                :clearable="false"
                :is-must-fill="false"
                :select-options="selectStockSearchField"
                form-type="select"
                layout="left"
                placeholder="请选择"
                popper-class="popperClass"
                title-width="0px"
                width="60px"
                @change="
                  (val) => {
                    changeOption(val);
                  }
                "
              ></znl-input>
            </el-col>
          </el-row>
          <el-row style="width: 700px">
            <el-col :span="9">
              <label>型号匹配方式：</label>
              <el-radio-group v-model="radio">
                <el-radio :label="2">模糊</el-radio>
                <el-radio :label="0">左边精确</el-radio>
                <el-radio :label="1">精确</el-radio>
              </el-radio-group>
            </el-col>
            <el-col :span="9">
              <el-checkbox v-model="isZero" @change="changeCheck"
                >查零库存</el-checkbox
              >
              <el-checkbox v-model="isNegative" @change="changeCheck"
                >查负库存</el-checkbox
              >
              <el-checkbox v-model="isPositive" @change="changeCheck"
                >查正库存</el-checkbox
              >
            </el-col>
            <el-col :span="6">
              <znl-input
                ref="hmselect"
                :border="true"
                :clearable="true"
                :select-options="hmSelectOptions"
                form-type="select"
                layout="left"
                name="hmselect"
                placeholder="最近搜索型号"
                popper-class="popperClass"
                size="mini"
                width="172px"
                @change="hmSelectChange"
              ></znl-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="10" style="line-height: 50px; height: 50px">
          <el-row>
            <znl-button
              :height="35"
              :width="66"
              class="box-left-btn search-btn search-btn-1111"
              style="line-height: 33px; margin-left: 10px"
              style-type="main"
              @click="onSearchBtn"
            >
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button>
            <span
              v-show="warningCount > 0"
              class="qty-warning"
              @click="onWarningClick"
              >有{{ warningCount }}个型号库存已报警，点击查看详情>></span
            >

            <!-- AI全球找料 -->
            <GlobalCargo style="position: relative;top: 7px;margin-left: 5px;"></GlobalCargo>
          </el-row>
        </el-col>
        <!-- <el-col :span="0"></el-col> -->
      </el-row>
    </div>
    <a v-if="isErpClient" class="old-edition" @click="oldEdition()"
      >切换到旧版</a
    >

    <stk-warn-list ref="stkWarnList"></stk-warn-list>
  </div>
</template>
<script>
import { switchToLegacyPage } from "~/lib/native";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { isClient } from "~/lib/runtime";

import stkWarnList from "@c_modules/Stk/StkWarnList";
import GlobalCargo from "~/components/common/global-cargo/index";

const CONFIG = {
  GetPageParamURL: "PageParam/Get", // 获取页面参数配置
  SavePageParamURL: "PageParam/Save", // 保存页面参数配置
  GetWarningCountUrl: "StockSummary/GetWarningCount",
};
export default {
  name: "StockSearchManage",
  mixins: [getCommonDataMixin],
  config: CONFIG,
  components: {
    stkWarnList,GlobalCargo
  },
  data() {
    return {
      ParamJson: [],
      TempParamJson: [],
      radio: 2, // 型号匹配类型
      isZero: false, // 零库存
      isNegative: false, // 负库存
      isPositive: true, //正库存
      queryCondition1: "Model", // 查询条件1  默认型号
      queryCondition2: "Brand", // 查询条件2  默认品牌
      queryCondition3: "Packaging", // 查询条件3  默认封装
      GCode: "StkStock",
      modelMatching: "Like",
      // hmSelectVal: '', // 最近搜索型号
      hmSelectOptions: [],
      tempSelectOptions: [],
      searchValue1: "",
      searchValue2: "",
      searchValue3: "",
      gridShow: false,
      warningCount: 0,
      allSearchValue: {},
      isErpClient: false,
    };
  },
  props: {
    stockCheckedRows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    refreshWarningCount: false,
  },
  watch: {
    refreshWarningCount() {
      this.getWarningCount(this.allSearchValue);
    },
  },
  methods: {
    async onInit(config) {
      // 获取参数设置
      await this.GetParamJson();
      this.gridShow = true;

      this.$emit("grid-show", true);
      this.$nextTick(() => {
        this.onSearch();
        this.getWarningCount();
      });
    },
    changeCheck() {
      // 搜索时保存参数配置
      this.SetDefault();
      this.onSearch();
    },
    onSearchBtn() {
      this.$emit("is-search-btn", true);
      this.onSearch();
    },

    // 获取搜索条件对象
    getSearchCondition() {
      if (this.radio === 0) {
        this.modelMatching = "LeftMatch";
      } else if (this.radio === 1) {
        this.modelMatching = "Equal";
      } else {
        this.modelMatching = "Like";
      }
      // let value = {
      //   isNegative: this.isNegative, // 仅查负库存
      //   isZero: this.isZero, // 包含零库存
      //   MatchOperation: this.modelMatching// 型号匹配方式
      // }
      // value[this.queryCondition1] = this.searchValue1
      // value[this.queryCondition2] = this.searchValue2
      // value[this.queryCondition3] = this.searchValue3
      let value = {};

      this.GetValue(value);
      this.checkDate(value);
      if (this.hasValue(value.Model)) {
        this.createSelectOptions(value);
      }
      this.allSearchValue = value;
      return value;
    },

    onSearch() {
      // 搜索时保存参数配置
      this.SetDefault();
      let value = this.getSearchCondition();
      this.$emit("on-search", value);
    },
    GetValue(value) {
      if (
        this.queryCondition1 === this.queryCondition2 &&
        this.queryCondition1 !== this.queryCondition3
      ) {
        value[this.queryCondition1] =
          this.searchValue1 === "" ? this.searchValue2 : this.searchValue1;
        value[this.queryCondition3] = this.searchValue3;
      } else if (
        this.queryCondition1 === this.queryCondition3 &&
        this.queryCondition1 !== this.queryCondition2
      ) {
        value[this.queryCondition1] =
          this.searchValue1 === "" ? this.searchValue3 : this.searchValue1;
        value[this.queryCondition2] = this.searchValue2;
      } else if (
        this.queryCondition2 === this.queryCondition3 &&
        this.queryCondition1 !== this.queryCondition2
      ) {
        value[this.queryCondition1] = this.searchValue1;
        value[this.queryCondition2] =
          this.searchValue2 === "" ? this.searchValue3 : this.searchValue2;
      } else if (
        this.queryCondition1 === this.queryCondition2 &&
        this.queryCondition1 === this.queryCondition3
      ) {
        let searchValue = "";
        if (
          this.searchValue1 === "" &&
          this.searchValue2 !== "" &&
          this.searchValue3 === ""
        ) {
          searchValue = this.searchValue2;
        } else if (
          this.searchValue1 === "" &&
          this.searchValue2 === "" &&
          this.searchValue3 !== ""
        ) {
          searchValue = this.searchValue3;
        } else if (
          this.searchValue1 === "" &&
          this.searchValue2 !== "" &&
          this.searchValue3 !== ""
        ) {
          searchValue = this.searchValue2;
        } else {
          searchValue = this.searchValue1;
        }
        value[this.queryCondition1] = searchValue;
      } else {
        value[this.queryCondition1] = this.searchValue1;
        value[this.queryCondition2] = this.searchValue2;
        value[this.queryCondition3] = this.searchValue3;
      }
      value.isNegative = this.isNegative; // 仅查负库存
      value.isZero = this.isZero; // 包含0库存
      value.isPositive = this.isPositive; //仅查正库存
      value.MatchOperation = this.modelMatching; // 仅查负库存
    },
    checkDate(value) {
      if (this.hasValue(value.InvQty)) {
        value.InvQty = this.toInt(value.InvQty);
      }
    },
    async hmSelectChange(sval) {
      if (sval) {
        this.searchValue1 = sval.key.split(" --> ")[1];
        this.$refs.hmselect.clear();
        this.onSearch();
      }
    },
    // 获取参数配置默认值
    async GetParamJson() {
      this.ParamJson = await this.$post(CONFIG.GetPageParamURL, {
        GCode: this.GCode,
      });
      if (!this.hasValue(this.ParamJson)) {
        return;
      }
      this.TempParamJson = JSON.parse(JSON.stringify(this.ParamJson));
      if (this.ParamJson.length > 0) {
        // 型号匹配类型
        this.radio =
          this.toInt(
            this.findVal(this.ParamJson, (a) => a.ParamCode === "ModelMatching")
              .ParamValue
          ) || this.radio;
        // 零库存
        let paramIsZero = this.findVal(
          this.ParamJson,
          (a) => a.ParamCode === "IsZeroInventory"
        ).ParamValue;
        if (paramIsZero === "true") {
          this.isZero = true;
        }
        // 负库存
        let isNegative = this.findVal(
          this.ParamJson,
          (a) => a.ParamCode === "IsNegativeInventoryOnly"
        ).ParamValue;
        if (isNegative === "true") {
          this.isNegative = true;
        }
        // 正库存
        let isPositive = this.findVal(
          this.ParamJson,
          (a) => a.ParamCode === "IsPositiveInventoryOnly"
        ).ParamValue;
        if (isPositive) {
          this.isPositive = isPositive === "true" ? true : false;
        }
        // 查询条件1
        this.queryCondition1 =
          this.findVal(this.ParamJson, (a) => a.ParamCode === "QueryCondition1")
            .ParamValue || this.queryCondition1;
        // 查询条件2
        this.queryCondition2 =
          this.findVal(this.ParamJson, (a) => a.ParamCode === "QueryCondition2")
            .ParamValue || this.queryCondition2;
        // 查询条件3
        this.queryCondition3 =
          this.findVal(this.ParamJson, (a) => a.ParamCode === "QueryCondition3")
            .ParamValue || this.queryCondition3;
      }
    },

    SetDefault() {
      _.each(this.ParamJson, (item) => {
        if (item.ParamCode === "IsZeroInventory") {
          item.ParamValue = this.isZero;
        }
        if (item.ParamCode === "IsNegativeInventoryOnly") {
          item.ParamValue = this.isNegative;
        }
        if (item.ParamCode === "IsPositiveInventoryOnly") {
          item.ParamValue = this.isPositive;
        }
        if (item.ParamCode === "ModelMatching") {
          item.ParamValue = this.radio;
        }
        if (item.ParamCode === "QueryCondition1") {
          item.ParamValue = this.queryCondition1;
        }
        if (item.ParamCode === "QueryCondition2") {
          item.ParamValue = this.queryCondition2;
        }
        if (item.ParamCode === "QueryCondition3") {
          item.ParamValue = this.queryCondition3;
        }
      });

      let obj = {
        ParamText: "仅查正库存",
        ParamCode: "IsPositiveInventoryOnly",
        ParamValue: this.isPositive,
      };

      let isPositiveItem = this.ParamJson.find((item) => {
        return item.ParamCode === "IsPositiveInventoryOnly";
      });

      if (!isPositiveItem) {
        this.ParamJson.push(obj);
      }

      // 判断值是否改变 如果没有改变不执行保存操作
      let IsPass = false;
      let isZero = false;
      let isNegative = false;
      let isPositive = false;
      let that = this;
      _.each(this.TempParamJson, (tempitem) => {
        if (tempitem.ParamCode === "IsZeroInventory") {
          if (tempitem.ParamValue === "true") {
            isZero = true;
          } else if (tempitem.ParamValue === "false") {
            isZero = false;
          } else {
            isZero = tempitem.ParamValue;
          }
          if (isZero !== that.isZero) {
            IsPass = true;
            return;
          }
        }
        if (tempitem.ParamCode === "IsNegativeInventoryOnly") {
          if (tempitem.ParamValue === "true") {
            isNegative = true;
          } else if (tempitem.ParamValue === "false") {
            isNegative = false;
          } else {
            isNegative = tempitem.ParamValue;
          }
          if (isNegative !== that.isNegative) {
            IsPass = true;
            return;
          }
        }
        if (tempitem.ParamCode === "IsPositiveInventoryOnly") {
          if (tempitem.ParamValue === "true") {
            isPositive = true;
          } else if (tempitem.ParamValue === "false") {
            isPositive = false;
          } else {
            isPositive = tempitem.ParamValue;
          }
          if (isPositive !== that.isPositive) {
            IsPass = true;
            return;
          }
        }
        if (tempitem.ParamCode === "ModelMatching") {
          if (this.toInt(tempitem.ParamValue) !== that.radio) {
            IsPass = true;
            return;
          }
        }
        if (tempitem.ParamCode === "QueryCondition1") {
          if (tempitem.ParamValue !== that.queryCondition1) {
            IsPass = true;
            return;
          }
        }
        if (tempitem.ParamCode === "QueryCondition2") {
          if (tempitem.ParamValue !== that.queryCondition2) {
            IsPass = true;
            return;
          }
        }
        if (tempitem.ParamCode === "QueryCondition3") {
          if (tempitem.ParamValue !== that.queryCondition3) {
            IsPass = true;
            return;
          }
        }
      });
      if (IsPass) {
        let params = { GCode: this.GCode, ParamJson: this.ParamJson };
        this.$post(CONFIG.SavePageParamURL, params, (data) => {});
        this.TempParamJson = JSON.parse(JSON.stringify(this.ParamJson));
      }
    },
    async getSelectOptions() {
      this.$post("SearchKeysHistory/GetRecentlySearch", (data) => {
        this.hmSelectOptions = [];
        this.tempSelectOptions = JSON.parse(JSON.stringify(data));
        _.each(data, (item) => {
          this.hmSelectOptions.push({
            key: item.SLogID + " --> " + item.KeyWords,
            value: item.KeysAndTimeStr,
          });
        });
      });
    },
    async createSelectOptions(value) {
      let nowdate = new Date();
      this.tempSelectOptions.unshift({
        KeyWords: value.Model,
        KeysAndTimeStr: "",
        SLogID: this.getNewGuid(),
        SearchTime: nowdate,
      });
      this.tempSelectOptions.pop();
      _.each(this.tempSelectOptions, (item) => {
        let ret = this.getTimespan(item.SearchTime);
        item.KeysAndTimeStr = item.KeyWords + " --> " + ret;
      });
      this.hmSelectOptions = [];
      _.each(this.tempSelectOptions, (item) => {
        this.hmSelectOptions.push({
          key: item.SLogID + " --> " + item.KeyWords,
          value: item.KeysAndTimeStr,
        });
      });
    },

    getTimespan(date) {
      let nowdate = new Date(); // 开始时间
      let msec = 0;
      msec = new Date(nowdate).getTime() - new Date(date).getTime(); // 时间差的毫秒数
      // 计算出相差天数
      let days = Math.floor(msec / (24 * 3600 * 1000));
      // 计算出小时数
      let leave1 = msec % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
      let hours = Math.floor(leave1 / (3600 * 1000));
      // 计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
      let minutes = Math.floor(leave2 / (60 * 1000));
      // 计算相差秒数
      let leave3 = leave2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
      let seconds = Math.round(leave3 / 1000);
      let ret = "";
      if (days !== 0) {
        ret = days + "天前";
      } else if (days === 0 && hours !== 0) {
        ret = hours + "小时前";
      } else if (days === 0 && hours === 0 && minutes !== 0) {
        ret = minutes + "分钟前";
      } else if (days === 0 && hours === 0 && minutes === 0 && seconds !== 0) {
        ret = seconds + "秒前";
      } else {
        ret = "1秒前";
      }
      return ret;
    },

    // 清除搜索条件
    clearSearchCondition(resetDropdownlist) {
      this.searchValue1 = "";
      this.searchValue2 = "";
      this.searchValue3 = "";
      this.isZero = false;
      this.isNegative = false;
      this.isPositive = false;
      if (resetDropdownlist) {
        this.queryCondition1 = "Model";
        this.queryCondition2 = "Brand";
        this.queryCondition3 = "Packaging";
      }
    },

    // 根据当前条件查询触发已预警的汇总型号数量
    async getWarningCount() {
      this.warningCount = await this.$post(
        "StkStockWarning/GetWarningCount",
        {}
      );
    },

    // 当点击查看预警详情时
    onWarningClick() {
      this.clearSearchCondition();
      this.isZero = true;
      let value = this.getSearchCondition();
      value["IsWarning"] = true;
      // this.$emit('change-tab', 'SummaryStock', value)

      this.$refs.stkWarnList.onOpen();
    },

    changeOption() {
      return;
    },
    async oldEdition() {
      await switchToLegacyPage("Stock");
    },

    // 提供给父组件调用的，获取搜索参数，并执行回调函数
    getSearchParam(callbackFn = null) {
      let param = this.getSearchCondition();
      if (
        callbackFn !== null &&
        callbackFn !== undefined &&
        typeof callbackFn === "function"
      ) {
        callbackFn(param);
      }
      return param;
    },
  },
  async created() {
    this.isErpClient = isClient();
    await this.onInit();
    this.getSelectOptions();
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss">
.StockManage-search-box {
  padding: 8px 10px 0px 10px;
  // width: 850px;
  .el-radio + .el-radio {
    margin-left: 0 !important;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #ee7700;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #ee7700;
    background: #ee7700;
  }

  .el-radio__inner {
    width: 10px;
    height: 10px;
  }

  .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #ee7700;
  }

  .el-radio__label {
    font-size: 12px;
    line-height: 22px;
    padding: 0 10px 0 4px;
  }

  .el-col {
    padding: 1px 0;
    line-height: 24px;
  }

  .qty-warning {
    color: red;
    text-decoration: underline;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
  }
}

.stkStock-action-form {
  display: flex;
  position: relative;
  float: left;
  line-height: 25px;

  .ml5 {
    margin-left: 5px;
  }

  .znl-line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 120px;
    color: #ccc;
  }
}

.action-form2 {
  padding-top: 4px;
}

.action-form2 > .el-checkbox {
  margin-left: 5px;
}
</style>
