<template>
  <znl-dialog
    :visible="visible"
    width="930px"
    height="500px"
    class="znl_log_grid"
    title="操作日志"
    :is-footer-show="false"
    :loading="loading"
    :loading-text="elementLoadingText"
    :close-on-click-modal="false"
    @close="()=>{$emit('close')}"
  >
    <znl-gridmodule
      ref="flexGrid"
      gridtype="base"
      :columns="columns"
      height="100%"
      :grid-show="gridShow"
      :has-znl-actions="false"
      :has-znl-btns="false"
      :item-source="itemSource"
      :column-colors="columnColors"
      :row-colors="rowColors"
      :summary-columns="summaryColumns"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :on-init="onInit"
      :is-init="true"
      :on-refresh="onRefresh"
      :on-search="onSearch"
      :on-page-changed="onBindData"
      @page-loading="d=>{$emit('page-loading', d)}"
      @selection-changed="d=>{$emit('selection-changed',d)}"
    >
      <!-- 合并库存撤回弹窗 -->
      <merge-recall
        v-if="showMergeRecall"
        :visible="showMergeRecall"
        :stk-log-id="stkLogId"
        @confirm="recallConfirmed"
        @close="()=>{showMergeRecall = false}"
      ></merge-recall>
    </znl-gridmodule>
  </znl-dialog>
</template>
<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import MergeRecall from "@c_modules/StkMerge/MergeRecall";

const CONFIG = {
  configURL: "StkStockLog/GetConfig",
  saveConfigURL: "StkStockLog/SaveConfig",
  resetConfigURL: "StkStockLog/ResetConfig",
  searchURL: "StkStockLog/Search",
};

export default {
  name: "StkStockLog",
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
    MergeRecall,
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      filterFields: {},
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 30,
      pageIndex: 1,
      gridShow: false,
      totalCount: 0,
      elementLoadingText: "数据加载中,请稍后...",
      loading: true,
      showMergeRecall: false,
      stkLogId: 0,
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: true,
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: String,
    stkGUID: String, // 库存对象
    showstockLog: Boolean, // 是否需要刷新页面
    visible: Boolean,
  },
  methods: {
    async onInit(config) {
      if (!config) {
        config = await this.isSaveStorage("StkStockLog", CONFIG.configURL);
      }

      let columns = this.flexGridColumnsHandler(config.ColumnConfigs);
      this.genLogContent(
        _.find(columns, (col) => col.binding === "LogContent")
      );

      this.columns = columns;

      this.initData && this.onBindData();
    },

    // 格式化日志内容
    genLogContent(column) {
      if (column === undefined || column === null) {
        return false;
      }
      let vm = this;

      column.renderCell = (h, rowObj) => {
        let row = rowObj.row;
        let isRecall = row.CanRecall;

        return h("div", [
          h("span", row.LogContent),
          h("span", {
            domProps: {
              innerHTML: isRecall
                ? '<span class="znl-table-btn-success znl-pointer ml10">撤回</span>'
                : "",
            },
            on: {
              click() {
                if (isRecall) {
                  vm.onReCall(row);
                }
              },
            },
          }),
        ]);
      };
    },

    onBindData(pageIndex = 1) {
      let data = _.extend(
        {},
        {
          PageIndex: pageIndex,
          PageSize: this.pageSize,
        },
        { stkGUID: this.stkGUID }
      );

      return this.$post(CONFIG.searchURL, data, (datas) => {
        this.loading = false;
        this.pageIndex = pageIndex;
        this.itemSource = datas.ResultList;
        this.totalCount = datas.TotalCount;
        this.gridShow = true;
      });
    },

    onReCall(row) {
      console.log(row);
      this.stkLogId = row.StkLogID;
      this.showMergeRecall = true;
    },
    recallConfirmed() {
      this.showMergeRecall = false;
      this.onBindData(1);
    },
  },
};
</script>

<style lang="scss">
.znl_log_grid {
  background-color: rgba(0, 0, 0, 0.4);
  .baseStyleDefalut.base-flex-grid .el-table {
    border-left: 1px solid #e6e6e6;
  }
  .el-dialog__footer {
    padding: 0 !important;
  }
  .ml10 {
    margin-left: 10px;
  }

  .znl-content {
    height: 450px;
  }

  .znl-table-body-wrapper {
    height: 450px;
  }

  .table-container {
    height: 450px;
  }
}
</style>
