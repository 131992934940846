<template>
  <div class="p-stx-transfer-list">
    <znl-dialog
      :visible="visible"
      title="调出库存列表"
      width="885px"
      height="490px"
      :is-footer-show="true"
      v-loading="dialogLoading"
      :element-loading-text="loadingText"
      @close="()=>{$emit('close')}">
      <znl-layout-topbottom top-height="40%" slot="znl-dialog">
        <znl-gridmodule gridtype="action" slot="znlTop"
                        ref="flexGrid"
                        :has-znl-btns="false"
                        :is-init="true"
                        height="100%"
                        :show-shortcut-column="false"
                        :is-show-frombtns="false"
                        :from-height="220"
                        :columns="columns"
                        :item-source="itemSource"
                        :hasDefaultBeforeNewRow="true"
                        :edit-row-index="editRowIndex"
                        :column-colors="columnColors"
                        :row-colors="rowColors"
                        :summary-columns="summaryColumns"
                        :is-fields-search="false"
                        :is-multi-rows-check="false"
                        :show-add-row-button="false"
                        :show-delete-row-button="false"
                        :search-fields="searchModel"
                        :role="role"
                        :users="$store.state.users"
                        :on-init="onInit"
                        :is-action-from='false'
                        @selection-changed="onRowChanged"
                        @edit:cell-ended="onCellEdited">
        </znl-gridmodule>
        <div  slot="znlBottom"  class="znlBottom">
          <znl-layout-leftright leftWidth="445px">
            <div slot="znlLeft" class="znlLeft">
              <!-- 库存选择模块 -->
              <stock-select
                ref="stockList"
                :init-data="true"
                page-position="top"
                :is-init="true"
                header=''
                :height=240
                :isShowLoading="false"
                :multi-rows-check="false"
                @page-loading="d=>{dialogLoading = d}"
                @stock-check-row="val=>{this.stkStockCheckChanged(val)}"
              >
              </stock-select>
            </div>
            <div slot="znlRight" class="znlRight">
              <div class="right-header-minbox" style="font-size:15px;color:#4D4D4D;font-weight:700">调入后库存:</div>
              <el-row type="flex" style="line-height: 32px">
                <el-col>
                  <el-row >
                    <znl-input
                      title-width="60px"
                      form-type="input"
                      :disabled="true"
                      v-show="false"
                      placeholder=""
                      size="mini"
                      layout="left"
                      :is-must-fill="false"
                      title="库存GUID"
                      width="100%"
                      :clearable="true"
                      :border="true"
                      v-model="stkStock.StkGUID"
                      type="text">
                    </znl-input>
                    <!-- :on-icon-click="onIconClick" -->
                    <znl-input
                      title-width="60px"
                      form-type="input"
                      :disabled="true"
                      placeholder=""
                      size="mini"
                      layout="left"
                      :is-must-fill="false"
                      title="型号："
                      width="100%"
                      :clearable="true"
                      :border="true"
                      v-model="stkStock.Model"
                      type="text">
                    </znl-input>

                    <znl-input
                      title-width="60px"
                      form-type="input"
                      :disabled="true"
                      placeholder=""
                      size="mini"
                      layout="left"
                      :is-must-fill="false"
                      title="封装："
                      width="100%"
                      :clearable="true"
                      :border="true"
                      v-model="stkStock.Packaging"
                      type="text">
                    </znl-input>

                    <znl-input
                      title-width="60px"
                      form-type="input"
                      :disabled="true"
                      placeholder=""
                      size="mini"
                      layout="left"
                      :is-must-fill="false"
                      title="批号："
                      width="100%"
                      :clearable="true"
                      :border="true"
                      v-model="stkStock.MakeYear"
                      type="text">
                    </znl-input>

                    <znl-input
                      title-width="60px"
                      form-type="input"
                      :disabled="true"
                      placeholder=""
                      size="mini"
                      layout="left"
                      :is-must-fill="false"
                      title="库存量："
                      width="100%"
                      :clearable="true"
                      :border="true"
                      v-model="stkStock.InvQty"
                      type="text">
                    </znl-input>

                    <znl-input
                      title-width="60px"
                      layout="left"
                      form-type="select"
                      width="100%"
                      placeholder="请选择"
                      popper-class="popperClass"
                      title="仓库："
                      :is-must-fill="false"
                      :border="true"
                      :clearble="true"
                      :select-options="selectOptionsWarehouseOptions"
                      v-model="stkStock.InvPosition">
                    </znl-input>

                    <znl-input
                      title-width="60px"
                      form-type="input"
                      layout="left"
                      :is-must-fill="false"
                      title="位置："
                      placeholder=""
                      width="100%"
                      :clearable="true"
                      v-model="stkStock.StorageName"
                      :border="true"
                      @change="(val)=>{stkStock.StorageName=val}"
                      type="text">
                    </znl-input>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </znl-layout-leftright>
        </div>
      </znl-layout-topbottom>
      <el-row slot="footer">
        <znl-button :height="33" :width="60" style-type="main" :disabled='loading' @click="TransferSave">保存</znl-button>
      </el-row>
    </znl-dialog>
  </div>

</template>
<script>

import StockSelect from '@c_modules/Stk/StockSelect'

import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
export default {
  name: 'StkTransferList',
  mixins: [getCommonDataMixin],
  components: {
    StockSelect
  },
  data () {
    return {
      config: {
        configURL: 'StkTransferList/GetConfig',
        saveConfigURL: 'StkTransferList/SaveConfig',
        resetConfigURL: 'StkTransferList/ResetConfig',
        searchURL: 'StkTransferList/Search',
        saveTransferURL: 'StkTransferList/Save'
      },
      stkStock: {
        // 库存对象
        StkGUID: '',
        Model: '',
        Brand: '',
        Packaging: '',
        MakeYear: '',
        InvPosition: '',
        StorageName: '',
        InvQty: '',
        StkLineList: [] // 调出库存列表
      },
      showStkTransferModelList: false,
      checkQty: 0, // 选中行的数量
      refreshChecklist: false, // 是否刷新选择列表
      InvPositionOptions: [], // 仓库列表
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      users: [],
      usableUser: [],
      role: '1',
      dialogLoading: true,
      loading: false,
      loadingText: '数据加载中,请稍后...'
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String,
    stkGUIDS: Array, // 库存对象
    showstkTransfer: Boolean, // 是否需要刷新页面
    visible: Boolean
  },
  watch: {

    itemSource (val) {
      this.stkStock.Model = val[0].Model
      this.stkStock.Packaging = val[0].Packaging || ''
      this.stkStock.Brand = val[0].Brand || ''
      this.stkStock.MakeYear = val[0].MakeYear || ''
      this.stkStock.InvPosition = val[0].InvPosition || ''
      this.stkStock.StorageName = val[0].StorageName || ''
      this.onCalcQty()
    }
  },
  methods: {
    onInit: async function () {
      let config = await this.$post(this.config.configURL)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.initData && this.onBindData()
    },
    onBindData () {
      return this.$post(this.config.searchURL, { StkGUIDS: this.stkGUIDS }, datas => {
        this.itemSource = datas === undefined ? [] : datas
        // console.log('条数')
        this.onResetStkstock()
        this.onCalcQty()
        this.refreshChecklist = !this.refreshChecklist
      })
    },
    stkStockCheckChanged (item) {
      // console.log(item)
      this.checkQty = this.toFloat(item.InvQty, 2)
      this.stkStock.InvQty = 0
      this.onCalcQty()
      this.stkStock.Model = item.Model
      this.stkStock.Brand = item.Brand
      this.stkStock.Packaging = item.Packaging
      this.stkStock.MakeYear = item.MakeYear
      this.stkStock.InvPosition = item.InvPosition
      this.stkStock.StorageName = item.StorageName
      this.stkStock.StkGUID = item.StkGUID
    },
    StkTransferModelListSelected (val) {
      this.stkStock.StkGUID = val[0].StkGUID || ''
      this.stkStock.Model = val[0].Model || ''
      this.stkStock.Packaging = val[0].Packaging || ''
      this.stkStock.Brand = val[0].Brand || ''
      this.stkStock.MakeYear = val[0].MakeYear || ''
      this.stkStock.InvPosition = val[0].InvPosition || ''
      this.stkStock.StorageName = val[0].StorageName || ''
      this.onCalcQty()
      let isAdd = true
      _.each(this.itemSource, item => {
        if (item.StkGUID === val[0].StkGUID) {
          isAdd = false
        }
      })
      isAdd && (this.stkStock.InvQty += val[0].InvQty)
    },
    onIconClick () {
      this.showStkTransferModelList = true
    },
    // onSearch (params) {
    //   this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
    //   return this.onBindData(1)
    // },
    onCellEdited (
      aftervalue,
      beforevalue,
      colname,
      gridobj,
      rowindex,
      colindex
    ) {
      // 更新调出数量
      this.onCalcQty()
    },
    onRowChanged (val) {
      // this.stkStock.Model = val.Model
      // this.stkStock.Brand = val.Brand
      // this.stkStock.Packaging = val.Packaging
      // this.stkStock.MakeYear = val.MakeYear
      // this.stkStock.InvPosition = val.InvPosition
      // this.stkStock.StorageName = val.StorageName
      // this.stkStock.InvQty = val.InvQty
      // this.checkQty = 0
      // this.onCalcQty()
    },

    onCancel () {
      this.p_dialogVisible = false
      this.$emit('confirm', false)
    },
    TransferSave () {
      // 调动保存
      // console.log(11, this)
      this.stkStock.StkLineList = this.itemSource
      if (!isFinite(this.stkStock.InvQty) || this.stkStock.InvQty < 0) {
        return this.$message({
          message: '调出数量必须为大于0的数字类型',
          type: 'error'
        })
      }
      this.loadingText = '正在保存,请稍等...'
      this.dialogLoading = true
      return this.$post(this.config.saveTransferURL, this.stkStock, data => {
        if (data > 0) {
          this.$message({ message: '库存调货成功', type: 'success' })
          this.$emit('confirm', true)
        } else if (data === -1) {
          this.$message({ message: '调出数量不能小于零', type: 'error' })
        } else if (data === -2) {
          this.$message({ message: '调出数量不能大于库存数量', type: 'error' })
        } else {
          this.$message({ message: '库存调货失败', type: 'error' })
        }
      }).finally(() => {
        this.dialogLoading = false
      })
    },
    onCalcQty () {
      // 计算总量
      let rows = this.itemSource
      let SumQty = 0
      _.each(rows, t => {
        SumQty += this.toFloat(t.TransferQty, 2)
      })
      this.stkStock.InvQty =
        this.toFloat(SumQty, 2) + this.toFloat(this.checkQty, 2)
    },
    onResetStkstock () {
      // 重置页面
      this.stkStock.StkGUID = ''
      this.stkStock.Model = ''
      this.stkStock.Brand = ''
      this.stkStock.Packaging = ''
      this.stkStock.MakeYear = ''
      this.stkStock.InvPosition = ''
      this.stkStock.StorageName = ''
      this.stkStock.InvQty = ''
      this.checkQty = 0
      this.stkStock.StkLineList = []
    },
    onSaveSetting (columns, pageSize) {
      return this.$post(
        this.config.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, this.config.default)
      )
    },
    onResetSetting () {
      return this.$post(this.config.resetConfigURL)
    }
  },
  mounted () { }
}
</script>

<style lang="scss" >
.p-stx-transfer-list {
  .znl-table-body-wrapper {
    overflow: auto;
  }
  .table-container {
    overflow: auto;
  }
}
</style>
