<template>
  <!-- 发货记录 出库记录 -->
  <znl-gridmodule ref="flexGrid"
    :header="header"
    height="100%"
    :columns="columns"
    :item-source="itemSource"
    :btn-mouse-enter-menus="btnMouseEnterMenus"
    :is-fields-search="false"
    :is-multi-rows-check="false"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :users="$store.state.users"
    :on-init="onInit"
    :is-init="true"
    :show-header="false"
    :on-search="onSearch"
    :loading="loading"
    :is-show-rightbtns="false"
    :on-page-changed="onBindData"
    :on-refresh="onRefresh"
    :on-save-setting="onSaveSetting"
    :on-save-config='onSaveConfig'
    :on-reset-setting="onResetSetting"
    @ondblclick="val=>$emit('ondblclick', val)"
    @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
    @selection-changed="d=>{$emit('selection-changed',d)}">
    <stk-out-edit :title="StkOutAddTitle"
      :editvisible="stkOutEditVisible"
      v-if="stkOutEditVisible"
      :edit-type="editType"
      height="500px"
      @confirm="stkOutEditVisible=false"
      @close="stkOutEditVisible=false"></stk-out-edit>
    <stk-in-edit :title="StkInAddTitle"
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      :edit-type="editType"
      height="500px"
      @confirm="stkInEditVisible=false"
      @close="stkInEditVisible=false">
    </stk-in-edit>
  </znl-gridmodule>
</template>
<script>
const StkInEdit = () =>
  import('@c_modules/Stk/StkInEdit' /* webpackChunkName: "pages/stk" */)
const StkOutEdit = () =>
  import('@c_modules/Stk/StkOutEdit' /* webpackChunkName: "pages/stk" */)
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { openLinkAll } from '@scripts/methods/common'

const CONFIG = {
  PK: 'StkOutLineID',
  configURL: 'StkOutByModel/GetConfig',
  saveConfigURL: 'StkOutByModel/SaveConfig',
  resetConfigURL: 'StkOutByModel/ResetConfig',
  searchURL: 'StkOutByModel/Search'
}

export default {
  name: 'CStkOutByModel',
  mixins: [znlMethodsMixin, getCommonDataMixin, openLinkAll],
  config: CONFIG,
  components: {
    StkInEdit,
    StkOutEdit
  },
  data () {
    return {
      editType: 'edit',
      StkOutAddTitle: '新增发货单',
      stkOutEditVisible: false,
      StkInAddTitle: '新增入库单',
      stkInEditVisible: false,
      Status: true,
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      searchFields: {},
      columnColors: [
        {
          field: 'InvQty',
          color: '#EE7700'
        }
      ],
      btnMouseEnterMenus: {
        List: []
      },
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      dataMapSourse: {
        InvTypeSource: [],
        UnitSource: []
      },
      loading: false
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    header: {
      type: String,
      defaule: '出库记录'
    },
    isMini: {
      type: Boolean,
      default: false
    },
    isActivity: Boolean,
    pagePosition: {
      type: String,
      defaule: 'bottom'
    },
    isShowBtn: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isMultiRowsCheck: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    height: {
      type: String,
      default: 'auto'
    },
    // 是否启用分词搜索
    IsAnalyzer: {
      type: Boolean,
      default: false
    },
    isShowOperate: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async onInit (config) {
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('stkOutRecord', CONFIG.configURL)
      }

      // 获取模块打开或者关闭的状态
      this.GetStatus(config.Status)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 列的初始化
      this.settingColumns(columns)
      // 操作按钮初始化
      this.settingActionBtn(columns)

      this.setDateColumnFormat(columns, 'ShipTime')
      this.defaultConfig = config
      this.columns = columns
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      this.role = config.Role
      if (this.initData) {
        await this.onBindData()
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    // 列的初始化
    settingColumns (columns) {
      _.each(columns, (column, index) => {
        if (
          column.binding === 'Model' ||
          column.binding === 'SupplierName' ||
          column.binding === 'CustomerName' ||
          column.binding === 'BillNo'
        ) {
          _.extend(column, {
            className: 'znl-linkAll-column',
            renderCell: this.openLinkAll(column)
          })
        }
      })
      let custcol = _.find(columns, m => m.binding === 'CustomerName')
      // 处理特殊权限
      let hideCols = ',CustomerName,'
      if (
        this.getSpecialResourceByCode('NoSeeCustomer') &&
        hideCols.indexOf(custcol.binding) > 0
      ) {
        custcol.hasRole = false
      }
    },

    // 操作按钮初始化
    settingActionBtn (columns) {
      this.btnMouseEnterMenus = {
        List: [
          {
            name: '销售出货',
            // isShow: this.hasRes('onStkOut'),
            isShow: !this.onlySalesPurchaseOrder,
            click: row => {
              let rows = []
              rows.push(row)
              this.onStkOutadd(rows)
            }
          },
          {
            name: '采购入库',
            // isShow: this.hasRes('onStkIn'),
            isShow: !this.onlySalesPurchaseOrder,
            click: row => {
              this.onStkInadd(row)
            }
          }
        ],
        Binding: 'operate'
      }
      !this.isShowBtn
        ? ''
        : columns.unshift({
          // 操作列
          name: 'operate',
          dataType: 5,
          binding: 'operate',
          width: 36,
          header: '操作',
          visible: true,
          isReadOnly: true,
          isFieldsSearch: false,
          isSystem: true,
          align: 'left',
          className: 'znlerp-operate znl-text-center',
          buttons: [
            {
              content: '<i class="iconfont icon-left_nav_show_btn"></i>',
              className: 'znl-table-btn',
              click: () => { }
            }
          ]
        })

      this.isShowOperate && !this.onlySalesPurchaseOrder
        ? columns.unshift({
          name: 'stkIn',
          dataType: 5,
          binding: 'stkIn',
          width: 30,
          header: '入',
          visible: true,
          allowSorting: false,
          isFieldsSearch: false,
          isSystem: true,
          isReadOnly: true,
          isFixed: false,
          buttons: [
            {
              content: '入',
              className: 'znl-table-btn-success',
              when: true,
              click: row => {
                this.onStkInadd(row)
              }
            }
          ]
        })
        : ''
      this.isShowOperate && !this.onlySalesPurchaseOrder
        ? columns.unshift({
          name: 'stkOut',
          dataType: 5,
          binding: 'stkOut',
          width: 30,
          header: '出',
          visible: true,
          allowSorting: false,
          isFieldsSearch: false,
          isSystem: true,
          isReadOnly: true,
          isFixed: false,
          buttons: [
            {
              content: '出',
              className: 'znl-table-btn-success',
              when: true,
              click: row => {
                let rows = []
                rows.push(row)
                this.onStkOutadd(rows)
              }
            }
          ]
        })
        : ''
    },
    findColumn (columns, name) {
      const matched = columns.filter(x => x.binding === name)
      if (matched.length < 1) {
        console.log(`Can not find column: ${name}`)
        return null
      }

      return matched[0]
    },

    renderTextCell (h, content) {
      return h('span', {
        domProps: {
          innerHTML: content
        }
      })
    },

    setDateColumnFormat (columns, name) {
      const column = this.findColumn(columns, name)
      if (!column) {
        return
      }

      // 时间列数据样例: 2018-05-24 00:00:00
      // 去掉尾部'00:00:00'的时间
      //
      // 时间列是字符串，用正则检查并处理;
      // 不满足正则的数据原样输出
      const dateRe = /^\s*(\d{4}-\d{1,2}-\d{1,2}) \d{1,2}:\d{1,2}:\d{1,2}\s*$/
      column.renderCell = (h, { row: { ShipTime } }) => {
        const value = ShipTime
        let content = value
        if (value) {
          const m = value.match(dateRe)
          if (m) {
            content = m[1]
          }
        }

        return this.renderTextCell(h, content)
      }
    },

    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stkOutRecord')
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, CONFIG.default)
      )
    },
    onResetSetting () {
      localStorage.removeItem('stkOutRecord')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stkOutRecord')
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, CONFIG.default)
      )
    },
    async onBindData (pageIndex = 1) {
      this.loading = true
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          Model:
            this.searchModel.models === undefined
              ? ''
              : this.searchModel.models.Model,
          Brand:
            this.searchModel.models === undefined
              ? ''
              : this.searchModel.models.Brand,
          Packaging:
            this.searchModel.models === undefined
              ? ''
              : this.searchModel.models.Packaging,
          PageIndex: pageIndex,
          PageSize: this.pageSize,
          IsAnalyzer: false
        }
      )
      console.log(data, 333)
      return this.$post(CONFIG.searchURL, data, data => {
        this.loading = false
        this.$emit('page-loading', false)
        this.$emit('set-total-count', data.TotalCount)
        console.log(data.TotalCount)
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    },
    onHeadSearch (params) {
      this.searchFields.Model = params.Model
      return this.onBindData(1)
    },
    onSearch (params) {
      // console.log(params)
      this.searchFields = _.extend({}, this.searchFields, params)
      return this.onBindData(1)
    },
    // 特殊权限
    hideCustomerInfo () {
      // 特殊权限，不看客户资料。 隐藏客户资料 （客户名称、联系人、电话、QQ、Email、地址）
      let isnotSee = _.some(
        this.selectOptionsSpecialResource,
        item => item.Code === 'NoSeeCustomer'
      )
      return isnotSee
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page === code)
    },
    onStkOutadd (rows) {
      // 销售出库传值方法
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkOutEditVisible = true // 显示编辑页面
      })
    },
    onStkInadd (rows) {
      // 入库
      let order = { StkInRecord: [rows] }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true // 显示编辑页面
      })
    },
    // 获取模块状态
    GetStatus (val) {
      if (this.isActivity) {
        this.Status = val !== 0
        this.Status
          ? this.$refs.flexGrid.toggleTableShow(true)
          : this.$refs.flexGrid.toggleTableShow(false)
      } else {
        this.$refs.flexGrid.toggleTableShow(true)
      }
    },

    // 保存模块状态
    saveStatus (val) {
      let Status = val ? 1 : 0
      let defaultConfig = this.defaultConfig
      if (defaultConfig !== undefined && Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    }
  },
  mounted () {
    !this.isShow && this.$refs.flexGrid.toggleTableShow(false)
  }
}
</script>
