<template>
  <!-- 销售备货 -->
  <div class="module-wrapper m-sales-reserve-manage">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :showCheck="true"
      v-loading="loading"
      box-class="m-sales-reserve-manage"
      element-loading-text="数据加载中,请稍后..."
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('stkStockReserve')"
      @select-change="rows => {selectedRows = rows}"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          placeholder="型号"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :border="true"
          v-model="searchFields.Model"
          type="text"
        ></znl-input>

        <znl-input
          form-type="input"
          placeholder="客户名称"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="searchFields.CustomerName"
          :border="true"
          type="text"
        ></znl-input>

        <znl-input
          form-type="select"
          placeholder="备货人"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="120px"
          size="mini"
          layout="left"
          inp-perc="100%"
          :select-options="selectOptionsReserver"
          v-model="searchFields.Reserver"
          :border="true"
          type="text"
        ></znl-input>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="备货时间起"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          v-model="searchFields.StartReserveTime"
          :clearable="true"
        ></znl-input>

        <znl-input
          form-type="datepicker"
          :disabled="false"
          placeholder="备货时间止"
          value-format="yyyy-MM-dd"
          size="mini"
          layout="left"
          width="100px"
          :border="true"
          :clearable="true"
          v-model="searchFields.EndReserveTime"
          inp-perc="100%"
        ></znl-input>

        <el-checkbox v-model="isUnReserve" @change="SetUnReserveDefault" size="small">未备</el-checkbox>
        <el-checkbox v-model="isReserve" @change="SetReserveDefault" size="small">已备</el-checkbox>
        <el-checkbox v-model="isStkOut" size="small">已发货</el-checkbox>

        <znl-button class="minor-self-btn" style-type="mac" :width="50" @click="onSearch()">
          <i class="iconfont icon-search_ic"></i>
          <span>搜索</span>
        </znl-button>

        <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="50"
          @click="onStkReverseAdd()"
          v-if="this.hasRes('Add')"
        >
          <i class="iconfont icon-add_btn_ic"></i>
          <span>增加</span>
        </znl-button>

        <!-- 有问题，暂时隐藏 -->
        <!-- <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="74"
          @click="stkReserve()"
          v-if="this.hasRes('SignReserve')"
        >
          <i class="iconfont icon-labelling_btn"></i>
          <span>标记备货</span>
        </znl-button>-->

        <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="50"
          @click="onstkReserveImport()"
          v-if="this.hasRes('ImportReserve')"
        >
          <i class="iconfont icon-leading-in-wh_btn_ic"></i>
          <span>导入</span>
        </znl-button>

        <znl-button
          class="minor-self-btn"
          style-type="mac"
          :width="63"
          :btns="printznlbtns"
          @click="onExport()"
          v-if="this.hasRes('Export')"
        >
          <i class="iconfont icon-leading-out-wh_btn_i"></i>
          <span>导出</span>
        </znl-button>
      </div>
    </znl-table>

    <StkStockReserveAdd
      ref="StkStockReserveAdd"
      v-if="stkReserveAddVisible"
      :Srguid="stkReserveGUID"
      :visible="stkReserveAddVisible"
      :isRefresh="onrefreshAdd"
      @confirm="onChangeEnd"
      @close="()=>{stkReserveAddVisible=false}"
    ></StkStockReserveAdd>
    <stk-out-edit
      :editvisible="stkOutEditVisible"
      v-if="stkOutEditVisible"
      edit-type="edit"
      @confirm="stkOutEditVisible=false"
      @close="stkOutEditVisible=false"
    ></stk-out-edit>
    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="setConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>

    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </div>
</template>
<script>

import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkStockReserveAdd from '@c_modules/Stk/StkStockReserveAdd'
import StkOutEdit from '@c_modules/Stk/StkOutEdit'
import ExportColumn from '@c_modules/Crm/setExportColumn'
import { exportData, erpTableSetHandler } from '@scripts/methods/common'

const CONFIG = {
  configURL: 'StkStockReserve/GetConfig',
  saveConfigURL: 'StkStockReserve/SaveConfig',
  searchURL: 'StkStockReserve/Search',
  reserveURL: 'StkStockReserve/ReserveCompleted',
  checkAllowImport: 'StkStock/checkAllowImport', // 检查是否允许导入
  onDeleteURL: 'StkStockReserve/DeleteStkReserve',
  DropDownURL: 'DictItem/GetItemValue',
}
export default {
  name: 'ReserveManage',
  config: CONFIG,
  mixins: [getCommonDataMixin, exportData, erpTableSetHandler],
  components: {
    StkStockReserveAdd,
    StkOutEdit,
    ExportColumn
  },
  data() {
    return {
      isShowFiedsSearch: false,
      stkReserveGUID: '', // 需要传递给编辑页面的ID
      UnReserve: true, // 未备货
      AlReserve: false, // 已备货
      AlOut: false, // 已出库

      stkReserveAddVisible: false, // 是否显示备货编辑页面
      stkOutEditVisible: false, // 是否显示发货单编辑页面
      onrefreshAdd: true, // 刷新添加页面
      columns: [],
      defaultConfig: {},
      itemSource: [],
      searchFields: {
        Model: '',
        CustomerName: '',
        Reserver: '',
        StartReserveTime: '',
        EndReserveTime: ''
      },
      isStkOut: false, // 是否发货
      isUnReserve: true, // 未备货
      isReserve: false, // 已备货
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      selectOptionsReserver: [],
      selectedRows: [],
      resourceList: [],
      loading: false,
      printznlbtns: [
        {
          name: '设置导出列',
          // iconName: 'icon-table_set_btn_n',
          click: () => {
            this.showExportColumn()
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setSaveConfigURL: '',
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    }
  },

  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    }
  },
  watch: {
    UnReserve(value) {
      this.pageIndex = 1
      this.$refs.flexGrid.clearSelection()
      this.$refs.flexGrid.search()
    },
    AlReserve(value) {
      this.pageIndex = 1
      this.$refs.flexGrid.clearSelection()
      this.$refs.flexGrid.search()
    },
    AlOut(value) {
      this.pageIndex = 1
      this.$refs.flexGrid.clearSelection()
      this.$refs.flexGrid.search()
    }
  },
  async created() {

  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function (config) {
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('stkStockReserve', CONFIG.configURL)
      }
      let columns = config.ColumnConfigs
      this.resourceList = config.ResourceList.map(item => item.Code)
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index
      ) {
        return { key: obj.value, value: obj.value }
      })
      this.selectOptionsReserver = UserAllowUsers
      let tempConfig = []
      if (this.hasRes('StorageOut')) {
        tempConfig.push({
          width: 30,
          type: 'button',
          title: '出',
          btnTxt: '出',
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: row => {
            this.onStkOut(row)
          }
        })
      }
      if (this.hasRes('Edit')) {
        tempConfig.push({
          width: 30,
          type: 'button',
          title: '改',
          btnTxt: '改',
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: row => {
            this.onOpenAddDialog(row.SRGUID)
          }
        })
      }
      if (this.hasRes('Delete')) {
        tempConfig.push({
          width: 30,
          type: 'button',
          title: '删',
          btnTxt: '删',
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: row => {
            this.onDeleteOne(row)
          }
        })
      }
      if (!this.IsMainAccount) {
        this.settingColumns(columns)
      }
      columns = tempConfig.concat(columns)
      this.columns = columns
      this.defaultConfig = config
      this.$refs.table.init()
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      if (this.initData) {
        await this.onBindData()
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    settingColumns(columns) {
      let hideCols = ',CustomerName,Contact,Telephone,Email,Address,QQ,'
      let noSeeCustomer = this.getSpecialResourceByCode('NoSeeCustomer')
      _.remove(columns, item => {
        if (noSeeCustomer && hideCols.indexOf(item.BindField) > 0) {
          return true
        }
      })

      // 看备注1
      if (!this.hasRes('ViewRemark1')) {
        _.remove(columns, item => {
          if (item.BindField === 'Remark') {
            return true;
          }
        })
      }
      // 看备注2
      if (!this.hasRes('ViewRemark2')) {
        _.remove(columns, item => {
          if (item.BindField === 'Remark2') {
            return true;
          }
        })
      }
      // 看备注3
      if (!this.hasRes('ViewRemark3')) {
        _.remove(columns, item => {
          if (item.BindField === 'Remark3') {
            return true;
          }
        })
      }
    },
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true
      let data = this.getSearchWhere(pageIndex)
      return await this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      }).finally(() => {
        this.$emit('page-loading', false)
        setTimeout(() => {
          this.loading = false
        }, 800);
      })
    },
    getSearchWhere(pageIndex) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          IsStkOutCompleted: this.isStkOut, // 是否发货
          IsUnReserve: this.isUnReserve, // 未备货
          IsReserve: this.isReserve // 已备货
        }
      )
      return data
    },
    SetReserveDefault() {
      if (this.isReserve) {
        this.isUnReserve = false
      }
    },
    SetUnReserveDefault() {
      if (this.isUnReserve) {
        this.isReserve = false
      }
    },
    async onHeadSearch(params) {
      if (this.hasValue(params) && this.hasValue(params.Model)) {
        this.searchFields.Model = params.Model
      }
      await this.onBindData(1)
    },
    onSearch(params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      return this.onBindData(1)
    },
    onSelectionChange(currentItem) {
      this.$emit('selection-change', currentItem)
    },
    onStkReverseAdd() {
      this.onOpenAddDialog()
    },
    onOpenAddDialog(SrGUID = '') {
      // 传值id值为0，显示页面
      this.stkReserveAddVisible = true // 显示页面
      this.onrefreshAdd = !this.onrefreshAdd
      this.stkReserveGUID = SrGUID
    },
    onstkReserveImport: async function () {
      // 导入
      // 先做验证，库存量已超出最大库存量，不能再导入
      let IsAllowImport = await this.$post(CONFIG.checkAllowImport)
      this.$nextTick(function () {
        if (!IsAllowImport) {
          return this.$message({
            message: '您的库存量已超出最大库存量，不能再导入',
            type: 'error'
          })
        }
        this.importData({ importType: 'stkreserve' })
        this.clearSelectedRows()
      })
    },
    async onExport() {
      this.loading = true
      this.loading = (await this.exportData("StkStockReserve", this.getSearchWhere(1), this.columns, "销售备货"))
    },
    onSelectRow() {
      // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.selectedRows
      guid = _.uniq(_.map(rows, item => item.SRGUID))
      return guid
    },

    onChangeEnd(val) {
      val && (this.stkReserveAddVisible = false)
      this.onBindData(this.pageIndex)
    },
    async onDelete(SRGuids, showConfirm = true) {
      if (SRGuids.length > 0) {
        this.$post(CONFIG.onDeleteURL, { SRGUIDS: SRGuids }, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: '删除成功', type: 'success' })
            this.onBindData(this.pageIndex)
          } else {
            return this.$message({ message: logic.msg, type: 'error' })
          }

        })
      }
    },
    clearSelectedRows() {
      this.$refs.table.cleanAllCheck()
    },
    stkReserve() {
      // 标记为已备货
      let SRGuids = this.onSelectRow()
      if (SRGuids.length < 1) {
        return this.$message({ message: '请选择要标记的数据', type: 'error' })
      } else {
        this.$post(CONFIG.reserveURL, { SRGUIDS: SRGuids }, data => {
          if (data > 0) {
            this.$message({ message: '标记成功', type: 'success' })
            this.onBindData(this.pageIndex)
          } else {
            return this.$message({ message: '标记失败', type: 'error' })
          }
        })
      }
    },
    async onDeleteOne(row) {
      // 单条删除
      let ok = await this.$confirm('确定要删除本条型号【' + row.Model + '】为的备货吗?', '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
      if (ok) {
        this.onDelete([row.SRGUID], false)
      }
    },
    onStkOut(row) {
      // 出库
      let order = { ReverseGuid: row.SRGUID }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkOutEditVisible = true
      })
    },
    hasRes(code) {
      // 权限
      return _.includes(this.resourceList, code)
    },
    showExportColumn() {
      this.addColumnsList = this.columns
      this.setConfigColumns = this.defaultConfig
      this.setSaveConfigURL = CONFIG.saveConfigURL
      this.setExportColumnVisible = true
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("stkStockReserve", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("stkStockReserve", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("stkStockReserve", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("stkStockReserve")
    }
  }
}
</script>
<style lang="scss">
</style>
