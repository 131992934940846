<template>
  <znl-dialog
    :visible="visible"
    width="880px"
    height="500px"
    class="p-stock-merge-recall"
    title="合并库存撤回"
    :loading="loading"
    :loading-text="elementLoadingText"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="()=>{$emit('close')}"
  >
    <znl-gridmodule
      ref="flexGrid"
      gridtype="base"
      :columns="columns"
      height="100%"
      :grid-show="gridShow"
      :has-znl-actions="false"
      :has-znl-btns="false"
      :item-source="itemSource"
      :is-multi-rows-check="false"
      :on-init="onInit"
      :is-init="true"
      :on-refresh="onRefresh"
      :on-search="onSearch"
      :table-row-class-name="tableRowClassName"
      @page-loading="d=>{$emit('page-loading', d)}"
      @selection-changed="d=>{$emit('selection-changed',d)}"
    >
      <div slot="action-form">
        提示：撤回后，
        <b>库存量</b>将进行如下变化. 红色字体的库存型号(合并新增)，其数量将修改为0. 其余的库存型号(合并之前)，数量将修改为合并前的数量.
      </div>
    </znl-gridmodule>
    <div slot="footer">
      <znl-button :height="33" :width="60" style-type="main" @click="onSave">撤回</znl-button>
      <znl-button :height="33" :width="60" @click="() => {$emit('close')}">取消</znl-button>
    </div>
  </znl-dialog>
</template>
<script>

import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  configURL: 'StockMergeRecall/GetConfig',
  saveConfigURL: 'StockMergeRecall/SaveConfig',
  resetConfigURL: 'StockMergeRecall/ResetConfig',
  searchURL: 'StockMergeRecall/Search',
  saveURL: 'StockMergeRecall/Save'
}

export default {
  name: 'StockMergeRecall',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      gridShow: false,
      totalCount: 0,
      elementLoadingText: '数据加载中,请稍后...',
      loading: true
    }
  },
  props: {
    visible: Boolean,
    stkLogId: Number
  },
  watch: {

  },
  methods: {
    async onInit(config) {
      if (!config) {
        config = await this.isSaveStorage('StockMergeRecall', CONFIG.configURL)
      }

      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)

      this.columns = columns

      this.onBindData()
    },

    onBindData(pageIndex = 1) {
      let data = { StkLogID: this.stkLogId }

      return this.$post(CONFIG.searchURL, data, (datas) => {
        this.loading = false
        this.itemSource = datas
        this.gridShow = true
      })
    },

    tableRowClassName(obj) {
      let row = obj.row
      if (row.IsMergeStock) {
        return 'merge-stock-row'
      }
    },

    async onSave() {
      let ok = await this.$confirm('撤回后，库存量将会更新为列表中的库存量，是否继续？', '温馨提示', {
        confirmButtonText: '继续',
        cancelButtonText: '取消'
      })
      let vm = this
      if (ok) {
        this.$post(CONFIG.saveURL, { StkLogID: this.stkLogId }, (data, logic) => {
          if (data) {
            this.$message({ message: '撤回成功', type: 'success' })
            vm.$emit('confirm')
          } else {
            this.$message({ message: '撤回失败.' + logic.msg, type: 'error' })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.p-stock-merge-recall {
  .merge-stock-row {
    .znl-tablecell {
      // background-color: red;
      color: red;
      // color: #fff;
    }
  }
}
</style>
