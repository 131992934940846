<template>
  <!-- 采购订货 -->
  <znl-gridmodule ref="flexGrid"
    :layoutType="layoutType"
    :header="header"
    height="100%"
    :is-activity="isActivity"
    :pagination-position="pagePosition"
    :grid-show="gridShow"
    :columns="columns"
    :item-source="itemSource"
    :column-colors="columnColors"
    :row-colors="rowColors"
    :summary-columns="summaryColumns"
    :is-fields-search="isShowFiedsSearch"
    :is-multi-rows-check="true"
    :is-multi-rows-check-fixed="false"
    :search-fields="searchModel"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :role="role"
    :users="$store.state.users"
    :is-show-rightbtns="false"
    :has-znl-actions="false"
    :on-init="onInit"
    :loading="loading"
    :on-refresh="onRefresh"
    :on-search="onSearch"
    :on-save-setting="onSaveSetting"
    :on-save-config='onSaveConfig'
    :on-reset-setting="onResetSetting"
    :on-page-changed="onBindData"
    @page-changed="onBindData"
    @switch-to-hide="val=>{$emit('switch-to-hide',val)}"
    @selection-changed="d=>{this.stkstockSelectItem=d,$emit('selection-changed',d)}">

    <div slot="heading-slot">
      <znl-input form-type="input"
        placeholder="型号"
        :clearable="true"
        @keyup.enter.native="onSearch()"
        width='120px'
        size="mini"
        layout="left"
        inp-perc="100%"
        :border="true"
        v-model="searchFields.Model"
        type="text">
      </znl-input>

      <znl-input form-type="input"
        placeholder="供应商名称"
        :clearable="true"
        @keyup.enter.native="onSearch()"
        width='120px'
        size="mini"
        layout="left"
        inp-perc="100%"
        v-model="searchFields.SupplierName"
        :border="true"
        type="text">
      </znl-input>

      <znl-input form-type="select"
        placeholder="订货人"
        :clearable="true"
        @keyup.enter.native="onSearch()"
        width='120px'
        size="mini"
        layout="left"
        inp-perc="100%"
        :select-options="selectOptionsPurchaser"
        v-model="searchFields.Purchaser"
        :border="true"
        type="text">
      </znl-input>

      <znl-input form-type="datepicker"
        :disabled="false"
        placeholder="订货时间起"
        value-format="yyyy-MM-dd"
        size="mini"
        layout="left"
        width='100px'
        :border="true"
        v-model="searchFields.StartPurchaseTime"
        :clearable="true">
      </znl-input>

      <znl-input form-type="datepicker"
        :disabled="false"
        placeholder="订货时间止"
        value-format="yyyy-MM-dd"
        size="mini"
        layout="left"
        width='100px'
        :border="true"
        :clearable="true"
        v-model="searchFields.EndPurchaseTime"
        inp-perc="100%">
      </znl-input>

      <el-checkbox v-model="IsCompleted"
        @change="SetCompletedDefault"
        size="small">完成</el-checkbox>
      <el-checkbox v-model="IsUnCompleted"
        @change="SetUnCompletedDefault"
        size="small">未完成</el-checkbox>

      <znl-button style-type='mac' class="box-left-btn search-btn" :height='22' :width='50' @click="onSearch()">
        <i class="iconfont icon-search_ic"></i><span>搜索</span>
      </znl-button>

      <znl-button class='minor-self-btn'
        :height='22'
        :width='60'
        @click="onStkReverseAdd()"
        v-if="this.hasRes('Add')"
        style-type="minor">
        <i class="iconfont icon-add_btn_ic"></i><span>增加</span>
      </znl-button>

      <znl-button class='minor-self-btn'
        :height='22'
        :width='60'
        @click="onstkPurchaseImport()"
        v-if=" this.hasRes('ImportPurchase')"
        style-type="minor">
        <i class="iconfont icon-leading-in-wh_btn_ic"></i><span>导入</span>
      </znl-button>

      <znl-button class='minor-self-btn'
        :height='22'
        :width='63'
        v-if="true"
        style-type="mac"
        :btns='printznlbtns'
        @click="onstkReserveExport()"
        tip="按搜索条件">
        <i class="iconfont icon-leading-out-wh_btn_i"></i><span>导出</span>
      </znl-button>
    </div>
    <div>
      <StkStockPurchaseAdd v-if="stkPurchaseAddVisible"
        :visible="stkPurchaseAddVisible"
        ref='StkStockPurchaseAdd'
        :isRefresh="onrefreshAdd"
        :PurchaseGUID="stkPurchaseGUID"
        @confirm="onChangeEnd"
        @close="()=>{stkPurchaseAddVisible=false}">
      </StkStockPurchaseAdd>
      <stk-in-edit :title="StkInAddTitle"
        :editvisible="stkInEditVisible"
        v-if="stkInEditVisible"
        :edit-type="editType"
        @confirm="stkInEditVisible=false"
        @close="stkInEditVisible=false">
      </stk-in-edit>
    </div>
    <export-column :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="setConfigColumns"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}">
    </export-column>
    <znl-data-import
      :type="zznlImportType"
      :show="zznlShowDataImport"
      @close="zznlShowDataImport = false"
    />
  </znl-gridmodule>
</template>
<script>
import { mixin as znlMethodsMinix } from '@c_common/znlGrid/scripts/znlMethods'
import StkStockPurchaseAdd from '@c_modules/Stk/StkStockPurchaseAdd'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import StkInEdit from '@c_modules/Stk/StkInEdit'
import ExportColumn from '@c_modules/Crm/setExportColumn'
import * as urls from '~/lib/urls'
const CONFIG = {
  configURL: 'StkStockPurchase/GetConfig',
  saveConfigURL: 'StkStockPurchase/SaveConfig',
  resetConfigURL: 'StkStockPurchase/ResetConfig',
  default: null,
  searchURL: 'StkStockPurchase/Search',
  onDeleteURL: 'StkStockPurchase/DeleteStkPurchase',
  checkAllowImport: 'StkStock/checkAllowImport', // 检查是否允许导入
  DropDownURL: 'DictItem/GetItemValue',
  apiBaseUrl: urls.getErpApiBaseUrl()
}
export default {
  name: 'StkStockPurchase',
  config: CONFIG,
  mixins: [znlMethodsMinix, getCommonDataMixin],
  components: {
    StkStockPurchaseAdd,
    StkInEdit,
    ExportColumn
  },
  data () {
    return {
      isShowFiedsSearch: false,
      editType: 'edit',
      StkInAddTitle: '新增入库单',
      stkInEditVisible: false,
      stkWarningSelectItem: [],
      stkPurchaseGUID: '', // 需要传递给编辑页面的ID
      inputModel: '', // 需要查询的型号
      inputBrand: '', // 需要查询的品牌
      inputCustomer: '', // 需要查询的客户名称
      finishStk: false, // 完成备货
      stkReserveIds: [], // 需要批量操作的ID集合
      stkReserveSelectItem: [], // 选中的数据
      stkPurchaseAddVisible: false, // 是否显示备货编辑页面
      stkInAddVisible: false, // 是否显示入库页面
      onrefreshAdd: true, // 刷新添加页面
      onrefreshStkInAdd: true, // 刷新入库
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      gridShow: false,
      Status: true,
      resourceList: [],
      searchFields: {
        Model: '',
        SupplierName: '',
        Purchaser: '',
        StartPurchaseTime: '',
        EndPurchaseTime: '',
        Packaging: '',
        Brand: '',
        MakeYear: ''
      },
      IsCompleted: false,
      IsUnCompleted: true,
      selectOptionsPurchaser: [],
      Size: 30,
      loading: false,
      printznlbtns: [
        {
          name: '设置导出列',
          // iconName: 'icon-table_set_btn_n',
          click: () => {
            this.showExportColumn()
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      setSaveConfigURL: '',
      searchCondition: {}
    }
  },
  computed: {
    pageSize: {
      get () {
        return this.isMini ? 5 : this.Size
      },
      set (val) {
        this.Size = val
      }
    }
  },
  props: {
    isInit: Boolean,
    initData: {
      type: Boolean,
      default: true
    },
    isMini: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: () => {
        return '采购订货'
      }
    },
    searchModel: {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    },
    layoutType: {
      type: String,
      default: () => {
        return 'spa'
      }
    },
    isActivity: Boolean,
    pagePosition: String,
    height: {
      type: String,
      default: 'auto'
    },
    isRefresh: Boolean // 刷新页面
  },
  watch: {
    finishStk (value) {
      this.pageIndex = 1
      this.$refs.flexGrid.clearSelection()
      this.$refs.flexGrid.search()
    }
  },
  methods: {
    onInit: async function (config) {
      this.loading = true
      if (!config) {
        config = await this.isSaveStorage('stkStockPurchase', CONFIG.configURL)
      }
      // 获取模块打开或者关闭的状态
      // this.GetStatus(config.Status)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 列的初始化
      this.settingColumns(columns)
      // 操作按钮初始化
      this.settingActionBtn(columns)
      this.resourceList = config.ResourceList
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      if (this.initData) {
        await this.onBindData()
      } else {
        this.loading = false
        this.$emit('page-loading', false)
      }
    },
    // 列的初始化
    settingColumns (columns) {
      if (
        _.find(columns, function (column) {
          return column.binding === 'IsCompletedLable'
        })
      ) {
        _.extend(
          _.find(columns, function (column) {
            return column.binding === 'IsCompletedLable'
          }),
          {
            dataMap: [{ key: '0', value: '否' }, { key: '1', value: '是' }],
            showDropDown: true
          }
        )
      }

      // 订货员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (
        obj,
        index
      ) {
        return { key: obj.value, value: obj.value }
      })
      this.selectOptionsPurchaser = UserAllowUsers
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Purchaser'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 单位
      let QtyUnitDropDown = this.selectOptionsQtyUnit
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Unit'
        }),
        { dataMap: QtyUnitDropDown, showDropDown: true }
      )

      // 处理特殊权限  不看供应商资料(供应商、联系人、电话、手机、地址)
      let noSeeSupplier = this.getSpecialResourceByCode('NoSeeSupplier')
      _.each(columns, (item, index) => {
        if (noSeeSupplier && item.binding === 'SupplierName') {
          item.hasRole = false
        }
      })
    },
    // 操作按钮初始化
    settingActionBtn (columns) {
      columns.unshift({
        name: 'delete',
        dataType: 5,
        binding: 'delete',
        width: 30,
        header: '删',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '删',
            className: 'znl-table-btn-success',
            when: true,
            click: row => {
              let guid = []
              guid.push(row.SPGUID)
              this.onDeleteOne(guid)
            }
          }
        ]
      })
      columns.unshift({
        name: 'edit',
        dataType: 5,
        binding: 'edit',
        width: 30,
        header: '改',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '改',
            className: 'znl-table-btn-success',
            when: true,
            click: row => {
              this.onOpenAddDialog(row.SPGUID)
            }
          }
        ]
      })
      !this.onlySalesPurchaseOrder && columns.unshift({
        name: 'stkIn',
        dataType: 5,
        binding: 'stkIn',
        width: 30,
        header: '入',
        visible: true,
        allowSorting: false,
        isFieldsSearch: false,
        isSystem: true,
        isReadOnly: true,
        isFixed: false,
        buttons: [
          {
            content: '入',
            className: 'znl-table-btn-success',
            when: true,
            click: row => {
              let rows = []
              rows.push(row)
              this.onStkInadd(rows)
            }
          }
        ]
      })
    },
    onBindData (pageIndex = 1) {
      this.loading = true
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          IsCompleted: this.IsCompleted,
          IsUnCompleted: this.IsUnCompleted
        }
      )

      this.searchCondition = data
      this.stkWarningSelectItem = []
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.loading = false
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    // 导出
    onstkReserveExport () {

      let exportColumns = []
      let selectedColumns = _.filter(this.columns, function (item, index) {
        return (
          !item.isSystem &&
          (_.isUndefined(item.hasRole) || item.hasRole) &&
          item.IsExprot
        )
      })
      _.each(selectedColumns, item => {
        exportColumns.push({
          Name: item.binding,
          DisplayName: item.header
        })
      })
      let postData = {
        Name: 'StkStockPurchase',
        Conditions: this.searchCondition,
        Columns: exportColumns
      }
      let that = this
      this.loading = true
      this.$post('export/excel', postData, (data, logic) => {
        if (logic.code === 200) {
          let downloadUrl = `${CONFIG.apiBaseUrl}export/download?id=` + data.Id
          if (that.isClient()) {
            that.clientDownLoadFile(data.Id, downloadUrl)
          } else {
            window.open(downloadUrl) // 下载文件
          }
          that.loading = false
        }
      }).finally(e => {
        this.loading = false
      })
    },
    SetCompletedDefault () {
      if (this.IsCompleted) {
        this.IsUnCompleted = false
      }
    },
    SetUnCompletedDefault () {
      if (this.IsUnCompleted) {
        this.IsCompleted = false
      }
    },
    onHeadSearch (params) {
      this.searchFields.Model = params.Model
      this.searchFields.Packaging = params.Packaging
      this.searchFields.Brand = params.Brand
      this.searchFields.MakeYear = params.MakeYear
      return this.onBindData(1)
    },
    onSearch (params) {
      this.searchFields = _.extend({}, this.searchFields, params)
      return this.onBindData(1)
    },
    onSelectionChange (currentItem) {
      this.$emit('selection-change', currentItem)
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('stkStockPurchase')
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, CONFIG.default)
      )
    },
    onResetSetting () {
      localStorage.removeItem('stkStockPurchase')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('stkStockPurchase')
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, CONFIG.default)
      )
    },
    onStkReverseAdd () {
      this.onOpenAddDialog()
    },
    onShowFiedsSearch () {
      if (!this.isShowFiedsSearch) {
        this.isShowFiedsSearch = true
      } else {
        this.isShowFiedsSearch = false
      }
    },
    onOpenAddDialog (swID = '') {
      // 传值id值为0，显示页面
      this.stkPurchaseAddVisible = true // 显示页面
      this.onrefreshAdd = !this.onrefreshAdd
      this.stkPurchaseGUID = swID
      this.$refs.flexGrid.clearSelection()
    },
    onSelectRow () {
      // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (this.stkWarningSelectItem.length === 0) {
          // 选中行
          return this.$message({
            message: '请至少选择一条数据进行操作',
            type: 'error'
          })
        } else {
          guid.push(this.stkWarningSelectItem.SPGUID)
        }
      } else {
        guid = _.map(rows, item => {
          return item.SPGUID
        })
      }
      return guid
    },
    onStkReserveEdit (swid) {
      this.onOpenAddDialog(swid)
    },
    onstkPurchaseImport: async function () {
      // 导入
      // 先做验证，库存量已超出最大库存量，不能再导入
      let IsAllowImport = await this.$post(CONFIG.checkAllowImport)
      this.$nextTick(function () {
        if (!IsAllowImport) {
          return this.$message({
            message: '您的库存量已超出最大库存量，不能再导入',
            type: 'error'
          })
        }
        this.importData({ importType: 'stkpurchase' })
        this.clearSelectedRows()
      })
    },
    // 获取模块状态
    // GetStatus (val) {
    //   this.Status = val !== 0
    //   this.Status ? this.$refs.flexGrid.toggleTableShow(true)
    //   : this.$refs.flexGrid.toggleTableShow(false)
    // },
    // 保存模块状态
    saveStatus (val) {
      let Status = val ? 1 : 0
      if (Status !== this.defaultConfig.Status) {
        this.defaultConfig.Status = Status
        let statusConfig = this.defaultConfig
        statusConfig.ColumnConfigs = []
        this.$post(this.$options.config.saveConfigURL, statusConfig)
      }
    },
    onStkInadd (rows) {
      // 批量采购入库传值方法
      // console.log(rows, 'rows')
      this.$store.state.visibled = false
      let order = { stockLine: rows }
      this.$store.commit('setParamOrder', order)
      this.$nextTick(function () {
        this.stkInEditVisible = true
      })
    },
    onDelete (SRGuids) {
      if (SRGuids.length > 0) {
        this.$confirm('删除确认, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$post(CONFIG.onDeleteURL, { SPGUIDS: SRGuids }, data => {
            this.$refs.flexGrid.clearSelection()
            if (data > 0) {
              this.$message({ message: '删除成功', type: 'success' })
              this.onBindData(this.pageIndex)
              this.$refs.flexGrid.clearSelection()
            } else {
              return this.$message({ message: '删除失败', type: 'error' })
            }
          })
        })
      }
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page.Code === code)
    },
    onMulitDelete () {
      // 多条删除
      let SRGuids = this.onSelectRow()
      if (SRGuids.length < 1) {
        return this.$message({ message: '请选择要删除的数据', type: 'error' })
      } else {
        this.onDelete(SRGuids)
      }
    },
    clearSelectedRows () {
      this.$refs.flexGrid.clearSelection()
    },
    stkInAddRows () {
      // 批量采购入库
      let guids = this.onSelectRow()

      if (guids.length >= 1) {
        let rows = this.$refs.flexGrid.getSelectedRows()
        // 判断选中行的客户是否一致
        var lastCustomerName = rows[0].SupplierName
        var isDiff = false
        _.each(rows, r => {
          if (lastCustomerName !== r.SupplierName) {
            isDiff = true
          }
        })
        if (isDiff) {
          return this.$message({
            message: '请选择相同客户的数据进行操作',
            type: 'error'
          })
        }
        this.onStkInadd(rows)
        this.clearSelectedRows()
      } else {
        return this.$message({
          message: '至少选择一条数据进行操作',
          type: 'error'
        })
      }
    },
    onSelectRowGuid () {
      let guid = []
      let rows = this.getCheckedKeys()
      if (rows.length === 0) {
        rows = this.onSelectRow()
      }
      // console.log(rows)
      if (rows[0] + '' === 'undefined') {
        // 选中行
        return this.$message({
          message: '请至少选择一条数据进行操作',
          type: 'error'
        })
      }
      if (rows.length > 0) {
        _.each(rows, row => {
          guid.push(row.SrGUID)
        })
      }
      return guid
    },
    onDeleteOne (guid) {
      // 单条删除
      this.onDelete(guid)
    },
    onChangeEnd (val) {
      val && (this.stkPurchaseAddVisible = false)
      this.onBindData(this.pageIndex)
    },
    isClient () {
      return window.cefQuery !== undefined
    },
    showExportColumn () {
      this.setExportColumnVisible = true
      this.addColumnsList = this.columns
      this.setConfigColumns = this.defaultConfig
      this.setSaveConfigURL = this.$options.config.saveConfigURL
    },
    clientDownLoadFile (fileName, url) {
      if (!url) {
        return
      }
      this.downFile({ FileName: '采购订货.xls', FileUrl: url }, function (res) {
        // console.log(res)
        this.dialogShow = false
      })
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
</style>
