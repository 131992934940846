<template>
  <div>
    <znl-module ref="flexGrid"
                header="发运单"
                :height="height"
                :columns="columns"
                :item-source="itemSource"
                :column-colors="columnColors"
                :row-colors="rowColors"
                :summary-columns="summaryColumns"
                :is-fields-search="true"
                :is-multi-rows-check="true"
                :search-fields="searchModel"
                :page-size="pageSize"
                :page-index="pageIndex"
                :total-count="totalCount"
                :right-menus="rightMenus"
                :role="role"
                :on-init="onInit"
                :show-add-row-right-menu="false"
                :show-delete-row-right-menu="false"
                :on-refresh="onRefresh"
                :on-search="onSearch"
                :on-save-setting="onSaveSetting"
                :on-save-config='onSaveConfig'
                :on-reset-setting="onResetSetting"
                @selection-changed="d=>{this.stkoutSelectItem=d,$emit('selection-changed',d)}"
                :on-page-changed="onBindData">
      <template slot="action-buttons">
        <el-row>

          <znl-button type="default"
                      size="mini"
                      :isShow='hasRes("Edit")'
                      @click="onExpressOrderEdit">
            <i class="iconfont icon-edit_btn_ic"></i>
            <span class="ver">编辑</span>
          </znl-button>
          <znl-button type="default"
                      size="mini"
                      :isShow='hasRes("Delete")'
                      @click="onDelete">
            <i class="iconfont icon-delete_btn_ic"></i>
            <span class="ver">删除</span>
          </znl-button>

        </el-row>
      </template>
      <template slot="action-form">
        <el-row>
          <el-input size="mini"
                    v-model="fieldsFilter.inputModel"
                    placeholder="型号"
                    class="form-group"
                    @keyup.enter.native="$refs.flexGrid.search()"></el-input>
          <el-date-picker size="mini"
                          v-model="fieldsFilter.startOutOn"
                          type="date"
                          class="form-group"
                          placeholder="发货时间从">
          </el-date-picker>
          到
          <el-date-picker size="mini"
                          v-model="fieldsFilter.endOutOn"
                          type="date"
                          class="form-group"
                          placeholder="发货时间止">
          </el-date-picker>
          <znl-button size="mini"
                      type="primary"
                      :isShow='true'
                      style-type='main'
                      @click="$refs.flexGrid.search()">
            <i class="iconfont icon-search_ic"></i>搜索</znl-button>
        </el-row>
      </template>
    </znl-module>

  </div>
</template>
<script>
import { mixin as znlMethodsMinix } from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
  configURL: 'ExpressOrder/GetConfig',
  saveConfigURL: 'ExpressOrder/SaveConfig',
  resetConfigURL: 'ExpressOrder/ResetConfig',
  UserAllowCompanyUserURL: 'CompanyAccount/GetUserAllowCompanyUser',
  default: null,
  searchURL: 'ExpressOrder/Search',
  onDeleteURL: 'ExpressOrder/DeleteList'
}
export default {
  name: 'ZNLExpressOrder',
  config: CONFIG,
  mixins: [znlMethodsMinix],
  components: {
  },
  data () {
    return {
      stkOutGUID: '', // 需要传递给编辑页面的GUID
      stkoutSelectItem: [], // 选中的数据
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      fieldsFilter: {
        startOutOn: '',
        endOutOn: '',
        inputModel: ''
      },
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      resourceList: [],
      operateLogVisible: false,
      orderGuid: '',
      stkOutGUIDS: []
    }
  },
  computed: {
    rightMenus () {
      return [{
        iconName: 'columns', // Icon名称
        text: '编辑', // 显示文字
        disabled: false, // 是否禁用 Boolean/Function 默认：false
        // display: true, // 是否显示 Boolean/Function 默认：true
        display: this.hasRes('Edit'), // this.resourceList.includes('编辑发货单'),
        click: (rows) => { // click事件执行方法 s:当前的Grid,e：当行的行数据
          this.onExpressOrderEdit()
        }
      }]
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    height: String,
    searchModel:
    {
      type: Object,
      default: () => {
        return {
          data: {},
          extra: {}
        }
      }
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        // config = await this.$post(CONFIG.configURL)
        config = await this.isSaveStorage('expressOrder', CONFIG.configURL)
      }
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // 发货员
      if (_.find(columns, function (column) { return column.binding === 'Shipper' })) {
        let UserAllowUsers = await this.$post(CONFIG.UserAllowCompanyUserURL)
        if (_.isArray(UserAllowUsers.Users)) {
          let dateMap = _.map(UserAllowUsers.Users, function (obj, index) { return { 'key': obj.UserName, 'value': obj.UserName } })
          _.extend(_.find(columns, function (column) { return column.binding === 'Shipper' }),
          { dataMap: dateMap, showDropDown: true })
        }
      }
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.resourceList = config.ResourceList
      if (this.initData) {
        this.onBindData()
        if (this.hasRes('Print')) {
          this.onInitPrintTemplate()
        }
      }
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize,
        StartShipTime: this.fieldsFilter.startOutOn,
        EndShipTime: this.fieldsFilter.endOutOn,
        InputModel: this.fieldsFilter.inputModel
      }, {
          // 外部搜索条件
        StartShipTime: this.fieldsFilter.startOutOn,
        EndShipTime: this.fieldsFilter.endOutOn,
        InputModel: this.fieldsFilter.inputModel
      })
      this.stkoutSelectItem = null
      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
      })
    },
    onSelectRow () { // 获取选中的行 先判断勾选再判断选中
      let guid = []
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (!this.stkoutSelectItem || !this.stkoutSelectItem.EOGUID) { // 选中行
          return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
        } else {
          guid.push(this.stkoutSelectItem.EOGUID)
        }
      } else {
        guid = _.map(rows, item => { return item.EOGUID })
      }
      return guid
    },
    onExpressOrderEdit () {
      let rows = this.$refs.flexGrid.getSelectedRows()
      if (_.isUndefined(rows[0])) {
        if (!this.stkoutSelectItem || !this.stkoutSelectItem.StkOutGUID) {
          return this.$message({ message: '请选择一条数据', type: 'error' })
        } else {
          let order = {EOGUID: this.stkoutSelectItem.EOGUID}
          this.$store.commit('setParamOrder', order)
          this.$store.commit('removeTab', 'Ord/ExpressOrderAdd')
          this.$nextTick(function () {
            this.openTab('Ord/ExpressOrderAdd', {}) // 显示编辑页面
          })
        }
      } else if (rows.length > 1) {
        return this.$message({ message: '只能选择一条数据进行操作', type: 'error' })
      } else {
        let paramOrder = {EOGUID: this.stkoutSelectItem.EOGUID}
        this.$store.commit('setParamOrder', paramOrder)
        this.$store.commit('removeTab', 'Ord/ExpressOrderAdd')
        this.$nextTick(function () {
          this.openTab('Ord/ExpressOrderAdd', {}) // 显示编辑页面
        })
      }
    },
    onSearch (params) {
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onRefresh () {
      return this.onBindData(this.pageIndex)
    },
    onSaveSetting (columns, pageSize) {
      localStorage.removeItem('expressOrder')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.config.default))
    },
    onResetSetting () {
      localStorage.removeItem('expressOrder')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('expressOrder')
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.config.default))
    },
    onDelete () { // 批量删除
      let guid = this.onSelectRow()
      if (guid.length > 0) {
        this.$confirm('删除确认, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          return this.onPostDelete(guid)
        })
      }
    },
    onPostDelete (guid) {
      this.$post(CONFIG.onDeleteURL, { ListEOGuid: guid }, (data) => {
        if (data > 0) {
          this.$message({ message: '删除成功', type: 'success' })
          this.onBindData(this.pageIndex)
        } else if (data < 0) {
          this.$message({ message: '删除失败，包含已出库或已审核的单据', type: 'error' })
          this.onBindData(this.pageIndex)
        } else {
          this.$message({ message: '删除失败', type: 'error' })
        }
        return data
      })
    },
    onChangeEnd (val) { // 行操作改变事件
      val && (this.stkOutAddVisible = false)
      this.onRefresh()
      this.$emit('changeEnd')
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page.Code === code)
    }
  }
}
</script>

<style lang="scss">

</style>
