<template>
  <div
    class="large-order-purchase"
    :style="{height:height}"
  >
    <iframe
      id="LargeOrderPurchase-iframe-id"
      :src="iframeSrc"
      frameborder="0"
      width="100%"
      height="100%"
      ref="iframe"
      @load="onload"
    ></iframe>

  </div>
</template>

<script>
import { mixin as common } from "~assets/scripts/methods/common";
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import { ERPCOOKIENAME } from "@scripts/config";
import * as urls from "~/lib/urls";
// const BomaiApiBaseUrl  = urls.getBomaiApiBaseUrl();
const BomaiApiBaseUrl = urls.bomaiUcBaseUrl();
const CONFIG = {
  //   正式环境：https://mm.bom.ai/#/GlobalOrderBuy
  //   测试环境正式的数据：http://test.bom.ai:8080/#/GlobalOrderBuy
  //   我本地运行环境：http://192.168.1.46:8080/#/GlobalOrderBuy
  //   我本地IIS环境: http://192.168.1.46:8001/#/GlobalOrderBuy
  //  10上的环境:http://192.168.1.10:8022/#/GlobalOrderBuy
  largeOrderPurchaseUrl: `${BomaiApiBaseUrl}ErpLogin?ToRouteName=GlobalOrderBuy&isClient=1`,
};
console.log("BomaiApiBaseUrl is ", BomaiApiBaseUrl);
console.log("largeOrderPurchaseUrl is ", CONFIG.largeOrderPurchaseUrl);
export default {
  mixins: [znlMethodsMixin, getCommonDataMixin, common],
  data() {
    return {
      iframeSrc: "",
      token: "",
      onQuotePriceQuery: null,
    };
  },

  props: {
    height: {
      type: String,
      default: "100%",
    },
    LargeOrderPurchaseQuery: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  mounted() {
    this.onInit();
  },

  created() {
    var erpToken =
      this.getCookie(ERPCOOKIENAME, this.$store) || getCookie2("login_token");
    this.token = encodeURIComponent(erpToken.slice(6));
    this.iframeSrc = `${CONFIG.largeOrderPurchaseUrl}`;
  },

  methods: {
    onInit() {
      this.iframeWatchSrc();
    },

    iframeWatchSrc() {
      var iframe = document.getElementById("LargeOrderPurchase-iframe-id");

      // 监听 iframe 的加载事件
      iframe.addEventListener("load", () => {
        console.log("watch 1");
        // 在加载完成后获取新的 src 值
        if (this.onQuotePriceQuery) {
          let time = setTimeout(() => {
            this.onQuotePrice(this.onQuotePriceQuery);
            this.onQuotePriceQuery = null;
            clearTimeout(time);
          }, 2500);
        }
      });
    },

    onQuotePrice(opts) {
      console.log("onQuotePrice init")
      this.onQuotePriceQuery = opts;
      this.$refs.iframe.contentWindow.postMessage(
        JSON.stringify({
          source: "onQuotePrice",
          BGuid: opts.BGuid,
          FromBy: opts.FromBy,
        }),
        "*"
      );
    },

    onload() {
      console.log("onload 111")
      this.$refs.iframe.contentWindow.postMessage(
        JSON.stringify({
          source: "onWebQuotePrice",
          token: this.token,
        }),
        "*"
      );
    },

    getCookie(name, store) {
      if (store) {
        return store.state[name];
      }
      return "";
    },
  },
};
</script>

<style>
</style>
