<template>
  <znl-dialog :visible="visible"
              height="250px"
              width="500px"
              title="库存预警设置"
              @close="()=>{$emit('close')}">

    <el-row type="flex"
            slot="znl-dialog">
      <el-col :span="11">
        <el-row class="form_Row_W100">

          <znl-input title-width="60px"
                     form-type="input"
                     placeholder=" "
                     size="mini"
                     layout="left"
                     :is-must-fill="false"
                     title="型号："
                     width="100%"
                     :clearable="true"
                     v-model="stkWarning.Model"
                     type="text">
          </znl-input>
        </el-row>
        <el-row class="form_Row_W100">

          <znl-input title-width="60px"
                     form-type="input"
                     placeholder=" "
                     size="mini"
                     layout="left"
                     :is-must-fill="false"
                     title="品牌："
                     width="100%"
                     :clearable="true"
                     v-model="stkWarning.Brand"
                     type="text">
          </znl-input>
        </el-row>
        <el-row class="form_Row_W100">

          <znl-input title-width="60px"
                     form-type="input"
                     placeholder=" "
                     size="mini"
                     layout="left"
                     :is-must-fill="false"
                     title="最小库存量："
                     width="100%"
                     :clearable="true"
                     v-model="stkWarning.LowerQty"
                     type="text">
          </znl-input>
        </el-row>

      </el-col>
      <el-col :span="10"
              :offset="1">
        <el-row class="form_Row_W100">
          <znl-input title-width="60px"
                     form-type="input"
                     placeholder=" "
                     size="mini"
                     layout="left"
                     :is-must-fill="false"
                     title="最大库存量："
                     width="100%"
                     :clearable="true"
                     v-model="stkWarning.UpperQty"
                     type="text">
          </znl-input>
        </el-row>
        <el-row class="form_Row_W100">
          <!-- <el-col class="form_subtitle">当前库存量：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="stkWarning.CurQty"></el-input>
              </el-col> -->
          <znl-input title-width="60px"
                     form-type="input"
                     placeholder=" "
                     size="mini"
                     layout="left"
                     :is-must-fill="false"
                     title="当前库存量："
                     width="100%"
                     :clearable="true"
                     v-model="stkWarning.CurQty"
                     type="text">
          </znl-input>
        </el-row>
        <el-row class="form_Row_W100">
          <!-- <el-col class="form_subtitle">备注：</el-col>
              <el-col>
                <el-input size="mini"
                          v-model="stkWarning.Remark"></el-input>
              </el-col> -->
          <znl-input title-width="60px"
                     form-type="input"
                     placeholder=" "
                     size="mini"
                     layout="left"
                     :is-must-fill="false"
                     title="备注："
                     width="100%"
                     :clearable="true"
                     v-model="stkWarning.Remark"
                     type="text">
          </znl-input>
        </el-row>
      </el-col>
    </el-row>


     <el-row
                align="middle"
                type="flex"
                justify="end"
                slot="footer"
                class="dialog-footer">
          <el-col style="display: flex;justify-content: flex-end;align-items: center">
            <input type="checkbox"
                  id="continueSave"
                  v-model="iscontinueSave"
                  style="vertical-align: text-bottom; margin-bottom:0px;"></input>
            <label for="continueSave" style="color:red;margin-right:3px;">保存后继续操作</label>
            <el-button type="primary-rev"
                      size="mini"
                      @click="onClear"
                    class="znl-btn-auxiliary">清空</el-button>
            <el-button type="primary"
                      size="mini"
                     class="znl-btn-main"
                      @click="Save">保存</el-button>
          </el-col>
      </el-row>

  </znl-dialog>
</template>
<script>

const defaultstkWarning = {
  SWID: -1,
  Model: '',
  Brand: '',
  LowerQty: 0,
  UpperQty: 0,
  CurQty: 0,
  Remark: ''
}
export default {
  name: 'StkWarningAdd',
  components: {
  },
  data () {
    return {
      stkWarning: {// 库存预警对象
        SWID: -1,
        Model: '',
        Brand: '',
        LowerQty: 0,
        UpperQty: 0,
        CurQty: 0,
        Remark: ''
      },
      config: {
        stkWarningSearchById: 'StkStockWarning/GetStkWarningById',
        stkWarningSave: 'StkStockWarning/Save'
      },
      iscontinueSave: false
    }
  },
  props: {
    visible: Boolean,
    swid: Number, // 库存GUID
    isRefresh: Boolean, // 是否刷新
    stkModelAndBrand: String// 库存中的型号和品牌
  },
  watch: {
    swid: {
      handler (value) {
        this.LoadPage(value)
      },
      immediate: true
    },
    isRefresh: {
      handler (value) {
        if (!_.isEmpty(this.swid)) {
          this.LoadPage(this.swid)
        } else if (!_.isEmpty(this.stkModelAndBrand)) {
          this.LoadModelByStock(this.stkModelAndBrand)
        }
      },
      immediate: true
    }
    // stkModelAndBrand: {
    //   handler (value) {
    //     if (!_.isEmpty(value)) {
    //       this.LoadModelByStock(value)
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
    onClear () {
      this.onResetPage()
    },
    LoadPage (swid) {
      console.log(swid)
      this.onResetPage()
      if (swid > 0) {
        this.$post(this.config.stkWarningSearchById, { SWID: swid }, (datas) => {
          this.stkWarning = datas
        })
      }
    },
    onResetPage () { // 重置页面
      this.stkWarning.SWID = 0
      this.stkWarning.Model = ''
      this.stkWarning.Brand = ''
      this.stkWarning.LowerQty = 0
      this.stkWarning.UpperQty = 0
      this.stkWarning.CurQty = 0
      this.stkWarning.Remark = ''
    },
    LoadModelByStock (stk) { // 根据库存的型号和品牌进行复制新增
      this.onResetPage()
      this.$nextTick(function () {
        this.stkWarning.Model = stk.split(',')[0].toString()
        this.stkWarning.Brand = stk.split(',')[1].toString()
      })
    },
    Save () { // 保存
      if (this.CheckData()) {
        this.$post(this.config.stkWarningSave, this.stkWarning, (datas) => {
          if (datas > 0) {
            this.$message({ message: '保存成功', type: 'success' })
            if (!this.iscontinueSave) {
              this.onResetPage()
              this.$emit('confirm', true)
            }
          }
        })
      }
    },
    CheckData () { // 检查数据的正确性
      let ret = true
      if (this.stkWarning.Model === '') {
        ret = false
        this.$message({ message: '没有设置型号', type: 'error' })
      } else if (_.isNaN(Number(this.stkWarning.LowerQty)) || _.isNaN(Number(this.stkWarning.UpperQty)) || _.isNaN(Number(this.stkWarning.CurQty))) {
        ret = false
        this.$message({ message: '数量必须为数字类型', type: 'error' })
      } else if (Number(this.stkWarning.LowerQty) < 0 || Number(this.stkWarning.UpperQty) < 0) {
        ret = false
        this.$message({ message: '最大库存量或最小库存量不能小于零!', type: 'error' })
      } else if (Number(this.stkWarning.LowerQty) >= Number(this.stkWarning.UpperQty)) {
        ret = false
        this.$message({ message: '最大库存量不能小于最小库存量!', type: 'error' })
      }
      return ret
    }
  },
  created () {
    this.stkWarning = defaultstkWarning
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_form';
.dialog-container {
  width: 620px;
  padding: 0 0px;
  margin: 0 auto;
}

.col-label-right {
  text-align: right;
  line-height: 30px;
}

.remarkFont {
  font-size: 10px;
  font-style: italic;
  color: #C0C0C0;
}
</style>
