<template>
  <el-upload ref="upload" class="upload-demo"
    :id="uploadInputId"
    action=""
    :accept="accept"
    :limit="filesLimit"
    :http-request="handleUpload"
    :before-upload="onBeforeUpload"
    :show-file-list="false"
    :file-list="fileList">
    <znl-button style-type="mac"
      :height="22" @click="handleUploadBtnClick">
      <!-- <i class="iconfont icon-add_btn_ic"></i> -->
      <slot></slot>
    </znl-button>
  </el-upload>
</template>

<script>
import XLSX from 'xlsx'

export default {
  name: 'vue-xlsx-table',
  data () {
    return {
      rawFile: null,
      workbook: null,
      tableData: {
        header: [],
        body: []
      },
      uploadInputId: new Date().getUTCMilliseconds(),
      uploadFile: {},
      fileList: [],
      filesLimit: 1
    }
  },
  props: {
    accept: {
      type: String,
      default: '.xlsx, .xls'
    }
  },
  computed: {
    rABS () {
      // 浏览器的FileReader API 有两个方法可以读取本地文件 readAsBinaryString 和 readAsArrayBuffer, 默认rABS为false 使用readAsArrayBuffer
      // return window.xlsxEventBus.XLSX_EVENTS_DATA.options.rABS
    }
  },
  methods: {
    onBeforeUpload (file) {
      // console.log('onBeforeUpload', file)
      let extension = file.name.split('.')[1] === 'xls'
      let extension2 = file.name.split('.')[1] === 'xlsx'
      let isLt2M = file.size / 1024 / 1024 < 2 // 2MB
      if (!extension && !extension2) {
        this.$message.warning('上传文档只能是 xls、xlsx格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.warning('上传文件大小不能超过 2MB!')
      }
      this.uploadFile = file
    },
    handleUpload () {
      if (this.rawFile !== null) {
        return
      }
      // this.rawFile = e.target.files[0]
      this.rawFile = this.uploadFile
      this.fileConvertToWorkbook(this.rawFile)
        .then(workbook => {
          let xlsxArr = XLSX.utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]]
          )
          this.workbook = workbook
          this.initTable(this.xlsxArrToTableArr(xlsxArr))
        })
        .catch(err => {
          console.error(err)
        })
    },
    fileConvertToWorkbook (file) {
      let reader = new FileReader()
      let fixdata = data => {
        let o = '',
          l = 0,
          w = 10240
        for (; l < data.byteLength / w; ++l) {
          o += String.fromCharCode.apply(
            null,
            new Uint8Array(data.slice(l * w, l * w + w))
          )
        }
        o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
        return o
      }
      return new Promise((resolve, reject) => {
        try {
          reader.onload = renderEvent => {
            let data = renderEvent.target.result
            if (this.rABS) {
              /* if binary string, read with type 'binary' */
              resolve(XLSX.read(data, { type: 'binary' }))
            } else {
              /* if array buffer, convert to base64 */
              let arr = fixdata(data)
              resolve(XLSX.read(btoa(arr), { type: 'base64' }))
            }
          }
          reader.onerror = error => {
            reject(error)
          }
          if (this.rABS) {
            reader.readAsBinaryString(file)
          } else {
            reader.readAsArrayBuffer(file)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    xlsxArrToTableArr (xlsxArr) {
      let tableArr = []
      let length = 0
      let maxLength = 0
      let maxLengthIndex = 0
      xlsxArr.forEach((item, index) => {
        length = Object.keys(item).length
        if (maxLength < length) {
          maxLength = length
          maxLengthIndex = index
        }
      })
      let tableHeader = Object.keys(xlsxArr[maxLengthIndex])
      let rowItem = {}
      xlsxArr.forEach(item => {
        rowItem = {}
        for (let i = 0; i < maxLength; i++) {
          rowItem[tableHeader[i]] = item[tableHeader[i]] || ''
        }
        tableArr.push(rowItem)
      })
      return {
        header: tableHeader,
        data: tableArr
      }
    },
    tableArrToXlsxArr ({ data, header }) {
      let xlsxArr = []
      let tempObj = {}
      data.forEach(rowItem => {
        tempObj = {}
        rowItem.forEach((item, index) => {
          tempObj[header[index]] = item
        })
        xlsxArr.push(tempObj)
      })
      return xlsxArr
    },
    initTable ({ data, header }) {
      this.tableData.header = header
      this.tableData.body = data
      this.$emit('on-select-file', this.tableData)
    },
    handleUploadBtnClick () {
      this.clearAllData()
    },
    clearAllData () {
      this.$refs.upload.clearFiles()
      this.tableData = {
        header: [],
        body: []
      }
      this.rawFile = null
      this.workbook = null
    }
  }
}
</script>

<style lang="scss">
</style>
